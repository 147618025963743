import { Text } from 'react-native';
import { colors } from '../../styles/colors';

const Header = ({ 
    text,
    level=1,
    color = colors.amplifyNeutral90,
    marginTop = 0,
    margin = 0,
    marginBottom=0,
    marginLeft=0,
    marginRight=0,
    padding=10,
    textAlign="left",
    fontWeight="500",
    fontStyle="normal"
}) => {
    return (
        <Text style={{
            fontSize: level * 17,
            color: color,
            padding:padding,
            margin: margin,
            marginTop: marginTop,
            marginBottom: marginBottom,
            marginLeft: marginLeft,
            marginRight: marginRight,
            textAlign: textAlign,
            fontWeight: fontWeight,
            fontStyle: fontStyle
        }}>
            {text}
        </Text>
    );
};

export default Header;