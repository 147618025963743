import React, { useContext, useEffect, useState } from "react";
import { useNavigation } from "@react-navigation/native";
import { Image, Platform, Pressable, StyleSheet, Text, View, useWindowDimensions } from "react-native";
import Flex from "../CustomElements/Flex";
import Badge from "../CustomElements/Badge";
import Header from "../CustomElements/Header";
import { useSelector } from "react-redux";
import getEnvVars from '../../../environment';
import { colors } from "../../styles/colors";
import SpaceAmenitiesMini from "./AmenitiesMiniView";
import { ReviewViewMini } from "../review/ReviewViewMini";
import { globalStyles } from "../../styles/styles";
import TextTruncate from "../CustomElements/TextTruncate";
import { UserDataContext } from "../../utils/UserContextProvider";
import CustomButton from "../CustomElements/CustomButton";
import { Entypo } from "@expo/vector-icons";
import { updateUserMutation } from "../../api/user";
import { Animated } from "react-native";
import FixedHeightCard from "../CustomElements/FixedHeightCard";
const { cloudfrontURL, REACT_APP_ENV } = getEnvVars();

const GoToSpaceDetailsScreen = ({ mobileView, children, propertyID }) => {
  const navigation = useNavigation();

  const goToSpacePage = () => {
    navigation.navigate("explore", {
      screen: "space",
      params: {
        propertyID: propertyID
      },
    });
  };


  return (
    <>
      {Platform.OS !== 'web' || mobileView ? (
        <Pressable onPress={() => goToSpacePage()}>
          {children}
        </Pressable>
      ) : (
        <a href={__DEV__ ? `http://localhost:19006/space/${propertyID}` : REACT_APP_ENV === "development" ? `https://staging.d18w937drvm0l.amplifyapp.com/space/${propertyID} ` : `https://www.hookhub.co/space/${propertyID}`} target="_blank" style={styles.hyperLink}>
          {children}
        </a >
      )}
    </>
  )
}

const PropertyDetails = ({ address, title, priceShowing, width, space, reviews, property, setSelectedType, selectedType, mobileView }) => {
  const viewWidth = useWindowDimensions().width
  return (
    <Flex textAlign="left" justifyContent="space-between" alignItems="space-between" alignContent="space-between" direction="row" width={width} marginTop={mobileView ? 1 : 5}>
      <View style={{ flexWrap: "wrap", flex: 1, width: width - 10 }}>
        <GoToSpaceDetailsScreen mobileView={mobileView} propertyID={property.id}>
          <View style={[globalStyles.flexStartColumn, { width: width }]}>
            <View style={globalStyles.spaceBetween}>
              <TextTruncate>{address}</TextTruncate>
              {!!reviews.length && <ReviewViewMini reviews={reviews} />}
            </View>
            <TextTruncate>{title}</TextTruncate>
          </View>
        </GoToSpaceDetailsScreen>
        <View style={[globalStyles.flexStart, { width: Platform.OS === 'web' ? width : viewWidth, flexWrap: "wrap", marginTop: mobileView ? 3 : 5, marginBottom: 4 }]}>
          {property.type_shortterm && <Pressable onPress={() => setSelectedType('vacation')}><Badge onPress={() => setSelectedType('vacation')} noWidth={mobileView} width={"90%"} margin={mobileView ? 2 : 1} padding={mobileView ? 1 : 2} paddingLeft={9} paddingRight={9} variation={selectedType === 'vacation' ? "warning" : "info"}><Text>Nightly</Text></Badge></Pressable>}
          {property.type_longterm && <Pressable onPress={() => setSelectedType('monthly')}><Badge onPress={() => setSelectedType('monthly')} noWidth={mobileView} width={"90%"} margin={mobileView ? 2 : 1} padding={mobileView ? 1 : 2} paddingLeft={9} paddingRight={9} variation={selectedType === 'monthly' ? "warning" : "info"}><Text>Monthly</Text></Badge></Pressable>}
          {property.type_yearly && <Pressable onPress={() => setSelectedType('type_yearly')}><Badge onPress={() => setSelectedType('type_yearly')} noWidth={mobileView} width={"90%"} margin={mobileView ? 2 : 1} padding={mobileView ? 1 : 2} paddingLeft={9} paddingRight={9} variation={selectedType === 'type_yearly' ? "warning" : "info"}><Text>Yearly</Text></Badge></Pressable>}
          {property.type_storage && <Pressable onPress={() => setSelectedType('storage')}><Badge onPress={() => setSelectedType('storage')} noWidth={mobileView} width={"90%"} margin={mobileView ? 2 : 1} padding={mobileView ? 1 : 2} paddingLeft={9} paddingRight={9} variation={selectedType === 'storage' ? "warning" : "info"}><Text>Storage</Text></Badge></Pressable>}
        </View>
        <View style={[globalStyles.spaceBetween, { width: width - 10, marginTop: 3 }]}>
          <View style={globalStyles.flexStart}>
            <Header level={1.15} padding={0} text={`$ ${priceShowing}`}></Header><Text> night</Text>
          </View>
          <SpaceAmenitiesMini space={space} width={90} />
        </View>
      </View>
    </Flex>
  )
};

const CardBody = ({
  displayImage,
  mobileView,
  width,
  height,
  property,
  setSelectedType,
  selectedType,
  space,
  reviews,
  priceShowing,
  userData
}) => {
  const imLoggedIn = useContext(UserDataContext);
  const scaleValue = new Animated.Value(1)
  const [favorited, setFavorited] = useState(null);

  const checkForFavorite = async () => {
    const { favorites } = userData.userData;
    if (!!favorites && !!favorites.length) {
      const favoritesCheck = favorites.findIndex((x => x === property.id));
      favoritesCheck !== -1 ? setFavorited(true) : setFavorited(false)
    }
  };

  const updateFavorite = async (v) => {
    const { favorites } = userData.userData;
    const setUserData = imLoggedIn.user.setUserData;
    const updateUserData = (data) => setUserData(data);
    let favsArray = favorites !== null ? [...favorites] : [];
    setFavorited(v);

    if (!!v) {
      favsArray.push(property.id);
    } else {
      favsArray = favsArray.filter(x => x !== property.id);
    }
    let data = {
      id: userData.sub,
      favorites: favsArray,
    }
    let updatedUser = await updateUserMutation({ currentUserData: userData, data })
    if (updatedUser) {
      updateUserData(userData)
    }
  };

  const zoomIn = () => {
    Animated.timing(scaleValue, {
      toValue: 1.3,
      duration: 400,
      useNativeDriver: true,
    }).start()
  }

  const zoomOut = () => {
    Animated.timing(scaleValue, {
      toValue: 1.1,
      duration: 200,
      useNativeDriver: true,
    }).start()
  }

  useEffect(() => {
    !!imLoggedIn && checkForFavorite();
  }, [imLoggedIn]);

  return (
    <View style={[globalStyles.centerColumn]}>
      <View>
        <GoToSpaceDetailsScreen mobileView={mobileView} propertyID={property.id}>
          {mobileView ? (
            <Image
              source={{ uri: displayImage }}
              style={{ width: width, height: height - 10, borderRadius: 8, justifyContent: "flex-start", alignContent: "flex-start", alignItems: "flex-start" }}
            />
          ) : (
            <Pressable onHoverIn={() => zoomIn()} onHoverOut={() => zoomOut()}>
              <View style={{ width: width - 10, height: height - 10, overflow: "hidden", borderRadius: 8 }}>
                <Animated.Image
                  source={{ uri: displayImage }}
                  resizeMode="stretch"
                  style={{ transform: [{ scale: scaleValue }], width: "100%", height: "100%", overflow: "hidden", borderRadius: 8 }}
                >
                </Animated.Image>
              </View>
            </Pressable>
          )}
          <View style={{ position: "absolute", top: 10, left: 5 }}>
            <View style={{ flexDirection: 'row' }}>
              {space.instant_book && <Badge width={Platform.OS === 'web' ? "100%" : 120} variation={"white"} ><Text>instant book</Text></Badge>}
            </View>
          </View>
        </GoToSpaceDetailsScreen>
        {imLoggedIn && (
          <View style={{ position: "absolute", top: 10, right: 15 }}>
            {!!favorited ? (
              <CustomButton
                width={50}
                height={30}
                hideBorder
                backgroundColor={"#fff"}
                border={colors.amplifyNeutral90}
                onPress={() => updateFavorite(false)}
              >
                <Entypo name="heart" size={25} color={colors.orange} style={{ position: "absolute" }} />
              </CustomButton>
            ) : (
              <CustomButton
                width={50}
                height={30}
                hideBorder
                backgroundColor={"#fff"}
                border={colors.amplifyNeutral90}
                onPress={() => updateFavorite(true)}
              >
                <Entypo name="heart-outlined" size={30} color={colors.white} />
                <Entypo name="heart" size={26} color={[colors.amplifyNeutral60]} style={{ position: "absolute", opacity: .5 }} />
              </CustomButton>
            )}
          </View>
        )}
      </View>
      <PropertyDetails
        address={property?.address ? `${property?.address?.cityName.toUpperCase()}, ${property?.address?.canadianCivicAddress ? property?.address?.canadianCivicAddress : property?.address?.stateName}` : ''}
        title={property.title || space.title}
        priceShowing={priceShowing}
        width={mobileView ? width : width - 20}
        height={height}
        reviews={reviews || []}
        property={property}
        space={space}
        setSelectedType={(v) => setSelectedType(v)}
        selectedType={selectedType}
        mobileView={mobileView}
      />
    </View >
  )
}

function Property({ property, userData = null, showCardBackground }) {
  const { width } = useWindowDimensions();
  const { mobileView } = useSelector(state => state.currentUser);
  const cardWidth = width < 550 ? width / 1.15 : width > 1000 ? width / 3.3 : width / 2.3;
  const cardHeight = width > 800 ? cardWidth / 1.3 : cardWidth;
  const { stayType } = useSelector(state => state.currentUser);
  const [displayImage, setDisplayImage] = useState(null);
  const [loaded, setLoaded] = useState(null);
  const [selectedType, setSelectedType] = useState(null);
  const [priceShowing, setPriceShowing] = useState(null);
  let space = property.spaces?.items[0];
  const reviews = property?.reviews?.items

  const fetchImagePreview = async (image) => {
    setDisplayImage(`${cloudfrontURL}${image}`)
    setLoaded(true)
  };

  useEffect(() => {
    property && property?.images.length && fetchImagePreview(property.images[0]);
  }, [property?.images]);

  useEffect(() => {
    if (stayType) {
      setSelectedType(stayType)
    } else {
      if (property.type_shortterm) {
        setSelectedType('vacation')
      } else if (property.type_longterm) {
        setSelectedType('monthly')
      } else if (property.type_yearly) {
        setSelectedType('type_yearly')
      } else { setSelectedType('storage') }
    }
  }, [stayType]);

  useEffect(() => {
    let price;
    if (property) {
      if (selectedType === "vacation") {
        price = space?.price_shortterm
      } else if (selectedType === "monthly") {
        price = space?.price_longterm
      } else if (selectedType === "type_yearly") {
        price = space?.price_yearly
      } else { price = space?.price_storage }
      price = Number.isInteger(price) ? price : Number(price).toLocaleString("en-US", { minimumFractionDigits: 2 })
      setPriceShowing(price);
    }
  }, [selectedType, property]);

  return (
    <>
      {!!loaded && displayImage && space && Object.keys(space).length &&
        <>
          {showCardBackground || width < 550 ? (
            <FixedHeightCard backgroundColor={colors.amplifyNeutral20} width={cardWidth + 10} height={cardHeight + 140} marginLeft={5} marginTop={20} marginBottom={5} marginRight={5}>
              <CardBody
                displayImage={displayImage}
                mobileView={mobileView}
                width={cardWidth}
                height={cardHeight}
                userData={userData}
                property={property}
                setSelectedType={(v) => setSelectedType(v)}
                selectedType={selectedType}
                space={space}
                reviews={reviews}
                priceShowing={priceShowing}
              />
            </FixedHeightCard>
          ) : (
            <View style={{ height: cardHeight + 105, margin: 7, marginBottom: 25 }}>
              <CardBody
                displayImage={displayImage}
                mobileView={mobileView}
                width={cardWidth}
                height={cardHeight}
                userData={userData}
                property={property}
                setSelectedType={(v) => setSelectedType(v)}
                selectedType={selectedType}
                space={space}
                reviews={reviews}
                priceShowing={priceShowing}
              />
            </View>
          )}
        </>
      }
    </>
  );
}

export default Property;

const styles = StyleSheet.create({
  title: {
    marginBottom: 10,
    marginTop: 5,
    color: colors.amplifyNeutral80,
    textAlign: "left",
    marginLeft: 10,

  },
  hyperLink: {
    textDecorationLine: "none"
  },
})
