import {
    View,
    Image,
} from 'react-native';
import TextBody from '../CustomElements/TextBody';
import { useSelector } from 'react-redux';
import { globalStyles } from '../../styles/styles';
import getEnvVars from '../../../environment';

const ReviewDisplay = ({
    reviewerAvatar,
    reviewerText,
    reviewerSignature,
    width
}) => {
    const { mobileView } = useSelector(state => state.currentUser);
    const { cloudfrontURLStaticImages } = getEnvVars();

    return (
        <View style={[globalStyles.centerColumn, { width: width, marginBottom: 40, marginTop: 20 }]}>
            <TextBody style={{ width: width / 1.3, fontStyle: "italic", fontSize: 16 }}>"{reviewerText}"</TextBody>
            <View style={[globalStyles.centerColumn, {}]}>
                {reviewerAvatar && (
                    <Image
                        style={{ width: 65, height: (65), margin: mobileView ? 2 : 5, borderRadius: 100 }}
                        source={{ uri: `${cloudfrontURLStaticImages}${reviewerAvatar}` }}
                    />
                )}
                <TextBody>-{reviewerSignature}</TextBody>
            </View>
        </View>
    )
}

export default ReviewDisplay;
