import { View, Text } from "react-native";
import { LoadingComponent } from "../LoadingComponent";
import Header from "../CustomElements/Header"
import CustomButton from "../CustomElements/CustomButton";
import { colors } from "../../styles/colors";
import { globalStyles } from "../../styles/styles";

const ReserveButton = ({ userData = null, handleSubmit, setSignUpModalOpen, loading, disabled = false }) => {
    if (!!loading) {
        return (
            <>
                <CustomButton
                    width={90}
                    height={50}
                    border={colors.brandGrey}
                >
                    <LoadingComponent />
                </CustomButton>
            </>
        )
    } else {
        return (
            <>
                <CustomButton
                    width={90}
                    height={40}
                    backgroundColor={colors.orange}
                    borderRadius={8}
                    border={colors.hookhubOrangeDark}
                    disabled={disabled}
                    onPress={e => userData === null ? setSignUpModalOpen() : handleSubmit(e)}
                >
                    <Text style={{ fontSize: 17 }}>Reserve</Text>
                </CustomButton>
            </>
        )
    }
};

const ReserveCardBodyMobile = ({
    userData = null,
    spaceTypes,
    error,
    activeTabIndex,
    setActiveTabIndex,
    setSignUpModalOpen,
    width,
    signUpModalOpen,
    prepareToReserve,
    loading
}) => {

    return (
        <View style={[globalStyles.center, { backgroundColor: "#fff", width: width, paddingBottom: "7vh" }]}>
            <View style={[globalStyles.spaceBetween, { width: width/1.05, marginVertical: 10 }]}>
                <View style={globalStyles.centerColumn}>
                    {spaceTypes.map((v, i) => (
                        <View key={i}>
                            {activeTabIndex === i ? (
                                <CustomButton
                                    width={85}
                                    marginBottom={1}
                                    height={22}
                                    backgroundColor={colors.orange}

                                    onPress={(e) => setActiveTabIndex(i)}
                                >
                                    <Text style={{ fontSize: 14 }}>{v.title}</Text>
                                </CustomButton>
                            ) : (
                                <CustomButton
                                    width={85}
                                    marginBottom={1}
                                    height={22}
                                    backgroundColor={colors.brandGrey}

                                    onPress={(e) => setActiveTabIndex(i)}
                                >
                                    <Text style={{ fontSize: 14 }}>{v.title}</Text>
                                </CustomButton>
                            )}
                        </View>
                    ))}
                </View>
                <View style={globalStyles.centerColumn}>
                    <Header padding={0} level={1.2} text={`$ ${spaceTypes[activeTabIndex].price}`} />
                    <Text> night</Text>
                </View>
                <View style={[globalStyles.flexEnd, { alignItems: "center" }]}>
                    <ReserveButton
                        width={width}
                        userData={userData}
                        setSignUpModalOpen={() => setSignUpModalOpen(!signUpModalOpen)}
                        error={error}
                        handleSubmit={(e) => prepareToReserve()}
                        loading={loading}
                    />
                </View>
            </View>
        </View>
    );
};


export default ReserveCardBodyMobile;
