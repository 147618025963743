import React, { useState, useEffect } from "react";
import { StyleSheet, useWindowDimensions, Text } from "react-native";
import HostOnboarding from "../../components/host/HostOnboarding";
import Flex from "../../components/CustomElements/Flex"
import { useDispatch, useSelector } from "react-redux";
import { setSpacePrices } from "../../redux/actions/HostOnboardingActions";
import SetPrice from "../../components/space/SetPrice";
import CustomButton from "../../components/CustomElements/CustomButton";
import { ServiceFeeExplanationModal } from "../../components/booking/ServiceFeeExplanationModal";


function SetPriceScreen() {
  const dispatch = useDispatch();
  const {
    price_storage,
    price_longterm,
    price_shortterm,
    type_shortterm,
    type_longterm,
    type_storage,
    type_yearly,
    price_yearly,
    newProperty
  } = useSelector((state) => state.hostOnboarding);
  const { width } = useWindowDimensions();
  const [amountStorage, setamountStorage] = useState(price_storage);
  const [amountLongTerm, setamountLongTerm] = useState(price_longterm);
  const [amountYearly, setamountYearly] = useState(price_yearly);
  const [amountShortTerm, setamountShortTerm] = useState(price_shortterm);
  const [showLocalPrices, setShowLocalPrices] = useState(false);
  const shortermSelected = type_shortterm;
  const longtermSelected = type_longterm;
  const storageSelected = type_storage;
  const yearlySelected = type_yearly;

  const setPrices = () => {
    dispatch(
      setSpacePrices({
        price_storage: amountStorage,
        price_shortterm: amountShortTerm,
        price_longterm: amountLongTerm,
        price_yearly: amountYearly,
      })
    );
  };

  return (
    <HostOnboarding
      heading="Set your Price"
      navigateF="SetThingsKnow"
      navigateB="SetDimensions"
      nextButtonText="Next"
      onClickNext={() => setPrices()}
    >
      {
        shortermSelected && (
          <SetPrice
            val={amountShortTerm}
            setVal={(v) => setamountShortTerm(v)}
            title="Short Term"
          />
        )
      }
      {
        longtermSelected && (
          <SetPrice
            val={amountLongTerm}
            setVal={(v) => setamountLongTerm(v)}
            title="Monthly"
            subtext="for stays at least 1 month long"
            showMonthlyPrice={true}
          />
        )
      }
      {
        yearlySelected && (
          <SetPrice
            val={amountYearly}
            setVal={(v) => setamountYearly(v)}
            title="Yearly"
            subtext="for stays at least 1 year long"
            showMonthlyPrice={true}
            showYearPrice={true}
          />
        )
      }
      {
        storageSelected && (
          <SetPrice
            val={amountStorage}
            setVal={(v) => setamountStorage(v)}
            title="Storage"
            subtext="$ per day price"
            showMonthlyPrice={true}
          />
        )
      }
      <Flex marginBottom={3} alignContent="center" justifyContent="center" alignItems="center" marginTop={4}>
        <Text style={{ textAlign: "center", fontSize: 16 }}>Unsure about pricing? Check out the prices at your local RV parks</Text>
      </Flex>
      <ServiceFeeExplanationModal />
    </HostOnboarding >
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    flexDirection: "column",
    backgroundColor: "#808080",
    padding: 70,
  },
  textBox: {
    alignContent: "center",
    alignItems: "center",
    justifyContent: "center",
    margin: 50,
  },
  text: {
    color: "white",
    fontSize: 33,
    fontWeight: "bold",
    textAlign: "center",
  },
  buttonSection: {
    flex: 1,
    flexDirection: "row",
    justifyContent: "center",
  },
  backButtonText: {
    color: "#343434",
    fontSize: 20,
    fontWeight: "bold",
  },
  backButton: {
    alignItems: "center",
    borderBottomWidth: 2,
    justifyContent: "center",
    alignSelf: "center",
    height: 40,
    marginTop: 25,
    marginLeft: 25,
  },
  forwardButtonText: {
    color: "#616A6B",
    fontSize: 20,
  },
  forwardButton: {
    borderColor: "#616A6B",
    borderRadius: 10,
    width: 100,
    borderWidth: 1,
    backgroundColor: "#fff",
    alignItems: "center",
    borderBottomWidth: 2,
    justifyContent: "center",
    alignSelf: "center",
    height: 40,
    marginTop: 25,
    marginLeft: 25,
  },
  disabledButtonText: {
    color: "#99A3A4",
    fontSize: 20,
  },
  disabledButton: {
    borderColor: "#616A6B",
    borderRadius: 10,
    width: 100,
    borderWidth: 1,
    backgroundColor: "#616A6B",
    alignItems: "center",
    borderBottomWidth: 2,
    justifyContent: "center",
    alignSelf: "center",
    height: 40,
    marginTop: 25,
    marginLeft: 25,
  },
});

export default SetPriceScreen;
