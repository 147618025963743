import React, { useState, useEffect, useContext } from "react";
import { Image, StyleSheet, View, Text } from "react-native";
import { useNavigation } from "@react-navigation/native";
import { useDispatch, useSelector } from "react-redux";
import { LoadingComponent } from "../LoadingComponent";
import { API } from "aws-amplify";
import { getUser as getUserQuery } from "../../graphql/queries";
import { addNewConvo, updateActiveConvo } from "../../redux/actions/ConversationsActions";
import { Ionicons } from '@expo/vector-icons';
import { UserDataContext } from "../../utils/UserContextProvider";
import getEnvVars from '../../../environment';
import UserDetailsModal from "../user/UserDetailsModal";
import { colors } from "../../styles/colors"
import CustomButton from "../CustomElements/CustomButton";
import { UUID } from "../../utils/UUID";
import { ReviewView } from "../review/ReviewView";
import { createConversationMutation, listConversationsForPropertyQuery } from "../../api/conversations";
import { globalStyles } from "../../styles/styles";
const { cloudfrontURL } = getEnvVars();

const ContactView = ({
  closeModal = null,
  hosteeID,
  ownerID,
  spaceID,
  propertyID,
  propertyTitle,
  spaceTitle,
  spaceCity,
  spaceState,
  contactToHost,
  userDataPresent = null,
  request = true,
  showButtons = true,
  compact = false,
  viewWidth = 150,
  centerView = true
}) => {
  const navigation = useNavigation();
  const dispatch = useDispatch();
  const { mobileView } = useSelector(state => state.currentUser);
  const { user } = useContext(UserDataContext);
  const { userData } = user;
  const [loading, setLoading] = useState(true);
  const [userDetails, setUserDetails] = useState(null);
  const [rigDetails, setrigDetails] = useState(null);
  const [showDetails, setShowDetails] = useState(false);
  const [userAvatar, setUserAvatar] = useState(null);
  const [mySpace, setMySpace] = useState(false);
  const [otherMembersEmail, setOtherMembersEmail] = useState('');
  const [membersNames, setMembersNames] = useState(null);
  const [error, setError] = useState(false);
  const [contactText, setContactText] = useState('');

  const getUser = async () => {
    if (hosteeID === ownerID) { setMySpace(true) } else {
      const user = await API.graphql({
        query: getUserQuery,
        variables: { id: contactToHost ? ownerID : hosteeID },
        authMode: "API_KEY",
      });
      const data = user.data && user.data.getUser;
      if (data) {
        setUserDetails(data);
        !!data?.avatar ? setUserAvatar(`${cloudfrontURL}${data.avatar}`) : setUserAvatar(null);
        !!data?.rig?.items.length ? setrigDetails(data.rig.items[0]) : setrigDetails(null);
        setOtherMembersEmail(data.email);
        setMembersNames([data.name, userData.name]);
      } else {
        setError(true)
      }
      setLoading(false)
    }
  };

  const setPresentData = () => {
    !!userDataPresent?.avatar ? setUserAvatar(`${cloudfrontURL}${userDataPresent.avatar}`) : setUserAvatar(null)
    setUserDetails(userDataPresent);
    setOtherMembersEmail(userDataPresent.email);
    setLoading(false)
    !!userDataPresent?.rig?.items.length ? setrigDetails(userDataPresent.rig.items[0]) : setrigDetails(null);
    setMembersNames([userDataPresent.name, userData.name]);
  };

  const navigateToMsgs = ({ convoID }) => {
    navigation.reset({
      index: 0,
      routes: [
        {
          name: "messages",
          params: convoID
        }
      ],
    })
    setLoading(false)
  }

  const createConvo = async () => {
    const partialAddress = `${spaceCity}, ${spaceState}`;
    const ImTheHost = ownerID == userData.sub;
    // TEMP fix to halt communications to TRISH
    let owner = ownerID === "fbc3a2bc-a6e9-4b25-bda8-bcfa86c85fd0" ? "77e508f7-a289-44d0-8888-2c2052a8ae49" : ownerID;
    let otherEmail = otherMembersEmail === "rtbear60@gmail.com" ? "cayleesheaharrington@gmail.com" : otherMembersEmail;
    const data = {
      id: UUID(),
      type: 'Conversation',
      hostee: hosteeID,
      host: owner,
      unreadHost: !ImTheHost,
      unreadHostee: !!ImTheHost,
      otherMembersEmail: otherEmail,
      convoCreatorsEmail: userData.email,
      propertyTitle: propertyTitle,
      propertyLocation: partialAddress,
      propertyID: propertyID,
      spaceID: spaceID,
      spaceTitle: spaceTitle,
      members: [hosteeID, owner],
      membersNames: membersNames
    };

    const newConvo = await createConversationMutation({ data });
    if (!!newConvo) {
      dispatch(addNewConvo({
        convo: newConvo,
        unreadBoolean: false
      }));
      navigateToMsgs({ convoID: newConvo.id });
    } else {
      console.log("error creating conversation: ")
      setError(true)
    }
  }

  const contactUser = async () => {
    setLoading(true)
    const conversations = await listConversationsForPropertyQuery({ ownerID, hosteeID, propertyID });
    if (conversations) {
      const items = conversations?.items;
      let convoExists = items?.length && items[0];
      !!closeModal && closeModal();
      convoExists ? navigateToMsgs({ convoID: convoExists.id }) : createConvo();
    }
  };

  useEffect(() => {
    setLoading(true);
    setrigDetails(null)
    setUserAvatar(null)
    setContactText(contactToHost ? "Hosted by " : request ? "Requested by " : "");
    !userDataPresent ? getUser() : setPresentData();
  }, [hosteeID, ownerID]);

  return (
    <View style={[centerView ? globalStyles.centerColumn : globalStyles.flexStart, { flexWrap: "wrap", width: viewWidth, marginTop: 20 }]}>
      {loading ? (
        <LoadingComponent />
      ) : (
        <>
          {!!userDetails && (
            <>
              <View style={[centerView ? globalStyles.center : globalStyles.flexStart, { width: 120, flexWrap: "wrap" }]}>
                {!!userAvatar ? (
                  <View style={{ marginBottom: 25, marginLeft: centerView ? "-10%" : "5%" }}>
                    {!!rigDetails && rigDetails?.image ? (
                      <>
                        <Image
                          style={[styles.avatarImage, { width: 105, height: 105, }]}
                          source={{ uri: userAvatar }}
                        />
                        <View style={{ position: "absolute", top: 55, left: 55 }}>
                          <Image
                            style={[styles.avatarImage, { width: 75, height: 75 }]}
                            source={{ uri: `${cloudfrontURL}${rigDetails.image}` }}
                          />
                        </View>
                      </>
                    ) : (
                      <Image
                        style={[styles.avatarImage, { width: 105, height: 105 }]}
                        source={{ uri: userAvatar }}
                      />
                    )}
                  </View>
                ) : (
                  <Ionicons name="person-circle" size={65} color="grey" />
                )}
                <View style={centerView ? globalStyles.centerColumn : globalStyles.flexStartColumn}>
                  <View style={[{ marginTop: 5, flexWrap: "wrap" }]}>
                    <View style={[{ width: viewWidth, flexWrap: "wrap", flexDirection: "row", justifyContent: centerView ? "center" : "flex-start", margin: 5 }]}>
                      {!compact && <Text>{contactText}</Text>}
                      <Text>{userDetails.name}</Text>
                    </View>
                    {!contactToHost && userDetails?.reviewsOfRenter?.items.length ? (
                      <ReviewView reviews={userDetails.reviewsOfRenter.items} />
                    ) : (<></>)}
                  </View>
                  {showButtons && (
                    <View style={[{ width: viewWidth, flexWrap: "wrap", margin: 5, flexDirection: "row", justifyContent: centerView ? "center" : "flex-start" }]}>
                      <CustomButton
                        width={135}
                        backgroundColor={colors.amplifyNeutral20}
                        onPress={() => setShowDetails(true)}
                      >
                        <Text>{`About ${contactToHost ? "Host" : "Renter"}`}</Text>
                      </CustomButton>
                      <CustomButton
                        width={135}
                        backgroundColor={colors.amplifyNeutral20}
                        onPress={() => contactUser()}
                      >
                        <Text>Contact</Text>
                      </CustomButton>
                    </View>
                  )}
                </View>
              </View>
              <UserDetailsModal
                mobileView={mobileView}
                modalVisible={showDetails}
                setModalVisible={() => setShowDetails(!showDetails)}
                userDetails={userDetails}
                userAvatar={userAvatar}
                contactText={contactText}
                myRig={rigDetails}
              />
            </>
          )}
        </>
      )}
    </View>
  )
}

export default ContactView;

const styles = StyleSheet.create({
  avatarImage: {
    borderRadius: 100,
    borderWidth: 1,
  },
});
