import { updateUser } from "../graphql/mutations";
import { getUser } from "../graphql/queries";
import { API } from "aws-amplify";

export const getUserDetails = async (userID) => {
    const user = await API.graphql({
        query: getUser,
        variables: { id: userID },
        authMode: "API_KEY",
    })
    return user.data.getUser;
};

export const updateUserMutation = async ({ currentUserData, data }) => {
    let userData = {
        ...currentUserData.userData,
        ...data
    }
    let updatedUser = await API.graphql({
        query: updateUser,
        variables: { input: data },
        authMode: "AMAZON_COGNITO_USER_POOLS",
    }).then((r) => {
        let updatedUserData = {
            ...currentUserData, 
            userData
        }
        return updatedUserData;
    }).catch((e) => {
        console.log("e in here >>  ", e)
        return false;
    })
    return updatedUser;
};
