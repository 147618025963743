import { StyleSheet, useWindowDimensions, Pressable, Text, View } from "react-native";
import Flex from "../CustomElements/Flex"
import { AntDesign } from "@expo/vector-icons";
import CustomDivider from "../CustomDivider";
import CustomModal from "../CustomElements/CustomModal";
import { colors } from "../../styles/colors";
import { useState } from "react";
import { globalStyles } from "../../styles/styles";

export default function PrivacyPolicyModal({ }) {
    const { width } = useWindowDimensions();
    const [privacyPolicyOpen, setPrivacyPolicyOpen] = useState(false);

    return (
        <View>

            <View style={globalStyles.center}>
                <Pressable onPress={() => setPrivacyPolicyOpen(true)}>
                    <Text style={{ color: colors.amplifyNeutral90 }}>
                        Privacy
                    </Text>
                </Pressable>
            </View>
            <CustomModal modalVisible={privacyPolicyOpen} setModalVisible={() => setPrivacyPolicyOpen(!privacyPolicyOpen)} modalWidth={width / 1.15}>
                <Flex
                    alignContent="center"
                    justifyContent="center"
                    alignItems="center"
                    direction="column"
                    backgroundColor={colors.amplifyNeutral60}
                    marginTop={30}
                    width={width / 1.3}
                >
                    <Text style={styles.titleText} marginBottom={2}>Privacy Policy</Text>
                    <CustomDivider fullWidth={true} />
                    <Text style={styles.marginVertical}>Last updated: May 2023</Text>
                    <Text>
                        Hookhub, Inc. values you as our customer and recognizes that privacy is important to you. This Privacy Statement explains how we collect, use, and disclose data when you use our platform and associated services, your rights in determining what we do with the information that we collect or hold about you and tells you how to contact us.
                        We are a community built on trust. A fundamental part of earning that trust means being clear about how we use your information and protecting your human right to privacy.
                    </Text>
                    <Text style={styles.subText} marginBottom={2}>Personal Information</Text>
                    <Text style={styles.marginBottom}>
                        When you use our platform, we may collect the following kinds of personal information from you as needed:
                    </Text>
                    <Flex width={width / 1.3} direction="column" marginLeft={4}>
                        <Text>
                            {`\u2022 ${"Name, email address, telephone number. Any billing data, including billing addressees, will be collected and secured by the Stripe platform. Hookhub has no access to any of your Payment information such as payment card number, expiration date, billing address, and financial account number"}`}
                        </Text>
                        <Text>
                            {`\u2022 ${"Government issued Identification required for booking or identity verification, such as passport, driver’s license, government redress numbers, and country of residence (for travel insurance purposes), and for vacation property owners, tax identification number"}`}
                        </Text>
                        <Text>
                            {`\u2022 ${"Geolocation"}`}
                        </Text>
                        <Text>
                            {`\u2022 ${"Searches you conduct, transactions, and other interactions with you on our online services and Apps"}`}
                        </Text>
                    </Flex>
                    <Text style={styles.subText} marginBottom={2}>Permissions for Location-Based Services</Text>
                    <Text>Depending on your device’s settings and permissions and your choice to participate in certain programs, we may collect the location of your device by using GPS signals, cell phone towers, Wi-Fi signals, Bluetooth or other technologies. We will collect this information, if you opt in through the app or other program (either during your initial login or later) to enable certain location-based services available within the app (for example, locating available spaces closest to you). To disable location capabilities of the app, you can log off or change your mobile device’s settings.</Text>
                    <Text style={styles.subText} marginBottom={2}>Use of Personal Information</Text>
                    <Text style={styles.marginBottom}>We use your personal information for various purposes described below.</Text>
                    <Flex width={width / 1.3} direction="column" marginLeft={4}>
                        <Text>
                            {`\u2022 ${"Book the requested space or enable space booking."}`}
                        </Text>
                        <Text>
                            {`\u2022 ${"Provide services related to the space and/or account"}`}
                        </Text>
                        <Text>
                            {`\u2022 ${"Create, maintain, and update user accounts on our platform and authenticate you as a user"}`}
                        </Text>
                        <Text>
                            {`\u2022 ${"Enable and facilitate acceptance and processing of payments, coupons, and other transactions"}`}
                        </Text>
                        <Text>
                            {`\u2022 ${"Administer loyalty and rewards programs"}`}
                        </Text>
                        <Text>
                            {`\u2022 ${"Collect and enable space-related reviews"}`}
                        </Text>
                    </Flex>
                    <Text style={styles.subText} marginBottom={2}>Communications and Marketing</Text>
                    <Flex width={width / 1.3} direction="column" marginLeft={4}>
                        <Text>
                            {`\u2022 ${"Contact you (such as by text message, email, phone calls, mail, push notifications, or messages on other communication platforms) to provide information like space booking confirmations and updates, for marketing purposes, or for other purposes as described in this Privacy Statement"}`}
                        </Text>
                        <Text>
                            {`\u2022 ${"Enable communication between you and the hosted space owners"}`}
                        </Text>
                        <Text>
                            {`\u2022 ${"Respond to your questions, requests for information, and process information choices"}`}
                        </Text>
                    </Flex>
                    <Text style={styles.subText} marginBottom={2}>Other Business Purposes and Compliance</Text>
                    <Flex width={width / 1.3} direction="column" marginLeft={4}>
                        <Text>
                            {`\u2022 ${"Conduct surveys, market research, and data analytics"}`}
                        </Text>
                        <Text>
                            {`\u2022 ${"Maintain, improve, research, and measure the effectiveness of our sites and apps, activities, tools, and services"}`}
                        </Text>
                        <Text>
                            {`\u2022 ${"Monitor or record chats, and other communications with our customer service team and other representatives, as well as platform communications between or among partners and travelers for quality control, training, dispute resolution, and as described in this Privacy Statement"}`}
                        </Text>
                        <Text>
                            {`\u2022 ${"Promote security, verify identity of our customers, prevent and investigate fraud and unauthorized activities, defend against claims and other liabilities, and manage other risks"}`}
                        </Text>
                        <Text>
                            {`\u2022 ${"Comply with applicable laws, protect our and our users’ rights and interest, defend ourselves, and respond to law enforcement, other legal authorities, and requests that are part of a legal process"}`}
                        </Text>
                        <Text>
                            {`\u2022 ${"Comply with applicable security and anti-terrorism, anti-bribery, customs and immigration, and other such due diligence laws and requirements"}`}
                        </Text>
                        <Text>
                            {`\u2022 ${"Operate our business using lawful business purposes and as permitted by law"}`}
                        </Text>
                    </Flex>
                    <Text style={styles.subText} marginBottom={2}>Lawful bases for processing:</Text>
                    <Text style={{ marginBottom: 2 }}>We will collect personal information from you only (i) where the personal information is necessary to perform a contract with you (e.g., manage your booking, process payments, or create an account at your request), (ii) where the processing is in our legitimate interests and not overridden by your rights (as explained below), or (iii) where we have your consent to do so (e.g., sending you marketing communications where consent is required). In some cases, we will have a legal obligation to collect personal information from you such as where it is necessary to use your transaction history to complete our financial and tax obligations under the law.</Text>
                    <Text style={styles.marginBottom}>If we ask you to provide personal information to comply with a legal requirement or to perform a contract with you, we will make this clear at the relevant time and advise you whether the provision of your personal information is mandatory or not (as well as of the possible consequences if you do not provide your personal information). Certain countries and regions allow us to process personal information on the basis of legitimate interests. If we collect and use your personal information in reliance on our legitimate interests (or the legitimate interests of any third-party), this interest will typically be to operate or improve our platform and communicate with you as necessary to provide our services to you, for security verification purposes when you contact us, to respond to your queries, undertaking marketing, or for the purposes of potentially detecting or preventing illegal activities.</Text>
                    <Text style={styles.subText} marginBottom={2}>Your Rights and Choices</Text>
                    <Text style={styles.marginBottom}>You have certain rights and choices with respect to your personal information, as described below:</Text>
                    <Flex width={width / 1.3} direction="column" marginLeft={4}>
                        <Text>
                            {`\u2022 ${"If you have an account with us, you may change your communication preferences by either (1) logging in and updating the information in your account or contacting Hookhub via the help screen."}`}
                        </Text>
                        <Text>
                            {`\u2022 ${"You can access, amend, inquire about deletion of, or update the accuracy of your information at any time by either logging into your account or contacting us."}`}
                        </Text>
                        <Text>
                            {`\u2022 ${"If you no longer wish to receive marketing and promotional emails, you may unsubscribe by clicking the ‘unsubscribe’ link in the email. Please note that if you choose to unsubscribe from or opt out of marketing emails, we may still send you important transactional and account-related messages from which you will not be able to unsubscribe"}`}
                        </Text>

                        <Text>
                            {`\u2022 ${"If we are processing your personal information on the basis of consent, you may withdraw that consent at any time by contacting us. Withdrawing your consent will not affect the lawfulness of any processing that occurred before you withdrew consent and it will not affect our processing of your personal information that is conducted in reliance on a legal basis other than consent"}`}
                        </Text>
                        <Text>
                            {`\u2022 ${"We respond to all requests we receive from individuals wanting to exercise their personal data protection rights in accordance with applicable data protection laws. Should you have the right to appeal a decision to not take action on a request under applicable law, instructions on how to make that appeal will be included in our response to you."}`}
                        </Text>
                    </Flex>
                    <Text style={styles.subText} marginBottom={2}>Security</Text>
                    <Text style={styles.marginBottom}>We want you to feel confident about using our platform and all associated tools and services, and we are committed to taking appropriate steps to protect the information we collect. While no company can guarantee absolute security, we do take reasonable steps to implement appropriate physical, technical, and organizational measures to protect the personal information that we collect and process.</Text>
                    <Text style={styles.subText} marginBottom={2}>Contact Us</Text>
                    <Text style={styles.marginBottom}>If you have any questions or concerns about our use of your personal information, or wish to inquire about our personal information handling practices, and exercise your rights to access, correct or inquire about deletion of personal information, please contact us via the help screen contact form.</Text>
                    <Text style={styles.subText} marginBottom={2}>Updates to Statement</Text>
                    <Text style={styles.marginBottom}>We may update this Statement in response to changing laws or technical or business developments. If we propose to make any material changes, we will notify you by means of a notice on this page. You can see when this Privacy Statement was last updated by checking the “last updated” date displayed at the top of this Statement.</Text>

                    <Pressable onPress={() => setPrivacyPolicyOpen(false)}>
                        <Flex width={width / 1.3} marginBottom={8} marginTop={20} justifyContent="center" alignContent="center" alignItems="center">
                            <AntDesign marginRight={9} name="closecircleo" size={28} color="black" />
                            <Text>Close</Text>
                        </Flex>
                    </Pressable>
                </Flex>
            </CustomModal>
        </View>
    );
}

const styles = StyleSheet.create({
    container: {
        flex: 1,
        alignItems: "center",
        justifyContent: "center",
        padding: 20,
    },
    modalView: {
        margin: 20,
        alignContent: "center",
        justifyContent: "center",
        alignItems: "center",
        backgroundColor: "grey",
        borderRadius: 20,
        padding: 35,
        shadowColor: "#000",
        shadowOffset: {
            width: 0,
            height: 2,
        },
        shadowOpacity: 0.25,
        shadowRadius: 4,
        elevation: 5,
    },
    title: {
        fontSize: 20,
        fontWeight: "bold",
    },
    link: {
        marginTop: 15,
        paddingVertical: 15,
    },
    titleText: {
        fontSize: 24,
        fontWeight: "bold",
        marginBottom: 2
    },
    subText: {
        fontSize: 17,
        fontWeight: "bold",
        marginTop: 6
    },
    marginBottom: {
        marginBottom: 4
    },
    marginVertical: {
        marginTop: 8,
        marginBottom: 4
    }
});
