import {
  StyleSheet,
  Pressable,
  Text,
  View
} from "react-native";
import Flex from "../CustomElements/Flex"
import Header from "../CustomElements/Header"
import { Ionicons, MaterialCommunityIcons } from "@expo/vector-icons";
import CustomDivider from "../CustomDivider";
import { colors } from "../../styles/colors";

export const DetailsReviewsAndStatus = ({ userState }) => {
  return (
    <Flex direction="column">
      <Header level={1} text="Reviews"></Header>
      <Flex>
        <Text>no reviews yet</Text>
        {/* <Button
            width="50%"
            marginBottom={8}
            onPress={() => alert("click")}
          >
            My reviews
          </Button> */}
      </Flex>
      <CustomDivider />
      <Header level={1} text="Account status"></Header>
      <Flex alignItems="center"  >
        <Text style={{ fontWeight: "bold", marginRight: 15 }}>Email: </Text>
        {userState.email_verified ? (
          <Flex alignItems="center" >
            <Ionicons name="checkmark-circle" size={24} color="grey" />
            <Text>Confirmed</Text>
          </Flex>
        ) : (
          <Pressable onPress={() => alert("nav to confirm this")}>
            <Flex>
              <MaterialCommunityIcons
                name="alert-box"
                size={24}
                color={colors.orange}
              />
              <Text>Unconfirmed</Text>
            </Flex>
          </Pressable>
        )}
      </Flex>
      {/* <Flex>
        <View width={30}>
          <Text>Phone Number: </Text>
        </View>
        <View>
          {userState.phone_number_verified ? (
            <Flex>
              <Ionicons name="checkmark-circle" size={24} color="grey" />
              <Text>Confirmed</Text>
            </Flex>
          ) : (
            <Pressable onPress={() => alert("nav to confirm this")}>
              <Flex>
                <MaterialCommunityIcons
                  name="alert-box"
                  size={24}
                  color={colors.orange}
                />
                <Text color={colors.orange} textDecoration="underline">
                  Unconfirmed
                </Text>
              </Flex>
            </Pressable>
          )}
        </View>
      </Flex> */}
    </Flex>
  );
};

const styles = StyleSheet.create({
  button: {
    borderRadius: 20,
    padding: 10,
    elevation: 2,
  },
  cancelbutton: {
    alignItems: "center",
    justifyContent: "center",
    paddingVertical: 1,
    paddingHorizontal: 32,
    borderRadius: 4,
    elevation: 3,
    backgroundColor: "white",
  },
  canceltext: {
    fontSize: 16,
    lineHeight: 21,
    fontWeight: "bold",
    letterSpacing: 0.25,
    color: "black",
  },
  buttonSection: {
    flexDirection: "row",
    justifyContent: "center",
    marginVertical: 4,
  },
  backButtonText: {
    color: "#484a4a",
    fontSize: 20,
    fontWeight: "bold",
  },
  backButton: {
    alignItems: "center",
    borderBottomWidth: 2,
    justifyContent: "center",
    alignSelf: "center",
    height: 40,
  },
  forwardButtonText: {
    color: "#fff",
    fontSize: 20,
  },
  forwardButton: {
    borderColor: "#616A6B",
    borderRadius: 10,
    width: 100,
    borderWidth: 1,
    backgroundColor: "#065d8c",
    alignItems: "center",
    borderBottomWidth: 2,
    justifyContent: "center",
    alignSelf: "center",
    height: 40,
    marginLeft: 40,
  },
});
