import React, { useState, useEffect } from "react";
import {
    View as ReactView,
    Text
} from "react-native";
import Flex from "../CustomElements/Flex"
import Header from "../CustomElements/Header"
import { CustomSimpleToggleButton } from "../CustomElements/CustomSimpleToggleButton";
import SelectField from "../CustomElements/SelectField";

const hours = [
    "1",
    "2",
    "3",
    "4",
    "5",
    "6",
    "7",
    "8",
    "9",
    "10",
    "11",
    "12"
];

export const TimePicker = ({ type, time, updateTime, subText = null, enabled, width, mobileView }) => {
    const timeDayString = time.slice(-2);
    const timeString = time.slice(0, -2);
    const [hour, sethour] = useState(timeString);
    const [amORpm, setamORpm] = useState(timeDayString || 'PM');

    useEffect(() => {
        if (hour && amORpm) {
            updateTime((hour + amORpm).toString())
        }
    }, [hour, amORpm]);

    return (
        <Flex direction="coloumn" textAlign="center" justifyContent="center" alignContent="center" alignItems="center" marginTop={10} width={mobileView ? width / 1.35 : width / 1.6}>
            <Header text={type}></Header>
            <Flex direction={mobileView ? "column" : "row"} textAlign="center" justifyContent="center" alignContent="center" alignItems="center" width={mobileView ? width / 1.35 : width / 1.6}>
                {!!subText && <Text>{subText}</Text>}
                <ReactView style={{ width: 100 }}>
                    <SelectField
                        options={hours}
                        label="hour"
                        placeholder="hour"
                        value={hour}
                        onChange={(e) => sethour(e)}
                    />
                </ReactView>
                <Flex direction={"row"} textAlign="center" justifyContent="center" alignContent="center" alignItems="center" width={mobileView ? width / 1.35 : width / 1.6}>
                    <CustomSimpleToggleButton
                        isPressed={amORpm === 'AM'}
                        onChange={() => setamORpm('AM')}
                        width={60}
                        value={'AM'}
                    />
                    <CustomSimpleToggleButton
                        isPressed={amORpm === 'PM'}
                        onChange={() => setamORpm('PM')}
                        width={60}
                        value={'PM'}
                    />
                </Flex>
            </Flex>
        </Flex>
    );
};
