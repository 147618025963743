import React from 'react';
import { View, Dimensions } from 'react-native';

const Flex = ({
    children,
    alignContent = "flex-start",
    textAlign = "left",
    alignItems = "flex-start",
    justifyContent = "flex-start",
    width="100%",
    marginTop = 0,
    marginBottom=0,
    marginLeft=0,
    marginRight=0,
    direction = "row",
    padding=0,
    wrap='nowrap'
}) => {
    return (
            <View style={{
                width:width,
                alignContent: alignContent,
                textAlign: textAlign,
                alignItems: alignItems,
                justifyContent: justifyContent,
                marginLeft: marginLeft,
                marginRight: marginRight,
                marginTop: marginTop,
                marginBottom: marginBottom,
                flexDirection: direction,
                flexWrap:wrap,
                padding:padding
            }}>
                {children}
            </View>
    );
};

export default Flex;