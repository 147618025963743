
export const formatDate = (dateString) => {
    const newDate = `${dateString.slice(5, 7)}/${dateString.slice(8, 10)}/${dateString.slice(0, 4)}`;
    return newDate
};

export const formatMonthDay = (dateString) => {
    const newDate = `${dateString.slice(5, 7)}/${dateString.slice(8, 10)}`;
    return newDate
};

export const formatDay = (dateString) => {
    const newDate = `${dateString.slice(8, 10)}`;
    return newDate
};

export const formatYear = (dateString) => {
    const newYear = `${dateString.slice(0, 4)}`;
    return newYear
};