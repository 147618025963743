import React from "react";
import { View, Text } from "react-native";
import CustomButton from "./CustomButton";
import { colors } from "../../styles/colors";


export const CustomIconToggleButton = ({ amenityType, updateAmenityType, icon, largeSize=true, selectedColor=colors.vibrantBlue, amenityTitle }) => {

    return (
        <CustomButton
            width={largeSize ? 105 : 70}
            height={largeSize ? 105 : 70}
            margin={6}
            marginBottom={12}
            padding={1}
            backgroundColor={amenityType ? selectedColor : colors.amplifyNeutral20}
            onPress={() => updateAmenityType()}
        >
            <View style={{
                alignItems: "center",
                textAlign: "center",
                justifyContent: "center",
                textAlign: "center",
                alignContent: "center",
                padding: 8
            }}>
                {icon}
                {amenityTitle ? (
                    <Text style={{ textAlign: "center", fontSize: 14, marginTop: largeSize ? 4 : 0, color: amenityType ? colors.black : colors.amplifyNeutral80 }}>
                        {amenityTitle}
                    </Text>
                ) : <></>}
            </View>
        </CustomButton>
    )
};