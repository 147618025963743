import React from 'react';
import { Text } from 'react-native';
import { colors } from '../../styles/colors';
import CustomButton from './CustomButton';

const ViewDetailsButton = ({ mobileView=false, onPress, cancelled=false, buttonText="View Details", width=150 }) => {
    return (
        <CustomButton
            width={width}
            height={45}
            marginTop={10}
            backgroundColor={cancelled ? colors.brandGrey : colors.white}
            textAlign="center"
            margin={2}
            onPress={() => onPress()}
        >
            <Text style={{ fontSize: mobileView ? 17 : 18, fontWeight: "400"}}>{buttonText}</Text>
        </CustomButton>
    );
};

export default ViewDetailsButton;
