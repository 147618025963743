import React, { useEffect, useState, useRef, useContext } from "react";
import {
  TextInput,
  StyleSheet,
  TouchableOpacity,
  View,
  ScrollView,
  Animated,
  Pressable,
  Platform,
  Text
} from "react-native";
import { KeyboardAccessoryView } from "react-native-keyboard-accessory";
import { Ionicons, MaterialIcons } from "@expo/vector-icons";
import { MsgList } from "./Message";
import { UserDataContext } from "../../utils/UserContextProvider";
import { UUID } from "../../utils/UUID";
import { createMessageMutation, getConversationDetails, updateConversationMutation } from "../../api/conversations";
import { globalStyles } from "../../styles/styles";
import { colors } from "../../styles/colors";
import { sendNewMessageEmail } from "../../lambdaFunctions/sendEmail/sendNewMessageEmail";
import { useDispatch, useSelector } from "react-redux";
import { updateUnreadMsgs } from "../../redux/actions/ConversationsActions";
import { LoadingComponent } from "../LoadingComponent";

const SelectedConvo = ({ convoID, width, userId, authorName, authorAvatar, mobileView, goBack }) => {
  const scrollViewRef = useRef();
  const dispatch = useDispatch();
  const { user } = useContext(UserDataContext);
  const { userData } = user;
  const { sub, email } = userData;
  const { unreadBoolean } = useSelector((state) => state.conversations);
  const [inputValue, setInputValue] = useState("");
  const [loading, setLoading] = useState(false);
  const [messages, setMessages] = useState([]);
  const [currentConvo, setCurrentConvo] = useState({});
  const [otherMembersEmail, setOtherMembersEmail] = useState({});
  const [inputHeight, setInputHeight] = useState(45);

  async function createMsg() {
    let data = {
      id: UUID(),
      authorID: userId,
      authorName: authorName,
      authorAvatar: authorAvatar,
      otherMembersEmail: otherMembersEmail,
      content: inputValue,
      convoID: convoID,
      type: "message"
    }
    const newMsg = await createMessageMutation({ data })
    setInputValue("")
    setInputHeight(45)

    if (newMsg) {
      setMessages([...messages, newMsg]);
      let data = currentConvo.host === sub ?
        {
          id: convoID,
          unreadHostee: true
        } : {
          id: convoID,
          unreadHost: true
        }
      setLoading(false)
      updateConvo({ data });
      sendNewMessageEmail({ emailAddress: otherMembersEmail, authorName })
    } else {
      setLoading(false)
    }
  };

  async function getConversation() {
    let conversation = await getConversationDetails({ convoID });
    if (!!conversation) {
      setCurrentConvo(conversation);
      email === conversation.convoCreatorsEmail ? setOtherMembersEmail(conversation.otherMembersEmail) : setOtherMembersEmail(conversation.convoCreatorsEmail);
      setMessages(conversation?.messages?.items);
      let data = conversation.host === sub ?
        {
          id: convoID,
          unreadHost: false
        } : {
          id: convoID,
          unreadHostee: false
        }
      setLoading(false)
      updateConvo({ data });
    }
  };

  const updateConvo = async ({ data }) => {
    let conversation = await updateConversationMutation({ data });
    if (conversation) {
      dispatch(updateUnreadMsgs({
        unreadBoolean: false,
        updatedConvo: conversation
      }))
    }
  };

  const onChangeTextMobile = (v) => {
    let newChar = v.charAt(v.length - 1)
    if (isNaN(newChar) || v.length === 0 || newChar === " ") {
      setInputValue(v)
    }
  };

  const handleKeyDownWeb = (e) => {
    if (e.nativeEvent.key == "Backspace") {
      let updated = inputValue.slice(0, -1)
      setInputValue(updated)
    } else if (e.nativeEvent.key == "Enter") {
      createMsg()
    } else if (e.nativeEvent.key == "Meta" || e.nativeEvent.key == "Shift" || e.nativeEvent.key == "CapsLock" || e.nativeEvent.key == "ArrowRight" || e.nativeEvent.key == "ArrowLeft") {
      return;
    } else if (isNaN(e.key) || e.length === 0 || e.key === " ") {
      let updated = inputValue + e.key;
      setInputValue(updated)
    }
  };

  useEffect(() => {
    getConversation();
  }, [convoID, unreadBoolean]);

  useEffect(() => {
    setLoading(true)
  }, [convoID]);

  return (
    <>
      {mobileView && (
        <View style={{ width: 60 }}>
          <Pressable onPress={() => goBack()} style={{ margin: 8 }}>
            <Ionicons
              name="chevron-back-circle-sharp"
              size={35}
              color="#b1b1b3"
            />
          </Pressable>
        </View>
      )}
      {loading ? (<View style={{ flex: 1 }}><LoadingComponent /></View>) : (
        <ScrollView
          ref={scrollViewRef}
          onContentSizeChange={() => scrollViewRef.current.scrollToEnd({ animated: true })}
          style={{ width: mobileView ? width : width * .6, paddingBottom: "25%" }}
        >
          <View style={{ width: mobileView ? width : width * .6 }}>
            {!!messages.length && (<MsgList messages={messages} myID={userId} mobileView={mobileView} width={width} />)}
          </View>
        </ScrollView>
      )}
      {!messages.length && <View style={[globalStyles.center, { margin: 30 }]}><Text style={{ color: colors.amplifyNeutral60 }}>no messages yet</Text></View>}
      {mobileView ? (
        <>
          <Animated.View
            style={{
              position: 'sticky',
              bottom: 0,
              right: 0,
              width: "100%",
              borderRadius: 12,
              borderColor: colors.amplifyNeutral60,
              height: "10%",
              borderWidth: .1,
              borderBottomWidth: 1.5,
            }}
          >
            <KeyboardAccessoryView
              style={({ backgroundColor: "#fff", width: width, paddingTop: 5, marginBottom: 5 })}
              alwaysVisible
              avoidKeyboard
              hideBorder
            >
              <View style={[globalStyles.flexStart, { backgroundColor: "#fff", height: inputHeight + 50, alignContent: "center" }]}>
                <TextInput
                  onContentSizeChange={e => setInputHeight(e.nativeEvent.contentSize.height)}
                  multiline
                  onChangeText={onChangeTextMobile}
                  value={inputValue}
                  placeholder={"message..."}
                  placeholderTextColor={"#9D9FA3"}
                  style={[styles.mobileInput, { height: inputHeight, marginBottom: 10 }]}
                />
                <TouchableOpacity
                  onPress={() => createMsg()}
                  style={{ opacity: inputValue ? 1 : 0.5, margin: 5 }}
                  disabled={!inputValue}
                >
                  <MaterialIcons
                    style={styles.send}
                    name="send"
                    size={22}
                    color="white"
                  />
                </TouchableOpacity>
              </View>
            </KeyboardAccessoryView>
          </Animated.View>
        </>
      ) : (
        <Animated.View
          style={{
            position: 'sticky',
            bottom: 0,
            right: 0,
            width: "100%",
            borderRadius: 12,
            borderColor: colors.amplifyNeutral60,
            height: 50,
            borderWidth: .1,
            borderBottomWidth: 1.5,
            marginTop: 20,
            backgroundColor: "#fff",
            paddingBottom: inputHeight
          }}
        >
          <KeyboardAccessoryView
            style={({ backgroundColor: "#FFF", width: width, paddingTop: 5 })}
            alwaysVisible
            avoidKeyboard
            hideBorder
          >
            <View style={[globalStyles.flexStart, { backgroundColor: "#fff", height: 100 + inputHeight, alignContent: "center", width: mobileView ? width : width * .56, marginTop: 5, paddingBottom: inputHeight }]}>
              <TextInput
                multiline
                onContentSizeChange={e => setInputHeight(e.nativeEvent.contentSize.height)}
                onKeyPress={handleKeyDownWeb}
                value={inputValue}
                placeholder={"message..."}
                placeholderTextColor={"#9D9FA3"}
                style={[styles.input, { height: inputHeight }]}
              />
              <TouchableOpacity
                onPress={() => createMsg()}
                style={{ opacity: inputValue ? 1 : 0.5, marginTop: 5 }}
                disabled={!inputValue}
              >
                <MaterialIcons
                  style={styles.send}
                  name="send"
                  size={22}
                  color="white"
                />
              </TouchableOpacity>
            </View>
          </KeyboardAccessoryView>
        </Animated.View>
      )}
    </>
    // {/* {isIphoneX && !isVisibleKeyboard && (
    //   <View style={{ height: 30 }} />
    // )} */}
  );
};

export default SelectedConvo;

const styles = StyleSheet.create({
  footer: {
    position: "fixed",
    borderWidth: .02,
    borderRadius: 12,
    width: "100%",
    backgroundColor: colors.white,
    flexDirection: "row",
    alignItems: "flex-start",
    bottom: 0,
    paddingTop: 5
  },
  container: {
    flexDirection: 'column',
    flexGrow: 1,
    justifyContent: "space-between"
  },
  input: {
    borderRadius: 12,
    flex: 1,
    paddingTop: 8,
    paddingHorizontal: 12,
    paddingBottom: 10,
    fontSize: 15,
    flexGrow: 1,
    color: "#262626",
    backgroundColor: "#F2F3F5",
    marginLeft: 10,
  },
  mobileInput: {
    borderRadius: 12,
    paddingBottom: Platform.OS === "android" ? 6 : 6,
    paddingTop: Platform.OS === "android" ? 5 : 8,
    paddingHorizontal: 12,
    marginLeft: 5,
    fontSize: 15,
    flexGrow: 1,
    color: "#262626",
    backgroundColor: "#F2F3F5",
  },
  inputOpen: {
    borderRadius: 8,
    borderWidth: 0,
    paddingBottom: Platform.OS === "android" ? 6 : 6,
    paddingTop: Platform.OS === "android" ? 5 : 8,
    paddingHorizontal: 12,
    fontSize: 15,
    flexGrow: 1,
    color: "#262626",
    backgroundColor: "#F2F3F5",
    marginBottom: 5,
    justifyContent: "flex-start",
    alignItems: "flex-start",
    alignSelf: "flex-start",
    alignContent: "flex-start",
    marginLeft: 10
  },
  send: {
    backgroundColor: colors.brightBlue,
    padding: 7,
    borderRadius: 15,
    marginLeft: 10
  },
});
