import React from "react";
import { StyleSheet, Image, View, Platform } from "react-native";

function LoadingScreen() {

  return (
    <View style={styles.container}>
      {Platform.OS === 'web' && (
        <Image
          style={{ width: 250, height: 65 }}
          source={require("../../assets/images/hookhub-logo_mini.png")}
        />
      )}
    </View>
  )
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    alignContent: "center",
    justifyContent: "center",
    alignItems: "center",
    textAlign: "center",
  },
  logoImage: {
    height: 100,
    justifyContent: "center",
    width: 400,
    margin: 10,
    marginTop: 15,
  },
  image: {
    flex: 1,
    justifyContent: "center"
  },
  title: {
    color: "white",
    fontSize: 30,
    lineHeight: 120,
    fontWeight: "bold",
    textAlign: "left",
    backgroundColor: "#000000c0"
  },
  buttonText: {
    color: "#000000c0",
    fontSize: 16,
    fontWeight: "bold",
  },
  button: {
    alignItems: 'center',
    backgroundColor: '#fff',
    borderRadius: 10,
    justifyContent: 'center',
    alignSelf: 'center',
    height: 40,
    marginTop: 25,
    marginLeft: 25,
    width: 200,
  },
});

export default LoadingScreen;