export const capitalizeFirstLetter = (str) => {
    if (str.length > 45) {
        return false
    }
    const myArray = str.split(" ");
    const capitalizedArr = [];
    myArray.forEach(element => {
        capitalizedArr.push(element.charAt(0).toUpperCase() + element.slice(1))
    });
    return capitalizedArr.join(" ")
}

export const validateNumbersOnly = (val) => {
    const myArray = val.split("");
    const numArr = [];
    myArray.forEach((element, i) => {
        var ascii = element.charCodeAt(i);
        if (ascii < 48 || ascii > 57) {
            return false;
        } else { numArr.push(element) }
    });
    if (myArray.length > 5) { return false }
    return numArr.join("")
}