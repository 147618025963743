
import React, { useEffect, useState } from 'react';
import { StyleSheet, Text, View, Dimensions, useWindowDimensions, useCallback } from "react-native";
import Flex from "../../components/CustomElements/Flex"
import { useDispatch, useSelector } from 'react-redux';
import HostOnboarding from '../../components/host/HostOnboarding';
import Geocode from "react-geocode";
import { setSpaceCoordinates } from '../../redux/actions/HostOnboardingActions';
import MapCustomView from '../../components/space/MapView';

function LocationConfirmationScreen() {
  const state = useSelector(state => state.hostOnboarding);
  const dispatch = useDispatch();
  const { width } = useWindowDimensions();
  const addressUSA = `${state.cityName} ${state.stateName} ${state.countryName} ${state?.zipcode}`
  const addressCanada = `${state.cityName} ${state.canadianCivicAddress}`
  const partialAddress = !!state.canadianCivicAddress ? addressCanada : addressUSA;
  const fullAddress = `${state.address} ${partialAddress}`;
  const [coordinates, setCoordinates] = useState({ lat: 0, lng: 0 });
  const [addressNotFound, setAddressNotFound] = useState(false);
  const [address, setAddress] = useState(fullAddress);
  Geocode.setLocationType("ROOFTOP");

  const fetchLatLng = () => {
    Geocode.fromAddress(address).then(
      (response) => {
        const result = response.results[0];
        if (result.partial_match) {
          setAddressNotFound(true);
        }
        setCoordinates(result.geometry.location);
      },
      (error) => {
        setAddressNotFound(true);
        console.error(error);
        setAddress(partialAddress);
      }
    );
  };

  const onMarkerDragEnd = (e) => {
    const lat = e.latLng.lat();
    const lng = e.latLng.lng();
    setCoordinates({ lat, lng })
  };

  const setLatLng = () => {
    dispatch(setSpaceCoordinates({
      coordinates: coordinates
    }));
  };

  useEffect(() => {
    fetchLatLng();
  }, [address])

  return (
    <HostOnboarding
      heading="Confirm your address"
      subtext="Your address will not be shared until a guest is booked. You can move the marker if you dont want to pinpoint the exact location."
      navigateF="PhotoUpload"
      navigateB="SpaceLocation"
      nextButtonText="Next"
      onClickNext={() => setLatLng()}
    >
      <Flex
        alignContent="center"
        alignItems="center"
        justifyContent="center"
        direction='column'
      >
        {addressNotFound && (
          <View style={styles.subtextBox}>
            <Text style={styles.subtext}>Exact address not showing, move marker to approximate location</Text>
          </View>
        )}
        {!!coordinates.lat && (
          <MapCustomView
            markerMovable={(v) => onMarkerDragEnd(v)}
            space={{ coordinates_lat: coordinates.lat, coordinates_lng: coordinates.lng }}
          />
        )}
      </Flex>
    </HostOnboarding>
  );

}

export default LocationConfirmationScreen;

const styles = StyleSheet.create({
  map: {
    height: '100%',
    width: '100%',
  },
  subtextBox: {
    alignContent: 'center',
    alignItems: 'center',
    justifyContent: 'center',
    marginBottom: 18
  },
  subtext: {
    color: "black",
    fontSize: Dimensions.get('window').width < 400 ? 14 : 18,
    textAlign: "center",
  },
});
