import { Storage } from "aws-amplify";

export const uploadSaveImages = async (imageData) => {
    const images = Object.values(imageData);

    for (let i = 0; i < images.length; i++) {
        const { imageID, blob } = images[i];
        try {
            await Storage.put(imageID, blob, {
                level: "public",
                contentType: "image/jpg",
            })
        } catch {
            return false
        }
    }
    return true
}

export const uploadSaveImage = async (imageData) => {
    const { imageID, blob } = imageData;
    try {
        await Storage.put(imageID, blob, {
            level: "public",
            contentType: "image/jpg",
        })
        return true
    } catch {
        return false
    }
}
