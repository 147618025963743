import React from "react";
import { Text, View } from "react-native";
import Card from "../CustomElements/Card"
import Header from "../CustomElements/Header"
import { colors } from "../../styles/colors"


const ThingsToDoInAreaView = ({ thingsToDo, width }) => {

    return (
        <View style={{width: width}}>
            <Header level={1} padding={0} color={colors.amplifyNeutral90} text="Things to do in the area"></Header>
            <View style={{ marginLeft: 15, marginBottom: 5, marginTop: 15 }}>
                <Text>{thingsToDo}</Text>
            </View>
        </View>
    );
};

export default ThingsToDoInAreaView;
