

export const notifyHookhub = async (
    { 
        subject,
        body,
        userID,
        userEmail,
        userName="N/A"
    }) => {
    const requestOptions = {
        method: 'POST',
        body: JSON.stringify({
            userID: userID,
            userEmail: userEmail,
            userName: userName,
            emailSubject: subject,
            emailBody: body
        })
    };
    fetch('https://7ehxkuroembqxttntka4aumigq0kedch.lambda-url.us-west-2.on.aws/', requestOptions).then(response => console.log("success sending email"))
}