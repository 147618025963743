import React, { useContext, useState } from "react";
import { StyleSheet, useWindowDimensions, Pressable, ScrollView, Text } from "react-native";
import { useDispatch, useSelector } from "react-redux";
import SelectPhotos from "../../components/space/SelectPhotos";
import Header from "../../components/CustomElements/Header";
import Flex from "../../components/CustomElements/Flex";
import CustomButton from "../../components/CustomElements/CustomButton";
import { colors } from "../../styles/colors";
import { uploadSaveImages } from "../../utils/uploadSaveImages";

export default function AdminScreen() {
    const { mobileView } = useSelector(state => state.currentUser);
    const { width } = useWindowDimensions();
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(false);
    const [imageIDs, setImageIDs] = useState([]);
    const [imagePreviews, setLocalImagePreviews] = useState({});
    const [uploadImages, setUploadImages] = useState({});

    const uploadTo = async () => {
        setLoading(true);
        await uploadSaveImages(uploadImages)
    };

    const updateImagesArr = (index, imageID, imageValue, blob) => {
        setError(false)
        const imagesToUpload = { ...uploadImages };
        const previewImages = { ...imagePreviews };
        const imageIds = { ...imageIDs };
        imagesToUpload[index] = {
            imageID: imageID,
            blob: blob
        }
        previewImages[index] = imageValue;
        imageIds[index] = imageID;
        setImageIDs(imageIds);
        setUploadImages(imagesToUpload);
        setLocalImagePreviews(previewImages);
        setLoading(false);
    };


    return (
        <ScrollView style={styles.scrollView}>
            <Header marginTop={12} level={1} text={"ADMIN SCREEN"}></Header>
            <Flex width={width} justifyContent="center" alignItems="center" direction="column" marginTop={30} marginBottom={40}>
                <SelectPhotos
                    images={imageIDs}
                    imagePreviews={imagePreviews}
                    setImages={(i, x, y, z) => updateImagesArr(i, x, y, z)}
                    loading={loading}
                    setLoading={() => setLoading(!loading)}
                    imageUploadError={false}
                />
                <CustomButton
                    width={300}
                    backgroundColor={colors.hookhubOrangeDark}
                    color="#fff"
                    textAlign="center"
                    margin={2}
                    border={colors.amplifyNeutral90}
                    onPress={() => uploadTo()}
                >
                    <Text style={{ textAlign: "center", fontSize:19 }}>update photos</Text>
                </CustomButton>
            </Flex>
        </ScrollView>
    );
}

const styles = StyleSheet.create({
    container: {
        flex: 1,
        alignItems: "center",
        justifyContent: "center",
        padding: 20,
    },
    title: {
        fontSize: 20,
        fontWeight: "bold",
    },
    link: {
        marginTop: 15,
        paddingVertical: 15,
    },
    linkText: {
        fontSize: 14,
        color: "#2e78b7",
    },
});
