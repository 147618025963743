import React from "react";
import { createStackNavigator } from "@react-navigation/stack";
import HostAccountScreen from "../screens/host/HostAccountScreen.js";
import HostEditSpaceAvailabilityScreen from "../screens/host/HostEditSpaceAvailabilityScreen.js";
import PaymentSettingsScreen from "../screens/account/PaymentSettingsScreen.js";

const Stack = createStackNavigator();

function HostAccountNavigator() {
  return (
    <Stack.Navigator screenOptions={{headerTitleAlign: 'center', cardStyle: { backgroundColor: "#fff" }}}>
      <Stack.Screen
        component={HostAccountScreen}
        name={"accountscreen"}
        options={{ headerShown: false }}
      />
      <Stack.Screen
        component={HostEditSpaceAvailabilityScreen}
        name={"availability"}
        options={{ headerShown: false }}
      />
      <Stack.Screen
        component={PaymentSettingsScreen}
        name={'paymentsettings'}
        options={{headerShown: false}}
      />
    </Stack.Navigator>
  );
}

export default HostAccountNavigator;
