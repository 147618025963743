/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const onMessagebyConversationId = /* GraphQL */ `
  subscription OnMessagebyConversationId($convoID: ID!) {
    onMessagebyConversationId(convoID: $convoID) {
      id
      authorID
      authorName
      authorAvatar
      otherMembersEmail
      content
      convoID
      conversation {
        id
        hostee
        host
        convoCreatorsEmail
        otherMembersEmail
        spaceID
        spaceTitle
        propertyID
        propertyTitle
        propertyLocation
        bookingRequestID
        bookingRequest {
          id
          members
          confirmed
          reviewed
          cancelled
          rentalType
          stripePaymentMethodID
          stripeCustomerID
          stripePaymentIntentID
          check_in
          check_out
          bookedDays
          spaceID
          spaceTitle
          spaceCity
          spaceState
          propertyTitle
          propertyID
          renterID
          bookingID
          booking {
            id
            members
            cancelled
            cancelledTooLate
            cancelledTooLatePayout
            cancelledDate
            spaceID
            renterID
            hostID
            bookingRequestId
            paymentID
            bookedDays
            check_in
            check_out
            stripePaymentMethodID
            stripeCustomerID
            stripeHostConnectedAccountId
            hostEmail
            renterEmail
            createdAt
            updatedAt
            baseBookingCost
            renterFeeCost
            renterTotalBookingCost
            renterStripeFee
            hostFeeCost
            hostPayout
            hookhubPayout
            paymentRequiresAction
            payMonthly
            numberOfMonths
            paymentSchedule
            nextPaymentsSchedule
            nextPaymentDue
            monthlyPaymentAmount
            monthlyHostPayout
            monthlyHookhubPayout
            cancelledFinalHostPayout
            cancelledFinalRenterPayment
            cancelledBeforeCheckIn
            refundRenter
            full_refund_enabled
            no_refund_enabled
            half_refund_enabled
            full_refund_date
            half_refund_date
            stripePaymentIntentID
            _version
            __typename
          }
          hostID
          hostEmail
          renterEmail
          conversation {
            id
            hostee
            host
            convoCreatorsEmail
            otherMembersEmail
            spaceID
            spaceTitle
            propertyID
            propertyTitle
            propertyLocation
            bookingRequestID
            members
            membersNames
            unreadHost
            unreadHostee
            type
            createdAt
            updatedAt
            _version
            __typename
          }
          createdAt
          updatedAt
          renter_acknowledge_liability
          baseBookingCost
          renterFeeCost
          renterTotalBookingCost
          renterStripeFee
          hostFeeCost
          hostPayout
          hookhubPayout
          payMonthly
          numberOfMonths
          paymentSchedule
          nextPaymentDue
          nextPaymentsSchedule
          monthlyPaymentAmount
          monthlyHookhubPayout
          monthlyHostPayout
          full_refund_enabled
          no_refund_enabled
          half_refund_enabled
          full_refund_date
          half_refund_date
          errorCode
          _version
          bookingRequestConversationId
          __typename
        }
        messages {
          items {
            id
            authorID
            authorName
            authorAvatar
            otherMembersEmail
            content
            convoID
            type
            createdAt
            updatedAt
            _version
            owner
            __typename
          }
          nextToken
          __typename
        }
        members
        membersNames
        unreadHost
        unreadHostee
        type
        createdAt
        updatedAt
        _version
        __typename
      }
      type
      createdAt
      updatedAt
      _version
      owner
      __typename
    }
  }
`;
export const onConversationbyHostId = /* GraphQL */ `
  subscription OnConversationbyHostId($host: ID!, $otherMembersEmail: String!) {
    onConversationbyHostId(host: $host, otherMembersEmail: $otherMembersEmail) {
      id
      hostee
      host
      convoCreatorsEmail
      otherMembersEmail
      spaceID
      spaceTitle
      propertyID
      propertyTitle
      propertyLocation
      bookingRequestID
      bookingRequest {
        id
        members
        confirmed
        reviewed
        cancelled
        rentalType
        stripePaymentMethodID
        stripeCustomerID
        stripePaymentIntentID
        check_in
        check_out
        bookedDays
        spaceID
        spaceTitle
        spaceCity
        spaceState
        propertyTitle
        propertyID
        renterID
        bookingID
        booking {
          id
          members
          cancelled
          cancelledTooLate
          cancelledTooLatePayout
          cancelledDate
          spaceID
          renterID
          hostID
          bookingRequestId
          bookingRequest {
            id
            members
            confirmed
            reviewed
            cancelled
            rentalType
            stripePaymentMethodID
            stripeCustomerID
            stripePaymentIntentID
            check_in
            check_out
            bookedDays
            spaceID
            spaceTitle
            spaceCity
            spaceState
            propertyTitle
            propertyID
            renterID
            bookingID
            hostID
            hostEmail
            renterEmail
            createdAt
            updatedAt
            renter_acknowledge_liability
            baseBookingCost
            renterFeeCost
            renterTotalBookingCost
            renterStripeFee
            hostFeeCost
            hostPayout
            hookhubPayout
            payMonthly
            numberOfMonths
            paymentSchedule
            nextPaymentDue
            nextPaymentsSchedule
            monthlyPaymentAmount
            monthlyHookhubPayout
            monthlyHostPayout
            full_refund_enabled
            no_refund_enabled
            half_refund_enabled
            full_refund_date
            half_refund_date
            errorCode
            _version
            bookingRequestConversationId
            __typename
          }
          paymentID
          payment {
            id
            members
            date
            stripePaymentMethodID
            stripeCustomerID
            paid
            hostID
            bookingId
            baseBookingCost
            renterFeeCost
            renterTotalBookingCost
            renterStripeFee
            hostFeeCost
            hostPayout
            hookhubPayout
            createdAt
            updatedAt
            _version
            __typename
          }
          bookedDays
          check_in
          check_out
          stripePaymentMethodID
          stripeCustomerID
          stripeHostConnectedAccountId
          hostEmail
          renterEmail
          createdAt
          updatedAt
          baseBookingCost
          renterFeeCost
          renterTotalBookingCost
          renterStripeFee
          hostFeeCost
          hostPayout
          hookhubPayout
          paymentRequiresAction
          payMonthly
          numberOfMonths
          paymentSchedule
          nextPaymentsSchedule
          nextPaymentDue
          monthlyPaymentAmount
          monthlyHostPayout
          monthlyHookhubPayout
          cancelledFinalHostPayout
          cancelledFinalRenterPayment
          cancelledBeforeCheckIn
          refundRenter
          full_refund_enabled
          no_refund_enabled
          half_refund_enabled
          full_refund_date
          half_refund_date
          stripePaymentIntentID
          _version
          __typename
        }
        hostID
        hostEmail
        renterEmail
        conversation {
          id
          hostee
          host
          convoCreatorsEmail
          otherMembersEmail
          spaceID
          spaceTitle
          propertyID
          propertyTitle
          propertyLocation
          bookingRequestID
          bookingRequest {
            id
            members
            confirmed
            reviewed
            cancelled
            rentalType
            stripePaymentMethodID
            stripeCustomerID
            stripePaymentIntentID
            check_in
            check_out
            bookedDays
            spaceID
            spaceTitle
            spaceCity
            spaceState
            propertyTitle
            propertyID
            renterID
            bookingID
            hostID
            hostEmail
            renterEmail
            createdAt
            updatedAt
            renter_acknowledge_liability
            baseBookingCost
            renterFeeCost
            renterTotalBookingCost
            renterStripeFee
            hostFeeCost
            hostPayout
            hookhubPayout
            payMonthly
            numberOfMonths
            paymentSchedule
            nextPaymentDue
            nextPaymentsSchedule
            monthlyPaymentAmount
            monthlyHookhubPayout
            monthlyHostPayout
            full_refund_enabled
            no_refund_enabled
            half_refund_enabled
            full_refund_date
            half_refund_date
            errorCode
            _version
            bookingRequestConversationId
            __typename
          }
          messages {
            nextToken
            __typename
          }
          members
          membersNames
          unreadHost
          unreadHostee
          type
          createdAt
          updatedAt
          _version
          __typename
        }
        createdAt
        updatedAt
        renter_acknowledge_liability
        baseBookingCost
        renterFeeCost
        renterTotalBookingCost
        renterStripeFee
        hostFeeCost
        hostPayout
        hookhubPayout
        payMonthly
        numberOfMonths
        paymentSchedule
        nextPaymentDue
        nextPaymentsSchedule
        monthlyPaymentAmount
        monthlyHookhubPayout
        monthlyHostPayout
        full_refund_enabled
        no_refund_enabled
        half_refund_enabled
        full_refund_date
        half_refund_date
        errorCode
        _version
        bookingRequestConversationId
        __typename
      }
      messages {
        items {
          id
          authorID
          authorName
          authorAvatar
          otherMembersEmail
          content
          convoID
          conversation {
            id
            hostee
            host
            convoCreatorsEmail
            otherMembersEmail
            spaceID
            spaceTitle
            propertyID
            propertyTitle
            propertyLocation
            bookingRequestID
            members
            membersNames
            unreadHost
            unreadHostee
            type
            createdAt
            updatedAt
            _version
            __typename
          }
          type
          createdAt
          updatedAt
          _version
          owner
          __typename
        }
        nextToken
        __typename
      }
      members
      membersNames
      unreadHost
      unreadHostee
      type
      createdAt
      updatedAt
      _version
      __typename
    }
  }
`;
export const onConversationbyRenterId = /* GraphQL */ `
  subscription OnConversationbyRenterId(
    $hostee: ID!
    $otherMembersEmail: String!
  ) {
    onConversationbyRenterId(
      hostee: $hostee
      otherMembersEmail: $otherMembersEmail
    ) {
      id
      hostee
      host
      convoCreatorsEmail
      otherMembersEmail
      spaceID
      spaceTitle
      propertyID
      propertyTitle
      propertyLocation
      bookingRequestID
      bookingRequest {
        id
        members
        confirmed
        reviewed
        cancelled
        rentalType
        stripePaymentMethodID
        stripeCustomerID
        stripePaymentIntentID
        check_in
        check_out
        bookedDays
        spaceID
        spaceTitle
        spaceCity
        spaceState
        propertyTitle
        propertyID
        renterID
        bookingID
        booking {
          id
          members
          cancelled
          cancelledTooLate
          cancelledTooLatePayout
          cancelledDate
          spaceID
          renterID
          hostID
          bookingRequestId
          bookingRequest {
            id
            members
            confirmed
            reviewed
            cancelled
            rentalType
            stripePaymentMethodID
            stripeCustomerID
            stripePaymentIntentID
            check_in
            check_out
            bookedDays
            spaceID
            spaceTitle
            spaceCity
            spaceState
            propertyTitle
            propertyID
            renterID
            bookingID
            hostID
            hostEmail
            renterEmail
            createdAt
            updatedAt
            renter_acknowledge_liability
            baseBookingCost
            renterFeeCost
            renterTotalBookingCost
            renterStripeFee
            hostFeeCost
            hostPayout
            hookhubPayout
            payMonthly
            numberOfMonths
            paymentSchedule
            nextPaymentDue
            nextPaymentsSchedule
            monthlyPaymentAmount
            monthlyHookhubPayout
            monthlyHostPayout
            full_refund_enabled
            no_refund_enabled
            half_refund_enabled
            full_refund_date
            half_refund_date
            errorCode
            _version
            bookingRequestConversationId
            __typename
          }
          paymentID
          payment {
            id
            members
            date
            stripePaymentMethodID
            stripeCustomerID
            paid
            hostID
            bookingId
            baseBookingCost
            renterFeeCost
            renterTotalBookingCost
            renterStripeFee
            hostFeeCost
            hostPayout
            hookhubPayout
            createdAt
            updatedAt
            _version
            __typename
          }
          bookedDays
          check_in
          check_out
          stripePaymentMethodID
          stripeCustomerID
          stripeHostConnectedAccountId
          hostEmail
          renterEmail
          createdAt
          updatedAt
          baseBookingCost
          renterFeeCost
          renterTotalBookingCost
          renterStripeFee
          hostFeeCost
          hostPayout
          hookhubPayout
          paymentRequiresAction
          payMonthly
          numberOfMonths
          paymentSchedule
          nextPaymentsSchedule
          nextPaymentDue
          monthlyPaymentAmount
          monthlyHostPayout
          monthlyHookhubPayout
          cancelledFinalHostPayout
          cancelledFinalRenterPayment
          cancelledBeforeCheckIn
          refundRenter
          full_refund_enabled
          no_refund_enabled
          half_refund_enabled
          full_refund_date
          half_refund_date
          stripePaymentIntentID
          _version
          __typename
        }
        hostID
        hostEmail
        renterEmail
        conversation {
          id
          hostee
          host
          convoCreatorsEmail
          otherMembersEmail
          spaceID
          spaceTitle
          propertyID
          propertyTitle
          propertyLocation
          bookingRequestID
          bookingRequest {
            id
            members
            confirmed
            reviewed
            cancelled
            rentalType
            stripePaymentMethodID
            stripeCustomerID
            stripePaymentIntentID
            check_in
            check_out
            bookedDays
            spaceID
            spaceTitle
            spaceCity
            spaceState
            propertyTitle
            propertyID
            renterID
            bookingID
            hostID
            hostEmail
            renterEmail
            createdAt
            updatedAt
            renter_acknowledge_liability
            baseBookingCost
            renterFeeCost
            renterTotalBookingCost
            renterStripeFee
            hostFeeCost
            hostPayout
            hookhubPayout
            payMonthly
            numberOfMonths
            paymentSchedule
            nextPaymentDue
            nextPaymentsSchedule
            monthlyPaymentAmount
            monthlyHookhubPayout
            monthlyHostPayout
            full_refund_enabled
            no_refund_enabled
            half_refund_enabled
            full_refund_date
            half_refund_date
            errorCode
            _version
            bookingRequestConversationId
            __typename
          }
          messages {
            nextToken
            __typename
          }
          members
          membersNames
          unreadHost
          unreadHostee
          type
          createdAt
          updatedAt
          _version
          __typename
        }
        createdAt
        updatedAt
        renter_acknowledge_liability
        baseBookingCost
        renterFeeCost
        renterTotalBookingCost
        renterStripeFee
        hostFeeCost
        hostPayout
        hookhubPayout
        payMonthly
        numberOfMonths
        paymentSchedule
        nextPaymentDue
        nextPaymentsSchedule
        monthlyPaymentAmount
        monthlyHookhubPayout
        monthlyHostPayout
        full_refund_enabled
        no_refund_enabled
        half_refund_enabled
        full_refund_date
        half_refund_date
        errorCode
        _version
        bookingRequestConversationId
        __typename
      }
      messages {
        items {
          id
          authorID
          authorName
          authorAvatar
          otherMembersEmail
          content
          convoID
          conversation {
            id
            hostee
            host
            convoCreatorsEmail
            otherMembersEmail
            spaceID
            spaceTitle
            propertyID
            propertyTitle
            propertyLocation
            bookingRequestID
            members
            membersNames
            unreadHost
            unreadHostee
            type
            createdAt
            updatedAt
            _version
            __typename
          }
          type
          createdAt
          updatedAt
          _version
          owner
          __typename
        }
        nextToken
        __typename
      }
      members
      membersNames
      unreadHost
      unreadHostee
      type
      createdAt
      updatedAt
      _version
      __typename
    }
  }
`;
export const onBookingRequestbySpaceOwnerId = /* GraphQL */ `
  subscription OnBookingRequestbySpaceOwnerId($hostID: ID!) {
    onBookingRequestbySpaceOwnerId(hostID: $hostID) {
      id
      members
      confirmed
      reviewed
      cancelled
      rentalType
      stripePaymentMethodID
      stripeCustomerID
      stripePaymentIntentID
      check_in
      check_out
      bookedDays
      spaceID
      spaceTitle
      spaceCity
      spaceState
      propertyTitle
      propertyID
      renterID
      bookingID
      booking {
        id
        members
        cancelled
        cancelledTooLate
        cancelledTooLatePayout
        cancelledDate
        spaceID
        renterID
        hostID
        bookingRequestId
        bookingRequest {
          id
          members
          confirmed
          reviewed
          cancelled
          rentalType
          stripePaymentMethodID
          stripeCustomerID
          stripePaymentIntentID
          check_in
          check_out
          bookedDays
          spaceID
          spaceTitle
          spaceCity
          spaceState
          propertyTitle
          propertyID
          renterID
          bookingID
          booking {
            id
            members
            cancelled
            cancelledTooLate
            cancelledTooLatePayout
            cancelledDate
            spaceID
            renterID
            hostID
            bookingRequestId
            paymentID
            bookedDays
            check_in
            check_out
            stripePaymentMethodID
            stripeCustomerID
            stripeHostConnectedAccountId
            hostEmail
            renterEmail
            createdAt
            updatedAt
            baseBookingCost
            renterFeeCost
            renterTotalBookingCost
            renterStripeFee
            hostFeeCost
            hostPayout
            hookhubPayout
            paymentRequiresAction
            payMonthly
            numberOfMonths
            paymentSchedule
            nextPaymentsSchedule
            nextPaymentDue
            monthlyPaymentAmount
            monthlyHostPayout
            monthlyHookhubPayout
            cancelledFinalHostPayout
            cancelledFinalRenterPayment
            cancelledBeforeCheckIn
            refundRenter
            full_refund_enabled
            no_refund_enabled
            half_refund_enabled
            full_refund_date
            half_refund_date
            stripePaymentIntentID
            _version
            __typename
          }
          hostID
          hostEmail
          renterEmail
          conversation {
            id
            hostee
            host
            convoCreatorsEmail
            otherMembersEmail
            spaceID
            spaceTitle
            propertyID
            propertyTitle
            propertyLocation
            bookingRequestID
            members
            membersNames
            unreadHost
            unreadHostee
            type
            createdAt
            updatedAt
            _version
            __typename
          }
          createdAt
          updatedAt
          renter_acknowledge_liability
          baseBookingCost
          renterFeeCost
          renterTotalBookingCost
          renterStripeFee
          hostFeeCost
          hostPayout
          hookhubPayout
          payMonthly
          numberOfMonths
          paymentSchedule
          nextPaymentDue
          nextPaymentsSchedule
          monthlyPaymentAmount
          monthlyHookhubPayout
          monthlyHostPayout
          full_refund_enabled
          no_refund_enabled
          half_refund_enabled
          full_refund_date
          half_refund_date
          errorCode
          _version
          bookingRequestConversationId
          __typename
        }
        paymentID
        payment {
          id
          members
          date
          stripePaymentMethodID
          stripeCustomerID
          paid
          hostID
          bookingId
          booking {
            id
            members
            cancelled
            cancelledTooLate
            cancelledTooLatePayout
            cancelledDate
            spaceID
            renterID
            hostID
            bookingRequestId
            paymentID
            bookedDays
            check_in
            check_out
            stripePaymentMethodID
            stripeCustomerID
            stripeHostConnectedAccountId
            hostEmail
            renterEmail
            createdAt
            updatedAt
            baseBookingCost
            renterFeeCost
            renterTotalBookingCost
            renterStripeFee
            hostFeeCost
            hostPayout
            hookhubPayout
            paymentRequiresAction
            payMonthly
            numberOfMonths
            paymentSchedule
            nextPaymentsSchedule
            nextPaymentDue
            monthlyPaymentAmount
            monthlyHostPayout
            monthlyHookhubPayout
            cancelledFinalHostPayout
            cancelledFinalRenterPayment
            cancelledBeforeCheckIn
            refundRenter
            full_refund_enabled
            no_refund_enabled
            half_refund_enabled
            full_refund_date
            half_refund_date
            stripePaymentIntentID
            _version
            __typename
          }
          baseBookingCost
          renterFeeCost
          renterTotalBookingCost
          renterStripeFee
          hostFeeCost
          hostPayout
          hookhubPayout
          createdAt
          updatedAt
          _version
          __typename
        }
        bookedDays
        check_in
        check_out
        stripePaymentMethodID
        stripeCustomerID
        stripeHostConnectedAccountId
        hostEmail
        renterEmail
        createdAt
        updatedAt
        baseBookingCost
        renterFeeCost
        renterTotalBookingCost
        renterStripeFee
        hostFeeCost
        hostPayout
        hookhubPayout
        paymentRequiresAction
        payMonthly
        numberOfMonths
        paymentSchedule
        nextPaymentsSchedule
        nextPaymentDue
        monthlyPaymentAmount
        monthlyHostPayout
        monthlyHookhubPayout
        cancelledFinalHostPayout
        cancelledFinalRenterPayment
        cancelledBeforeCheckIn
        refundRenter
        full_refund_enabled
        no_refund_enabled
        half_refund_enabled
        full_refund_date
        half_refund_date
        stripePaymentIntentID
        _version
        __typename
      }
      hostID
      hostEmail
      renterEmail
      conversation {
        id
        hostee
        host
        convoCreatorsEmail
        otherMembersEmail
        spaceID
        spaceTitle
        propertyID
        propertyTitle
        propertyLocation
        bookingRequestID
        bookingRequest {
          id
          members
          confirmed
          reviewed
          cancelled
          rentalType
          stripePaymentMethodID
          stripeCustomerID
          stripePaymentIntentID
          check_in
          check_out
          bookedDays
          spaceID
          spaceTitle
          spaceCity
          spaceState
          propertyTitle
          propertyID
          renterID
          bookingID
          booking {
            id
            members
            cancelled
            cancelledTooLate
            cancelledTooLatePayout
            cancelledDate
            spaceID
            renterID
            hostID
            bookingRequestId
            paymentID
            bookedDays
            check_in
            check_out
            stripePaymentMethodID
            stripeCustomerID
            stripeHostConnectedAccountId
            hostEmail
            renterEmail
            createdAt
            updatedAt
            baseBookingCost
            renterFeeCost
            renterTotalBookingCost
            renterStripeFee
            hostFeeCost
            hostPayout
            hookhubPayout
            paymentRequiresAction
            payMonthly
            numberOfMonths
            paymentSchedule
            nextPaymentsSchedule
            nextPaymentDue
            monthlyPaymentAmount
            monthlyHostPayout
            monthlyHookhubPayout
            cancelledFinalHostPayout
            cancelledFinalRenterPayment
            cancelledBeforeCheckIn
            refundRenter
            full_refund_enabled
            no_refund_enabled
            half_refund_enabled
            full_refund_date
            half_refund_date
            stripePaymentIntentID
            _version
            __typename
          }
          hostID
          hostEmail
          renterEmail
          conversation {
            id
            hostee
            host
            convoCreatorsEmail
            otherMembersEmail
            spaceID
            spaceTitle
            propertyID
            propertyTitle
            propertyLocation
            bookingRequestID
            members
            membersNames
            unreadHost
            unreadHostee
            type
            createdAt
            updatedAt
            _version
            __typename
          }
          createdAt
          updatedAt
          renter_acknowledge_liability
          baseBookingCost
          renterFeeCost
          renterTotalBookingCost
          renterStripeFee
          hostFeeCost
          hostPayout
          hookhubPayout
          payMonthly
          numberOfMonths
          paymentSchedule
          nextPaymentDue
          nextPaymentsSchedule
          monthlyPaymentAmount
          monthlyHookhubPayout
          monthlyHostPayout
          full_refund_enabled
          no_refund_enabled
          half_refund_enabled
          full_refund_date
          half_refund_date
          errorCode
          _version
          bookingRequestConversationId
          __typename
        }
        messages {
          items {
            id
            authorID
            authorName
            authorAvatar
            otherMembersEmail
            content
            convoID
            type
            createdAt
            updatedAt
            _version
            owner
            __typename
          }
          nextToken
          __typename
        }
        members
        membersNames
        unreadHost
        unreadHostee
        type
        createdAt
        updatedAt
        _version
        __typename
      }
      createdAt
      updatedAt
      renter_acknowledge_liability
      baseBookingCost
      renterFeeCost
      renterTotalBookingCost
      renterStripeFee
      hostFeeCost
      hostPayout
      hookhubPayout
      payMonthly
      numberOfMonths
      paymentSchedule
      nextPaymentDue
      nextPaymentsSchedule
      monthlyPaymentAmount
      monthlyHookhubPayout
      monthlyHostPayout
      full_refund_enabled
      no_refund_enabled
      half_refund_enabled
      full_refund_date
      half_refund_date
      errorCode
      _version
      bookingRequestConversationId
      __typename
    }
  }
`;
export const onBookingbyRenterId = /* GraphQL */ `
  subscription OnBookingbyRenterId($renterID: ID!) {
    onBookingbyRenterId(renterID: $renterID) {
      id
      members
      cancelled
      cancelledTooLate
      cancelledTooLatePayout
      cancelledDate
      spaceID
      renterID
      hostID
      bookingRequestId
      bookingRequest {
        id
        members
        confirmed
        reviewed
        cancelled
        rentalType
        stripePaymentMethodID
        stripeCustomerID
        stripePaymentIntentID
        check_in
        check_out
        bookedDays
        spaceID
        spaceTitle
        spaceCity
        spaceState
        propertyTitle
        propertyID
        renterID
        bookingID
        booking {
          id
          members
          cancelled
          cancelledTooLate
          cancelledTooLatePayout
          cancelledDate
          spaceID
          renterID
          hostID
          bookingRequestId
          bookingRequest {
            id
            members
            confirmed
            reviewed
            cancelled
            rentalType
            stripePaymentMethodID
            stripeCustomerID
            stripePaymentIntentID
            check_in
            check_out
            bookedDays
            spaceID
            spaceTitle
            spaceCity
            spaceState
            propertyTitle
            propertyID
            renterID
            bookingID
            hostID
            hostEmail
            renterEmail
            createdAt
            updatedAt
            renter_acknowledge_liability
            baseBookingCost
            renterFeeCost
            renterTotalBookingCost
            renterStripeFee
            hostFeeCost
            hostPayout
            hookhubPayout
            payMonthly
            numberOfMonths
            paymentSchedule
            nextPaymentDue
            nextPaymentsSchedule
            monthlyPaymentAmount
            monthlyHookhubPayout
            monthlyHostPayout
            full_refund_enabled
            no_refund_enabled
            half_refund_enabled
            full_refund_date
            half_refund_date
            errorCode
            _version
            bookingRequestConversationId
            __typename
          }
          paymentID
          payment {
            id
            members
            date
            stripePaymentMethodID
            stripeCustomerID
            paid
            hostID
            bookingId
            baseBookingCost
            renterFeeCost
            renterTotalBookingCost
            renterStripeFee
            hostFeeCost
            hostPayout
            hookhubPayout
            createdAt
            updatedAt
            _version
            __typename
          }
          bookedDays
          check_in
          check_out
          stripePaymentMethodID
          stripeCustomerID
          stripeHostConnectedAccountId
          hostEmail
          renterEmail
          createdAt
          updatedAt
          baseBookingCost
          renterFeeCost
          renterTotalBookingCost
          renterStripeFee
          hostFeeCost
          hostPayout
          hookhubPayout
          paymentRequiresAction
          payMonthly
          numberOfMonths
          paymentSchedule
          nextPaymentsSchedule
          nextPaymentDue
          monthlyPaymentAmount
          monthlyHostPayout
          monthlyHookhubPayout
          cancelledFinalHostPayout
          cancelledFinalRenterPayment
          cancelledBeforeCheckIn
          refundRenter
          full_refund_enabled
          no_refund_enabled
          half_refund_enabled
          full_refund_date
          half_refund_date
          stripePaymentIntentID
          _version
          __typename
        }
        hostID
        hostEmail
        renterEmail
        conversation {
          id
          hostee
          host
          convoCreatorsEmail
          otherMembersEmail
          spaceID
          spaceTitle
          propertyID
          propertyTitle
          propertyLocation
          bookingRequestID
          bookingRequest {
            id
            members
            confirmed
            reviewed
            cancelled
            rentalType
            stripePaymentMethodID
            stripeCustomerID
            stripePaymentIntentID
            check_in
            check_out
            bookedDays
            spaceID
            spaceTitle
            spaceCity
            spaceState
            propertyTitle
            propertyID
            renterID
            bookingID
            hostID
            hostEmail
            renterEmail
            createdAt
            updatedAt
            renter_acknowledge_liability
            baseBookingCost
            renterFeeCost
            renterTotalBookingCost
            renterStripeFee
            hostFeeCost
            hostPayout
            hookhubPayout
            payMonthly
            numberOfMonths
            paymentSchedule
            nextPaymentDue
            nextPaymentsSchedule
            monthlyPaymentAmount
            monthlyHookhubPayout
            monthlyHostPayout
            full_refund_enabled
            no_refund_enabled
            half_refund_enabled
            full_refund_date
            half_refund_date
            errorCode
            _version
            bookingRequestConversationId
            __typename
          }
          messages {
            nextToken
            __typename
          }
          members
          membersNames
          unreadHost
          unreadHostee
          type
          createdAt
          updatedAt
          _version
          __typename
        }
        createdAt
        updatedAt
        renter_acknowledge_liability
        baseBookingCost
        renterFeeCost
        renterTotalBookingCost
        renterStripeFee
        hostFeeCost
        hostPayout
        hookhubPayout
        payMonthly
        numberOfMonths
        paymentSchedule
        nextPaymentDue
        nextPaymentsSchedule
        monthlyPaymentAmount
        monthlyHookhubPayout
        monthlyHostPayout
        full_refund_enabled
        no_refund_enabled
        half_refund_enabled
        full_refund_date
        half_refund_date
        errorCode
        _version
        bookingRequestConversationId
        __typename
      }
      paymentID
      payment {
        id
        members
        date
        stripePaymentMethodID
        stripeCustomerID
        paid
        hostID
        bookingId
        booking {
          id
          members
          cancelled
          cancelledTooLate
          cancelledTooLatePayout
          cancelledDate
          spaceID
          renterID
          hostID
          bookingRequestId
          bookingRequest {
            id
            members
            confirmed
            reviewed
            cancelled
            rentalType
            stripePaymentMethodID
            stripeCustomerID
            stripePaymentIntentID
            check_in
            check_out
            bookedDays
            spaceID
            spaceTitle
            spaceCity
            spaceState
            propertyTitle
            propertyID
            renterID
            bookingID
            hostID
            hostEmail
            renterEmail
            createdAt
            updatedAt
            renter_acknowledge_liability
            baseBookingCost
            renterFeeCost
            renterTotalBookingCost
            renterStripeFee
            hostFeeCost
            hostPayout
            hookhubPayout
            payMonthly
            numberOfMonths
            paymentSchedule
            nextPaymentDue
            nextPaymentsSchedule
            monthlyPaymentAmount
            monthlyHookhubPayout
            monthlyHostPayout
            full_refund_enabled
            no_refund_enabled
            half_refund_enabled
            full_refund_date
            half_refund_date
            errorCode
            _version
            bookingRequestConversationId
            __typename
          }
          paymentID
          payment {
            id
            members
            date
            stripePaymentMethodID
            stripeCustomerID
            paid
            hostID
            bookingId
            baseBookingCost
            renterFeeCost
            renterTotalBookingCost
            renterStripeFee
            hostFeeCost
            hostPayout
            hookhubPayout
            createdAt
            updatedAt
            _version
            __typename
          }
          bookedDays
          check_in
          check_out
          stripePaymentMethodID
          stripeCustomerID
          stripeHostConnectedAccountId
          hostEmail
          renterEmail
          createdAt
          updatedAt
          baseBookingCost
          renterFeeCost
          renterTotalBookingCost
          renterStripeFee
          hostFeeCost
          hostPayout
          hookhubPayout
          paymentRequiresAction
          payMonthly
          numberOfMonths
          paymentSchedule
          nextPaymentsSchedule
          nextPaymentDue
          monthlyPaymentAmount
          monthlyHostPayout
          monthlyHookhubPayout
          cancelledFinalHostPayout
          cancelledFinalRenterPayment
          cancelledBeforeCheckIn
          refundRenter
          full_refund_enabled
          no_refund_enabled
          half_refund_enabled
          full_refund_date
          half_refund_date
          stripePaymentIntentID
          _version
          __typename
        }
        baseBookingCost
        renterFeeCost
        renterTotalBookingCost
        renterStripeFee
        hostFeeCost
        hostPayout
        hookhubPayout
        createdAt
        updatedAt
        _version
        __typename
      }
      bookedDays
      check_in
      check_out
      stripePaymentMethodID
      stripeCustomerID
      stripeHostConnectedAccountId
      hostEmail
      renterEmail
      createdAt
      updatedAt
      baseBookingCost
      renterFeeCost
      renterTotalBookingCost
      renterStripeFee
      hostFeeCost
      hostPayout
      hookhubPayout
      paymentRequiresAction
      payMonthly
      numberOfMonths
      paymentSchedule
      nextPaymentsSchedule
      nextPaymentDue
      monthlyPaymentAmount
      monthlyHostPayout
      monthlyHookhubPayout
      cancelledFinalHostPayout
      cancelledFinalRenterPayment
      cancelledBeforeCheckIn
      refundRenter
      full_refund_enabled
      no_refund_enabled
      half_refund_enabled
      full_refund_date
      half_refund_date
      stripePaymentIntentID
      _version
      __typename
    }
  }
`;
export const onCreateUser = /* GraphQL */ `
  subscription OnCreateUser(
    $filter: ModelSubscriptionUserFilterInput
    $id: String
  ) {
    onCreateUser(filter: $filter, id: $id) {
      id
      name
      email
      phoneNumber
      rig {
        items {
          id
          ownerID
          image
          length
          width
          year
          make
          model
          _version
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      avatar
      about
      myHostedSpaces {
        items {
          id
          propertyID
          ownerID
          bookings {
            nextToken
            __typename
          }
          bookingRequests {
            nextToken
            __typename
          }
          space_approved
          active
          title
          description
          unavailable_days
          set_availability
          longest_stay_allowed
          shortest_stay_allowed
          width
          length
          electric_hookup
          water_hookup
          sewage_hookup
          wifi_available
          wifi_password
          wifi_network_name
          shower_available
          restroom_available
          food_nearby
          restaurant_nearby
          water_accessible
          easy_entry
          pre_booking_message_required
          pre_booking_message
          instant_book
          distance_electric_hookup
          distance_water_hookup
          distance_sewage_hookup
          parking_spaces
          additional_rules
          parties_allowed
          things_to_do
          images
          laundry_available
          pool_available
          hottub_available
          outdoor_area_available
          indoor_area_available
          trash_available
          extra_amenities_available
          host_acknowledge_liability
          host_acknowledge_tax_responsibility
          check_in_time
          check_out_time
          check_in_instructions
          space_rules
          pets_allowed
          smoking_allowed
          adults_only
          quiet_hours_start
          quiet_hours_end
          outdoor_fires
          profile_photo_required
          full_refund_date
          half_refund_date
          price_storage
          price_shortterm
          price_longterm
          price_yearly
          _version
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      myHostedProperties {
        items {
          id
          active
          ownerID
          spaces {
            nextToken
            __typename
          }
          title
          description
          address {
            id
            address
            cityName
            stateName
            zipcode
            countryName
            canadianCivicAddress
            _version
            createdAt
            updatedAt
            owner
            __typename
          }
          coordinates_lat
          coordinates_lng
          type_storage
          type_longterm
          type_shortterm
          type_yearly
          price_storage
          price_shortterm
          price_longterm
          price_yearly
          instant_book
          images
          reviews {
            nextToken
            __typename
          }
          conversations {
            nextToken
            __typename
          }
          _version
          createdAt
          updatedAt
          propertyAddressId
          __typename
        }
        nextToken
        __typename
      }
      favorites
      stripePayoutsEnabled
      stripeConnectedAccountId
      stripeCustomerID
      stripePaymentMethodID
      govID_verified
      bookings {
        items {
          id
          members
          cancelled
          cancelledTooLate
          cancelledTooLatePayout
          cancelledDate
          spaceID
          renterID
          hostID
          bookingRequestId
          bookingRequest {
            id
            members
            confirmed
            reviewed
            cancelled
            rentalType
            stripePaymentMethodID
            stripeCustomerID
            stripePaymentIntentID
            check_in
            check_out
            bookedDays
            spaceID
            spaceTitle
            spaceCity
            spaceState
            propertyTitle
            propertyID
            renterID
            bookingID
            hostID
            hostEmail
            renterEmail
            createdAt
            updatedAt
            renter_acknowledge_liability
            baseBookingCost
            renterFeeCost
            renterTotalBookingCost
            renterStripeFee
            hostFeeCost
            hostPayout
            hookhubPayout
            payMonthly
            numberOfMonths
            paymentSchedule
            nextPaymentDue
            nextPaymentsSchedule
            monthlyPaymentAmount
            monthlyHookhubPayout
            monthlyHostPayout
            full_refund_enabled
            no_refund_enabled
            half_refund_enabled
            full_refund_date
            half_refund_date
            errorCode
            _version
            bookingRequestConversationId
            __typename
          }
          paymentID
          payment {
            id
            members
            date
            stripePaymentMethodID
            stripeCustomerID
            paid
            hostID
            bookingId
            baseBookingCost
            renterFeeCost
            renterTotalBookingCost
            renterStripeFee
            hostFeeCost
            hostPayout
            hookhubPayout
            createdAt
            updatedAt
            _version
            __typename
          }
          bookedDays
          check_in
          check_out
          stripePaymentMethodID
          stripeCustomerID
          stripeHostConnectedAccountId
          hostEmail
          renterEmail
          createdAt
          updatedAt
          baseBookingCost
          renterFeeCost
          renterTotalBookingCost
          renterStripeFee
          hostFeeCost
          hostPayout
          hookhubPayout
          paymentRequiresAction
          payMonthly
          numberOfMonths
          paymentSchedule
          nextPaymentsSchedule
          nextPaymentDue
          monthlyPaymentAmount
          monthlyHostPayout
          monthlyHookhubPayout
          cancelledFinalHostPayout
          cancelledFinalRenterPayment
          cancelledBeforeCheckIn
          refundRenter
          full_refund_enabled
          no_refund_enabled
          half_refund_enabled
          full_refund_date
          half_refund_date
          stripePaymentIntentID
          _version
          __typename
        }
        nextToken
        __typename
      }
      bookingRequests {
        items {
          id
          members
          confirmed
          reviewed
          cancelled
          rentalType
          stripePaymentMethodID
          stripeCustomerID
          stripePaymentIntentID
          check_in
          check_out
          bookedDays
          spaceID
          spaceTitle
          spaceCity
          spaceState
          propertyTitle
          propertyID
          renterID
          bookingID
          booking {
            id
            members
            cancelled
            cancelledTooLate
            cancelledTooLatePayout
            cancelledDate
            spaceID
            renterID
            hostID
            bookingRequestId
            paymentID
            bookedDays
            check_in
            check_out
            stripePaymentMethodID
            stripeCustomerID
            stripeHostConnectedAccountId
            hostEmail
            renterEmail
            createdAt
            updatedAt
            baseBookingCost
            renterFeeCost
            renterTotalBookingCost
            renterStripeFee
            hostFeeCost
            hostPayout
            hookhubPayout
            paymentRequiresAction
            payMonthly
            numberOfMonths
            paymentSchedule
            nextPaymentsSchedule
            nextPaymentDue
            monthlyPaymentAmount
            monthlyHostPayout
            monthlyHookhubPayout
            cancelledFinalHostPayout
            cancelledFinalRenterPayment
            cancelledBeforeCheckIn
            refundRenter
            full_refund_enabled
            no_refund_enabled
            half_refund_enabled
            full_refund_date
            half_refund_date
            stripePaymentIntentID
            _version
            __typename
          }
          hostID
          hostEmail
          renterEmail
          conversation {
            id
            hostee
            host
            convoCreatorsEmail
            otherMembersEmail
            spaceID
            spaceTitle
            propertyID
            propertyTitle
            propertyLocation
            bookingRequestID
            members
            membersNames
            unreadHost
            unreadHostee
            type
            createdAt
            updatedAt
            _version
            __typename
          }
          createdAt
          updatedAt
          renter_acknowledge_liability
          baseBookingCost
          renterFeeCost
          renterTotalBookingCost
          renterStripeFee
          hostFeeCost
          hostPayout
          hookhubPayout
          payMonthly
          numberOfMonths
          paymentSchedule
          nextPaymentDue
          nextPaymentsSchedule
          monthlyPaymentAmount
          monthlyHookhubPayout
          monthlyHostPayout
          full_refund_enabled
          no_refund_enabled
          half_refund_enabled
          full_refund_date
          half_refund_date
          errorCode
          _version
          bookingRequestConversationId
          __typename
        }
        nextToken
        __typename
      }
      bookingsHosted {
        items {
          id
          members
          cancelled
          cancelledTooLate
          cancelledTooLatePayout
          cancelledDate
          spaceID
          renterID
          hostID
          bookingRequestId
          bookingRequest {
            id
            members
            confirmed
            reviewed
            cancelled
            rentalType
            stripePaymentMethodID
            stripeCustomerID
            stripePaymentIntentID
            check_in
            check_out
            bookedDays
            spaceID
            spaceTitle
            spaceCity
            spaceState
            propertyTitle
            propertyID
            renterID
            bookingID
            hostID
            hostEmail
            renterEmail
            createdAt
            updatedAt
            renter_acknowledge_liability
            baseBookingCost
            renterFeeCost
            renterTotalBookingCost
            renterStripeFee
            hostFeeCost
            hostPayout
            hookhubPayout
            payMonthly
            numberOfMonths
            paymentSchedule
            nextPaymentDue
            nextPaymentsSchedule
            monthlyPaymentAmount
            monthlyHookhubPayout
            monthlyHostPayout
            full_refund_enabled
            no_refund_enabled
            half_refund_enabled
            full_refund_date
            half_refund_date
            errorCode
            _version
            bookingRequestConversationId
            __typename
          }
          paymentID
          payment {
            id
            members
            date
            stripePaymentMethodID
            stripeCustomerID
            paid
            hostID
            bookingId
            baseBookingCost
            renterFeeCost
            renterTotalBookingCost
            renterStripeFee
            hostFeeCost
            hostPayout
            hookhubPayout
            createdAt
            updatedAt
            _version
            __typename
          }
          bookedDays
          check_in
          check_out
          stripePaymentMethodID
          stripeCustomerID
          stripeHostConnectedAccountId
          hostEmail
          renterEmail
          createdAt
          updatedAt
          baseBookingCost
          renterFeeCost
          renterTotalBookingCost
          renterStripeFee
          hostFeeCost
          hostPayout
          hookhubPayout
          paymentRequiresAction
          payMonthly
          numberOfMonths
          paymentSchedule
          nextPaymentsSchedule
          nextPaymentDue
          monthlyPaymentAmount
          monthlyHostPayout
          monthlyHookhubPayout
          cancelledFinalHostPayout
          cancelledFinalRenterPayment
          cancelledBeforeCheckIn
          refundRenter
          full_refund_enabled
          no_refund_enabled
          half_refund_enabled
          full_refund_date
          half_refund_date
          stripePaymentIntentID
          _version
          __typename
        }
        nextToken
        __typename
      }
      reviewsOfRenter {
        items {
          id
          rating
          cleanlinessRating
          communicationRating
          overallRating
          description
          renterID
          reviewerName
          reviewerAvatar
          reviewerID
          bookingID
          propertyID
          _version
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      hosteeConvos {
        items {
          id
          hostee
          host
          convoCreatorsEmail
          otherMembersEmail
          spaceID
          spaceTitle
          propertyID
          propertyTitle
          propertyLocation
          bookingRequestID
          bookingRequest {
            id
            members
            confirmed
            reviewed
            cancelled
            rentalType
            stripePaymentMethodID
            stripeCustomerID
            stripePaymentIntentID
            check_in
            check_out
            bookedDays
            spaceID
            spaceTitle
            spaceCity
            spaceState
            propertyTitle
            propertyID
            renterID
            bookingID
            hostID
            hostEmail
            renterEmail
            createdAt
            updatedAt
            renter_acknowledge_liability
            baseBookingCost
            renterFeeCost
            renterTotalBookingCost
            renterStripeFee
            hostFeeCost
            hostPayout
            hookhubPayout
            payMonthly
            numberOfMonths
            paymentSchedule
            nextPaymentDue
            nextPaymentsSchedule
            monthlyPaymentAmount
            monthlyHookhubPayout
            monthlyHostPayout
            full_refund_enabled
            no_refund_enabled
            half_refund_enabled
            full_refund_date
            half_refund_date
            errorCode
            _version
            bookingRequestConversationId
            __typename
          }
          messages {
            nextToken
            __typename
          }
          members
          membersNames
          unreadHost
          unreadHostee
          type
          createdAt
          updatedAt
          _version
          __typename
        }
        nextToken
        __typename
      }
      hostConvos {
        items {
          id
          hostee
          host
          convoCreatorsEmail
          otherMembersEmail
          spaceID
          spaceTitle
          propertyID
          propertyTitle
          propertyLocation
          bookingRequestID
          bookingRequest {
            id
            members
            confirmed
            reviewed
            cancelled
            rentalType
            stripePaymentMethodID
            stripeCustomerID
            stripePaymentIntentID
            check_in
            check_out
            bookedDays
            spaceID
            spaceTitle
            spaceCity
            spaceState
            propertyTitle
            propertyID
            renterID
            bookingID
            hostID
            hostEmail
            renterEmail
            createdAt
            updatedAt
            renter_acknowledge_liability
            baseBookingCost
            renterFeeCost
            renterTotalBookingCost
            renterStripeFee
            hostFeeCost
            hostPayout
            hookhubPayout
            payMonthly
            numberOfMonths
            paymentSchedule
            nextPaymentDue
            nextPaymentsSchedule
            monthlyPaymentAmount
            monthlyHookhubPayout
            monthlyHostPayout
            full_refund_enabled
            no_refund_enabled
            half_refund_enabled
            full_refund_date
            half_refund_date
            errorCode
            _version
            bookingRequestConversationId
            __typename
          }
          messages {
            nextToken
            __typename
          }
          members
          membersNames
          unreadHost
          unreadHostee
          type
          createdAt
          updatedAt
          _version
          __typename
        }
        nextToken
        __typename
      }
      payments {
        items {
          id
          members
          date
          stripePaymentMethodID
          stripeCustomerID
          paid
          hostID
          bookingId
          booking {
            id
            members
            cancelled
            cancelledTooLate
            cancelledTooLatePayout
            cancelledDate
            spaceID
            renterID
            hostID
            bookingRequestId
            paymentID
            bookedDays
            check_in
            check_out
            stripePaymentMethodID
            stripeCustomerID
            stripeHostConnectedAccountId
            hostEmail
            renterEmail
            createdAt
            updatedAt
            baseBookingCost
            renterFeeCost
            renterTotalBookingCost
            renterStripeFee
            hostFeeCost
            hostPayout
            hookhubPayout
            paymentRequiresAction
            payMonthly
            numberOfMonths
            paymentSchedule
            nextPaymentsSchedule
            nextPaymentDue
            monthlyPaymentAmount
            monthlyHostPayout
            monthlyHookhubPayout
            cancelledFinalHostPayout
            cancelledFinalRenterPayment
            cancelledBeforeCheckIn
            refundRenter
            full_refund_enabled
            no_refund_enabled
            half_refund_enabled
            full_refund_date
            half_refund_date
            stripePaymentIntentID
            _version
            __typename
          }
          baseBookingCost
          renterFeeCost
          renterTotalBookingCost
          renterStripeFee
          hostFeeCost
          hostPayout
          hookhubPayout
          createdAt
          updatedAt
          _version
          __typename
        }
        nextToken
        __typename
      }
      createdAt
      _version
      updatedAt
      __typename
    }
  }
`;
export const onUpdateUser = /* GraphQL */ `
  subscription OnUpdateUser(
    $filter: ModelSubscriptionUserFilterInput
    $id: String
  ) {
    onUpdateUser(filter: $filter, id: $id) {
      id
      name
      email
      phoneNumber
      rig {
        items {
          id
          ownerID
          image
          length
          width
          year
          make
          model
          _version
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      avatar
      about
      myHostedSpaces {
        items {
          id
          propertyID
          ownerID
          bookings {
            nextToken
            __typename
          }
          bookingRequests {
            nextToken
            __typename
          }
          space_approved
          active
          title
          description
          unavailable_days
          set_availability
          longest_stay_allowed
          shortest_stay_allowed
          width
          length
          electric_hookup
          water_hookup
          sewage_hookup
          wifi_available
          wifi_password
          wifi_network_name
          shower_available
          restroom_available
          food_nearby
          restaurant_nearby
          water_accessible
          easy_entry
          pre_booking_message_required
          pre_booking_message
          instant_book
          distance_electric_hookup
          distance_water_hookup
          distance_sewage_hookup
          parking_spaces
          additional_rules
          parties_allowed
          things_to_do
          images
          laundry_available
          pool_available
          hottub_available
          outdoor_area_available
          indoor_area_available
          trash_available
          extra_amenities_available
          host_acknowledge_liability
          host_acknowledge_tax_responsibility
          check_in_time
          check_out_time
          check_in_instructions
          space_rules
          pets_allowed
          smoking_allowed
          adults_only
          quiet_hours_start
          quiet_hours_end
          outdoor_fires
          profile_photo_required
          full_refund_date
          half_refund_date
          price_storage
          price_shortterm
          price_longterm
          price_yearly
          _version
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      myHostedProperties {
        items {
          id
          active
          ownerID
          spaces {
            nextToken
            __typename
          }
          title
          description
          address {
            id
            address
            cityName
            stateName
            zipcode
            countryName
            canadianCivicAddress
            _version
            createdAt
            updatedAt
            owner
            __typename
          }
          coordinates_lat
          coordinates_lng
          type_storage
          type_longterm
          type_shortterm
          type_yearly
          price_storage
          price_shortterm
          price_longterm
          price_yearly
          instant_book
          images
          reviews {
            nextToken
            __typename
          }
          conversations {
            nextToken
            __typename
          }
          _version
          createdAt
          updatedAt
          propertyAddressId
          __typename
        }
        nextToken
        __typename
      }
      favorites
      stripePayoutsEnabled
      stripeConnectedAccountId
      stripeCustomerID
      stripePaymentMethodID
      govID_verified
      bookings {
        items {
          id
          members
          cancelled
          cancelledTooLate
          cancelledTooLatePayout
          cancelledDate
          spaceID
          renterID
          hostID
          bookingRequestId
          bookingRequest {
            id
            members
            confirmed
            reviewed
            cancelled
            rentalType
            stripePaymentMethodID
            stripeCustomerID
            stripePaymentIntentID
            check_in
            check_out
            bookedDays
            spaceID
            spaceTitle
            spaceCity
            spaceState
            propertyTitle
            propertyID
            renterID
            bookingID
            hostID
            hostEmail
            renterEmail
            createdAt
            updatedAt
            renter_acknowledge_liability
            baseBookingCost
            renterFeeCost
            renterTotalBookingCost
            renterStripeFee
            hostFeeCost
            hostPayout
            hookhubPayout
            payMonthly
            numberOfMonths
            paymentSchedule
            nextPaymentDue
            nextPaymentsSchedule
            monthlyPaymentAmount
            monthlyHookhubPayout
            monthlyHostPayout
            full_refund_enabled
            no_refund_enabled
            half_refund_enabled
            full_refund_date
            half_refund_date
            errorCode
            _version
            bookingRequestConversationId
            __typename
          }
          paymentID
          payment {
            id
            members
            date
            stripePaymentMethodID
            stripeCustomerID
            paid
            hostID
            bookingId
            baseBookingCost
            renterFeeCost
            renterTotalBookingCost
            renterStripeFee
            hostFeeCost
            hostPayout
            hookhubPayout
            createdAt
            updatedAt
            _version
            __typename
          }
          bookedDays
          check_in
          check_out
          stripePaymentMethodID
          stripeCustomerID
          stripeHostConnectedAccountId
          hostEmail
          renterEmail
          createdAt
          updatedAt
          baseBookingCost
          renterFeeCost
          renterTotalBookingCost
          renterStripeFee
          hostFeeCost
          hostPayout
          hookhubPayout
          paymentRequiresAction
          payMonthly
          numberOfMonths
          paymentSchedule
          nextPaymentsSchedule
          nextPaymentDue
          monthlyPaymentAmount
          monthlyHostPayout
          monthlyHookhubPayout
          cancelledFinalHostPayout
          cancelledFinalRenterPayment
          cancelledBeforeCheckIn
          refundRenter
          full_refund_enabled
          no_refund_enabled
          half_refund_enabled
          full_refund_date
          half_refund_date
          stripePaymentIntentID
          _version
          __typename
        }
        nextToken
        __typename
      }
      bookingRequests {
        items {
          id
          members
          confirmed
          reviewed
          cancelled
          rentalType
          stripePaymentMethodID
          stripeCustomerID
          stripePaymentIntentID
          check_in
          check_out
          bookedDays
          spaceID
          spaceTitle
          spaceCity
          spaceState
          propertyTitle
          propertyID
          renterID
          bookingID
          booking {
            id
            members
            cancelled
            cancelledTooLate
            cancelledTooLatePayout
            cancelledDate
            spaceID
            renterID
            hostID
            bookingRequestId
            paymentID
            bookedDays
            check_in
            check_out
            stripePaymentMethodID
            stripeCustomerID
            stripeHostConnectedAccountId
            hostEmail
            renterEmail
            createdAt
            updatedAt
            baseBookingCost
            renterFeeCost
            renterTotalBookingCost
            renterStripeFee
            hostFeeCost
            hostPayout
            hookhubPayout
            paymentRequiresAction
            payMonthly
            numberOfMonths
            paymentSchedule
            nextPaymentsSchedule
            nextPaymentDue
            monthlyPaymentAmount
            monthlyHostPayout
            monthlyHookhubPayout
            cancelledFinalHostPayout
            cancelledFinalRenterPayment
            cancelledBeforeCheckIn
            refundRenter
            full_refund_enabled
            no_refund_enabled
            half_refund_enabled
            full_refund_date
            half_refund_date
            stripePaymentIntentID
            _version
            __typename
          }
          hostID
          hostEmail
          renterEmail
          conversation {
            id
            hostee
            host
            convoCreatorsEmail
            otherMembersEmail
            spaceID
            spaceTitle
            propertyID
            propertyTitle
            propertyLocation
            bookingRequestID
            members
            membersNames
            unreadHost
            unreadHostee
            type
            createdAt
            updatedAt
            _version
            __typename
          }
          createdAt
          updatedAt
          renter_acknowledge_liability
          baseBookingCost
          renterFeeCost
          renterTotalBookingCost
          renterStripeFee
          hostFeeCost
          hostPayout
          hookhubPayout
          payMonthly
          numberOfMonths
          paymentSchedule
          nextPaymentDue
          nextPaymentsSchedule
          monthlyPaymentAmount
          monthlyHookhubPayout
          monthlyHostPayout
          full_refund_enabled
          no_refund_enabled
          half_refund_enabled
          full_refund_date
          half_refund_date
          errorCode
          _version
          bookingRequestConversationId
          __typename
        }
        nextToken
        __typename
      }
      bookingsHosted {
        items {
          id
          members
          cancelled
          cancelledTooLate
          cancelledTooLatePayout
          cancelledDate
          spaceID
          renterID
          hostID
          bookingRequestId
          bookingRequest {
            id
            members
            confirmed
            reviewed
            cancelled
            rentalType
            stripePaymentMethodID
            stripeCustomerID
            stripePaymentIntentID
            check_in
            check_out
            bookedDays
            spaceID
            spaceTitle
            spaceCity
            spaceState
            propertyTitle
            propertyID
            renterID
            bookingID
            hostID
            hostEmail
            renterEmail
            createdAt
            updatedAt
            renter_acknowledge_liability
            baseBookingCost
            renterFeeCost
            renterTotalBookingCost
            renterStripeFee
            hostFeeCost
            hostPayout
            hookhubPayout
            payMonthly
            numberOfMonths
            paymentSchedule
            nextPaymentDue
            nextPaymentsSchedule
            monthlyPaymentAmount
            monthlyHookhubPayout
            monthlyHostPayout
            full_refund_enabled
            no_refund_enabled
            half_refund_enabled
            full_refund_date
            half_refund_date
            errorCode
            _version
            bookingRequestConversationId
            __typename
          }
          paymentID
          payment {
            id
            members
            date
            stripePaymentMethodID
            stripeCustomerID
            paid
            hostID
            bookingId
            baseBookingCost
            renterFeeCost
            renterTotalBookingCost
            renterStripeFee
            hostFeeCost
            hostPayout
            hookhubPayout
            createdAt
            updatedAt
            _version
            __typename
          }
          bookedDays
          check_in
          check_out
          stripePaymentMethodID
          stripeCustomerID
          stripeHostConnectedAccountId
          hostEmail
          renterEmail
          createdAt
          updatedAt
          baseBookingCost
          renterFeeCost
          renterTotalBookingCost
          renterStripeFee
          hostFeeCost
          hostPayout
          hookhubPayout
          paymentRequiresAction
          payMonthly
          numberOfMonths
          paymentSchedule
          nextPaymentsSchedule
          nextPaymentDue
          monthlyPaymentAmount
          monthlyHostPayout
          monthlyHookhubPayout
          cancelledFinalHostPayout
          cancelledFinalRenterPayment
          cancelledBeforeCheckIn
          refundRenter
          full_refund_enabled
          no_refund_enabled
          half_refund_enabled
          full_refund_date
          half_refund_date
          stripePaymentIntentID
          _version
          __typename
        }
        nextToken
        __typename
      }
      reviewsOfRenter {
        items {
          id
          rating
          cleanlinessRating
          communicationRating
          overallRating
          description
          renterID
          reviewerName
          reviewerAvatar
          reviewerID
          bookingID
          propertyID
          _version
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      hosteeConvos {
        items {
          id
          hostee
          host
          convoCreatorsEmail
          otherMembersEmail
          spaceID
          spaceTitle
          propertyID
          propertyTitle
          propertyLocation
          bookingRequestID
          bookingRequest {
            id
            members
            confirmed
            reviewed
            cancelled
            rentalType
            stripePaymentMethodID
            stripeCustomerID
            stripePaymentIntentID
            check_in
            check_out
            bookedDays
            spaceID
            spaceTitle
            spaceCity
            spaceState
            propertyTitle
            propertyID
            renterID
            bookingID
            hostID
            hostEmail
            renterEmail
            createdAt
            updatedAt
            renter_acknowledge_liability
            baseBookingCost
            renterFeeCost
            renterTotalBookingCost
            renterStripeFee
            hostFeeCost
            hostPayout
            hookhubPayout
            payMonthly
            numberOfMonths
            paymentSchedule
            nextPaymentDue
            nextPaymentsSchedule
            monthlyPaymentAmount
            monthlyHookhubPayout
            monthlyHostPayout
            full_refund_enabled
            no_refund_enabled
            half_refund_enabled
            full_refund_date
            half_refund_date
            errorCode
            _version
            bookingRequestConversationId
            __typename
          }
          messages {
            nextToken
            __typename
          }
          members
          membersNames
          unreadHost
          unreadHostee
          type
          createdAt
          updatedAt
          _version
          __typename
        }
        nextToken
        __typename
      }
      hostConvos {
        items {
          id
          hostee
          host
          convoCreatorsEmail
          otherMembersEmail
          spaceID
          spaceTitle
          propertyID
          propertyTitle
          propertyLocation
          bookingRequestID
          bookingRequest {
            id
            members
            confirmed
            reviewed
            cancelled
            rentalType
            stripePaymentMethodID
            stripeCustomerID
            stripePaymentIntentID
            check_in
            check_out
            bookedDays
            spaceID
            spaceTitle
            spaceCity
            spaceState
            propertyTitle
            propertyID
            renterID
            bookingID
            hostID
            hostEmail
            renterEmail
            createdAt
            updatedAt
            renter_acknowledge_liability
            baseBookingCost
            renterFeeCost
            renterTotalBookingCost
            renterStripeFee
            hostFeeCost
            hostPayout
            hookhubPayout
            payMonthly
            numberOfMonths
            paymentSchedule
            nextPaymentDue
            nextPaymentsSchedule
            monthlyPaymentAmount
            monthlyHookhubPayout
            monthlyHostPayout
            full_refund_enabled
            no_refund_enabled
            half_refund_enabled
            full_refund_date
            half_refund_date
            errorCode
            _version
            bookingRequestConversationId
            __typename
          }
          messages {
            nextToken
            __typename
          }
          members
          membersNames
          unreadHost
          unreadHostee
          type
          createdAt
          updatedAt
          _version
          __typename
        }
        nextToken
        __typename
      }
      payments {
        items {
          id
          members
          date
          stripePaymentMethodID
          stripeCustomerID
          paid
          hostID
          bookingId
          booking {
            id
            members
            cancelled
            cancelledTooLate
            cancelledTooLatePayout
            cancelledDate
            spaceID
            renterID
            hostID
            bookingRequestId
            paymentID
            bookedDays
            check_in
            check_out
            stripePaymentMethodID
            stripeCustomerID
            stripeHostConnectedAccountId
            hostEmail
            renterEmail
            createdAt
            updatedAt
            baseBookingCost
            renterFeeCost
            renterTotalBookingCost
            renterStripeFee
            hostFeeCost
            hostPayout
            hookhubPayout
            paymentRequiresAction
            payMonthly
            numberOfMonths
            paymentSchedule
            nextPaymentsSchedule
            nextPaymentDue
            monthlyPaymentAmount
            monthlyHostPayout
            monthlyHookhubPayout
            cancelledFinalHostPayout
            cancelledFinalRenterPayment
            cancelledBeforeCheckIn
            refundRenter
            full_refund_enabled
            no_refund_enabled
            half_refund_enabled
            full_refund_date
            half_refund_date
            stripePaymentIntentID
            _version
            __typename
          }
          baseBookingCost
          renterFeeCost
          renterTotalBookingCost
          renterStripeFee
          hostFeeCost
          hostPayout
          hookhubPayout
          createdAt
          updatedAt
          _version
          __typename
        }
        nextToken
        __typename
      }
      createdAt
      _version
      updatedAt
      __typename
    }
  }
`;
export const onDeleteUser = /* GraphQL */ `
  subscription OnDeleteUser(
    $filter: ModelSubscriptionUserFilterInput
    $id: String
  ) {
    onDeleteUser(filter: $filter, id: $id) {
      id
      name
      email
      phoneNumber
      rig {
        items {
          id
          ownerID
          image
          length
          width
          year
          make
          model
          _version
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      avatar
      about
      myHostedSpaces {
        items {
          id
          propertyID
          ownerID
          bookings {
            nextToken
            __typename
          }
          bookingRequests {
            nextToken
            __typename
          }
          space_approved
          active
          title
          description
          unavailable_days
          set_availability
          longest_stay_allowed
          shortest_stay_allowed
          width
          length
          electric_hookup
          water_hookup
          sewage_hookup
          wifi_available
          wifi_password
          wifi_network_name
          shower_available
          restroom_available
          food_nearby
          restaurant_nearby
          water_accessible
          easy_entry
          pre_booking_message_required
          pre_booking_message
          instant_book
          distance_electric_hookup
          distance_water_hookup
          distance_sewage_hookup
          parking_spaces
          additional_rules
          parties_allowed
          things_to_do
          images
          laundry_available
          pool_available
          hottub_available
          outdoor_area_available
          indoor_area_available
          trash_available
          extra_amenities_available
          host_acknowledge_liability
          host_acknowledge_tax_responsibility
          check_in_time
          check_out_time
          check_in_instructions
          space_rules
          pets_allowed
          smoking_allowed
          adults_only
          quiet_hours_start
          quiet_hours_end
          outdoor_fires
          profile_photo_required
          full_refund_date
          half_refund_date
          price_storage
          price_shortterm
          price_longterm
          price_yearly
          _version
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      myHostedProperties {
        items {
          id
          active
          ownerID
          spaces {
            nextToken
            __typename
          }
          title
          description
          address {
            id
            address
            cityName
            stateName
            zipcode
            countryName
            canadianCivicAddress
            _version
            createdAt
            updatedAt
            owner
            __typename
          }
          coordinates_lat
          coordinates_lng
          type_storage
          type_longterm
          type_shortterm
          type_yearly
          price_storage
          price_shortterm
          price_longterm
          price_yearly
          instant_book
          images
          reviews {
            nextToken
            __typename
          }
          conversations {
            nextToken
            __typename
          }
          _version
          createdAt
          updatedAt
          propertyAddressId
          __typename
        }
        nextToken
        __typename
      }
      favorites
      stripePayoutsEnabled
      stripeConnectedAccountId
      stripeCustomerID
      stripePaymentMethodID
      govID_verified
      bookings {
        items {
          id
          members
          cancelled
          cancelledTooLate
          cancelledTooLatePayout
          cancelledDate
          spaceID
          renterID
          hostID
          bookingRequestId
          bookingRequest {
            id
            members
            confirmed
            reviewed
            cancelled
            rentalType
            stripePaymentMethodID
            stripeCustomerID
            stripePaymentIntentID
            check_in
            check_out
            bookedDays
            spaceID
            spaceTitle
            spaceCity
            spaceState
            propertyTitle
            propertyID
            renterID
            bookingID
            hostID
            hostEmail
            renterEmail
            createdAt
            updatedAt
            renter_acknowledge_liability
            baseBookingCost
            renterFeeCost
            renterTotalBookingCost
            renterStripeFee
            hostFeeCost
            hostPayout
            hookhubPayout
            payMonthly
            numberOfMonths
            paymentSchedule
            nextPaymentDue
            nextPaymentsSchedule
            monthlyPaymentAmount
            monthlyHookhubPayout
            monthlyHostPayout
            full_refund_enabled
            no_refund_enabled
            half_refund_enabled
            full_refund_date
            half_refund_date
            errorCode
            _version
            bookingRequestConversationId
            __typename
          }
          paymentID
          payment {
            id
            members
            date
            stripePaymentMethodID
            stripeCustomerID
            paid
            hostID
            bookingId
            baseBookingCost
            renterFeeCost
            renterTotalBookingCost
            renterStripeFee
            hostFeeCost
            hostPayout
            hookhubPayout
            createdAt
            updatedAt
            _version
            __typename
          }
          bookedDays
          check_in
          check_out
          stripePaymentMethodID
          stripeCustomerID
          stripeHostConnectedAccountId
          hostEmail
          renterEmail
          createdAt
          updatedAt
          baseBookingCost
          renterFeeCost
          renterTotalBookingCost
          renterStripeFee
          hostFeeCost
          hostPayout
          hookhubPayout
          paymentRequiresAction
          payMonthly
          numberOfMonths
          paymentSchedule
          nextPaymentsSchedule
          nextPaymentDue
          monthlyPaymentAmount
          monthlyHostPayout
          monthlyHookhubPayout
          cancelledFinalHostPayout
          cancelledFinalRenterPayment
          cancelledBeforeCheckIn
          refundRenter
          full_refund_enabled
          no_refund_enabled
          half_refund_enabled
          full_refund_date
          half_refund_date
          stripePaymentIntentID
          _version
          __typename
        }
        nextToken
        __typename
      }
      bookingRequests {
        items {
          id
          members
          confirmed
          reviewed
          cancelled
          rentalType
          stripePaymentMethodID
          stripeCustomerID
          stripePaymentIntentID
          check_in
          check_out
          bookedDays
          spaceID
          spaceTitle
          spaceCity
          spaceState
          propertyTitle
          propertyID
          renterID
          bookingID
          booking {
            id
            members
            cancelled
            cancelledTooLate
            cancelledTooLatePayout
            cancelledDate
            spaceID
            renterID
            hostID
            bookingRequestId
            paymentID
            bookedDays
            check_in
            check_out
            stripePaymentMethodID
            stripeCustomerID
            stripeHostConnectedAccountId
            hostEmail
            renterEmail
            createdAt
            updatedAt
            baseBookingCost
            renterFeeCost
            renterTotalBookingCost
            renterStripeFee
            hostFeeCost
            hostPayout
            hookhubPayout
            paymentRequiresAction
            payMonthly
            numberOfMonths
            paymentSchedule
            nextPaymentsSchedule
            nextPaymentDue
            monthlyPaymentAmount
            monthlyHostPayout
            monthlyHookhubPayout
            cancelledFinalHostPayout
            cancelledFinalRenterPayment
            cancelledBeforeCheckIn
            refundRenter
            full_refund_enabled
            no_refund_enabled
            half_refund_enabled
            full_refund_date
            half_refund_date
            stripePaymentIntentID
            _version
            __typename
          }
          hostID
          hostEmail
          renterEmail
          conversation {
            id
            hostee
            host
            convoCreatorsEmail
            otherMembersEmail
            spaceID
            spaceTitle
            propertyID
            propertyTitle
            propertyLocation
            bookingRequestID
            members
            membersNames
            unreadHost
            unreadHostee
            type
            createdAt
            updatedAt
            _version
            __typename
          }
          createdAt
          updatedAt
          renter_acknowledge_liability
          baseBookingCost
          renterFeeCost
          renterTotalBookingCost
          renterStripeFee
          hostFeeCost
          hostPayout
          hookhubPayout
          payMonthly
          numberOfMonths
          paymentSchedule
          nextPaymentDue
          nextPaymentsSchedule
          monthlyPaymentAmount
          monthlyHookhubPayout
          monthlyHostPayout
          full_refund_enabled
          no_refund_enabled
          half_refund_enabled
          full_refund_date
          half_refund_date
          errorCode
          _version
          bookingRequestConversationId
          __typename
        }
        nextToken
        __typename
      }
      bookingsHosted {
        items {
          id
          members
          cancelled
          cancelledTooLate
          cancelledTooLatePayout
          cancelledDate
          spaceID
          renterID
          hostID
          bookingRequestId
          bookingRequest {
            id
            members
            confirmed
            reviewed
            cancelled
            rentalType
            stripePaymentMethodID
            stripeCustomerID
            stripePaymentIntentID
            check_in
            check_out
            bookedDays
            spaceID
            spaceTitle
            spaceCity
            spaceState
            propertyTitle
            propertyID
            renterID
            bookingID
            hostID
            hostEmail
            renterEmail
            createdAt
            updatedAt
            renter_acknowledge_liability
            baseBookingCost
            renterFeeCost
            renterTotalBookingCost
            renterStripeFee
            hostFeeCost
            hostPayout
            hookhubPayout
            payMonthly
            numberOfMonths
            paymentSchedule
            nextPaymentDue
            nextPaymentsSchedule
            monthlyPaymentAmount
            monthlyHookhubPayout
            monthlyHostPayout
            full_refund_enabled
            no_refund_enabled
            half_refund_enabled
            full_refund_date
            half_refund_date
            errorCode
            _version
            bookingRequestConversationId
            __typename
          }
          paymentID
          payment {
            id
            members
            date
            stripePaymentMethodID
            stripeCustomerID
            paid
            hostID
            bookingId
            baseBookingCost
            renterFeeCost
            renterTotalBookingCost
            renterStripeFee
            hostFeeCost
            hostPayout
            hookhubPayout
            createdAt
            updatedAt
            _version
            __typename
          }
          bookedDays
          check_in
          check_out
          stripePaymentMethodID
          stripeCustomerID
          stripeHostConnectedAccountId
          hostEmail
          renterEmail
          createdAt
          updatedAt
          baseBookingCost
          renterFeeCost
          renterTotalBookingCost
          renterStripeFee
          hostFeeCost
          hostPayout
          hookhubPayout
          paymentRequiresAction
          payMonthly
          numberOfMonths
          paymentSchedule
          nextPaymentsSchedule
          nextPaymentDue
          monthlyPaymentAmount
          monthlyHostPayout
          monthlyHookhubPayout
          cancelledFinalHostPayout
          cancelledFinalRenterPayment
          cancelledBeforeCheckIn
          refundRenter
          full_refund_enabled
          no_refund_enabled
          half_refund_enabled
          full_refund_date
          half_refund_date
          stripePaymentIntentID
          _version
          __typename
        }
        nextToken
        __typename
      }
      reviewsOfRenter {
        items {
          id
          rating
          cleanlinessRating
          communicationRating
          overallRating
          description
          renterID
          reviewerName
          reviewerAvatar
          reviewerID
          bookingID
          propertyID
          _version
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      hosteeConvos {
        items {
          id
          hostee
          host
          convoCreatorsEmail
          otherMembersEmail
          spaceID
          spaceTitle
          propertyID
          propertyTitle
          propertyLocation
          bookingRequestID
          bookingRequest {
            id
            members
            confirmed
            reviewed
            cancelled
            rentalType
            stripePaymentMethodID
            stripeCustomerID
            stripePaymentIntentID
            check_in
            check_out
            bookedDays
            spaceID
            spaceTitle
            spaceCity
            spaceState
            propertyTitle
            propertyID
            renterID
            bookingID
            hostID
            hostEmail
            renterEmail
            createdAt
            updatedAt
            renter_acknowledge_liability
            baseBookingCost
            renterFeeCost
            renterTotalBookingCost
            renterStripeFee
            hostFeeCost
            hostPayout
            hookhubPayout
            payMonthly
            numberOfMonths
            paymentSchedule
            nextPaymentDue
            nextPaymentsSchedule
            monthlyPaymentAmount
            monthlyHookhubPayout
            monthlyHostPayout
            full_refund_enabled
            no_refund_enabled
            half_refund_enabled
            full_refund_date
            half_refund_date
            errorCode
            _version
            bookingRequestConversationId
            __typename
          }
          messages {
            nextToken
            __typename
          }
          members
          membersNames
          unreadHost
          unreadHostee
          type
          createdAt
          updatedAt
          _version
          __typename
        }
        nextToken
        __typename
      }
      hostConvos {
        items {
          id
          hostee
          host
          convoCreatorsEmail
          otherMembersEmail
          spaceID
          spaceTitle
          propertyID
          propertyTitle
          propertyLocation
          bookingRequestID
          bookingRequest {
            id
            members
            confirmed
            reviewed
            cancelled
            rentalType
            stripePaymentMethodID
            stripeCustomerID
            stripePaymentIntentID
            check_in
            check_out
            bookedDays
            spaceID
            spaceTitle
            spaceCity
            spaceState
            propertyTitle
            propertyID
            renterID
            bookingID
            hostID
            hostEmail
            renterEmail
            createdAt
            updatedAt
            renter_acknowledge_liability
            baseBookingCost
            renterFeeCost
            renterTotalBookingCost
            renterStripeFee
            hostFeeCost
            hostPayout
            hookhubPayout
            payMonthly
            numberOfMonths
            paymentSchedule
            nextPaymentDue
            nextPaymentsSchedule
            monthlyPaymentAmount
            monthlyHookhubPayout
            monthlyHostPayout
            full_refund_enabled
            no_refund_enabled
            half_refund_enabled
            full_refund_date
            half_refund_date
            errorCode
            _version
            bookingRequestConversationId
            __typename
          }
          messages {
            nextToken
            __typename
          }
          members
          membersNames
          unreadHost
          unreadHostee
          type
          createdAt
          updatedAt
          _version
          __typename
        }
        nextToken
        __typename
      }
      payments {
        items {
          id
          members
          date
          stripePaymentMethodID
          stripeCustomerID
          paid
          hostID
          bookingId
          booking {
            id
            members
            cancelled
            cancelledTooLate
            cancelledTooLatePayout
            cancelledDate
            spaceID
            renterID
            hostID
            bookingRequestId
            paymentID
            bookedDays
            check_in
            check_out
            stripePaymentMethodID
            stripeCustomerID
            stripeHostConnectedAccountId
            hostEmail
            renterEmail
            createdAt
            updatedAt
            baseBookingCost
            renterFeeCost
            renterTotalBookingCost
            renterStripeFee
            hostFeeCost
            hostPayout
            hookhubPayout
            paymentRequiresAction
            payMonthly
            numberOfMonths
            paymentSchedule
            nextPaymentsSchedule
            nextPaymentDue
            monthlyPaymentAmount
            monthlyHostPayout
            monthlyHookhubPayout
            cancelledFinalHostPayout
            cancelledFinalRenterPayment
            cancelledBeforeCheckIn
            refundRenter
            full_refund_enabled
            no_refund_enabled
            half_refund_enabled
            full_refund_date
            half_refund_date
            stripePaymentIntentID
            _version
            __typename
          }
          baseBookingCost
          renterFeeCost
          renterTotalBookingCost
          renterStripeFee
          hostFeeCost
          hostPayout
          hookhubPayout
          createdAt
          updatedAt
          _version
          __typename
        }
        nextToken
        __typename
      }
      createdAt
      _version
      updatedAt
      __typename
    }
  }
`;
export const onCreateRig = /* GraphQL */ `
  subscription OnCreateRig(
    $filter: ModelSubscriptionRigFilterInput
    $ownerID: String
  ) {
    onCreateRig(filter: $filter, ownerID: $ownerID) {
      id
      ownerID
      image
      length
      width
      year
      make
      model
      _version
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onUpdateRig = /* GraphQL */ `
  subscription OnUpdateRig(
    $filter: ModelSubscriptionRigFilterInput
    $ownerID: String
  ) {
    onUpdateRig(filter: $filter, ownerID: $ownerID) {
      id
      ownerID
      image
      length
      width
      year
      make
      model
      _version
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onDeleteRig = /* GraphQL */ `
  subscription OnDeleteRig(
    $filter: ModelSubscriptionRigFilterInput
    $ownerID: String
  ) {
    onDeleteRig(filter: $filter, ownerID: $ownerID) {
      id
      ownerID
      image
      length
      width
      year
      make
      model
      _version
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onCreateSpace = /* GraphQL */ `
  subscription OnCreateSpace(
    $filter: ModelSubscriptionSpaceFilterInput
    $ownerID: String
  ) {
    onCreateSpace(filter: $filter, ownerID: $ownerID) {
      id
      propertyID
      ownerID
      bookings {
        items {
          id
          members
          cancelled
          cancelledTooLate
          cancelledTooLatePayout
          cancelledDate
          spaceID
          renterID
          hostID
          bookingRequestId
          bookingRequest {
            id
            members
            confirmed
            reviewed
            cancelled
            rentalType
            stripePaymentMethodID
            stripeCustomerID
            stripePaymentIntentID
            check_in
            check_out
            bookedDays
            spaceID
            spaceTitle
            spaceCity
            spaceState
            propertyTitle
            propertyID
            renterID
            bookingID
            hostID
            hostEmail
            renterEmail
            createdAt
            updatedAt
            renter_acknowledge_liability
            baseBookingCost
            renterFeeCost
            renterTotalBookingCost
            renterStripeFee
            hostFeeCost
            hostPayout
            hookhubPayout
            payMonthly
            numberOfMonths
            paymentSchedule
            nextPaymentDue
            nextPaymentsSchedule
            monthlyPaymentAmount
            monthlyHookhubPayout
            monthlyHostPayout
            full_refund_enabled
            no_refund_enabled
            half_refund_enabled
            full_refund_date
            half_refund_date
            errorCode
            _version
            bookingRequestConversationId
            __typename
          }
          paymentID
          payment {
            id
            members
            date
            stripePaymentMethodID
            stripeCustomerID
            paid
            hostID
            bookingId
            baseBookingCost
            renterFeeCost
            renterTotalBookingCost
            renterStripeFee
            hostFeeCost
            hostPayout
            hookhubPayout
            createdAt
            updatedAt
            _version
            __typename
          }
          bookedDays
          check_in
          check_out
          stripePaymentMethodID
          stripeCustomerID
          stripeHostConnectedAccountId
          hostEmail
          renterEmail
          createdAt
          updatedAt
          baseBookingCost
          renterFeeCost
          renterTotalBookingCost
          renterStripeFee
          hostFeeCost
          hostPayout
          hookhubPayout
          paymentRequiresAction
          payMonthly
          numberOfMonths
          paymentSchedule
          nextPaymentsSchedule
          nextPaymentDue
          monthlyPaymentAmount
          monthlyHostPayout
          monthlyHookhubPayout
          cancelledFinalHostPayout
          cancelledFinalRenterPayment
          cancelledBeforeCheckIn
          refundRenter
          full_refund_enabled
          no_refund_enabled
          half_refund_enabled
          full_refund_date
          half_refund_date
          stripePaymentIntentID
          _version
          __typename
        }
        nextToken
        __typename
      }
      bookingRequests {
        items {
          id
          members
          confirmed
          reviewed
          cancelled
          rentalType
          stripePaymentMethodID
          stripeCustomerID
          stripePaymentIntentID
          check_in
          check_out
          bookedDays
          spaceID
          spaceTitle
          spaceCity
          spaceState
          propertyTitle
          propertyID
          renterID
          bookingID
          booking {
            id
            members
            cancelled
            cancelledTooLate
            cancelledTooLatePayout
            cancelledDate
            spaceID
            renterID
            hostID
            bookingRequestId
            paymentID
            bookedDays
            check_in
            check_out
            stripePaymentMethodID
            stripeCustomerID
            stripeHostConnectedAccountId
            hostEmail
            renterEmail
            createdAt
            updatedAt
            baseBookingCost
            renterFeeCost
            renterTotalBookingCost
            renterStripeFee
            hostFeeCost
            hostPayout
            hookhubPayout
            paymentRequiresAction
            payMonthly
            numberOfMonths
            paymentSchedule
            nextPaymentsSchedule
            nextPaymentDue
            monthlyPaymentAmount
            monthlyHostPayout
            monthlyHookhubPayout
            cancelledFinalHostPayout
            cancelledFinalRenterPayment
            cancelledBeforeCheckIn
            refundRenter
            full_refund_enabled
            no_refund_enabled
            half_refund_enabled
            full_refund_date
            half_refund_date
            stripePaymentIntentID
            _version
            __typename
          }
          hostID
          hostEmail
          renterEmail
          conversation {
            id
            hostee
            host
            convoCreatorsEmail
            otherMembersEmail
            spaceID
            spaceTitle
            propertyID
            propertyTitle
            propertyLocation
            bookingRequestID
            members
            membersNames
            unreadHost
            unreadHostee
            type
            createdAt
            updatedAt
            _version
            __typename
          }
          createdAt
          updatedAt
          renter_acknowledge_liability
          baseBookingCost
          renterFeeCost
          renterTotalBookingCost
          renterStripeFee
          hostFeeCost
          hostPayout
          hookhubPayout
          payMonthly
          numberOfMonths
          paymentSchedule
          nextPaymentDue
          nextPaymentsSchedule
          monthlyPaymentAmount
          monthlyHookhubPayout
          monthlyHostPayout
          full_refund_enabled
          no_refund_enabled
          half_refund_enabled
          full_refund_date
          half_refund_date
          errorCode
          _version
          bookingRequestConversationId
          __typename
        }
        nextToken
        __typename
      }
      space_approved
      active
      title
      description
      unavailable_days
      set_availability
      longest_stay_allowed
      shortest_stay_allowed
      width
      length
      electric_hookup
      water_hookup
      sewage_hookup
      wifi_available
      wifi_password
      wifi_network_name
      shower_available
      restroom_available
      food_nearby
      restaurant_nearby
      water_accessible
      easy_entry
      pre_booking_message_required
      pre_booking_message
      instant_book
      distance_electric_hookup
      distance_water_hookup
      distance_sewage_hookup
      parking_spaces
      additional_rules
      parties_allowed
      things_to_do
      images
      laundry_available
      pool_available
      hottub_available
      outdoor_area_available
      indoor_area_available
      trash_available
      extra_amenities_available
      host_acknowledge_liability
      host_acknowledge_tax_responsibility
      check_in_time
      check_out_time
      check_in_instructions
      space_rules
      pets_allowed
      smoking_allowed
      adults_only
      quiet_hours_start
      quiet_hours_end
      outdoor_fires
      profile_photo_required
      full_refund_date
      half_refund_date
      price_storage
      price_shortterm
      price_longterm
      price_yearly
      _version
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onUpdateSpace = /* GraphQL */ `
  subscription OnUpdateSpace(
    $filter: ModelSubscriptionSpaceFilterInput
    $ownerID: String
  ) {
    onUpdateSpace(filter: $filter, ownerID: $ownerID) {
      id
      propertyID
      ownerID
      bookings {
        items {
          id
          members
          cancelled
          cancelledTooLate
          cancelledTooLatePayout
          cancelledDate
          spaceID
          renterID
          hostID
          bookingRequestId
          bookingRequest {
            id
            members
            confirmed
            reviewed
            cancelled
            rentalType
            stripePaymentMethodID
            stripeCustomerID
            stripePaymentIntentID
            check_in
            check_out
            bookedDays
            spaceID
            spaceTitle
            spaceCity
            spaceState
            propertyTitle
            propertyID
            renterID
            bookingID
            hostID
            hostEmail
            renterEmail
            createdAt
            updatedAt
            renter_acknowledge_liability
            baseBookingCost
            renterFeeCost
            renterTotalBookingCost
            renterStripeFee
            hostFeeCost
            hostPayout
            hookhubPayout
            payMonthly
            numberOfMonths
            paymentSchedule
            nextPaymentDue
            nextPaymentsSchedule
            monthlyPaymentAmount
            monthlyHookhubPayout
            monthlyHostPayout
            full_refund_enabled
            no_refund_enabled
            half_refund_enabled
            full_refund_date
            half_refund_date
            errorCode
            _version
            bookingRequestConversationId
            __typename
          }
          paymentID
          payment {
            id
            members
            date
            stripePaymentMethodID
            stripeCustomerID
            paid
            hostID
            bookingId
            baseBookingCost
            renterFeeCost
            renterTotalBookingCost
            renterStripeFee
            hostFeeCost
            hostPayout
            hookhubPayout
            createdAt
            updatedAt
            _version
            __typename
          }
          bookedDays
          check_in
          check_out
          stripePaymentMethodID
          stripeCustomerID
          stripeHostConnectedAccountId
          hostEmail
          renterEmail
          createdAt
          updatedAt
          baseBookingCost
          renterFeeCost
          renterTotalBookingCost
          renterStripeFee
          hostFeeCost
          hostPayout
          hookhubPayout
          paymentRequiresAction
          payMonthly
          numberOfMonths
          paymentSchedule
          nextPaymentsSchedule
          nextPaymentDue
          monthlyPaymentAmount
          monthlyHostPayout
          monthlyHookhubPayout
          cancelledFinalHostPayout
          cancelledFinalRenterPayment
          cancelledBeforeCheckIn
          refundRenter
          full_refund_enabled
          no_refund_enabled
          half_refund_enabled
          full_refund_date
          half_refund_date
          stripePaymentIntentID
          _version
          __typename
        }
        nextToken
        __typename
      }
      bookingRequests {
        items {
          id
          members
          confirmed
          reviewed
          cancelled
          rentalType
          stripePaymentMethodID
          stripeCustomerID
          stripePaymentIntentID
          check_in
          check_out
          bookedDays
          spaceID
          spaceTitle
          spaceCity
          spaceState
          propertyTitle
          propertyID
          renterID
          bookingID
          booking {
            id
            members
            cancelled
            cancelledTooLate
            cancelledTooLatePayout
            cancelledDate
            spaceID
            renterID
            hostID
            bookingRequestId
            paymentID
            bookedDays
            check_in
            check_out
            stripePaymentMethodID
            stripeCustomerID
            stripeHostConnectedAccountId
            hostEmail
            renterEmail
            createdAt
            updatedAt
            baseBookingCost
            renterFeeCost
            renterTotalBookingCost
            renterStripeFee
            hostFeeCost
            hostPayout
            hookhubPayout
            paymentRequiresAction
            payMonthly
            numberOfMonths
            paymentSchedule
            nextPaymentsSchedule
            nextPaymentDue
            monthlyPaymentAmount
            monthlyHostPayout
            monthlyHookhubPayout
            cancelledFinalHostPayout
            cancelledFinalRenterPayment
            cancelledBeforeCheckIn
            refundRenter
            full_refund_enabled
            no_refund_enabled
            half_refund_enabled
            full_refund_date
            half_refund_date
            stripePaymentIntentID
            _version
            __typename
          }
          hostID
          hostEmail
          renterEmail
          conversation {
            id
            hostee
            host
            convoCreatorsEmail
            otherMembersEmail
            spaceID
            spaceTitle
            propertyID
            propertyTitle
            propertyLocation
            bookingRequestID
            members
            membersNames
            unreadHost
            unreadHostee
            type
            createdAt
            updatedAt
            _version
            __typename
          }
          createdAt
          updatedAt
          renter_acknowledge_liability
          baseBookingCost
          renterFeeCost
          renterTotalBookingCost
          renterStripeFee
          hostFeeCost
          hostPayout
          hookhubPayout
          payMonthly
          numberOfMonths
          paymentSchedule
          nextPaymentDue
          nextPaymentsSchedule
          monthlyPaymentAmount
          monthlyHookhubPayout
          monthlyHostPayout
          full_refund_enabled
          no_refund_enabled
          half_refund_enabled
          full_refund_date
          half_refund_date
          errorCode
          _version
          bookingRequestConversationId
          __typename
        }
        nextToken
        __typename
      }
      space_approved
      active
      title
      description
      unavailable_days
      set_availability
      longest_stay_allowed
      shortest_stay_allowed
      width
      length
      electric_hookup
      water_hookup
      sewage_hookup
      wifi_available
      wifi_password
      wifi_network_name
      shower_available
      restroom_available
      food_nearby
      restaurant_nearby
      water_accessible
      easy_entry
      pre_booking_message_required
      pre_booking_message
      instant_book
      distance_electric_hookup
      distance_water_hookup
      distance_sewage_hookup
      parking_spaces
      additional_rules
      parties_allowed
      things_to_do
      images
      laundry_available
      pool_available
      hottub_available
      outdoor_area_available
      indoor_area_available
      trash_available
      extra_amenities_available
      host_acknowledge_liability
      host_acknowledge_tax_responsibility
      check_in_time
      check_out_time
      check_in_instructions
      space_rules
      pets_allowed
      smoking_allowed
      adults_only
      quiet_hours_start
      quiet_hours_end
      outdoor_fires
      profile_photo_required
      full_refund_date
      half_refund_date
      price_storage
      price_shortterm
      price_longterm
      price_yearly
      _version
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onDeleteSpace = /* GraphQL */ `
  subscription OnDeleteSpace(
    $filter: ModelSubscriptionSpaceFilterInput
    $ownerID: String
  ) {
    onDeleteSpace(filter: $filter, ownerID: $ownerID) {
      id
      propertyID
      ownerID
      bookings {
        items {
          id
          members
          cancelled
          cancelledTooLate
          cancelledTooLatePayout
          cancelledDate
          spaceID
          renterID
          hostID
          bookingRequestId
          bookingRequest {
            id
            members
            confirmed
            reviewed
            cancelled
            rentalType
            stripePaymentMethodID
            stripeCustomerID
            stripePaymentIntentID
            check_in
            check_out
            bookedDays
            spaceID
            spaceTitle
            spaceCity
            spaceState
            propertyTitle
            propertyID
            renterID
            bookingID
            hostID
            hostEmail
            renterEmail
            createdAt
            updatedAt
            renter_acknowledge_liability
            baseBookingCost
            renterFeeCost
            renterTotalBookingCost
            renterStripeFee
            hostFeeCost
            hostPayout
            hookhubPayout
            payMonthly
            numberOfMonths
            paymentSchedule
            nextPaymentDue
            nextPaymentsSchedule
            monthlyPaymentAmount
            monthlyHookhubPayout
            monthlyHostPayout
            full_refund_enabled
            no_refund_enabled
            half_refund_enabled
            full_refund_date
            half_refund_date
            errorCode
            _version
            bookingRequestConversationId
            __typename
          }
          paymentID
          payment {
            id
            members
            date
            stripePaymentMethodID
            stripeCustomerID
            paid
            hostID
            bookingId
            baseBookingCost
            renterFeeCost
            renterTotalBookingCost
            renterStripeFee
            hostFeeCost
            hostPayout
            hookhubPayout
            createdAt
            updatedAt
            _version
            __typename
          }
          bookedDays
          check_in
          check_out
          stripePaymentMethodID
          stripeCustomerID
          stripeHostConnectedAccountId
          hostEmail
          renterEmail
          createdAt
          updatedAt
          baseBookingCost
          renterFeeCost
          renterTotalBookingCost
          renterStripeFee
          hostFeeCost
          hostPayout
          hookhubPayout
          paymentRequiresAction
          payMonthly
          numberOfMonths
          paymentSchedule
          nextPaymentsSchedule
          nextPaymentDue
          monthlyPaymentAmount
          monthlyHostPayout
          monthlyHookhubPayout
          cancelledFinalHostPayout
          cancelledFinalRenterPayment
          cancelledBeforeCheckIn
          refundRenter
          full_refund_enabled
          no_refund_enabled
          half_refund_enabled
          full_refund_date
          half_refund_date
          stripePaymentIntentID
          _version
          __typename
        }
        nextToken
        __typename
      }
      bookingRequests {
        items {
          id
          members
          confirmed
          reviewed
          cancelled
          rentalType
          stripePaymentMethodID
          stripeCustomerID
          stripePaymentIntentID
          check_in
          check_out
          bookedDays
          spaceID
          spaceTitle
          spaceCity
          spaceState
          propertyTitle
          propertyID
          renterID
          bookingID
          booking {
            id
            members
            cancelled
            cancelledTooLate
            cancelledTooLatePayout
            cancelledDate
            spaceID
            renterID
            hostID
            bookingRequestId
            paymentID
            bookedDays
            check_in
            check_out
            stripePaymentMethodID
            stripeCustomerID
            stripeHostConnectedAccountId
            hostEmail
            renterEmail
            createdAt
            updatedAt
            baseBookingCost
            renterFeeCost
            renterTotalBookingCost
            renterStripeFee
            hostFeeCost
            hostPayout
            hookhubPayout
            paymentRequiresAction
            payMonthly
            numberOfMonths
            paymentSchedule
            nextPaymentsSchedule
            nextPaymentDue
            monthlyPaymentAmount
            monthlyHostPayout
            monthlyHookhubPayout
            cancelledFinalHostPayout
            cancelledFinalRenterPayment
            cancelledBeforeCheckIn
            refundRenter
            full_refund_enabled
            no_refund_enabled
            half_refund_enabled
            full_refund_date
            half_refund_date
            stripePaymentIntentID
            _version
            __typename
          }
          hostID
          hostEmail
          renterEmail
          conversation {
            id
            hostee
            host
            convoCreatorsEmail
            otherMembersEmail
            spaceID
            spaceTitle
            propertyID
            propertyTitle
            propertyLocation
            bookingRequestID
            members
            membersNames
            unreadHost
            unreadHostee
            type
            createdAt
            updatedAt
            _version
            __typename
          }
          createdAt
          updatedAt
          renter_acknowledge_liability
          baseBookingCost
          renterFeeCost
          renterTotalBookingCost
          renterStripeFee
          hostFeeCost
          hostPayout
          hookhubPayout
          payMonthly
          numberOfMonths
          paymentSchedule
          nextPaymentDue
          nextPaymentsSchedule
          monthlyPaymentAmount
          monthlyHookhubPayout
          monthlyHostPayout
          full_refund_enabled
          no_refund_enabled
          half_refund_enabled
          full_refund_date
          half_refund_date
          errorCode
          _version
          bookingRequestConversationId
          __typename
        }
        nextToken
        __typename
      }
      space_approved
      active
      title
      description
      unavailable_days
      set_availability
      longest_stay_allowed
      shortest_stay_allowed
      width
      length
      electric_hookup
      water_hookup
      sewage_hookup
      wifi_available
      wifi_password
      wifi_network_name
      shower_available
      restroom_available
      food_nearby
      restaurant_nearby
      water_accessible
      easy_entry
      pre_booking_message_required
      pre_booking_message
      instant_book
      distance_electric_hookup
      distance_water_hookup
      distance_sewage_hookup
      parking_spaces
      additional_rules
      parties_allowed
      things_to_do
      images
      laundry_available
      pool_available
      hottub_available
      outdoor_area_available
      indoor_area_available
      trash_available
      extra_amenities_available
      host_acknowledge_liability
      host_acknowledge_tax_responsibility
      check_in_time
      check_out_time
      check_in_instructions
      space_rules
      pets_allowed
      smoking_allowed
      adults_only
      quiet_hours_start
      quiet_hours_end
      outdoor_fires
      profile_photo_required
      full_refund_date
      half_refund_date
      price_storage
      price_shortterm
      price_longterm
      price_yearly
      _version
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onCreateProperty = /* GraphQL */ `
  subscription OnCreateProperty(
    $filter: ModelSubscriptionPropertyFilterInput
    $ownerID: String
  ) {
    onCreateProperty(filter: $filter, ownerID: $ownerID) {
      id
      active
      ownerID
      spaces {
        items {
          id
          propertyID
          ownerID
          bookings {
            nextToken
            __typename
          }
          bookingRequests {
            nextToken
            __typename
          }
          space_approved
          active
          title
          description
          unavailable_days
          set_availability
          longest_stay_allowed
          shortest_stay_allowed
          width
          length
          electric_hookup
          water_hookup
          sewage_hookup
          wifi_available
          wifi_password
          wifi_network_name
          shower_available
          restroom_available
          food_nearby
          restaurant_nearby
          water_accessible
          easy_entry
          pre_booking_message_required
          pre_booking_message
          instant_book
          distance_electric_hookup
          distance_water_hookup
          distance_sewage_hookup
          parking_spaces
          additional_rules
          parties_allowed
          things_to_do
          images
          laundry_available
          pool_available
          hottub_available
          outdoor_area_available
          indoor_area_available
          trash_available
          extra_amenities_available
          host_acknowledge_liability
          host_acknowledge_tax_responsibility
          check_in_time
          check_out_time
          check_in_instructions
          space_rules
          pets_allowed
          smoking_allowed
          adults_only
          quiet_hours_start
          quiet_hours_end
          outdoor_fires
          profile_photo_required
          full_refund_date
          half_refund_date
          price_storage
          price_shortterm
          price_longterm
          price_yearly
          _version
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      title
      description
      address {
        id
        address
        cityName
        stateName
        zipcode
        countryName
        canadianCivicAddress
        _version
        createdAt
        updatedAt
        owner
        __typename
      }
      coordinates_lat
      coordinates_lng
      type_storage
      type_longterm
      type_shortterm
      type_yearly
      price_storage
      price_shortterm
      price_longterm
      price_yearly
      instant_book
      images
      reviews {
        items {
          id
          rating
          locationRating
          communicationRating
          checkinRating
          valueRating
          description
          propertyID
          reviewerName
          reviewerAvatar
          reviewerID
          bookingID
          images
          _version
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      conversations {
        items {
          id
          hostee
          host
          convoCreatorsEmail
          otherMembersEmail
          spaceID
          spaceTitle
          propertyID
          propertyTitle
          propertyLocation
          bookingRequestID
          bookingRequest {
            id
            members
            confirmed
            reviewed
            cancelled
            rentalType
            stripePaymentMethodID
            stripeCustomerID
            stripePaymentIntentID
            check_in
            check_out
            bookedDays
            spaceID
            spaceTitle
            spaceCity
            spaceState
            propertyTitle
            propertyID
            renterID
            bookingID
            hostID
            hostEmail
            renterEmail
            createdAt
            updatedAt
            renter_acknowledge_liability
            baseBookingCost
            renterFeeCost
            renterTotalBookingCost
            renterStripeFee
            hostFeeCost
            hostPayout
            hookhubPayout
            payMonthly
            numberOfMonths
            paymentSchedule
            nextPaymentDue
            nextPaymentsSchedule
            monthlyPaymentAmount
            monthlyHookhubPayout
            monthlyHostPayout
            full_refund_enabled
            no_refund_enabled
            half_refund_enabled
            full_refund_date
            half_refund_date
            errorCode
            _version
            bookingRequestConversationId
            __typename
          }
          messages {
            nextToken
            __typename
          }
          members
          membersNames
          unreadHost
          unreadHostee
          type
          createdAt
          updatedAt
          _version
          __typename
        }
        nextToken
        __typename
      }
      _version
      createdAt
      updatedAt
      propertyAddressId
      __typename
    }
  }
`;
export const onUpdateProperty = /* GraphQL */ `
  subscription OnUpdateProperty(
    $filter: ModelSubscriptionPropertyFilterInput
    $ownerID: String
  ) {
    onUpdateProperty(filter: $filter, ownerID: $ownerID) {
      id
      active
      ownerID
      spaces {
        items {
          id
          propertyID
          ownerID
          bookings {
            nextToken
            __typename
          }
          bookingRequests {
            nextToken
            __typename
          }
          space_approved
          active
          title
          description
          unavailable_days
          set_availability
          longest_stay_allowed
          shortest_stay_allowed
          width
          length
          electric_hookup
          water_hookup
          sewage_hookup
          wifi_available
          wifi_password
          wifi_network_name
          shower_available
          restroom_available
          food_nearby
          restaurant_nearby
          water_accessible
          easy_entry
          pre_booking_message_required
          pre_booking_message
          instant_book
          distance_electric_hookup
          distance_water_hookup
          distance_sewage_hookup
          parking_spaces
          additional_rules
          parties_allowed
          things_to_do
          images
          laundry_available
          pool_available
          hottub_available
          outdoor_area_available
          indoor_area_available
          trash_available
          extra_amenities_available
          host_acknowledge_liability
          host_acknowledge_tax_responsibility
          check_in_time
          check_out_time
          check_in_instructions
          space_rules
          pets_allowed
          smoking_allowed
          adults_only
          quiet_hours_start
          quiet_hours_end
          outdoor_fires
          profile_photo_required
          full_refund_date
          half_refund_date
          price_storage
          price_shortterm
          price_longterm
          price_yearly
          _version
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      title
      description
      address {
        id
        address
        cityName
        stateName
        zipcode
        countryName
        canadianCivicAddress
        _version
        createdAt
        updatedAt
        owner
        __typename
      }
      coordinates_lat
      coordinates_lng
      type_storage
      type_longterm
      type_shortterm
      type_yearly
      price_storage
      price_shortterm
      price_longterm
      price_yearly
      instant_book
      images
      reviews {
        items {
          id
          rating
          locationRating
          communicationRating
          checkinRating
          valueRating
          description
          propertyID
          reviewerName
          reviewerAvatar
          reviewerID
          bookingID
          images
          _version
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      conversations {
        items {
          id
          hostee
          host
          convoCreatorsEmail
          otherMembersEmail
          spaceID
          spaceTitle
          propertyID
          propertyTitle
          propertyLocation
          bookingRequestID
          bookingRequest {
            id
            members
            confirmed
            reviewed
            cancelled
            rentalType
            stripePaymentMethodID
            stripeCustomerID
            stripePaymentIntentID
            check_in
            check_out
            bookedDays
            spaceID
            spaceTitle
            spaceCity
            spaceState
            propertyTitle
            propertyID
            renterID
            bookingID
            hostID
            hostEmail
            renterEmail
            createdAt
            updatedAt
            renter_acknowledge_liability
            baseBookingCost
            renterFeeCost
            renterTotalBookingCost
            renterStripeFee
            hostFeeCost
            hostPayout
            hookhubPayout
            payMonthly
            numberOfMonths
            paymentSchedule
            nextPaymentDue
            nextPaymentsSchedule
            monthlyPaymentAmount
            monthlyHookhubPayout
            monthlyHostPayout
            full_refund_enabled
            no_refund_enabled
            half_refund_enabled
            full_refund_date
            half_refund_date
            errorCode
            _version
            bookingRequestConversationId
            __typename
          }
          messages {
            nextToken
            __typename
          }
          members
          membersNames
          unreadHost
          unreadHostee
          type
          createdAt
          updatedAt
          _version
          __typename
        }
        nextToken
        __typename
      }
      _version
      createdAt
      updatedAt
      propertyAddressId
      __typename
    }
  }
`;
export const onDeleteProperty = /* GraphQL */ `
  subscription OnDeleteProperty(
    $filter: ModelSubscriptionPropertyFilterInput
    $ownerID: String
  ) {
    onDeleteProperty(filter: $filter, ownerID: $ownerID) {
      id
      active
      ownerID
      spaces {
        items {
          id
          propertyID
          ownerID
          bookings {
            nextToken
            __typename
          }
          bookingRequests {
            nextToken
            __typename
          }
          space_approved
          active
          title
          description
          unavailable_days
          set_availability
          longest_stay_allowed
          shortest_stay_allowed
          width
          length
          electric_hookup
          water_hookup
          sewage_hookup
          wifi_available
          wifi_password
          wifi_network_name
          shower_available
          restroom_available
          food_nearby
          restaurant_nearby
          water_accessible
          easy_entry
          pre_booking_message_required
          pre_booking_message
          instant_book
          distance_electric_hookup
          distance_water_hookup
          distance_sewage_hookup
          parking_spaces
          additional_rules
          parties_allowed
          things_to_do
          images
          laundry_available
          pool_available
          hottub_available
          outdoor_area_available
          indoor_area_available
          trash_available
          extra_amenities_available
          host_acknowledge_liability
          host_acknowledge_tax_responsibility
          check_in_time
          check_out_time
          check_in_instructions
          space_rules
          pets_allowed
          smoking_allowed
          adults_only
          quiet_hours_start
          quiet_hours_end
          outdoor_fires
          profile_photo_required
          full_refund_date
          half_refund_date
          price_storage
          price_shortterm
          price_longterm
          price_yearly
          _version
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      title
      description
      address {
        id
        address
        cityName
        stateName
        zipcode
        countryName
        canadianCivicAddress
        _version
        createdAt
        updatedAt
        owner
        __typename
      }
      coordinates_lat
      coordinates_lng
      type_storage
      type_longterm
      type_shortterm
      type_yearly
      price_storage
      price_shortterm
      price_longterm
      price_yearly
      instant_book
      images
      reviews {
        items {
          id
          rating
          locationRating
          communicationRating
          checkinRating
          valueRating
          description
          propertyID
          reviewerName
          reviewerAvatar
          reviewerID
          bookingID
          images
          _version
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      conversations {
        items {
          id
          hostee
          host
          convoCreatorsEmail
          otherMembersEmail
          spaceID
          spaceTitle
          propertyID
          propertyTitle
          propertyLocation
          bookingRequestID
          bookingRequest {
            id
            members
            confirmed
            reviewed
            cancelled
            rentalType
            stripePaymentMethodID
            stripeCustomerID
            stripePaymentIntentID
            check_in
            check_out
            bookedDays
            spaceID
            spaceTitle
            spaceCity
            spaceState
            propertyTitle
            propertyID
            renterID
            bookingID
            hostID
            hostEmail
            renterEmail
            createdAt
            updatedAt
            renter_acknowledge_liability
            baseBookingCost
            renterFeeCost
            renterTotalBookingCost
            renterStripeFee
            hostFeeCost
            hostPayout
            hookhubPayout
            payMonthly
            numberOfMonths
            paymentSchedule
            nextPaymentDue
            nextPaymentsSchedule
            monthlyPaymentAmount
            monthlyHookhubPayout
            monthlyHostPayout
            full_refund_enabled
            no_refund_enabled
            half_refund_enabled
            full_refund_date
            half_refund_date
            errorCode
            _version
            bookingRequestConversationId
            __typename
          }
          messages {
            nextToken
            __typename
          }
          members
          membersNames
          unreadHost
          unreadHostee
          type
          createdAt
          updatedAt
          _version
          __typename
        }
        nextToken
        __typename
      }
      _version
      createdAt
      updatedAt
      propertyAddressId
      __typename
    }
  }
`;
export const onCreateAddress = /* GraphQL */ `
  subscription OnCreateAddress(
    $filter: ModelSubscriptionAddressFilterInput
    $owner: String
  ) {
    onCreateAddress(filter: $filter, owner: $owner) {
      id
      address
      cityName
      stateName
      zipcode
      countryName
      canadianCivicAddress
      _version
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const onUpdateAddress = /* GraphQL */ `
  subscription OnUpdateAddress(
    $filter: ModelSubscriptionAddressFilterInput
    $owner: String
  ) {
    onUpdateAddress(filter: $filter, owner: $owner) {
      id
      address
      cityName
      stateName
      zipcode
      countryName
      canadianCivicAddress
      _version
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const onDeleteAddress = /* GraphQL */ `
  subscription OnDeleteAddress(
    $filter: ModelSubscriptionAddressFilterInput
    $owner: String
  ) {
    onDeleteAddress(filter: $filter, owner: $owner) {
      id
      address
      cityName
      stateName
      zipcode
      countryName
      canadianCivicAddress
      _version
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const onCreateBooking = /* GraphQL */ `
  subscription OnCreateBooking($filter: ModelSubscriptionBookingFilterInput) {
    onCreateBooking(filter: $filter) {
      id
      members
      cancelled
      cancelledTooLate
      cancelledTooLatePayout
      cancelledDate
      spaceID
      renterID
      hostID
      bookingRequestId
      bookingRequest {
        id
        members
        confirmed
        reviewed
        cancelled
        rentalType
        stripePaymentMethodID
        stripeCustomerID
        stripePaymentIntentID
        check_in
        check_out
        bookedDays
        spaceID
        spaceTitle
        spaceCity
        spaceState
        propertyTitle
        propertyID
        renterID
        bookingID
        booking {
          id
          members
          cancelled
          cancelledTooLate
          cancelledTooLatePayout
          cancelledDate
          spaceID
          renterID
          hostID
          bookingRequestId
          bookingRequest {
            id
            members
            confirmed
            reviewed
            cancelled
            rentalType
            stripePaymentMethodID
            stripeCustomerID
            stripePaymentIntentID
            check_in
            check_out
            bookedDays
            spaceID
            spaceTitle
            spaceCity
            spaceState
            propertyTitle
            propertyID
            renterID
            bookingID
            hostID
            hostEmail
            renterEmail
            createdAt
            updatedAt
            renter_acknowledge_liability
            baseBookingCost
            renterFeeCost
            renterTotalBookingCost
            renterStripeFee
            hostFeeCost
            hostPayout
            hookhubPayout
            payMonthly
            numberOfMonths
            paymentSchedule
            nextPaymentDue
            nextPaymentsSchedule
            monthlyPaymentAmount
            monthlyHookhubPayout
            monthlyHostPayout
            full_refund_enabled
            no_refund_enabled
            half_refund_enabled
            full_refund_date
            half_refund_date
            errorCode
            _version
            bookingRequestConversationId
            __typename
          }
          paymentID
          payment {
            id
            members
            date
            stripePaymentMethodID
            stripeCustomerID
            paid
            hostID
            bookingId
            baseBookingCost
            renterFeeCost
            renterTotalBookingCost
            renterStripeFee
            hostFeeCost
            hostPayout
            hookhubPayout
            createdAt
            updatedAt
            _version
            __typename
          }
          bookedDays
          check_in
          check_out
          stripePaymentMethodID
          stripeCustomerID
          stripeHostConnectedAccountId
          hostEmail
          renterEmail
          createdAt
          updatedAt
          baseBookingCost
          renterFeeCost
          renterTotalBookingCost
          renterStripeFee
          hostFeeCost
          hostPayout
          hookhubPayout
          paymentRequiresAction
          payMonthly
          numberOfMonths
          paymentSchedule
          nextPaymentsSchedule
          nextPaymentDue
          monthlyPaymentAmount
          monthlyHostPayout
          monthlyHookhubPayout
          cancelledFinalHostPayout
          cancelledFinalRenterPayment
          cancelledBeforeCheckIn
          refundRenter
          full_refund_enabled
          no_refund_enabled
          half_refund_enabled
          full_refund_date
          half_refund_date
          stripePaymentIntentID
          _version
          __typename
        }
        hostID
        hostEmail
        renterEmail
        conversation {
          id
          hostee
          host
          convoCreatorsEmail
          otherMembersEmail
          spaceID
          spaceTitle
          propertyID
          propertyTitle
          propertyLocation
          bookingRequestID
          bookingRequest {
            id
            members
            confirmed
            reviewed
            cancelled
            rentalType
            stripePaymentMethodID
            stripeCustomerID
            stripePaymentIntentID
            check_in
            check_out
            bookedDays
            spaceID
            spaceTitle
            spaceCity
            spaceState
            propertyTitle
            propertyID
            renterID
            bookingID
            hostID
            hostEmail
            renterEmail
            createdAt
            updatedAt
            renter_acknowledge_liability
            baseBookingCost
            renterFeeCost
            renterTotalBookingCost
            renterStripeFee
            hostFeeCost
            hostPayout
            hookhubPayout
            payMonthly
            numberOfMonths
            paymentSchedule
            nextPaymentDue
            nextPaymentsSchedule
            monthlyPaymentAmount
            monthlyHookhubPayout
            monthlyHostPayout
            full_refund_enabled
            no_refund_enabled
            half_refund_enabled
            full_refund_date
            half_refund_date
            errorCode
            _version
            bookingRequestConversationId
            __typename
          }
          messages {
            nextToken
            __typename
          }
          members
          membersNames
          unreadHost
          unreadHostee
          type
          createdAt
          updatedAt
          _version
          __typename
        }
        createdAt
        updatedAt
        renter_acknowledge_liability
        baseBookingCost
        renterFeeCost
        renterTotalBookingCost
        renterStripeFee
        hostFeeCost
        hostPayout
        hookhubPayout
        payMonthly
        numberOfMonths
        paymentSchedule
        nextPaymentDue
        nextPaymentsSchedule
        monthlyPaymentAmount
        monthlyHookhubPayout
        monthlyHostPayout
        full_refund_enabled
        no_refund_enabled
        half_refund_enabled
        full_refund_date
        half_refund_date
        errorCode
        _version
        bookingRequestConversationId
        __typename
      }
      paymentID
      payment {
        id
        members
        date
        stripePaymentMethodID
        stripeCustomerID
        paid
        hostID
        bookingId
        booking {
          id
          members
          cancelled
          cancelledTooLate
          cancelledTooLatePayout
          cancelledDate
          spaceID
          renterID
          hostID
          bookingRequestId
          bookingRequest {
            id
            members
            confirmed
            reviewed
            cancelled
            rentalType
            stripePaymentMethodID
            stripeCustomerID
            stripePaymentIntentID
            check_in
            check_out
            bookedDays
            spaceID
            spaceTitle
            spaceCity
            spaceState
            propertyTitle
            propertyID
            renterID
            bookingID
            hostID
            hostEmail
            renterEmail
            createdAt
            updatedAt
            renter_acknowledge_liability
            baseBookingCost
            renterFeeCost
            renterTotalBookingCost
            renterStripeFee
            hostFeeCost
            hostPayout
            hookhubPayout
            payMonthly
            numberOfMonths
            paymentSchedule
            nextPaymentDue
            nextPaymentsSchedule
            monthlyPaymentAmount
            monthlyHookhubPayout
            monthlyHostPayout
            full_refund_enabled
            no_refund_enabled
            half_refund_enabled
            full_refund_date
            half_refund_date
            errorCode
            _version
            bookingRequestConversationId
            __typename
          }
          paymentID
          payment {
            id
            members
            date
            stripePaymentMethodID
            stripeCustomerID
            paid
            hostID
            bookingId
            baseBookingCost
            renterFeeCost
            renterTotalBookingCost
            renterStripeFee
            hostFeeCost
            hostPayout
            hookhubPayout
            createdAt
            updatedAt
            _version
            __typename
          }
          bookedDays
          check_in
          check_out
          stripePaymentMethodID
          stripeCustomerID
          stripeHostConnectedAccountId
          hostEmail
          renterEmail
          createdAt
          updatedAt
          baseBookingCost
          renterFeeCost
          renterTotalBookingCost
          renterStripeFee
          hostFeeCost
          hostPayout
          hookhubPayout
          paymentRequiresAction
          payMonthly
          numberOfMonths
          paymentSchedule
          nextPaymentsSchedule
          nextPaymentDue
          monthlyPaymentAmount
          monthlyHostPayout
          monthlyHookhubPayout
          cancelledFinalHostPayout
          cancelledFinalRenterPayment
          cancelledBeforeCheckIn
          refundRenter
          full_refund_enabled
          no_refund_enabled
          half_refund_enabled
          full_refund_date
          half_refund_date
          stripePaymentIntentID
          _version
          __typename
        }
        baseBookingCost
        renterFeeCost
        renterTotalBookingCost
        renterStripeFee
        hostFeeCost
        hostPayout
        hookhubPayout
        createdAt
        updatedAt
        _version
        __typename
      }
      bookedDays
      check_in
      check_out
      stripePaymentMethodID
      stripeCustomerID
      stripeHostConnectedAccountId
      hostEmail
      renterEmail
      createdAt
      updatedAt
      baseBookingCost
      renterFeeCost
      renterTotalBookingCost
      renterStripeFee
      hostFeeCost
      hostPayout
      hookhubPayout
      paymentRequiresAction
      payMonthly
      numberOfMonths
      paymentSchedule
      nextPaymentsSchedule
      nextPaymentDue
      monthlyPaymentAmount
      monthlyHostPayout
      monthlyHookhubPayout
      cancelledFinalHostPayout
      cancelledFinalRenterPayment
      cancelledBeforeCheckIn
      refundRenter
      full_refund_enabled
      no_refund_enabled
      half_refund_enabled
      full_refund_date
      half_refund_date
      stripePaymentIntentID
      _version
      __typename
    }
  }
`;
export const onUpdateBooking = /* GraphQL */ `
  subscription OnUpdateBooking($filter: ModelSubscriptionBookingFilterInput) {
    onUpdateBooking(filter: $filter) {
      id
      members
      cancelled
      cancelledTooLate
      cancelledTooLatePayout
      cancelledDate
      spaceID
      renterID
      hostID
      bookingRequestId
      bookingRequest {
        id
        members
        confirmed
        reviewed
        cancelled
        rentalType
        stripePaymentMethodID
        stripeCustomerID
        stripePaymentIntentID
        check_in
        check_out
        bookedDays
        spaceID
        spaceTitle
        spaceCity
        spaceState
        propertyTitle
        propertyID
        renterID
        bookingID
        booking {
          id
          members
          cancelled
          cancelledTooLate
          cancelledTooLatePayout
          cancelledDate
          spaceID
          renterID
          hostID
          bookingRequestId
          bookingRequest {
            id
            members
            confirmed
            reviewed
            cancelled
            rentalType
            stripePaymentMethodID
            stripeCustomerID
            stripePaymentIntentID
            check_in
            check_out
            bookedDays
            spaceID
            spaceTitle
            spaceCity
            spaceState
            propertyTitle
            propertyID
            renterID
            bookingID
            hostID
            hostEmail
            renterEmail
            createdAt
            updatedAt
            renter_acknowledge_liability
            baseBookingCost
            renterFeeCost
            renterTotalBookingCost
            renterStripeFee
            hostFeeCost
            hostPayout
            hookhubPayout
            payMonthly
            numberOfMonths
            paymentSchedule
            nextPaymentDue
            nextPaymentsSchedule
            monthlyPaymentAmount
            monthlyHookhubPayout
            monthlyHostPayout
            full_refund_enabled
            no_refund_enabled
            half_refund_enabled
            full_refund_date
            half_refund_date
            errorCode
            _version
            bookingRequestConversationId
            __typename
          }
          paymentID
          payment {
            id
            members
            date
            stripePaymentMethodID
            stripeCustomerID
            paid
            hostID
            bookingId
            baseBookingCost
            renterFeeCost
            renterTotalBookingCost
            renterStripeFee
            hostFeeCost
            hostPayout
            hookhubPayout
            createdAt
            updatedAt
            _version
            __typename
          }
          bookedDays
          check_in
          check_out
          stripePaymentMethodID
          stripeCustomerID
          stripeHostConnectedAccountId
          hostEmail
          renterEmail
          createdAt
          updatedAt
          baseBookingCost
          renterFeeCost
          renterTotalBookingCost
          renterStripeFee
          hostFeeCost
          hostPayout
          hookhubPayout
          paymentRequiresAction
          payMonthly
          numberOfMonths
          paymentSchedule
          nextPaymentsSchedule
          nextPaymentDue
          monthlyPaymentAmount
          monthlyHostPayout
          monthlyHookhubPayout
          cancelledFinalHostPayout
          cancelledFinalRenterPayment
          cancelledBeforeCheckIn
          refundRenter
          full_refund_enabled
          no_refund_enabled
          half_refund_enabled
          full_refund_date
          half_refund_date
          stripePaymentIntentID
          _version
          __typename
        }
        hostID
        hostEmail
        renterEmail
        conversation {
          id
          hostee
          host
          convoCreatorsEmail
          otherMembersEmail
          spaceID
          spaceTitle
          propertyID
          propertyTitle
          propertyLocation
          bookingRequestID
          bookingRequest {
            id
            members
            confirmed
            reviewed
            cancelled
            rentalType
            stripePaymentMethodID
            stripeCustomerID
            stripePaymentIntentID
            check_in
            check_out
            bookedDays
            spaceID
            spaceTitle
            spaceCity
            spaceState
            propertyTitle
            propertyID
            renterID
            bookingID
            hostID
            hostEmail
            renterEmail
            createdAt
            updatedAt
            renter_acknowledge_liability
            baseBookingCost
            renterFeeCost
            renterTotalBookingCost
            renterStripeFee
            hostFeeCost
            hostPayout
            hookhubPayout
            payMonthly
            numberOfMonths
            paymentSchedule
            nextPaymentDue
            nextPaymentsSchedule
            monthlyPaymentAmount
            monthlyHookhubPayout
            monthlyHostPayout
            full_refund_enabled
            no_refund_enabled
            half_refund_enabled
            full_refund_date
            half_refund_date
            errorCode
            _version
            bookingRequestConversationId
            __typename
          }
          messages {
            nextToken
            __typename
          }
          members
          membersNames
          unreadHost
          unreadHostee
          type
          createdAt
          updatedAt
          _version
          __typename
        }
        createdAt
        updatedAt
        renter_acknowledge_liability
        baseBookingCost
        renterFeeCost
        renterTotalBookingCost
        renterStripeFee
        hostFeeCost
        hostPayout
        hookhubPayout
        payMonthly
        numberOfMonths
        paymentSchedule
        nextPaymentDue
        nextPaymentsSchedule
        monthlyPaymentAmount
        monthlyHookhubPayout
        monthlyHostPayout
        full_refund_enabled
        no_refund_enabled
        half_refund_enabled
        full_refund_date
        half_refund_date
        errorCode
        _version
        bookingRequestConversationId
        __typename
      }
      paymentID
      payment {
        id
        members
        date
        stripePaymentMethodID
        stripeCustomerID
        paid
        hostID
        bookingId
        booking {
          id
          members
          cancelled
          cancelledTooLate
          cancelledTooLatePayout
          cancelledDate
          spaceID
          renterID
          hostID
          bookingRequestId
          bookingRequest {
            id
            members
            confirmed
            reviewed
            cancelled
            rentalType
            stripePaymentMethodID
            stripeCustomerID
            stripePaymentIntentID
            check_in
            check_out
            bookedDays
            spaceID
            spaceTitle
            spaceCity
            spaceState
            propertyTitle
            propertyID
            renterID
            bookingID
            hostID
            hostEmail
            renterEmail
            createdAt
            updatedAt
            renter_acknowledge_liability
            baseBookingCost
            renterFeeCost
            renterTotalBookingCost
            renterStripeFee
            hostFeeCost
            hostPayout
            hookhubPayout
            payMonthly
            numberOfMonths
            paymentSchedule
            nextPaymentDue
            nextPaymentsSchedule
            monthlyPaymentAmount
            monthlyHookhubPayout
            monthlyHostPayout
            full_refund_enabled
            no_refund_enabled
            half_refund_enabled
            full_refund_date
            half_refund_date
            errorCode
            _version
            bookingRequestConversationId
            __typename
          }
          paymentID
          payment {
            id
            members
            date
            stripePaymentMethodID
            stripeCustomerID
            paid
            hostID
            bookingId
            baseBookingCost
            renterFeeCost
            renterTotalBookingCost
            renterStripeFee
            hostFeeCost
            hostPayout
            hookhubPayout
            createdAt
            updatedAt
            _version
            __typename
          }
          bookedDays
          check_in
          check_out
          stripePaymentMethodID
          stripeCustomerID
          stripeHostConnectedAccountId
          hostEmail
          renterEmail
          createdAt
          updatedAt
          baseBookingCost
          renterFeeCost
          renterTotalBookingCost
          renterStripeFee
          hostFeeCost
          hostPayout
          hookhubPayout
          paymentRequiresAction
          payMonthly
          numberOfMonths
          paymentSchedule
          nextPaymentsSchedule
          nextPaymentDue
          monthlyPaymentAmount
          monthlyHostPayout
          monthlyHookhubPayout
          cancelledFinalHostPayout
          cancelledFinalRenterPayment
          cancelledBeforeCheckIn
          refundRenter
          full_refund_enabled
          no_refund_enabled
          half_refund_enabled
          full_refund_date
          half_refund_date
          stripePaymentIntentID
          _version
          __typename
        }
        baseBookingCost
        renterFeeCost
        renterTotalBookingCost
        renterStripeFee
        hostFeeCost
        hostPayout
        hookhubPayout
        createdAt
        updatedAt
        _version
        __typename
      }
      bookedDays
      check_in
      check_out
      stripePaymentMethodID
      stripeCustomerID
      stripeHostConnectedAccountId
      hostEmail
      renterEmail
      createdAt
      updatedAt
      baseBookingCost
      renterFeeCost
      renterTotalBookingCost
      renterStripeFee
      hostFeeCost
      hostPayout
      hookhubPayout
      paymentRequiresAction
      payMonthly
      numberOfMonths
      paymentSchedule
      nextPaymentsSchedule
      nextPaymentDue
      monthlyPaymentAmount
      monthlyHostPayout
      monthlyHookhubPayout
      cancelledFinalHostPayout
      cancelledFinalRenterPayment
      cancelledBeforeCheckIn
      refundRenter
      full_refund_enabled
      no_refund_enabled
      half_refund_enabled
      full_refund_date
      half_refund_date
      stripePaymentIntentID
      _version
      __typename
    }
  }
`;
export const onDeleteBooking = /* GraphQL */ `
  subscription OnDeleteBooking($filter: ModelSubscriptionBookingFilterInput) {
    onDeleteBooking(filter: $filter) {
      id
      members
      cancelled
      cancelledTooLate
      cancelledTooLatePayout
      cancelledDate
      spaceID
      renterID
      hostID
      bookingRequestId
      bookingRequest {
        id
        members
        confirmed
        reviewed
        cancelled
        rentalType
        stripePaymentMethodID
        stripeCustomerID
        stripePaymentIntentID
        check_in
        check_out
        bookedDays
        spaceID
        spaceTitle
        spaceCity
        spaceState
        propertyTitle
        propertyID
        renterID
        bookingID
        booking {
          id
          members
          cancelled
          cancelledTooLate
          cancelledTooLatePayout
          cancelledDate
          spaceID
          renterID
          hostID
          bookingRequestId
          bookingRequest {
            id
            members
            confirmed
            reviewed
            cancelled
            rentalType
            stripePaymentMethodID
            stripeCustomerID
            stripePaymentIntentID
            check_in
            check_out
            bookedDays
            spaceID
            spaceTitle
            spaceCity
            spaceState
            propertyTitle
            propertyID
            renterID
            bookingID
            hostID
            hostEmail
            renterEmail
            createdAt
            updatedAt
            renter_acknowledge_liability
            baseBookingCost
            renterFeeCost
            renterTotalBookingCost
            renterStripeFee
            hostFeeCost
            hostPayout
            hookhubPayout
            payMonthly
            numberOfMonths
            paymentSchedule
            nextPaymentDue
            nextPaymentsSchedule
            monthlyPaymentAmount
            monthlyHookhubPayout
            monthlyHostPayout
            full_refund_enabled
            no_refund_enabled
            half_refund_enabled
            full_refund_date
            half_refund_date
            errorCode
            _version
            bookingRequestConversationId
            __typename
          }
          paymentID
          payment {
            id
            members
            date
            stripePaymentMethodID
            stripeCustomerID
            paid
            hostID
            bookingId
            baseBookingCost
            renterFeeCost
            renterTotalBookingCost
            renterStripeFee
            hostFeeCost
            hostPayout
            hookhubPayout
            createdAt
            updatedAt
            _version
            __typename
          }
          bookedDays
          check_in
          check_out
          stripePaymentMethodID
          stripeCustomerID
          stripeHostConnectedAccountId
          hostEmail
          renterEmail
          createdAt
          updatedAt
          baseBookingCost
          renterFeeCost
          renterTotalBookingCost
          renterStripeFee
          hostFeeCost
          hostPayout
          hookhubPayout
          paymentRequiresAction
          payMonthly
          numberOfMonths
          paymentSchedule
          nextPaymentsSchedule
          nextPaymentDue
          monthlyPaymentAmount
          monthlyHostPayout
          monthlyHookhubPayout
          cancelledFinalHostPayout
          cancelledFinalRenterPayment
          cancelledBeforeCheckIn
          refundRenter
          full_refund_enabled
          no_refund_enabled
          half_refund_enabled
          full_refund_date
          half_refund_date
          stripePaymentIntentID
          _version
          __typename
        }
        hostID
        hostEmail
        renterEmail
        conversation {
          id
          hostee
          host
          convoCreatorsEmail
          otherMembersEmail
          spaceID
          spaceTitle
          propertyID
          propertyTitle
          propertyLocation
          bookingRequestID
          bookingRequest {
            id
            members
            confirmed
            reviewed
            cancelled
            rentalType
            stripePaymentMethodID
            stripeCustomerID
            stripePaymentIntentID
            check_in
            check_out
            bookedDays
            spaceID
            spaceTitle
            spaceCity
            spaceState
            propertyTitle
            propertyID
            renterID
            bookingID
            hostID
            hostEmail
            renterEmail
            createdAt
            updatedAt
            renter_acknowledge_liability
            baseBookingCost
            renterFeeCost
            renterTotalBookingCost
            renterStripeFee
            hostFeeCost
            hostPayout
            hookhubPayout
            payMonthly
            numberOfMonths
            paymentSchedule
            nextPaymentDue
            nextPaymentsSchedule
            monthlyPaymentAmount
            monthlyHookhubPayout
            monthlyHostPayout
            full_refund_enabled
            no_refund_enabled
            half_refund_enabled
            full_refund_date
            half_refund_date
            errorCode
            _version
            bookingRequestConversationId
            __typename
          }
          messages {
            nextToken
            __typename
          }
          members
          membersNames
          unreadHost
          unreadHostee
          type
          createdAt
          updatedAt
          _version
          __typename
        }
        createdAt
        updatedAt
        renter_acknowledge_liability
        baseBookingCost
        renterFeeCost
        renterTotalBookingCost
        renterStripeFee
        hostFeeCost
        hostPayout
        hookhubPayout
        payMonthly
        numberOfMonths
        paymentSchedule
        nextPaymentDue
        nextPaymentsSchedule
        monthlyPaymentAmount
        monthlyHookhubPayout
        monthlyHostPayout
        full_refund_enabled
        no_refund_enabled
        half_refund_enabled
        full_refund_date
        half_refund_date
        errorCode
        _version
        bookingRequestConversationId
        __typename
      }
      paymentID
      payment {
        id
        members
        date
        stripePaymentMethodID
        stripeCustomerID
        paid
        hostID
        bookingId
        booking {
          id
          members
          cancelled
          cancelledTooLate
          cancelledTooLatePayout
          cancelledDate
          spaceID
          renterID
          hostID
          bookingRequestId
          bookingRequest {
            id
            members
            confirmed
            reviewed
            cancelled
            rentalType
            stripePaymentMethodID
            stripeCustomerID
            stripePaymentIntentID
            check_in
            check_out
            bookedDays
            spaceID
            spaceTitle
            spaceCity
            spaceState
            propertyTitle
            propertyID
            renterID
            bookingID
            hostID
            hostEmail
            renterEmail
            createdAt
            updatedAt
            renter_acknowledge_liability
            baseBookingCost
            renterFeeCost
            renterTotalBookingCost
            renterStripeFee
            hostFeeCost
            hostPayout
            hookhubPayout
            payMonthly
            numberOfMonths
            paymentSchedule
            nextPaymentDue
            nextPaymentsSchedule
            monthlyPaymentAmount
            monthlyHookhubPayout
            monthlyHostPayout
            full_refund_enabled
            no_refund_enabled
            half_refund_enabled
            full_refund_date
            half_refund_date
            errorCode
            _version
            bookingRequestConversationId
            __typename
          }
          paymentID
          payment {
            id
            members
            date
            stripePaymentMethodID
            stripeCustomerID
            paid
            hostID
            bookingId
            baseBookingCost
            renterFeeCost
            renterTotalBookingCost
            renterStripeFee
            hostFeeCost
            hostPayout
            hookhubPayout
            createdAt
            updatedAt
            _version
            __typename
          }
          bookedDays
          check_in
          check_out
          stripePaymentMethodID
          stripeCustomerID
          stripeHostConnectedAccountId
          hostEmail
          renterEmail
          createdAt
          updatedAt
          baseBookingCost
          renterFeeCost
          renterTotalBookingCost
          renterStripeFee
          hostFeeCost
          hostPayout
          hookhubPayout
          paymentRequiresAction
          payMonthly
          numberOfMonths
          paymentSchedule
          nextPaymentsSchedule
          nextPaymentDue
          monthlyPaymentAmount
          monthlyHostPayout
          monthlyHookhubPayout
          cancelledFinalHostPayout
          cancelledFinalRenterPayment
          cancelledBeforeCheckIn
          refundRenter
          full_refund_enabled
          no_refund_enabled
          half_refund_enabled
          full_refund_date
          half_refund_date
          stripePaymentIntentID
          _version
          __typename
        }
        baseBookingCost
        renterFeeCost
        renterTotalBookingCost
        renterStripeFee
        hostFeeCost
        hostPayout
        hookhubPayout
        createdAt
        updatedAt
        _version
        __typename
      }
      bookedDays
      check_in
      check_out
      stripePaymentMethodID
      stripeCustomerID
      stripeHostConnectedAccountId
      hostEmail
      renterEmail
      createdAt
      updatedAt
      baseBookingCost
      renterFeeCost
      renterTotalBookingCost
      renterStripeFee
      hostFeeCost
      hostPayout
      hookhubPayout
      paymentRequiresAction
      payMonthly
      numberOfMonths
      paymentSchedule
      nextPaymentsSchedule
      nextPaymentDue
      monthlyPaymentAmount
      monthlyHostPayout
      monthlyHookhubPayout
      cancelledFinalHostPayout
      cancelledFinalRenterPayment
      cancelledBeforeCheckIn
      refundRenter
      full_refund_enabled
      no_refund_enabled
      half_refund_enabled
      full_refund_date
      half_refund_date
      stripePaymentIntentID
      _version
      __typename
    }
  }
`;
export const onCreateBookingRequest = /* GraphQL */ `
  subscription OnCreateBookingRequest(
    $filter: ModelSubscriptionBookingRequestFilterInput
  ) {
    onCreateBookingRequest(filter: $filter) {
      id
      members
      confirmed
      reviewed
      cancelled
      rentalType
      stripePaymentMethodID
      stripeCustomerID
      stripePaymentIntentID
      check_in
      check_out
      bookedDays
      spaceID
      spaceTitle
      spaceCity
      spaceState
      propertyTitle
      propertyID
      renterID
      bookingID
      booking {
        id
        members
        cancelled
        cancelledTooLate
        cancelledTooLatePayout
        cancelledDate
        spaceID
        renterID
        hostID
        bookingRequestId
        bookingRequest {
          id
          members
          confirmed
          reviewed
          cancelled
          rentalType
          stripePaymentMethodID
          stripeCustomerID
          stripePaymentIntentID
          check_in
          check_out
          bookedDays
          spaceID
          spaceTitle
          spaceCity
          spaceState
          propertyTitle
          propertyID
          renterID
          bookingID
          booking {
            id
            members
            cancelled
            cancelledTooLate
            cancelledTooLatePayout
            cancelledDate
            spaceID
            renterID
            hostID
            bookingRequestId
            paymentID
            bookedDays
            check_in
            check_out
            stripePaymentMethodID
            stripeCustomerID
            stripeHostConnectedAccountId
            hostEmail
            renterEmail
            createdAt
            updatedAt
            baseBookingCost
            renterFeeCost
            renterTotalBookingCost
            renterStripeFee
            hostFeeCost
            hostPayout
            hookhubPayout
            paymentRequiresAction
            payMonthly
            numberOfMonths
            paymentSchedule
            nextPaymentsSchedule
            nextPaymentDue
            monthlyPaymentAmount
            monthlyHostPayout
            monthlyHookhubPayout
            cancelledFinalHostPayout
            cancelledFinalRenterPayment
            cancelledBeforeCheckIn
            refundRenter
            full_refund_enabled
            no_refund_enabled
            half_refund_enabled
            full_refund_date
            half_refund_date
            stripePaymentIntentID
            _version
            __typename
          }
          hostID
          hostEmail
          renterEmail
          conversation {
            id
            hostee
            host
            convoCreatorsEmail
            otherMembersEmail
            spaceID
            spaceTitle
            propertyID
            propertyTitle
            propertyLocation
            bookingRequestID
            members
            membersNames
            unreadHost
            unreadHostee
            type
            createdAt
            updatedAt
            _version
            __typename
          }
          createdAt
          updatedAt
          renter_acknowledge_liability
          baseBookingCost
          renterFeeCost
          renterTotalBookingCost
          renterStripeFee
          hostFeeCost
          hostPayout
          hookhubPayout
          payMonthly
          numberOfMonths
          paymentSchedule
          nextPaymentDue
          nextPaymentsSchedule
          monthlyPaymentAmount
          monthlyHookhubPayout
          monthlyHostPayout
          full_refund_enabled
          no_refund_enabled
          half_refund_enabled
          full_refund_date
          half_refund_date
          errorCode
          _version
          bookingRequestConversationId
          __typename
        }
        paymentID
        payment {
          id
          members
          date
          stripePaymentMethodID
          stripeCustomerID
          paid
          hostID
          bookingId
          booking {
            id
            members
            cancelled
            cancelledTooLate
            cancelledTooLatePayout
            cancelledDate
            spaceID
            renterID
            hostID
            bookingRequestId
            paymentID
            bookedDays
            check_in
            check_out
            stripePaymentMethodID
            stripeCustomerID
            stripeHostConnectedAccountId
            hostEmail
            renterEmail
            createdAt
            updatedAt
            baseBookingCost
            renterFeeCost
            renterTotalBookingCost
            renterStripeFee
            hostFeeCost
            hostPayout
            hookhubPayout
            paymentRequiresAction
            payMonthly
            numberOfMonths
            paymentSchedule
            nextPaymentsSchedule
            nextPaymentDue
            monthlyPaymentAmount
            monthlyHostPayout
            monthlyHookhubPayout
            cancelledFinalHostPayout
            cancelledFinalRenterPayment
            cancelledBeforeCheckIn
            refundRenter
            full_refund_enabled
            no_refund_enabled
            half_refund_enabled
            full_refund_date
            half_refund_date
            stripePaymentIntentID
            _version
            __typename
          }
          baseBookingCost
          renterFeeCost
          renterTotalBookingCost
          renterStripeFee
          hostFeeCost
          hostPayout
          hookhubPayout
          createdAt
          updatedAt
          _version
          __typename
        }
        bookedDays
        check_in
        check_out
        stripePaymentMethodID
        stripeCustomerID
        stripeHostConnectedAccountId
        hostEmail
        renterEmail
        createdAt
        updatedAt
        baseBookingCost
        renterFeeCost
        renterTotalBookingCost
        renterStripeFee
        hostFeeCost
        hostPayout
        hookhubPayout
        paymentRequiresAction
        payMonthly
        numberOfMonths
        paymentSchedule
        nextPaymentsSchedule
        nextPaymentDue
        monthlyPaymentAmount
        monthlyHostPayout
        monthlyHookhubPayout
        cancelledFinalHostPayout
        cancelledFinalRenterPayment
        cancelledBeforeCheckIn
        refundRenter
        full_refund_enabled
        no_refund_enabled
        half_refund_enabled
        full_refund_date
        half_refund_date
        stripePaymentIntentID
        _version
        __typename
      }
      hostID
      hostEmail
      renterEmail
      conversation {
        id
        hostee
        host
        convoCreatorsEmail
        otherMembersEmail
        spaceID
        spaceTitle
        propertyID
        propertyTitle
        propertyLocation
        bookingRequestID
        bookingRequest {
          id
          members
          confirmed
          reviewed
          cancelled
          rentalType
          stripePaymentMethodID
          stripeCustomerID
          stripePaymentIntentID
          check_in
          check_out
          bookedDays
          spaceID
          spaceTitle
          spaceCity
          spaceState
          propertyTitle
          propertyID
          renterID
          bookingID
          booking {
            id
            members
            cancelled
            cancelledTooLate
            cancelledTooLatePayout
            cancelledDate
            spaceID
            renterID
            hostID
            bookingRequestId
            paymentID
            bookedDays
            check_in
            check_out
            stripePaymentMethodID
            stripeCustomerID
            stripeHostConnectedAccountId
            hostEmail
            renterEmail
            createdAt
            updatedAt
            baseBookingCost
            renterFeeCost
            renterTotalBookingCost
            renterStripeFee
            hostFeeCost
            hostPayout
            hookhubPayout
            paymentRequiresAction
            payMonthly
            numberOfMonths
            paymentSchedule
            nextPaymentsSchedule
            nextPaymentDue
            monthlyPaymentAmount
            monthlyHostPayout
            monthlyHookhubPayout
            cancelledFinalHostPayout
            cancelledFinalRenterPayment
            cancelledBeforeCheckIn
            refundRenter
            full_refund_enabled
            no_refund_enabled
            half_refund_enabled
            full_refund_date
            half_refund_date
            stripePaymentIntentID
            _version
            __typename
          }
          hostID
          hostEmail
          renterEmail
          conversation {
            id
            hostee
            host
            convoCreatorsEmail
            otherMembersEmail
            spaceID
            spaceTitle
            propertyID
            propertyTitle
            propertyLocation
            bookingRequestID
            members
            membersNames
            unreadHost
            unreadHostee
            type
            createdAt
            updatedAt
            _version
            __typename
          }
          createdAt
          updatedAt
          renter_acknowledge_liability
          baseBookingCost
          renterFeeCost
          renterTotalBookingCost
          renterStripeFee
          hostFeeCost
          hostPayout
          hookhubPayout
          payMonthly
          numberOfMonths
          paymentSchedule
          nextPaymentDue
          nextPaymentsSchedule
          monthlyPaymentAmount
          monthlyHookhubPayout
          monthlyHostPayout
          full_refund_enabled
          no_refund_enabled
          half_refund_enabled
          full_refund_date
          half_refund_date
          errorCode
          _version
          bookingRequestConversationId
          __typename
        }
        messages {
          items {
            id
            authorID
            authorName
            authorAvatar
            otherMembersEmail
            content
            convoID
            type
            createdAt
            updatedAt
            _version
            owner
            __typename
          }
          nextToken
          __typename
        }
        members
        membersNames
        unreadHost
        unreadHostee
        type
        createdAt
        updatedAt
        _version
        __typename
      }
      createdAt
      updatedAt
      renter_acknowledge_liability
      baseBookingCost
      renterFeeCost
      renterTotalBookingCost
      renterStripeFee
      hostFeeCost
      hostPayout
      hookhubPayout
      payMonthly
      numberOfMonths
      paymentSchedule
      nextPaymentDue
      nextPaymentsSchedule
      monthlyPaymentAmount
      monthlyHookhubPayout
      monthlyHostPayout
      full_refund_enabled
      no_refund_enabled
      half_refund_enabled
      full_refund_date
      half_refund_date
      errorCode
      _version
      bookingRequestConversationId
      __typename
    }
  }
`;
export const onUpdateBookingRequest = /* GraphQL */ `
  subscription OnUpdateBookingRequest(
    $filter: ModelSubscriptionBookingRequestFilterInput
  ) {
    onUpdateBookingRequest(filter: $filter) {
      id
      members
      confirmed
      reviewed
      cancelled
      rentalType
      stripePaymentMethodID
      stripeCustomerID
      stripePaymentIntentID
      check_in
      check_out
      bookedDays
      spaceID
      spaceTitle
      spaceCity
      spaceState
      propertyTitle
      propertyID
      renterID
      bookingID
      booking {
        id
        members
        cancelled
        cancelledTooLate
        cancelledTooLatePayout
        cancelledDate
        spaceID
        renterID
        hostID
        bookingRequestId
        bookingRequest {
          id
          members
          confirmed
          reviewed
          cancelled
          rentalType
          stripePaymentMethodID
          stripeCustomerID
          stripePaymentIntentID
          check_in
          check_out
          bookedDays
          spaceID
          spaceTitle
          spaceCity
          spaceState
          propertyTitle
          propertyID
          renterID
          bookingID
          booking {
            id
            members
            cancelled
            cancelledTooLate
            cancelledTooLatePayout
            cancelledDate
            spaceID
            renterID
            hostID
            bookingRequestId
            paymentID
            bookedDays
            check_in
            check_out
            stripePaymentMethodID
            stripeCustomerID
            stripeHostConnectedAccountId
            hostEmail
            renterEmail
            createdAt
            updatedAt
            baseBookingCost
            renterFeeCost
            renterTotalBookingCost
            renterStripeFee
            hostFeeCost
            hostPayout
            hookhubPayout
            paymentRequiresAction
            payMonthly
            numberOfMonths
            paymentSchedule
            nextPaymentsSchedule
            nextPaymentDue
            monthlyPaymentAmount
            monthlyHostPayout
            monthlyHookhubPayout
            cancelledFinalHostPayout
            cancelledFinalRenterPayment
            cancelledBeforeCheckIn
            refundRenter
            full_refund_enabled
            no_refund_enabled
            half_refund_enabled
            full_refund_date
            half_refund_date
            stripePaymentIntentID
            _version
            __typename
          }
          hostID
          hostEmail
          renterEmail
          conversation {
            id
            hostee
            host
            convoCreatorsEmail
            otherMembersEmail
            spaceID
            spaceTitle
            propertyID
            propertyTitle
            propertyLocation
            bookingRequestID
            members
            membersNames
            unreadHost
            unreadHostee
            type
            createdAt
            updatedAt
            _version
            __typename
          }
          createdAt
          updatedAt
          renter_acknowledge_liability
          baseBookingCost
          renterFeeCost
          renterTotalBookingCost
          renterStripeFee
          hostFeeCost
          hostPayout
          hookhubPayout
          payMonthly
          numberOfMonths
          paymentSchedule
          nextPaymentDue
          nextPaymentsSchedule
          monthlyPaymentAmount
          monthlyHookhubPayout
          monthlyHostPayout
          full_refund_enabled
          no_refund_enabled
          half_refund_enabled
          full_refund_date
          half_refund_date
          errorCode
          _version
          bookingRequestConversationId
          __typename
        }
        paymentID
        payment {
          id
          members
          date
          stripePaymentMethodID
          stripeCustomerID
          paid
          hostID
          bookingId
          booking {
            id
            members
            cancelled
            cancelledTooLate
            cancelledTooLatePayout
            cancelledDate
            spaceID
            renterID
            hostID
            bookingRequestId
            paymentID
            bookedDays
            check_in
            check_out
            stripePaymentMethodID
            stripeCustomerID
            stripeHostConnectedAccountId
            hostEmail
            renterEmail
            createdAt
            updatedAt
            baseBookingCost
            renterFeeCost
            renterTotalBookingCost
            renterStripeFee
            hostFeeCost
            hostPayout
            hookhubPayout
            paymentRequiresAction
            payMonthly
            numberOfMonths
            paymentSchedule
            nextPaymentsSchedule
            nextPaymentDue
            monthlyPaymentAmount
            monthlyHostPayout
            monthlyHookhubPayout
            cancelledFinalHostPayout
            cancelledFinalRenterPayment
            cancelledBeforeCheckIn
            refundRenter
            full_refund_enabled
            no_refund_enabled
            half_refund_enabled
            full_refund_date
            half_refund_date
            stripePaymentIntentID
            _version
            __typename
          }
          baseBookingCost
          renterFeeCost
          renterTotalBookingCost
          renterStripeFee
          hostFeeCost
          hostPayout
          hookhubPayout
          createdAt
          updatedAt
          _version
          __typename
        }
        bookedDays
        check_in
        check_out
        stripePaymentMethodID
        stripeCustomerID
        stripeHostConnectedAccountId
        hostEmail
        renterEmail
        createdAt
        updatedAt
        baseBookingCost
        renterFeeCost
        renterTotalBookingCost
        renterStripeFee
        hostFeeCost
        hostPayout
        hookhubPayout
        paymentRequiresAction
        payMonthly
        numberOfMonths
        paymentSchedule
        nextPaymentsSchedule
        nextPaymentDue
        monthlyPaymentAmount
        monthlyHostPayout
        monthlyHookhubPayout
        cancelledFinalHostPayout
        cancelledFinalRenterPayment
        cancelledBeforeCheckIn
        refundRenter
        full_refund_enabled
        no_refund_enabled
        half_refund_enabled
        full_refund_date
        half_refund_date
        stripePaymentIntentID
        _version
        __typename
      }
      hostID
      hostEmail
      renterEmail
      conversation {
        id
        hostee
        host
        convoCreatorsEmail
        otherMembersEmail
        spaceID
        spaceTitle
        propertyID
        propertyTitle
        propertyLocation
        bookingRequestID
        bookingRequest {
          id
          members
          confirmed
          reviewed
          cancelled
          rentalType
          stripePaymentMethodID
          stripeCustomerID
          stripePaymentIntentID
          check_in
          check_out
          bookedDays
          spaceID
          spaceTitle
          spaceCity
          spaceState
          propertyTitle
          propertyID
          renterID
          bookingID
          booking {
            id
            members
            cancelled
            cancelledTooLate
            cancelledTooLatePayout
            cancelledDate
            spaceID
            renterID
            hostID
            bookingRequestId
            paymentID
            bookedDays
            check_in
            check_out
            stripePaymentMethodID
            stripeCustomerID
            stripeHostConnectedAccountId
            hostEmail
            renterEmail
            createdAt
            updatedAt
            baseBookingCost
            renterFeeCost
            renterTotalBookingCost
            renterStripeFee
            hostFeeCost
            hostPayout
            hookhubPayout
            paymentRequiresAction
            payMonthly
            numberOfMonths
            paymentSchedule
            nextPaymentsSchedule
            nextPaymentDue
            monthlyPaymentAmount
            monthlyHostPayout
            monthlyHookhubPayout
            cancelledFinalHostPayout
            cancelledFinalRenterPayment
            cancelledBeforeCheckIn
            refundRenter
            full_refund_enabled
            no_refund_enabled
            half_refund_enabled
            full_refund_date
            half_refund_date
            stripePaymentIntentID
            _version
            __typename
          }
          hostID
          hostEmail
          renterEmail
          conversation {
            id
            hostee
            host
            convoCreatorsEmail
            otherMembersEmail
            spaceID
            spaceTitle
            propertyID
            propertyTitle
            propertyLocation
            bookingRequestID
            members
            membersNames
            unreadHost
            unreadHostee
            type
            createdAt
            updatedAt
            _version
            __typename
          }
          createdAt
          updatedAt
          renter_acknowledge_liability
          baseBookingCost
          renterFeeCost
          renterTotalBookingCost
          renterStripeFee
          hostFeeCost
          hostPayout
          hookhubPayout
          payMonthly
          numberOfMonths
          paymentSchedule
          nextPaymentDue
          nextPaymentsSchedule
          monthlyPaymentAmount
          monthlyHookhubPayout
          monthlyHostPayout
          full_refund_enabled
          no_refund_enabled
          half_refund_enabled
          full_refund_date
          half_refund_date
          errorCode
          _version
          bookingRequestConversationId
          __typename
        }
        messages {
          items {
            id
            authorID
            authorName
            authorAvatar
            otherMembersEmail
            content
            convoID
            type
            createdAt
            updatedAt
            _version
            owner
            __typename
          }
          nextToken
          __typename
        }
        members
        membersNames
        unreadHost
        unreadHostee
        type
        createdAt
        updatedAt
        _version
        __typename
      }
      createdAt
      updatedAt
      renter_acknowledge_liability
      baseBookingCost
      renterFeeCost
      renterTotalBookingCost
      renterStripeFee
      hostFeeCost
      hostPayout
      hookhubPayout
      payMonthly
      numberOfMonths
      paymentSchedule
      nextPaymentDue
      nextPaymentsSchedule
      monthlyPaymentAmount
      monthlyHookhubPayout
      monthlyHostPayout
      full_refund_enabled
      no_refund_enabled
      half_refund_enabled
      full_refund_date
      half_refund_date
      errorCode
      _version
      bookingRequestConversationId
      __typename
    }
  }
`;
export const onDeleteBookingRequest = /* GraphQL */ `
  subscription OnDeleteBookingRequest(
    $filter: ModelSubscriptionBookingRequestFilterInput
  ) {
    onDeleteBookingRequest(filter: $filter) {
      id
      members
      confirmed
      reviewed
      cancelled
      rentalType
      stripePaymentMethodID
      stripeCustomerID
      stripePaymentIntentID
      check_in
      check_out
      bookedDays
      spaceID
      spaceTitle
      spaceCity
      spaceState
      propertyTitle
      propertyID
      renterID
      bookingID
      booking {
        id
        members
        cancelled
        cancelledTooLate
        cancelledTooLatePayout
        cancelledDate
        spaceID
        renterID
        hostID
        bookingRequestId
        bookingRequest {
          id
          members
          confirmed
          reviewed
          cancelled
          rentalType
          stripePaymentMethodID
          stripeCustomerID
          stripePaymentIntentID
          check_in
          check_out
          bookedDays
          spaceID
          spaceTitle
          spaceCity
          spaceState
          propertyTitle
          propertyID
          renterID
          bookingID
          booking {
            id
            members
            cancelled
            cancelledTooLate
            cancelledTooLatePayout
            cancelledDate
            spaceID
            renterID
            hostID
            bookingRequestId
            paymentID
            bookedDays
            check_in
            check_out
            stripePaymentMethodID
            stripeCustomerID
            stripeHostConnectedAccountId
            hostEmail
            renterEmail
            createdAt
            updatedAt
            baseBookingCost
            renterFeeCost
            renterTotalBookingCost
            renterStripeFee
            hostFeeCost
            hostPayout
            hookhubPayout
            paymentRequiresAction
            payMonthly
            numberOfMonths
            paymentSchedule
            nextPaymentsSchedule
            nextPaymentDue
            monthlyPaymentAmount
            monthlyHostPayout
            monthlyHookhubPayout
            cancelledFinalHostPayout
            cancelledFinalRenterPayment
            cancelledBeforeCheckIn
            refundRenter
            full_refund_enabled
            no_refund_enabled
            half_refund_enabled
            full_refund_date
            half_refund_date
            stripePaymentIntentID
            _version
            __typename
          }
          hostID
          hostEmail
          renterEmail
          conversation {
            id
            hostee
            host
            convoCreatorsEmail
            otherMembersEmail
            spaceID
            spaceTitle
            propertyID
            propertyTitle
            propertyLocation
            bookingRequestID
            members
            membersNames
            unreadHost
            unreadHostee
            type
            createdAt
            updatedAt
            _version
            __typename
          }
          createdAt
          updatedAt
          renter_acknowledge_liability
          baseBookingCost
          renterFeeCost
          renterTotalBookingCost
          renterStripeFee
          hostFeeCost
          hostPayout
          hookhubPayout
          payMonthly
          numberOfMonths
          paymentSchedule
          nextPaymentDue
          nextPaymentsSchedule
          monthlyPaymentAmount
          monthlyHookhubPayout
          monthlyHostPayout
          full_refund_enabled
          no_refund_enabled
          half_refund_enabled
          full_refund_date
          half_refund_date
          errorCode
          _version
          bookingRequestConversationId
          __typename
        }
        paymentID
        payment {
          id
          members
          date
          stripePaymentMethodID
          stripeCustomerID
          paid
          hostID
          bookingId
          booking {
            id
            members
            cancelled
            cancelledTooLate
            cancelledTooLatePayout
            cancelledDate
            spaceID
            renterID
            hostID
            bookingRequestId
            paymentID
            bookedDays
            check_in
            check_out
            stripePaymentMethodID
            stripeCustomerID
            stripeHostConnectedAccountId
            hostEmail
            renterEmail
            createdAt
            updatedAt
            baseBookingCost
            renterFeeCost
            renterTotalBookingCost
            renterStripeFee
            hostFeeCost
            hostPayout
            hookhubPayout
            paymentRequiresAction
            payMonthly
            numberOfMonths
            paymentSchedule
            nextPaymentsSchedule
            nextPaymentDue
            monthlyPaymentAmount
            monthlyHostPayout
            monthlyHookhubPayout
            cancelledFinalHostPayout
            cancelledFinalRenterPayment
            cancelledBeforeCheckIn
            refundRenter
            full_refund_enabled
            no_refund_enabled
            half_refund_enabled
            full_refund_date
            half_refund_date
            stripePaymentIntentID
            _version
            __typename
          }
          baseBookingCost
          renterFeeCost
          renterTotalBookingCost
          renterStripeFee
          hostFeeCost
          hostPayout
          hookhubPayout
          createdAt
          updatedAt
          _version
          __typename
        }
        bookedDays
        check_in
        check_out
        stripePaymentMethodID
        stripeCustomerID
        stripeHostConnectedAccountId
        hostEmail
        renterEmail
        createdAt
        updatedAt
        baseBookingCost
        renterFeeCost
        renterTotalBookingCost
        renterStripeFee
        hostFeeCost
        hostPayout
        hookhubPayout
        paymentRequiresAction
        payMonthly
        numberOfMonths
        paymentSchedule
        nextPaymentsSchedule
        nextPaymentDue
        monthlyPaymentAmount
        monthlyHostPayout
        monthlyHookhubPayout
        cancelledFinalHostPayout
        cancelledFinalRenterPayment
        cancelledBeforeCheckIn
        refundRenter
        full_refund_enabled
        no_refund_enabled
        half_refund_enabled
        full_refund_date
        half_refund_date
        stripePaymentIntentID
        _version
        __typename
      }
      hostID
      hostEmail
      renterEmail
      conversation {
        id
        hostee
        host
        convoCreatorsEmail
        otherMembersEmail
        spaceID
        spaceTitle
        propertyID
        propertyTitle
        propertyLocation
        bookingRequestID
        bookingRequest {
          id
          members
          confirmed
          reviewed
          cancelled
          rentalType
          stripePaymentMethodID
          stripeCustomerID
          stripePaymentIntentID
          check_in
          check_out
          bookedDays
          spaceID
          spaceTitle
          spaceCity
          spaceState
          propertyTitle
          propertyID
          renterID
          bookingID
          booking {
            id
            members
            cancelled
            cancelledTooLate
            cancelledTooLatePayout
            cancelledDate
            spaceID
            renterID
            hostID
            bookingRequestId
            paymentID
            bookedDays
            check_in
            check_out
            stripePaymentMethodID
            stripeCustomerID
            stripeHostConnectedAccountId
            hostEmail
            renterEmail
            createdAt
            updatedAt
            baseBookingCost
            renterFeeCost
            renterTotalBookingCost
            renterStripeFee
            hostFeeCost
            hostPayout
            hookhubPayout
            paymentRequiresAction
            payMonthly
            numberOfMonths
            paymentSchedule
            nextPaymentsSchedule
            nextPaymentDue
            monthlyPaymentAmount
            monthlyHostPayout
            monthlyHookhubPayout
            cancelledFinalHostPayout
            cancelledFinalRenterPayment
            cancelledBeforeCheckIn
            refundRenter
            full_refund_enabled
            no_refund_enabled
            half_refund_enabled
            full_refund_date
            half_refund_date
            stripePaymentIntentID
            _version
            __typename
          }
          hostID
          hostEmail
          renterEmail
          conversation {
            id
            hostee
            host
            convoCreatorsEmail
            otherMembersEmail
            spaceID
            spaceTitle
            propertyID
            propertyTitle
            propertyLocation
            bookingRequestID
            members
            membersNames
            unreadHost
            unreadHostee
            type
            createdAt
            updatedAt
            _version
            __typename
          }
          createdAt
          updatedAt
          renter_acknowledge_liability
          baseBookingCost
          renterFeeCost
          renterTotalBookingCost
          renterStripeFee
          hostFeeCost
          hostPayout
          hookhubPayout
          payMonthly
          numberOfMonths
          paymentSchedule
          nextPaymentDue
          nextPaymentsSchedule
          monthlyPaymentAmount
          monthlyHookhubPayout
          monthlyHostPayout
          full_refund_enabled
          no_refund_enabled
          half_refund_enabled
          full_refund_date
          half_refund_date
          errorCode
          _version
          bookingRequestConversationId
          __typename
        }
        messages {
          items {
            id
            authorID
            authorName
            authorAvatar
            otherMembersEmail
            content
            convoID
            type
            createdAt
            updatedAt
            _version
            owner
            __typename
          }
          nextToken
          __typename
        }
        members
        membersNames
        unreadHost
        unreadHostee
        type
        createdAt
        updatedAt
        _version
        __typename
      }
      createdAt
      updatedAt
      renter_acknowledge_liability
      baseBookingCost
      renterFeeCost
      renterTotalBookingCost
      renterStripeFee
      hostFeeCost
      hostPayout
      hookhubPayout
      payMonthly
      numberOfMonths
      paymentSchedule
      nextPaymentDue
      nextPaymentsSchedule
      monthlyPaymentAmount
      monthlyHookhubPayout
      monthlyHostPayout
      full_refund_enabled
      no_refund_enabled
      half_refund_enabled
      full_refund_date
      half_refund_date
      errorCode
      _version
      bookingRequestConversationId
      __typename
    }
  }
`;
export const onCreatePayment = /* GraphQL */ `
  subscription OnCreatePayment($filter: ModelSubscriptionPaymentFilterInput) {
    onCreatePayment(filter: $filter) {
      id
      members
      date
      stripePaymentMethodID
      stripeCustomerID
      paid
      hostID
      bookingId
      booking {
        id
        members
        cancelled
        cancelledTooLate
        cancelledTooLatePayout
        cancelledDate
        spaceID
        renterID
        hostID
        bookingRequestId
        bookingRequest {
          id
          members
          confirmed
          reviewed
          cancelled
          rentalType
          stripePaymentMethodID
          stripeCustomerID
          stripePaymentIntentID
          check_in
          check_out
          bookedDays
          spaceID
          spaceTitle
          spaceCity
          spaceState
          propertyTitle
          propertyID
          renterID
          bookingID
          booking {
            id
            members
            cancelled
            cancelledTooLate
            cancelledTooLatePayout
            cancelledDate
            spaceID
            renterID
            hostID
            bookingRequestId
            paymentID
            bookedDays
            check_in
            check_out
            stripePaymentMethodID
            stripeCustomerID
            stripeHostConnectedAccountId
            hostEmail
            renterEmail
            createdAt
            updatedAt
            baseBookingCost
            renterFeeCost
            renterTotalBookingCost
            renterStripeFee
            hostFeeCost
            hostPayout
            hookhubPayout
            paymentRequiresAction
            payMonthly
            numberOfMonths
            paymentSchedule
            nextPaymentsSchedule
            nextPaymentDue
            monthlyPaymentAmount
            monthlyHostPayout
            monthlyHookhubPayout
            cancelledFinalHostPayout
            cancelledFinalRenterPayment
            cancelledBeforeCheckIn
            refundRenter
            full_refund_enabled
            no_refund_enabled
            half_refund_enabled
            full_refund_date
            half_refund_date
            stripePaymentIntentID
            _version
            __typename
          }
          hostID
          hostEmail
          renterEmail
          conversation {
            id
            hostee
            host
            convoCreatorsEmail
            otherMembersEmail
            spaceID
            spaceTitle
            propertyID
            propertyTitle
            propertyLocation
            bookingRequestID
            members
            membersNames
            unreadHost
            unreadHostee
            type
            createdAt
            updatedAt
            _version
            __typename
          }
          createdAt
          updatedAt
          renter_acknowledge_liability
          baseBookingCost
          renterFeeCost
          renterTotalBookingCost
          renterStripeFee
          hostFeeCost
          hostPayout
          hookhubPayout
          payMonthly
          numberOfMonths
          paymentSchedule
          nextPaymentDue
          nextPaymentsSchedule
          monthlyPaymentAmount
          monthlyHookhubPayout
          monthlyHostPayout
          full_refund_enabled
          no_refund_enabled
          half_refund_enabled
          full_refund_date
          half_refund_date
          errorCode
          _version
          bookingRequestConversationId
          __typename
        }
        paymentID
        payment {
          id
          members
          date
          stripePaymentMethodID
          stripeCustomerID
          paid
          hostID
          bookingId
          booking {
            id
            members
            cancelled
            cancelledTooLate
            cancelledTooLatePayout
            cancelledDate
            spaceID
            renterID
            hostID
            bookingRequestId
            paymentID
            bookedDays
            check_in
            check_out
            stripePaymentMethodID
            stripeCustomerID
            stripeHostConnectedAccountId
            hostEmail
            renterEmail
            createdAt
            updatedAt
            baseBookingCost
            renterFeeCost
            renterTotalBookingCost
            renterStripeFee
            hostFeeCost
            hostPayout
            hookhubPayout
            paymentRequiresAction
            payMonthly
            numberOfMonths
            paymentSchedule
            nextPaymentsSchedule
            nextPaymentDue
            monthlyPaymentAmount
            monthlyHostPayout
            monthlyHookhubPayout
            cancelledFinalHostPayout
            cancelledFinalRenterPayment
            cancelledBeforeCheckIn
            refundRenter
            full_refund_enabled
            no_refund_enabled
            half_refund_enabled
            full_refund_date
            half_refund_date
            stripePaymentIntentID
            _version
            __typename
          }
          baseBookingCost
          renterFeeCost
          renterTotalBookingCost
          renterStripeFee
          hostFeeCost
          hostPayout
          hookhubPayout
          createdAt
          updatedAt
          _version
          __typename
        }
        bookedDays
        check_in
        check_out
        stripePaymentMethodID
        stripeCustomerID
        stripeHostConnectedAccountId
        hostEmail
        renterEmail
        createdAt
        updatedAt
        baseBookingCost
        renterFeeCost
        renterTotalBookingCost
        renterStripeFee
        hostFeeCost
        hostPayout
        hookhubPayout
        paymentRequiresAction
        payMonthly
        numberOfMonths
        paymentSchedule
        nextPaymentsSchedule
        nextPaymentDue
        monthlyPaymentAmount
        monthlyHostPayout
        monthlyHookhubPayout
        cancelledFinalHostPayout
        cancelledFinalRenterPayment
        cancelledBeforeCheckIn
        refundRenter
        full_refund_enabled
        no_refund_enabled
        half_refund_enabled
        full_refund_date
        half_refund_date
        stripePaymentIntentID
        _version
        __typename
      }
      baseBookingCost
      renterFeeCost
      renterTotalBookingCost
      renterStripeFee
      hostFeeCost
      hostPayout
      hookhubPayout
      createdAt
      updatedAt
      _version
      __typename
    }
  }
`;
export const onUpdatePayment = /* GraphQL */ `
  subscription OnUpdatePayment($filter: ModelSubscriptionPaymentFilterInput) {
    onUpdatePayment(filter: $filter) {
      id
      members
      date
      stripePaymentMethodID
      stripeCustomerID
      paid
      hostID
      bookingId
      booking {
        id
        members
        cancelled
        cancelledTooLate
        cancelledTooLatePayout
        cancelledDate
        spaceID
        renterID
        hostID
        bookingRequestId
        bookingRequest {
          id
          members
          confirmed
          reviewed
          cancelled
          rentalType
          stripePaymentMethodID
          stripeCustomerID
          stripePaymentIntentID
          check_in
          check_out
          bookedDays
          spaceID
          spaceTitle
          spaceCity
          spaceState
          propertyTitle
          propertyID
          renterID
          bookingID
          booking {
            id
            members
            cancelled
            cancelledTooLate
            cancelledTooLatePayout
            cancelledDate
            spaceID
            renterID
            hostID
            bookingRequestId
            paymentID
            bookedDays
            check_in
            check_out
            stripePaymentMethodID
            stripeCustomerID
            stripeHostConnectedAccountId
            hostEmail
            renterEmail
            createdAt
            updatedAt
            baseBookingCost
            renterFeeCost
            renterTotalBookingCost
            renterStripeFee
            hostFeeCost
            hostPayout
            hookhubPayout
            paymentRequiresAction
            payMonthly
            numberOfMonths
            paymentSchedule
            nextPaymentsSchedule
            nextPaymentDue
            monthlyPaymentAmount
            monthlyHostPayout
            monthlyHookhubPayout
            cancelledFinalHostPayout
            cancelledFinalRenterPayment
            cancelledBeforeCheckIn
            refundRenter
            full_refund_enabled
            no_refund_enabled
            half_refund_enabled
            full_refund_date
            half_refund_date
            stripePaymentIntentID
            _version
            __typename
          }
          hostID
          hostEmail
          renterEmail
          conversation {
            id
            hostee
            host
            convoCreatorsEmail
            otherMembersEmail
            spaceID
            spaceTitle
            propertyID
            propertyTitle
            propertyLocation
            bookingRequestID
            members
            membersNames
            unreadHost
            unreadHostee
            type
            createdAt
            updatedAt
            _version
            __typename
          }
          createdAt
          updatedAt
          renter_acknowledge_liability
          baseBookingCost
          renterFeeCost
          renterTotalBookingCost
          renterStripeFee
          hostFeeCost
          hostPayout
          hookhubPayout
          payMonthly
          numberOfMonths
          paymentSchedule
          nextPaymentDue
          nextPaymentsSchedule
          monthlyPaymentAmount
          monthlyHookhubPayout
          monthlyHostPayout
          full_refund_enabled
          no_refund_enabled
          half_refund_enabled
          full_refund_date
          half_refund_date
          errorCode
          _version
          bookingRequestConversationId
          __typename
        }
        paymentID
        payment {
          id
          members
          date
          stripePaymentMethodID
          stripeCustomerID
          paid
          hostID
          bookingId
          booking {
            id
            members
            cancelled
            cancelledTooLate
            cancelledTooLatePayout
            cancelledDate
            spaceID
            renterID
            hostID
            bookingRequestId
            paymentID
            bookedDays
            check_in
            check_out
            stripePaymentMethodID
            stripeCustomerID
            stripeHostConnectedAccountId
            hostEmail
            renterEmail
            createdAt
            updatedAt
            baseBookingCost
            renterFeeCost
            renterTotalBookingCost
            renterStripeFee
            hostFeeCost
            hostPayout
            hookhubPayout
            paymentRequiresAction
            payMonthly
            numberOfMonths
            paymentSchedule
            nextPaymentsSchedule
            nextPaymentDue
            monthlyPaymentAmount
            monthlyHostPayout
            monthlyHookhubPayout
            cancelledFinalHostPayout
            cancelledFinalRenterPayment
            cancelledBeforeCheckIn
            refundRenter
            full_refund_enabled
            no_refund_enabled
            half_refund_enabled
            full_refund_date
            half_refund_date
            stripePaymentIntentID
            _version
            __typename
          }
          baseBookingCost
          renterFeeCost
          renterTotalBookingCost
          renterStripeFee
          hostFeeCost
          hostPayout
          hookhubPayout
          createdAt
          updatedAt
          _version
          __typename
        }
        bookedDays
        check_in
        check_out
        stripePaymentMethodID
        stripeCustomerID
        stripeHostConnectedAccountId
        hostEmail
        renterEmail
        createdAt
        updatedAt
        baseBookingCost
        renterFeeCost
        renterTotalBookingCost
        renterStripeFee
        hostFeeCost
        hostPayout
        hookhubPayout
        paymentRequiresAction
        payMonthly
        numberOfMonths
        paymentSchedule
        nextPaymentsSchedule
        nextPaymentDue
        monthlyPaymentAmount
        monthlyHostPayout
        monthlyHookhubPayout
        cancelledFinalHostPayout
        cancelledFinalRenterPayment
        cancelledBeforeCheckIn
        refundRenter
        full_refund_enabled
        no_refund_enabled
        half_refund_enabled
        full_refund_date
        half_refund_date
        stripePaymentIntentID
        _version
        __typename
      }
      baseBookingCost
      renterFeeCost
      renterTotalBookingCost
      renterStripeFee
      hostFeeCost
      hostPayout
      hookhubPayout
      createdAt
      updatedAt
      _version
      __typename
    }
  }
`;
export const onDeletePayment = /* GraphQL */ `
  subscription OnDeletePayment($filter: ModelSubscriptionPaymentFilterInput) {
    onDeletePayment(filter: $filter) {
      id
      members
      date
      stripePaymentMethodID
      stripeCustomerID
      paid
      hostID
      bookingId
      booking {
        id
        members
        cancelled
        cancelledTooLate
        cancelledTooLatePayout
        cancelledDate
        spaceID
        renterID
        hostID
        bookingRequestId
        bookingRequest {
          id
          members
          confirmed
          reviewed
          cancelled
          rentalType
          stripePaymentMethodID
          stripeCustomerID
          stripePaymentIntentID
          check_in
          check_out
          bookedDays
          spaceID
          spaceTitle
          spaceCity
          spaceState
          propertyTitle
          propertyID
          renterID
          bookingID
          booking {
            id
            members
            cancelled
            cancelledTooLate
            cancelledTooLatePayout
            cancelledDate
            spaceID
            renterID
            hostID
            bookingRequestId
            paymentID
            bookedDays
            check_in
            check_out
            stripePaymentMethodID
            stripeCustomerID
            stripeHostConnectedAccountId
            hostEmail
            renterEmail
            createdAt
            updatedAt
            baseBookingCost
            renterFeeCost
            renterTotalBookingCost
            renterStripeFee
            hostFeeCost
            hostPayout
            hookhubPayout
            paymentRequiresAction
            payMonthly
            numberOfMonths
            paymentSchedule
            nextPaymentsSchedule
            nextPaymentDue
            monthlyPaymentAmount
            monthlyHostPayout
            monthlyHookhubPayout
            cancelledFinalHostPayout
            cancelledFinalRenterPayment
            cancelledBeforeCheckIn
            refundRenter
            full_refund_enabled
            no_refund_enabled
            half_refund_enabled
            full_refund_date
            half_refund_date
            stripePaymentIntentID
            _version
            __typename
          }
          hostID
          hostEmail
          renterEmail
          conversation {
            id
            hostee
            host
            convoCreatorsEmail
            otherMembersEmail
            spaceID
            spaceTitle
            propertyID
            propertyTitle
            propertyLocation
            bookingRequestID
            members
            membersNames
            unreadHost
            unreadHostee
            type
            createdAt
            updatedAt
            _version
            __typename
          }
          createdAt
          updatedAt
          renter_acknowledge_liability
          baseBookingCost
          renterFeeCost
          renterTotalBookingCost
          renterStripeFee
          hostFeeCost
          hostPayout
          hookhubPayout
          payMonthly
          numberOfMonths
          paymentSchedule
          nextPaymentDue
          nextPaymentsSchedule
          monthlyPaymentAmount
          monthlyHookhubPayout
          monthlyHostPayout
          full_refund_enabled
          no_refund_enabled
          half_refund_enabled
          full_refund_date
          half_refund_date
          errorCode
          _version
          bookingRequestConversationId
          __typename
        }
        paymentID
        payment {
          id
          members
          date
          stripePaymentMethodID
          stripeCustomerID
          paid
          hostID
          bookingId
          booking {
            id
            members
            cancelled
            cancelledTooLate
            cancelledTooLatePayout
            cancelledDate
            spaceID
            renterID
            hostID
            bookingRequestId
            paymentID
            bookedDays
            check_in
            check_out
            stripePaymentMethodID
            stripeCustomerID
            stripeHostConnectedAccountId
            hostEmail
            renterEmail
            createdAt
            updatedAt
            baseBookingCost
            renterFeeCost
            renterTotalBookingCost
            renterStripeFee
            hostFeeCost
            hostPayout
            hookhubPayout
            paymentRequiresAction
            payMonthly
            numberOfMonths
            paymentSchedule
            nextPaymentsSchedule
            nextPaymentDue
            monthlyPaymentAmount
            monthlyHostPayout
            monthlyHookhubPayout
            cancelledFinalHostPayout
            cancelledFinalRenterPayment
            cancelledBeforeCheckIn
            refundRenter
            full_refund_enabled
            no_refund_enabled
            half_refund_enabled
            full_refund_date
            half_refund_date
            stripePaymentIntentID
            _version
            __typename
          }
          baseBookingCost
          renterFeeCost
          renterTotalBookingCost
          renterStripeFee
          hostFeeCost
          hostPayout
          hookhubPayout
          createdAt
          updatedAt
          _version
          __typename
        }
        bookedDays
        check_in
        check_out
        stripePaymentMethodID
        stripeCustomerID
        stripeHostConnectedAccountId
        hostEmail
        renterEmail
        createdAt
        updatedAt
        baseBookingCost
        renterFeeCost
        renterTotalBookingCost
        renterStripeFee
        hostFeeCost
        hostPayout
        hookhubPayout
        paymentRequiresAction
        payMonthly
        numberOfMonths
        paymentSchedule
        nextPaymentsSchedule
        nextPaymentDue
        monthlyPaymentAmount
        monthlyHostPayout
        monthlyHookhubPayout
        cancelledFinalHostPayout
        cancelledFinalRenterPayment
        cancelledBeforeCheckIn
        refundRenter
        full_refund_enabled
        no_refund_enabled
        half_refund_enabled
        full_refund_date
        half_refund_date
        stripePaymentIntentID
        _version
        __typename
      }
      baseBookingCost
      renterFeeCost
      renterTotalBookingCost
      renterStripeFee
      hostFeeCost
      hostPayout
      hookhubPayout
      createdAt
      updatedAt
      _version
      __typename
    }
  }
`;
export const onCreateReview = /* GraphQL */ `
  subscription OnCreateReview(
    $filter: ModelSubscriptionReviewFilterInput
    $reviewerID: String
  ) {
    onCreateReview(filter: $filter, reviewerID: $reviewerID) {
      id
      rating
      locationRating
      communicationRating
      checkinRating
      valueRating
      description
      propertyID
      reviewerName
      reviewerAvatar
      reviewerID
      bookingID
      images
      _version
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onUpdateReview = /* GraphQL */ `
  subscription OnUpdateReview(
    $filter: ModelSubscriptionReviewFilterInput
    $reviewerID: String
  ) {
    onUpdateReview(filter: $filter, reviewerID: $reviewerID) {
      id
      rating
      locationRating
      communicationRating
      checkinRating
      valueRating
      description
      propertyID
      reviewerName
      reviewerAvatar
      reviewerID
      bookingID
      images
      _version
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onDeleteReview = /* GraphQL */ `
  subscription OnDeleteReview(
    $filter: ModelSubscriptionReviewFilterInput
    $reviewerID: String
  ) {
    onDeleteReview(filter: $filter, reviewerID: $reviewerID) {
      id
      rating
      locationRating
      communicationRating
      checkinRating
      valueRating
      description
      propertyID
      reviewerName
      reviewerAvatar
      reviewerID
      bookingID
      images
      _version
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onCreateRenterReview = /* GraphQL */ `
  subscription OnCreateRenterReview(
    $filter: ModelSubscriptionRenterReviewFilterInput
    $reviewerID: String
  ) {
    onCreateRenterReview(filter: $filter, reviewerID: $reviewerID) {
      id
      rating
      cleanlinessRating
      communicationRating
      overallRating
      description
      renterID
      reviewerName
      reviewerAvatar
      reviewerID
      bookingID
      propertyID
      _version
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onUpdateRenterReview = /* GraphQL */ `
  subscription OnUpdateRenterReview(
    $filter: ModelSubscriptionRenterReviewFilterInput
    $reviewerID: String
  ) {
    onUpdateRenterReview(filter: $filter, reviewerID: $reviewerID) {
      id
      rating
      cleanlinessRating
      communicationRating
      overallRating
      description
      renterID
      reviewerName
      reviewerAvatar
      reviewerID
      bookingID
      propertyID
      _version
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onDeleteRenterReview = /* GraphQL */ `
  subscription OnDeleteRenterReview(
    $filter: ModelSubscriptionRenterReviewFilterInput
    $reviewerID: String
  ) {
    onDeleteRenterReview(filter: $filter, reviewerID: $reviewerID) {
      id
      rating
      cleanlinessRating
      communicationRating
      overallRating
      description
      renterID
      reviewerName
      reviewerAvatar
      reviewerID
      bookingID
      propertyID
      _version
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onCreateConversation = /* GraphQL */ `
  subscription OnCreateConversation(
    $filter: ModelSubscriptionConversationFilterInput
  ) {
    onCreateConversation(filter: $filter) {
      id
      hostee
      host
      convoCreatorsEmail
      otherMembersEmail
      spaceID
      spaceTitle
      propertyID
      propertyTitle
      propertyLocation
      bookingRequestID
      bookingRequest {
        id
        members
        confirmed
        reviewed
        cancelled
        rentalType
        stripePaymentMethodID
        stripeCustomerID
        stripePaymentIntentID
        check_in
        check_out
        bookedDays
        spaceID
        spaceTitle
        spaceCity
        spaceState
        propertyTitle
        propertyID
        renterID
        bookingID
        booking {
          id
          members
          cancelled
          cancelledTooLate
          cancelledTooLatePayout
          cancelledDate
          spaceID
          renterID
          hostID
          bookingRequestId
          bookingRequest {
            id
            members
            confirmed
            reviewed
            cancelled
            rentalType
            stripePaymentMethodID
            stripeCustomerID
            stripePaymentIntentID
            check_in
            check_out
            bookedDays
            spaceID
            spaceTitle
            spaceCity
            spaceState
            propertyTitle
            propertyID
            renterID
            bookingID
            hostID
            hostEmail
            renterEmail
            createdAt
            updatedAt
            renter_acknowledge_liability
            baseBookingCost
            renterFeeCost
            renterTotalBookingCost
            renterStripeFee
            hostFeeCost
            hostPayout
            hookhubPayout
            payMonthly
            numberOfMonths
            paymentSchedule
            nextPaymentDue
            nextPaymentsSchedule
            monthlyPaymentAmount
            monthlyHookhubPayout
            monthlyHostPayout
            full_refund_enabled
            no_refund_enabled
            half_refund_enabled
            full_refund_date
            half_refund_date
            errorCode
            _version
            bookingRequestConversationId
            __typename
          }
          paymentID
          payment {
            id
            members
            date
            stripePaymentMethodID
            stripeCustomerID
            paid
            hostID
            bookingId
            baseBookingCost
            renterFeeCost
            renterTotalBookingCost
            renterStripeFee
            hostFeeCost
            hostPayout
            hookhubPayout
            createdAt
            updatedAt
            _version
            __typename
          }
          bookedDays
          check_in
          check_out
          stripePaymentMethodID
          stripeCustomerID
          stripeHostConnectedAccountId
          hostEmail
          renterEmail
          createdAt
          updatedAt
          baseBookingCost
          renterFeeCost
          renterTotalBookingCost
          renterStripeFee
          hostFeeCost
          hostPayout
          hookhubPayout
          paymentRequiresAction
          payMonthly
          numberOfMonths
          paymentSchedule
          nextPaymentsSchedule
          nextPaymentDue
          monthlyPaymentAmount
          monthlyHostPayout
          monthlyHookhubPayout
          cancelledFinalHostPayout
          cancelledFinalRenterPayment
          cancelledBeforeCheckIn
          refundRenter
          full_refund_enabled
          no_refund_enabled
          half_refund_enabled
          full_refund_date
          half_refund_date
          stripePaymentIntentID
          _version
          __typename
        }
        hostID
        hostEmail
        renterEmail
        conversation {
          id
          hostee
          host
          convoCreatorsEmail
          otherMembersEmail
          spaceID
          spaceTitle
          propertyID
          propertyTitle
          propertyLocation
          bookingRequestID
          bookingRequest {
            id
            members
            confirmed
            reviewed
            cancelled
            rentalType
            stripePaymentMethodID
            stripeCustomerID
            stripePaymentIntentID
            check_in
            check_out
            bookedDays
            spaceID
            spaceTitle
            spaceCity
            spaceState
            propertyTitle
            propertyID
            renterID
            bookingID
            hostID
            hostEmail
            renterEmail
            createdAt
            updatedAt
            renter_acknowledge_liability
            baseBookingCost
            renterFeeCost
            renterTotalBookingCost
            renterStripeFee
            hostFeeCost
            hostPayout
            hookhubPayout
            payMonthly
            numberOfMonths
            paymentSchedule
            nextPaymentDue
            nextPaymentsSchedule
            monthlyPaymentAmount
            monthlyHookhubPayout
            monthlyHostPayout
            full_refund_enabled
            no_refund_enabled
            half_refund_enabled
            full_refund_date
            half_refund_date
            errorCode
            _version
            bookingRequestConversationId
            __typename
          }
          messages {
            nextToken
            __typename
          }
          members
          membersNames
          unreadHost
          unreadHostee
          type
          createdAt
          updatedAt
          _version
          __typename
        }
        createdAt
        updatedAt
        renter_acknowledge_liability
        baseBookingCost
        renterFeeCost
        renterTotalBookingCost
        renterStripeFee
        hostFeeCost
        hostPayout
        hookhubPayout
        payMonthly
        numberOfMonths
        paymentSchedule
        nextPaymentDue
        nextPaymentsSchedule
        monthlyPaymentAmount
        monthlyHookhubPayout
        monthlyHostPayout
        full_refund_enabled
        no_refund_enabled
        half_refund_enabled
        full_refund_date
        half_refund_date
        errorCode
        _version
        bookingRequestConversationId
        __typename
      }
      messages {
        items {
          id
          authorID
          authorName
          authorAvatar
          otherMembersEmail
          content
          convoID
          conversation {
            id
            hostee
            host
            convoCreatorsEmail
            otherMembersEmail
            spaceID
            spaceTitle
            propertyID
            propertyTitle
            propertyLocation
            bookingRequestID
            members
            membersNames
            unreadHost
            unreadHostee
            type
            createdAt
            updatedAt
            _version
            __typename
          }
          type
          createdAt
          updatedAt
          _version
          owner
          __typename
        }
        nextToken
        __typename
      }
      members
      membersNames
      unreadHost
      unreadHostee
      type
      createdAt
      updatedAt
      _version
      __typename
    }
  }
`;
export const onUpdateConversation = /* GraphQL */ `
  subscription OnUpdateConversation(
    $filter: ModelSubscriptionConversationFilterInput
  ) {
    onUpdateConversation(filter: $filter) {
      id
      hostee
      host
      convoCreatorsEmail
      otherMembersEmail
      spaceID
      spaceTitle
      propertyID
      propertyTitle
      propertyLocation
      bookingRequestID
      bookingRequest {
        id
        members
        confirmed
        reviewed
        cancelled
        rentalType
        stripePaymentMethodID
        stripeCustomerID
        stripePaymentIntentID
        check_in
        check_out
        bookedDays
        spaceID
        spaceTitle
        spaceCity
        spaceState
        propertyTitle
        propertyID
        renterID
        bookingID
        booking {
          id
          members
          cancelled
          cancelledTooLate
          cancelledTooLatePayout
          cancelledDate
          spaceID
          renterID
          hostID
          bookingRequestId
          bookingRequest {
            id
            members
            confirmed
            reviewed
            cancelled
            rentalType
            stripePaymentMethodID
            stripeCustomerID
            stripePaymentIntentID
            check_in
            check_out
            bookedDays
            spaceID
            spaceTitle
            spaceCity
            spaceState
            propertyTitle
            propertyID
            renterID
            bookingID
            hostID
            hostEmail
            renterEmail
            createdAt
            updatedAt
            renter_acknowledge_liability
            baseBookingCost
            renterFeeCost
            renterTotalBookingCost
            renterStripeFee
            hostFeeCost
            hostPayout
            hookhubPayout
            payMonthly
            numberOfMonths
            paymentSchedule
            nextPaymentDue
            nextPaymentsSchedule
            monthlyPaymentAmount
            monthlyHookhubPayout
            monthlyHostPayout
            full_refund_enabled
            no_refund_enabled
            half_refund_enabled
            full_refund_date
            half_refund_date
            errorCode
            _version
            bookingRequestConversationId
            __typename
          }
          paymentID
          payment {
            id
            members
            date
            stripePaymentMethodID
            stripeCustomerID
            paid
            hostID
            bookingId
            baseBookingCost
            renterFeeCost
            renterTotalBookingCost
            renterStripeFee
            hostFeeCost
            hostPayout
            hookhubPayout
            createdAt
            updatedAt
            _version
            __typename
          }
          bookedDays
          check_in
          check_out
          stripePaymentMethodID
          stripeCustomerID
          stripeHostConnectedAccountId
          hostEmail
          renterEmail
          createdAt
          updatedAt
          baseBookingCost
          renterFeeCost
          renterTotalBookingCost
          renterStripeFee
          hostFeeCost
          hostPayout
          hookhubPayout
          paymentRequiresAction
          payMonthly
          numberOfMonths
          paymentSchedule
          nextPaymentsSchedule
          nextPaymentDue
          monthlyPaymentAmount
          monthlyHostPayout
          monthlyHookhubPayout
          cancelledFinalHostPayout
          cancelledFinalRenterPayment
          cancelledBeforeCheckIn
          refundRenter
          full_refund_enabled
          no_refund_enabled
          half_refund_enabled
          full_refund_date
          half_refund_date
          stripePaymentIntentID
          _version
          __typename
        }
        hostID
        hostEmail
        renterEmail
        conversation {
          id
          hostee
          host
          convoCreatorsEmail
          otherMembersEmail
          spaceID
          spaceTitle
          propertyID
          propertyTitle
          propertyLocation
          bookingRequestID
          bookingRequest {
            id
            members
            confirmed
            reviewed
            cancelled
            rentalType
            stripePaymentMethodID
            stripeCustomerID
            stripePaymentIntentID
            check_in
            check_out
            bookedDays
            spaceID
            spaceTitle
            spaceCity
            spaceState
            propertyTitle
            propertyID
            renterID
            bookingID
            hostID
            hostEmail
            renterEmail
            createdAt
            updatedAt
            renter_acknowledge_liability
            baseBookingCost
            renterFeeCost
            renterTotalBookingCost
            renterStripeFee
            hostFeeCost
            hostPayout
            hookhubPayout
            payMonthly
            numberOfMonths
            paymentSchedule
            nextPaymentDue
            nextPaymentsSchedule
            monthlyPaymentAmount
            monthlyHookhubPayout
            monthlyHostPayout
            full_refund_enabled
            no_refund_enabled
            half_refund_enabled
            full_refund_date
            half_refund_date
            errorCode
            _version
            bookingRequestConversationId
            __typename
          }
          messages {
            nextToken
            __typename
          }
          members
          membersNames
          unreadHost
          unreadHostee
          type
          createdAt
          updatedAt
          _version
          __typename
        }
        createdAt
        updatedAt
        renter_acknowledge_liability
        baseBookingCost
        renterFeeCost
        renterTotalBookingCost
        renterStripeFee
        hostFeeCost
        hostPayout
        hookhubPayout
        payMonthly
        numberOfMonths
        paymentSchedule
        nextPaymentDue
        nextPaymentsSchedule
        monthlyPaymentAmount
        monthlyHookhubPayout
        monthlyHostPayout
        full_refund_enabled
        no_refund_enabled
        half_refund_enabled
        full_refund_date
        half_refund_date
        errorCode
        _version
        bookingRequestConversationId
        __typename
      }
      messages {
        items {
          id
          authorID
          authorName
          authorAvatar
          otherMembersEmail
          content
          convoID
          conversation {
            id
            hostee
            host
            convoCreatorsEmail
            otherMembersEmail
            spaceID
            spaceTitle
            propertyID
            propertyTitle
            propertyLocation
            bookingRequestID
            members
            membersNames
            unreadHost
            unreadHostee
            type
            createdAt
            updatedAt
            _version
            __typename
          }
          type
          createdAt
          updatedAt
          _version
          owner
          __typename
        }
        nextToken
        __typename
      }
      members
      membersNames
      unreadHost
      unreadHostee
      type
      createdAt
      updatedAt
      _version
      __typename
    }
  }
`;
export const onDeleteConversation = /* GraphQL */ `
  subscription OnDeleteConversation(
    $filter: ModelSubscriptionConversationFilterInput
  ) {
    onDeleteConversation(filter: $filter) {
      id
      hostee
      host
      convoCreatorsEmail
      otherMembersEmail
      spaceID
      spaceTitle
      propertyID
      propertyTitle
      propertyLocation
      bookingRequestID
      bookingRequest {
        id
        members
        confirmed
        reviewed
        cancelled
        rentalType
        stripePaymentMethodID
        stripeCustomerID
        stripePaymentIntentID
        check_in
        check_out
        bookedDays
        spaceID
        spaceTitle
        spaceCity
        spaceState
        propertyTitle
        propertyID
        renterID
        bookingID
        booking {
          id
          members
          cancelled
          cancelledTooLate
          cancelledTooLatePayout
          cancelledDate
          spaceID
          renterID
          hostID
          bookingRequestId
          bookingRequest {
            id
            members
            confirmed
            reviewed
            cancelled
            rentalType
            stripePaymentMethodID
            stripeCustomerID
            stripePaymentIntentID
            check_in
            check_out
            bookedDays
            spaceID
            spaceTitle
            spaceCity
            spaceState
            propertyTitle
            propertyID
            renterID
            bookingID
            hostID
            hostEmail
            renterEmail
            createdAt
            updatedAt
            renter_acknowledge_liability
            baseBookingCost
            renterFeeCost
            renterTotalBookingCost
            renterStripeFee
            hostFeeCost
            hostPayout
            hookhubPayout
            payMonthly
            numberOfMonths
            paymentSchedule
            nextPaymentDue
            nextPaymentsSchedule
            monthlyPaymentAmount
            monthlyHookhubPayout
            monthlyHostPayout
            full_refund_enabled
            no_refund_enabled
            half_refund_enabled
            full_refund_date
            half_refund_date
            errorCode
            _version
            bookingRequestConversationId
            __typename
          }
          paymentID
          payment {
            id
            members
            date
            stripePaymentMethodID
            stripeCustomerID
            paid
            hostID
            bookingId
            baseBookingCost
            renterFeeCost
            renterTotalBookingCost
            renterStripeFee
            hostFeeCost
            hostPayout
            hookhubPayout
            createdAt
            updatedAt
            _version
            __typename
          }
          bookedDays
          check_in
          check_out
          stripePaymentMethodID
          stripeCustomerID
          stripeHostConnectedAccountId
          hostEmail
          renterEmail
          createdAt
          updatedAt
          baseBookingCost
          renterFeeCost
          renterTotalBookingCost
          renterStripeFee
          hostFeeCost
          hostPayout
          hookhubPayout
          paymentRequiresAction
          payMonthly
          numberOfMonths
          paymentSchedule
          nextPaymentsSchedule
          nextPaymentDue
          monthlyPaymentAmount
          monthlyHostPayout
          monthlyHookhubPayout
          cancelledFinalHostPayout
          cancelledFinalRenterPayment
          cancelledBeforeCheckIn
          refundRenter
          full_refund_enabled
          no_refund_enabled
          half_refund_enabled
          full_refund_date
          half_refund_date
          stripePaymentIntentID
          _version
          __typename
        }
        hostID
        hostEmail
        renterEmail
        conversation {
          id
          hostee
          host
          convoCreatorsEmail
          otherMembersEmail
          spaceID
          spaceTitle
          propertyID
          propertyTitle
          propertyLocation
          bookingRequestID
          bookingRequest {
            id
            members
            confirmed
            reviewed
            cancelled
            rentalType
            stripePaymentMethodID
            stripeCustomerID
            stripePaymentIntentID
            check_in
            check_out
            bookedDays
            spaceID
            spaceTitle
            spaceCity
            spaceState
            propertyTitle
            propertyID
            renterID
            bookingID
            hostID
            hostEmail
            renterEmail
            createdAt
            updatedAt
            renter_acknowledge_liability
            baseBookingCost
            renterFeeCost
            renterTotalBookingCost
            renterStripeFee
            hostFeeCost
            hostPayout
            hookhubPayout
            payMonthly
            numberOfMonths
            paymentSchedule
            nextPaymentDue
            nextPaymentsSchedule
            monthlyPaymentAmount
            monthlyHookhubPayout
            monthlyHostPayout
            full_refund_enabled
            no_refund_enabled
            half_refund_enabled
            full_refund_date
            half_refund_date
            errorCode
            _version
            bookingRequestConversationId
            __typename
          }
          messages {
            nextToken
            __typename
          }
          members
          membersNames
          unreadHost
          unreadHostee
          type
          createdAt
          updatedAt
          _version
          __typename
        }
        createdAt
        updatedAt
        renter_acknowledge_liability
        baseBookingCost
        renterFeeCost
        renterTotalBookingCost
        renterStripeFee
        hostFeeCost
        hostPayout
        hookhubPayout
        payMonthly
        numberOfMonths
        paymentSchedule
        nextPaymentDue
        nextPaymentsSchedule
        monthlyPaymentAmount
        monthlyHookhubPayout
        monthlyHostPayout
        full_refund_enabled
        no_refund_enabled
        half_refund_enabled
        full_refund_date
        half_refund_date
        errorCode
        _version
        bookingRequestConversationId
        __typename
      }
      messages {
        items {
          id
          authorID
          authorName
          authorAvatar
          otherMembersEmail
          content
          convoID
          conversation {
            id
            hostee
            host
            convoCreatorsEmail
            otherMembersEmail
            spaceID
            spaceTitle
            propertyID
            propertyTitle
            propertyLocation
            bookingRequestID
            members
            membersNames
            unreadHost
            unreadHostee
            type
            createdAt
            updatedAt
            _version
            __typename
          }
          type
          createdAt
          updatedAt
          _version
          owner
          __typename
        }
        nextToken
        __typename
      }
      members
      membersNames
      unreadHost
      unreadHostee
      type
      createdAt
      updatedAt
      _version
      __typename
    }
  }
`;
export const onCreateMessage = /* GraphQL */ `
  subscription OnCreateMessage(
    $filter: ModelSubscriptionMessageFilterInput
    $owner: String
  ) {
    onCreateMessage(filter: $filter, owner: $owner) {
      id
      authorID
      authorName
      authorAvatar
      otherMembersEmail
      content
      convoID
      conversation {
        id
        hostee
        host
        convoCreatorsEmail
        otherMembersEmail
        spaceID
        spaceTitle
        propertyID
        propertyTitle
        propertyLocation
        bookingRequestID
        bookingRequest {
          id
          members
          confirmed
          reviewed
          cancelled
          rentalType
          stripePaymentMethodID
          stripeCustomerID
          stripePaymentIntentID
          check_in
          check_out
          bookedDays
          spaceID
          spaceTitle
          spaceCity
          spaceState
          propertyTitle
          propertyID
          renterID
          bookingID
          booking {
            id
            members
            cancelled
            cancelledTooLate
            cancelledTooLatePayout
            cancelledDate
            spaceID
            renterID
            hostID
            bookingRequestId
            paymentID
            bookedDays
            check_in
            check_out
            stripePaymentMethodID
            stripeCustomerID
            stripeHostConnectedAccountId
            hostEmail
            renterEmail
            createdAt
            updatedAt
            baseBookingCost
            renterFeeCost
            renterTotalBookingCost
            renterStripeFee
            hostFeeCost
            hostPayout
            hookhubPayout
            paymentRequiresAction
            payMonthly
            numberOfMonths
            paymentSchedule
            nextPaymentsSchedule
            nextPaymentDue
            monthlyPaymentAmount
            monthlyHostPayout
            monthlyHookhubPayout
            cancelledFinalHostPayout
            cancelledFinalRenterPayment
            cancelledBeforeCheckIn
            refundRenter
            full_refund_enabled
            no_refund_enabled
            half_refund_enabled
            full_refund_date
            half_refund_date
            stripePaymentIntentID
            _version
            __typename
          }
          hostID
          hostEmail
          renterEmail
          conversation {
            id
            hostee
            host
            convoCreatorsEmail
            otherMembersEmail
            spaceID
            spaceTitle
            propertyID
            propertyTitle
            propertyLocation
            bookingRequestID
            members
            membersNames
            unreadHost
            unreadHostee
            type
            createdAt
            updatedAt
            _version
            __typename
          }
          createdAt
          updatedAt
          renter_acknowledge_liability
          baseBookingCost
          renterFeeCost
          renterTotalBookingCost
          renterStripeFee
          hostFeeCost
          hostPayout
          hookhubPayout
          payMonthly
          numberOfMonths
          paymentSchedule
          nextPaymentDue
          nextPaymentsSchedule
          monthlyPaymentAmount
          monthlyHookhubPayout
          monthlyHostPayout
          full_refund_enabled
          no_refund_enabled
          half_refund_enabled
          full_refund_date
          half_refund_date
          errorCode
          _version
          bookingRequestConversationId
          __typename
        }
        messages {
          items {
            id
            authorID
            authorName
            authorAvatar
            otherMembersEmail
            content
            convoID
            type
            createdAt
            updatedAt
            _version
            owner
            __typename
          }
          nextToken
          __typename
        }
        members
        membersNames
        unreadHost
        unreadHostee
        type
        createdAt
        updatedAt
        _version
        __typename
      }
      type
      createdAt
      updatedAt
      _version
      owner
      __typename
    }
  }
`;
export const onUpdateMessage = /* GraphQL */ `
  subscription OnUpdateMessage(
    $filter: ModelSubscriptionMessageFilterInput
    $owner: String
  ) {
    onUpdateMessage(filter: $filter, owner: $owner) {
      id
      authorID
      authorName
      authorAvatar
      otherMembersEmail
      content
      convoID
      conversation {
        id
        hostee
        host
        convoCreatorsEmail
        otherMembersEmail
        spaceID
        spaceTitle
        propertyID
        propertyTitle
        propertyLocation
        bookingRequestID
        bookingRequest {
          id
          members
          confirmed
          reviewed
          cancelled
          rentalType
          stripePaymentMethodID
          stripeCustomerID
          stripePaymentIntentID
          check_in
          check_out
          bookedDays
          spaceID
          spaceTitle
          spaceCity
          spaceState
          propertyTitle
          propertyID
          renterID
          bookingID
          booking {
            id
            members
            cancelled
            cancelledTooLate
            cancelledTooLatePayout
            cancelledDate
            spaceID
            renterID
            hostID
            bookingRequestId
            paymentID
            bookedDays
            check_in
            check_out
            stripePaymentMethodID
            stripeCustomerID
            stripeHostConnectedAccountId
            hostEmail
            renterEmail
            createdAt
            updatedAt
            baseBookingCost
            renterFeeCost
            renterTotalBookingCost
            renterStripeFee
            hostFeeCost
            hostPayout
            hookhubPayout
            paymentRequiresAction
            payMonthly
            numberOfMonths
            paymentSchedule
            nextPaymentsSchedule
            nextPaymentDue
            monthlyPaymentAmount
            monthlyHostPayout
            monthlyHookhubPayout
            cancelledFinalHostPayout
            cancelledFinalRenterPayment
            cancelledBeforeCheckIn
            refundRenter
            full_refund_enabled
            no_refund_enabled
            half_refund_enabled
            full_refund_date
            half_refund_date
            stripePaymentIntentID
            _version
            __typename
          }
          hostID
          hostEmail
          renterEmail
          conversation {
            id
            hostee
            host
            convoCreatorsEmail
            otherMembersEmail
            spaceID
            spaceTitle
            propertyID
            propertyTitle
            propertyLocation
            bookingRequestID
            members
            membersNames
            unreadHost
            unreadHostee
            type
            createdAt
            updatedAt
            _version
            __typename
          }
          createdAt
          updatedAt
          renter_acknowledge_liability
          baseBookingCost
          renterFeeCost
          renterTotalBookingCost
          renterStripeFee
          hostFeeCost
          hostPayout
          hookhubPayout
          payMonthly
          numberOfMonths
          paymentSchedule
          nextPaymentDue
          nextPaymentsSchedule
          monthlyPaymentAmount
          monthlyHookhubPayout
          monthlyHostPayout
          full_refund_enabled
          no_refund_enabled
          half_refund_enabled
          full_refund_date
          half_refund_date
          errorCode
          _version
          bookingRequestConversationId
          __typename
        }
        messages {
          items {
            id
            authorID
            authorName
            authorAvatar
            otherMembersEmail
            content
            convoID
            type
            createdAt
            updatedAt
            _version
            owner
            __typename
          }
          nextToken
          __typename
        }
        members
        membersNames
        unreadHost
        unreadHostee
        type
        createdAt
        updatedAt
        _version
        __typename
      }
      type
      createdAt
      updatedAt
      _version
      owner
      __typename
    }
  }
`;
export const onDeleteMessage = /* GraphQL */ `
  subscription OnDeleteMessage(
    $filter: ModelSubscriptionMessageFilterInput
    $owner: String
  ) {
    onDeleteMessage(filter: $filter, owner: $owner) {
      id
      authorID
      authorName
      authorAvatar
      otherMembersEmail
      content
      convoID
      conversation {
        id
        hostee
        host
        convoCreatorsEmail
        otherMembersEmail
        spaceID
        spaceTitle
        propertyID
        propertyTitle
        propertyLocation
        bookingRequestID
        bookingRequest {
          id
          members
          confirmed
          reviewed
          cancelled
          rentalType
          stripePaymentMethodID
          stripeCustomerID
          stripePaymentIntentID
          check_in
          check_out
          bookedDays
          spaceID
          spaceTitle
          spaceCity
          spaceState
          propertyTitle
          propertyID
          renterID
          bookingID
          booking {
            id
            members
            cancelled
            cancelledTooLate
            cancelledTooLatePayout
            cancelledDate
            spaceID
            renterID
            hostID
            bookingRequestId
            paymentID
            bookedDays
            check_in
            check_out
            stripePaymentMethodID
            stripeCustomerID
            stripeHostConnectedAccountId
            hostEmail
            renterEmail
            createdAt
            updatedAt
            baseBookingCost
            renterFeeCost
            renterTotalBookingCost
            renterStripeFee
            hostFeeCost
            hostPayout
            hookhubPayout
            paymentRequiresAction
            payMonthly
            numberOfMonths
            paymentSchedule
            nextPaymentsSchedule
            nextPaymentDue
            monthlyPaymentAmount
            monthlyHostPayout
            monthlyHookhubPayout
            cancelledFinalHostPayout
            cancelledFinalRenterPayment
            cancelledBeforeCheckIn
            refundRenter
            full_refund_enabled
            no_refund_enabled
            half_refund_enabled
            full_refund_date
            half_refund_date
            stripePaymentIntentID
            _version
            __typename
          }
          hostID
          hostEmail
          renterEmail
          conversation {
            id
            hostee
            host
            convoCreatorsEmail
            otherMembersEmail
            spaceID
            spaceTitle
            propertyID
            propertyTitle
            propertyLocation
            bookingRequestID
            members
            membersNames
            unreadHost
            unreadHostee
            type
            createdAt
            updatedAt
            _version
            __typename
          }
          createdAt
          updatedAt
          renter_acknowledge_liability
          baseBookingCost
          renterFeeCost
          renterTotalBookingCost
          renterStripeFee
          hostFeeCost
          hostPayout
          hookhubPayout
          payMonthly
          numberOfMonths
          paymentSchedule
          nextPaymentDue
          nextPaymentsSchedule
          monthlyPaymentAmount
          monthlyHookhubPayout
          monthlyHostPayout
          full_refund_enabled
          no_refund_enabled
          half_refund_enabled
          full_refund_date
          half_refund_date
          errorCode
          _version
          bookingRequestConversationId
          __typename
        }
        messages {
          items {
            id
            authorID
            authorName
            authorAvatar
            otherMembersEmail
            content
            convoID
            type
            createdAt
            updatedAt
            _version
            owner
            __typename
          }
          nextToken
          __typename
        }
        members
        membersNames
        unreadHost
        unreadHostee
        type
        createdAt
        updatedAt
        _version
        __typename
      }
      type
      createdAt
      updatedAt
      _version
      owner
      __typename
    }
  }
`;
