import React, { useEffect, useState } from "react";
import { View, Text } from "react-native";
import { FontAwesome } from "@expo/vector-icons";
import { colors } from "../../styles/colors";
import TextBody from "../CustomElements/TextBody";
import { globalStyles } from "../../styles/styles";

export const ReviewViewMini = ({ reviews }) => {
    const [averageRating, setAverageRating] = useState(0);
    const [loadingReview, setLoadingReview] = useState(true);

    const getAverageRating = () => {
        let allReviewsAdded = 0;
        reviews.forEach(element => {
            allReviewsAdded = allReviewsAdded + element.rating;
        });
        setAverageRating(Number(allReviewsAdded / reviews.length).toFixed(1))
        setLoadingReview(false)
    };

    useEffect(() => {
        getAverageRating();
    }, []);

    return (
        <View style={globalStyles.flexStart}>
            {!loadingReview && (
                <>
                    <FontAwesome name="star" size={18} color={colors.amplifyNeutral80} />
                    <Text style={{ fontSize: 16 }}>{averageRating}</Text>
                    <TextBody style={{ marginLeft: 4 }}>({reviews.length})</TextBody>
                </>
            )}
        </View>
    );
};
