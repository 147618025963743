import React, { useState, useEffect } from "react";
import { StyleSheet, View, useWindowDimensions, Platform } from "react-native";
import AuthenticatedNavigator from "./AuthenticatedNavigator";
import UnauthenticatedNavigator from "./UnauthenticatedNavigator";
import { useDispatch, useSelector } from "react-redux";
import { setAuthed, setDeviceView } from "../redux/actions/CurrentUserActions";
import { Auth } from "aws-amplify";
import LoadingScreen from "../screens/LoadingScreen";
import { colors } from "../styles/colors";

function Router() {
  const dispatch = useDispatch();
  const { isAuthed } = useSelector(state => state.currentUser);
  const { width } = useWindowDimensions();
  const smallScreen = width < 900;
  let mobileView = Platform.OS !== 'web' || smallScreen;
  const [loading, setLoading] = useState(true);

  const authCheck = async () => {
    await Auth.currentUserInfo()
      .then((userInfo) => {
        if (userInfo && userInfo.attributes) {
          dispatch(setAuthed({ setAuthed: true }))
        } else {
          dispatch(setAuthed({ setAuthed: false }))
        }
      })
    setLoading(false)
  }

  useEffect(() => {
    authCheck();
  }, [])

  useEffect(() => {
    dispatch(setDeviceView({ mobileView: mobileView }))
  }, [isAuthed])

  return loading ? <LoadingScreen /> : (
    <View style={styles.content}>
      {isAuthed ? <AuthenticatedNavigator /> : <UnauthenticatedNavigator authenticate={() => dispatch(setAuthed({ setAuthed: true }))} />}
    </View>
  )
}

export default Router;

const styles = StyleSheet.create({
  footer: {
    position: "fixed",
    bottom: 0,
    width: "100%",
    backgroundColor: colors.orange,
    justifyContent: "center",
    padding: 3,
    flexDirection: "row",
    alignContent: "center",
    alignItems: "center",
    textAlign: "center"
  },
  content: {
    flex: 1,
  },
  pad: {
    padding: 3,
    color: colors.amplifyNeutral90
  },
  footerText: {
    padding: 3,
    color: colors.amplifyNeutral90
  },
});