import { Text } from 'react-native';

const TextTruncate = ({ children, style = null }) => {
    return (

        <Text
            numberOfLines={1}
            ellipsizeMode="tail"
            style={{
                fontSize: 16
            }}>{children}</Text>
    );
};

export default TextTruncate;