import React from "react";
import { Text } from "react-native";
import CustomButton from "./CustomButton";
import { colors } from "../../styles/colors";

export const CustomSimpleToggleButton = ({
    isPressed,
    onChange,
    value,
    width=40
}) => {
    return (
        <CustomButton
            width={width}
            height={45}
            marginBottom={0}
            marginRight={.8}
            marginLeft={.8}
            padding={1}
            backgroundColor={isPressed ? colors.vibrantBlue : colors.amplifyNeutral20}
            onPress={() => onChange()}
            textAlign="center"
        >
            <Text>{value}</Text>
        </CustomButton>
    )
}