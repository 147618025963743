import React, { useContext, useState } from "react";
import {
    StyleSheet,
    useWindowDimensions,
    Text,
    View
} from "react-native";
import { AccountSettings } from '@aws-amplify/ui-react';
import Flex from "../CustomElements/Flex"
import Header from "../CustomElements/Header"
import { Auth, API } from "aws-amplify";
import { useSelector } from "react-redux";
import { deleteUser } from "../../graphql/mutations";
import { UserDataContext } from "../../utils/UserContextProvider";
import { colors } from "../../styles/colors";
import CustomButton from "../CustomElements/CustomButton";
import CustomModal from "../CustomElements/CustomModal";
import { globalStyles } from "../../styles/styles";


const DeleteAccountModal = ({ modalVisible, setModalVisible, mobileView }) => {
    const { user } = useContext(UserDataContext);
    const { userData } = user;
    const { sub, name } = userData;
    const { width, height } = useWindowDimensions();
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(false);

    const handleDelete = async () => {
        await API.graphql({
            query: deleteUser,
            variables: { input: { id: sub } },
            authMode: "AMAZON_COGNITO_USER_POOLS",
        })
            .then(async (d) => {
                await Auth.deleteUser();
            })
            .catch((e) => {
                console.log("Error deleting account", e)
                setError(true);
                setLoading(false);
            })
    };

    const closeThis = () => {
        setLoading(false);
        setModalVisible(!modalVisible);
    };

    const components = {
        DeleteButton: ({ onPress }) => (
            <CustomButton
                color="#fff"
                width={mobileView ? width/1.2 : 300}
                margin={2}
                backgroundColor={colors.hookhubOrangeDark}
                onPress={handleDelete}
            >
                <Text>Yes, delete my account</Text>
            </CustomButton>
        ),
    }

    return (
        <CustomModal modalVisible={modalVisible} setModalVisible={() => setModalVisible()} modalWidth={width / 1.15}>
            <Header level={2} text="Are you sure?"></Header>
            <Text style={{textAlign: "center"}}>Deleting your account is permanent. Once you delete your account, it cannot be recovered.</Text>
            <View style={[globalStyles.centerColumn, { marginVertical: 50 }]}>
                <AccountSettings.DeleteUser components={components} />
                <CustomButton
                    backgroundColor={colors.amplifyNeutral20}
                    width={mobileView ? width/1.2 : 300}
                    margin={5}
                    border={colors.brandGrey}
                    onPress={() => closeThis()}
                >
                    <Text>cancel</Text>
                </CustomButton>
            </View>
        </CustomModal >
    );
};

export const DeleteAccount = () => {
    const { mobileView } = useSelector(state => state.currentUser);
    const [modalOpen, setModalOpen] = useState(false);
    const { width } = useWindowDimensions();

    return (
        <Flex
            direction="column"
            justify-content="flex-start"
            alignItems="flex-start"
            width={width}
            marginTop={6}
            marginBottom={100}
        >
            <Header level={1} text="Danger Zone" marginBottom={10}></Header>
            <CustomButton
                width={200}
                backgroundColor={colors.orange}
                onPress={() => setModalOpen(true)}
                title="DELETE MY ACCOUNT"
            ><Text style={{ fontWeight: "bold", color: "#fff" }}>DELETE MY ACCOUNT</Text>
            </CustomButton>
            <DeleteAccountModal
                modalVisible={modalOpen}
                mobileView={mobileView}
                setModalVisible={() => setModalOpen(!modalOpen)}
            />
        </Flex>
    );
};

const styles = StyleSheet.create({
    centeredView: {
        flex: 1,
        justifyContent: "center",
        alignItems: "center",
    }
});
