import React, { useState, useEffect, useContext } from "react";
import {
  StyleSheet,
  Text,
  ScrollView,
  useWindowDimensions,
  Pressable,
  View
} from "react-native";
import moment from "moment";
import { API } from "aws-amplify";
import { useNavigation } from "@react-navigation/native";
import { listBookings as listBookingsQuery, listBookingRequests as listBookingRequestsQuery } from "../../graphql/queries";
import { Entypo, Ionicons, FontAwesome5, MaterialCommunityIcons } from '@expo/vector-icons';
import { MyTrips } from "../../components/booking/MyTrips"
import Flex from "../../components/CustomElements/Flex"
import Card from "../../components/CustomElements/Card"
import Header from "../../components/CustomElements/Header"
import { LoadingComponent } from "../../components/LoadingComponent";
import { getSpaceAndPropertyDetails } from "../../api/space";
import { UserDataContext } from "../../utils/UserContextProvider";
import RequestModal from "../../components/booking/RequestModal";
import { useSelector } from "react-redux";
import { formatMonthDay } from "../../utils/formatDate";
import { colors } from "../../styles/colors"
import CustomButton from "../../components/CustomElements/CustomButton";
import BackButton from "../../components/other/BackButton";
import Badge from "../../components/CustomElements/Badge";
import { globalStyles } from "../../styles/styles";


const PendingReservation = ({ mobileView, reservationDetail }) => {
  const { check_in, check_out, spaceCity, spaceState, spaceTitle, spaceID, id } = reservationDetail;
  const [checkIn, setCheckIn] = useState('');
  const [checkOut, setCheckOut] = useState('');
  const location = `${spaceCity}, ${spaceState}`;

  useEffect(() => {
    setCheckIn(formatMonthDay(check_in));
    setCheckOut(formatMonthDay(check_out));
  }, []);

  return (
    <Card
      backgroundColor={colors.brandGrey}
      border="grey"
      alignItems="center"
      textAlign="center"
      width={170}
      justifyContent="center"
      alignContent="center"
    >
      <View style={{ alignSelf: "flex-end" }}>
        <Ionicons name="open-outline" size={24} color="#676767" />
      </View>
      <Entypo name="time-slot" size={24} color="grey" />
      <Header textAlign="center" color={colors.amplifyNeutral90} marginBottom={10} level={1} text="Pending Request..."></Header>
      <Text>{spaceTitle}</Text>
      <Text>Dates: {checkIn}-{checkOut}</Text>
    </Card>
  )
};

const PendingReservations = ({ pendingBookingRequests, cancelledRequest }) => {
  const { width } = useWindowDimensions();
  const navigation = useNavigation();
  const { mobileView } = useSelector(state => state.currentUser);
  const boxWidth = width > 580 ? width / 1.3 : width / 1.4;
  const [requestToDisplay, setRequestToDisplay] = useState(null);
  const [rentalModalOpen, setRentalModalOpen] = useState(null);

  const viewThisRequest = (v) => {
    setRequestToDisplay(v);
    setRentalModalOpen(true)
  };

  const navigate = async () => {
    setRentalModalOpen(false);
    navigation.navigate('home', {
      screen: 'explore',
      params: {
        screen: 'space',
        params: {
          propertyID: requestToDisplay.propertyID,
        },
      },
    });
  }


  return (
    <View style={styles.wrapperView}>
      <Flex
        direction="column"
        marginBottom={25}
      >
        <Card
          backgroundColor={colors.amplifyNeutral60}
          width={width / 1.1}
          padding={20}
          alignItems="flex-start"
        >
          <Header
            textAlign="left"
            color={colors.amplifyNeutral90}
            text="PENDING RESERVATIONS"
          />
          <Flex
            direction="row"
            marginBottom={3}
            width={width / 1.2}
            marginTop={10}
            textAlign="center"
            justifyContent="center"
            alignContent="center"
            wrap="wrap"
          >
            {pendingBookingRequests.map((v, i) => (
              <View key={i}>
                <Pressable
                  key={i}
                  onPress={() => viewThisRequest(v)}
                >
                  <PendingReservation
                    boxWidth={boxWidth}
                    width={width}
                    mobileView={mobileView}
                    reservationDetail={v}
                    cancelledRequest={(v) => cancelledRequest(v)}
                  />
                </Pressable>
              </View>
            ))}
          </Flex>
          {!!requestToDisplay && !!Object.keys(requestToDisplay).length &&
            <RequestModal
              cancelledRequest={cancelledRequest}
              hostView={false}
              modalVisible={rentalModalOpen}
              setModalVisible={() => setRentalModalOpen(!rentalModalOpen)}
              bookingToDisplay={requestToDisplay}
              width={width}
              navigate={() => navigate()}
            />
          }
        </Card>
      </Flex>
    </View>
  );
};

function TripsScreen() {
  const navigation = useNavigation();
  const [pastReservations, setPastReservations] = useState([]);
  const [upcomingReservations, setUpcomingReservations] = useState([]);
  const [currentReservations, setCurrentReservations] = useState([]);
  const [pendingReservations, setPendingReservations] = useState([]);
  const [activeTabIndex, setActiveTabIndex] = useState('current');
  const [loaded, setLoaded] = useState(false);
  const [error, setError] = useState(false);
  const { user } = useContext(UserDataContext);
  const { userData } = user;
  const { sub } = userData;
  const { width } = useWindowDimensions();
  const { mobileView } = useSelector(state => state.currentUser);
  const [reservationsPresent, setReservationsPresent] = useState(false);

  const listReservations = async () => {
    setLoaded(false)
    await API.graphql({
      query: listBookingsQuery,
      variables: {
        filter: {
          renterID: { eq: sub },
          cancelledBeforeCheckIn: { eq: false }
        }
      },
      authMode: "AMAZON_COGNITO_USER_POOLS",
    }).then((d) => {
      const allReservations = d.data.listBookings?.items;
      const today = moment(new Date()).format("YYYY-MM-DD");
      const upcoming = allReservations.filter(function (el) {
        return moment(moment().format("YYYY-MM-DD")).isBefore(moment(el.check_in))
      });
      const current = allReservations.filter(function (el) {
        return moment(moment().format("YYYY-MM-DD")).isBetween(el.check_in, el.check_out, 'days', '[]');
      });
      const past = allReservations.filter(function (el) {
        return moment(el.check_out, "YYYY-MM-DD").isBefore(today)
      });
      const toShow = !!current.length ? 'current' : !!upcoming.length ? 'upcoming' : 'past';
      setActiveTabIndex(toShow);
      setUpcomingReservations(upcoming);
      setCurrentReservations(current);
      setPastReservations(past);
      setReservationsPresent(!!allReservations.length);
    }).catch((e) => {
      console.log("error getting your reservations:   ", e)
      setError(e)
    })
    setLoaded(true)
  };

  const listBookingRequests = async () => {
    await API.graphql({
      query: listBookingRequestsQuery,
      variables: {
        filter: {
          renterID: { eq: sub },
          reviewed: { eq: false },
          cancelled: { ne: true }
        }
      },
      authMode: "AMAZON_COGNITO_USER_POOLS",
    }).then(async (request) => {
      const bookingRequests = request.data.listBookingRequests.items;
      setPendingReservations(bookingRequests);
    })
  };

  const cancelledRequest = async (v) => {
    const requestArray = pendingReservations.filter(function (el) { return el.id !== v });
    setPendingReservations(requestArray)
  };

  const goToHome = () => {
    navigation.reset({ routes: [{ name: 'home' }] })
  }

  useEffect(() => {
    listReservations();
  }, []);

  useEffect(() => {
    listBookingRequests();
  }, []);

  return (
    <ScrollView>
      <BackButton goBack={false} />
      {loaded ? (
        <View style={{ marginTop: mobileView ? 5 : 15 }}>
          {!!upcomingReservations.length &&
            <View style={{ width: mobileView ? width : 400, marginBottom: mobileView ? 0 : 25 }}>
              <Badge variation="lightBlue" height={30}>
                <MaterialCommunityIcons
                  name="rv-truck"
                  size={25}
                  color={colors.amplifyNeutral90}
                />
                <Text style={{ fontSize: 14, marginLeft: 10 }}>you have an upcoming trip</Text>
              </Badge>
            </View>
          }
          {!pendingReservations.length && !reservationsPresent ? (
            <Flex
              marginTop={40}
              alignContent="center"
              justifyContent="center"
              alignItems="center"
              direction="column"
            >
              <Text style={{ marginBottom: 40, fontSize: 16 }}>No trips planned yet!</Text>
              <CustomButton
                onPress={goToHome}
                width={mobileView ? width / 1.25 : 300}
                style={[styles.goToHomeButton]}
              >
                <View style={{ flexDirection: "row" }}>
                  <Text style={styles.goToHomeButtonText}>check out spaces</Text>
                  <FontAwesome5 name="arrow-right" size={mobileView ? 20 : 24} color="#403f3f" />
                </View>
              </CustomButton>
            </Flex>
          ) : (
            <>
              {!!pendingReservations.length && <PendingReservations pendingBookingRequests={pendingReservations} cancelledRequest={(v) => cancelledRequest(v)} />}
              {!!reservationsPresent &&
                <MyTrips
                  listReservations={() => listReservations()}
                  mobileView={mobileView}
                  activeTabIndex={activeTabIndex}
                  setActiveTabIndex={(v) => setActiveTabIndex(v)}
                  currentReservations={currentReservations}
                  upcomingReservations={upcomingReservations}
                  pastReservations={pastReservations}
                />}
            </>
          )}
        </View>
      ) : (
        <Flex
          marginTop={100}
          alignContent="center"
          justifyContent="center"
          alignItems="center"
        >
          <LoadingComponent />
        </Flex>
      )}
    </ScrollView>
  );
}

const styles = StyleSheet.create({
  goToHomeButtonText: {
    color: "#303030",
    fontSize: 17,
    marginRight: 20
  },
  goToHomeButton: {
    borderColor: "grey",
    borderRadius: 10,
    marginTop: 40,
    borderWidth: 1,
    backgroundColor: "#c4833d",
    alignItems: "center",
    borderBottomWidth: 2,
    justifyContent: "center",
    alignSelf: "center",
    height: 40,
    padding: 20,
    shadowColor: "#252625",
    shadowOffset: { width: -2, height: 3 },
    shadowOpacity: 0.5,
  },
  wrapperView: {
    alignItems: "center",
    justifyContent: "center",
    alignSelf: "center",
  },
});

export default TripsScreen;
