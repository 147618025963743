import React, { useEffect, useState, useContext } from "react";
import { NavigationContainer, useNavigation } from "@react-navigation/native";
import { useDispatch, useSelector } from "react-redux";
import {
  View,
  TouchableOpacity,
  StyleSheet,
  Image,
  Pressable,
  useWindowDimensions,
  Text,
  Platform
} from "react-native";
import {
  Ionicons,
  MaterialCommunityIcons,
  MaterialIcons,
  Entypo,
  FontAwesome
} from "@expo/vector-icons";
import moment from "moment";
import * as Linking from "expo-linking";
import TripsScreen from "../screens/account/TripsScreen";
import LoadingScreen from "../screens/LoadingScreen";
import HelpScreen from "../screens/info/HelpScreen";
import AboutScreen from "../screens/info/AboutScreen";
import FavoritesSavedScreen from "../screens/account/FavoritesSavedScreen";
import HostCreateSpaceNavigator from "./HostCreateSpaceNavigator";
import AccountNavigator from "./AccountNavigator";
import HostAccountNavigator from "./HostAccountNavigator";
import { createDrawerNavigator } from "@react-navigation/drawer";
import { loadStripe } from '@stripe/stripe-js';
import { API } from "aws-amplify";
import { setStripePromise } from "../redux/actions/CurrentUserActions";
import { AppContextProvider } from '../utils/AppContextProvider';
import { UserDataContext } from "../utils/UserContextProvider";
import getEnvVars from '../../environment';
import UserContextProvider from '../utils/UserContextProvider';
import HomeTabNavigator from "./HomeTabNavigator";
import { BookingsContext } from "../utils/BookingsContextProvider";
import Authenticator from "../utils/Authenticator";
import CustomButton from "../components/CustomElements/CustomButton";
import { colors } from "../styles/colors";
import { CustomDrawerContent } from "../components/CustomElements/CustomDrawerContent";
import AdminScreen from "../screens/admin/AdminScreen";
import { listBookings } from "../graphql/queries";
import HelpChat from "../components/other/HelpChatMessageCard";
import HostSplashScreen from "../screens/info/HostSplashScreen";
import SecurityScreen from "../screens/info/SecurityScreen";
import CustomButtonUnderlined from "../components/CustomElements/CustomButtonUnderlined";
import MessagesScreen from "../screens/account/MessagesScreen";
const { stripePublishableKey } = getEnvVars();
const stripePromise = loadStripe(stripePublishableKey);


const HostButton = ({ imAHost }) => {
  const navigation = useNavigation();
  if (!imAHost) {
    return (
      <CustomButtonUnderlined
        width={120}
        backgroundColor={colors.amplifyNeutral20}
        onPress={(e) => navigation.reset({ routes: [{ name: 'host' }] })}
      >
        <Text>Host my space</Text>
      </CustomButtonUnderlined>
    )
  }
};

const headerRight = ({ mobileView, imAHost, unreadBoolean }) => {
  const width = useWindowDimensions().width;
  const navigation = useNavigation();
  const { user } = useContext(UserDataContext);
  const { userData } = user;
  const { payoutNeeded } = useSelector(state => state.currentUser);
  const { availabilityMissing, bookingRequests } = useContext(BookingsContext);
  const { availabilityNeeded } = availabilityMissing;
  const { bookingRequestsArray } = bookingRequests;
  const nextStepsRequired = !!availabilityNeeded.length || !!bookingRequestsArray.length || payoutNeeded;
  const [upcomingTrip, setUpcomingTrip] = useState(false);

  const checkForUpcomingTrip = async () => {
    const today = moment(new Date()).format("YYYY-MM-DD");
    await API.graphql({
      query: listBookings,
      variables: {
        filter: {
          renterID: { eq: userData.sub },
          cancelledBeforeCheckIn: { eq: false },
          check_in: { gt: today }
        }
      },
      authMode: "AMAZON_COGNITO_USER_POOLS",
    }).then((d) => {
      const allReservations = d.data.listBookings?.items;
      !!allReservations.length && setUpcomingTrip(true)
    }).catch((e) => {
      console.log("error getting your reservations:   ", e)
    })
  };

  useEffect(() => {
    checkForUpcomingTrip()
  }, []);

  return (
    <View style={styles.headerRight}>
      {width > 600 && <HostButton imAHost={imAHost} mobileView={mobileView} />}
      {upcomingTrip && <UpcomingTripIcon mobileView={mobileView} />}
      {unreadBoolean && <UnreadMessagesIcon mobileView={mobileView} />}
      {nextStepsRequired && <NextStepsRequired mobileView={mobileView} />}
      <TouchableOpacity
        style={[styles.drawerButton, { marginLeft: mobileView ? 2 : 10 }]}
        onPress={() => navigation.navigate("home", {
          screen: "account"
        })}
      >
        {!!userData && !!userData.avatarImage ? (
          <Image
            style={styles.avatarImage}
            source={{ uri: userData.avatarImage }}
          />
        ) : (
          <Ionicons name="person-circle" size={50} color="grey" />
        )}
      </TouchableOpacity>
    </View>
  )
};

const UnreadMessagesIcon = ({ mobileView }) => {
  const navigation = useNavigation();
  return (
    <Pressable
      onPress={() => navigation.navigate("home", { screen: "messages" })}
      style={{ marginHorizontal: mobileView ? 5 : 8 }}
    >
      <View>
        <Entypo name="mail" size={mobileView ? 25 : 28} color={colors.amplifyNeutral80} />
        <View style={{ position: "absolute", bottom: mobileView ? 13 : 16, right: mobileView ? 17 : 20 }}>
          <FontAwesome name="exclamation-circle" size={mobileView ? 18 : 20} color="#48a8db" />
        </View>
      </View>
    </Pressable>
  );
};

const UpcomingTripIcon = ({ mobileView }) => {
  const navigation = useNavigation();
  return (
    <Pressable
      onPress={() => navigation.navigate("trips")}
      style={{ marginHorizontal: mobileView ? 5 : 8 }}
    >
      <MaterialCommunityIcons
        name="rv-truck"
        size={mobileView ? 27 : 30}
        color={colors.amplifyNeutral80}
      />
    </Pressable>
  );
};

const NextStepsRequired = ({ mobileView }) => {
  const navigation = useNavigation();
  return (
    <Pressable
      onPress={() => navigation.navigate("hostaccount", { screen: "My Hosting" })}
      style={{ marginHorizontal: mobileView ? 5 : 8 }}
    >
      <View>
        <MaterialCommunityIcons name="folder-multiple-plus" size={mobileView ? 24 : 27} color={colors.amplifyNeutral80} />
        <View style={{ position: "absolute", bottom: mobileView ? 13 : 16, right: mobileView ? 17 : 20 }}>
          <FontAwesome name="exclamation-circle" size={mobileView ? 18 : 20} color="#48a8db" />
        </View>
      </View>
    </Pressable>
  );
}

const AuthNavigatorBody = ({ mobileView, width }) => {
  const navigation = useNavigation();
  const Drawer = createDrawerNavigator();
  const dispatch = useDispatch();
  const { user, loading } = useContext(UserDataContext);
  const { unreadBoolean, conversations } = useSelector((state) => state.conversations);
  const { userData } = user;
  const [imAHost, setImAHost] = useState(false)
  const hostAccountLabel = !!mobileView ? "My Hosting" : "Manage My Hosting";

  const userDataCheck = async () => {
    const myHostedProperties = userData.userData.myHostedProperties
    const host = !!myHostedProperties.items && !!myHostedProperties.items.length;
    setImAHost(host)
    dispatch(setStripePromise({ stripePromise: stripePromise }))
  };

  const tripsIcon = ({ }) => {
    return (
      <TouchableOpacity
        justifyContent="flex-start"
        onPress={() => {
          navigation.navigate("home", {
            screen: "explore",
          });
        }}
      >
        <MaterialCommunityIcons
          name="rv-truck"
          size={19}
        />
      </TouchableOpacity>

    )
  }

  const hostAccountIcon = ({ }) => {
    return (
      <TouchableOpacity
        justifyContent="flex-start"
        onPress={() => {
          navigation.navigate("hostaccount");
        }}
      >
        <MaterialCommunityIcons
          name="view-agenda"
          size={19}
        />
      </TouchableOpacity>
    )

  }

  const favoritesIcon = ({ }) => {
    return (
      <TouchableOpacity
        justifyContent="flex-start"
        onPress={() => {
          navigation.navigate("home", {
            screen: "explore",
          });
        }}
      >
        <MaterialIcons name="favorite" size={19} />
      </TouchableOpacity>
    )
  }

  const hostSpaceIcon = ({ }) => {
    return (
      <TouchableOpacity
        justifyContent="flex-start"
        onPress={() => {
          navigation.navigate("HostCreateSpaceNavigator");
        }}
      >
        <MaterialIcons name="attach-money" size={19} color="black" />
      </TouchableOpacity>
    )
  }

  const myAccountIcon = ({ }) => {
    return (
      <TouchableOpacity
        justifyContent="flex-start"
        onPress={() => {
          navigation.navigate("home", {
            screen: "explore",
          });
        }}
      >
        <Ionicons name="person-circle" size={19} />
      </TouchableOpacity>
    )
  };

  const myMessagesIcon = ({ }) => {
    return (
      <TouchableOpacity
        justifyContent="flex-start"
        onPress={() => {
          navigation.navigate("messages");
        }}
      >
        <MaterialIcons name="message" size={19} />
      </TouchableOpacity>
    )
  };

  useEffect(() => {
    !loading && userDataCheck();
  }, [loading]);

  if (loading) {
    return (<LoadingScreen />)
  } else {
    return (
      <Drawer.Navigator
        useLegacyImplementation={false}
        backBehavior="history"
        defaultStatus="closed"
        drawerContent={(props) => <CustomDrawerContent {...props} />}
        screenOptions={({ navigation }) => ({
          swipeEdgeWidth: 0,
          swipeEnabled: false,
          title: "",
          headerLeft: ({ focused, size }) => (
            <View style={styles.headerRight}>
              <TouchableOpacity
                style={styles.drawerButton}
                onPress={() => navigation.openDrawer()}
              >
                <Ionicons
                  name="menu"
                  size={mobileView ? 29 : 35}
                  color={focused ? "#7cc" : "#ccc"}
                />
              </TouchableOpacity>
              <TouchableOpacity
                onPress={() => navigation.reset({ routes: [{ name: 'home' }] })}
              >
                {width < 530 ? (
                  <Image
                    style={{ width: 45, height: 45, marginLeft: 5 }}
                    source={require("../../assets/images/favicon.png")}
                  />
                ) : (
                  <Image
                    style={{ width: 163.35, height: 42.35, marginLeft: 10 }}
                    source={require("../../assets/images/hookhub-logo-orange-mini.png")}
                  />
                )}
              </TouchableOpacity>
            </View>
          ),
          headerRight: ({ focused }) => headerRight({ mobileView, imAHost, unreadBoolean, focused })
        })}
      >
        <Drawer.Screen
          name="home"
          headerShown={false}
          component={HomeTabNavigator}
          options={{
            headerTitleStyle: {
              fontWeight: "bold",
            },
            drawerIcon: ({ focused, size }) => (
              <Image
                style={styles.miniLogoImage}
                source={require("../../assets/images/favicon.png")}
              />
            ),
          }}
        />
        <Drawer.Screen
          name="trips"
          component={TripsScreen}
          options={{
            drawerLabel: "My Trips",
            drawerIcon: tripsIcon
          }}
        />
        <Drawer.Screen
          name="hostaccount"
          component={HostAccountNavigator}
          options={{
            drawerLabel: hostAccountLabel,
            drawerIcon: hostAccountIcon
          }}
        />
        <Drawer.Screen
          name="HostCreateSpaceNavigator"
          headerShown={false}
          component={HostCreateSpaceNavigator}
          options={{
            drawerLabel: "Add new space",
            drawerIcon: hostSpaceIcon
          }}
        />
        <Drawer.Screen
          name="FavoritesSaved"
          component={FavoritesSavedScreen}
          options={{
            drawerLabel: "Favorites Saved",
            drawerIcon: favoritesIcon
          }}
        />
        <Drawer.Screen
          name="account"
          component={AccountNavigator}
          options={{
            drawerLabel: "My Account",
            drawerIcon: myAccountIcon
          }}
        />
        {conversations.length ? (
          <Drawer.Screen
            name="messages"
            component={MessagesScreen}
            options={{
              drawerLabel: "My Messages",
              drawerIcon: myMessagesIcon
            }}
          />
        ) : (<></>)}
        <Drawer.Screen
          name="about"
          component={AboutScreen}
          options={{ drawerItemStyle: { height: 0 } }}
        />
        <Drawer.Screen
          name="help"
          component={HelpScreen}
          options={{ drawerItemStyle: { height: 0 } }}
        />
        <Drawer.Screen
          name="host"
          component={HostSplashScreen}
          options={{ drawerItemStyle: { height: 0 } }}
        />
        <Drawer.Screen
          name="security"
          component={SecurityScreen}
          options={{ drawerItemStyle: { height: 0 } }}
        />
        {/* <Drawer.Screen
          name="admin"
          component={AdminScreen}
        /> */}
      </Drawer.Navigator>
    )
  }
}

const AuthenticatedNavigator = () => {
  const { width } = useWindowDimensions();
  const prefix = [Linking.createURL('/'), 'https://hookhub.co', 'https://www.hookhub.co']
  const mobileWebView = width < 450 && Platform.OS === 'web';
  const config = {
    screens: {
      home: {
        path: '',
        screens: {
          messages: "/messages",
          explore: {
            screens: {
              space: 'space/:propertyID'
            }
          },
        }
      },
      account: {
        screens: {
          profile: 'profile'
        },
      },
      about: {
        path: "/about"
      },
      host: {
        path: "/host"
      },
      security: {
        path: "/security"
      },
      help: {
        path: "/help"
      }
    }
  };
  const linking = {
    enabled: true,
    prefixes: [prefix],
    config
  };

  return (
    <Authenticator>
      <UserContextProvider>
        <AppContextProvider>
          <NavigationContainer
            linking={linking}
            documentTitle={{
              formatter: () => `hookhub`,
            }}
          >
            <AuthNavigatorBody mobileView={mobileWebView} width={width} />
          </NavigationContainer>
          {width < 900 ? <></> : <HelpChat />}
        </AppContextProvider>
      </UserContextProvider>
    </Authenticator>
  )
};

const styles = StyleSheet.create({
  logoButton: {
    width: 50,
    borderRadius: 20,
    padding: 10,
    marginBottom: 20,
    shadowColor: "#303838",
    shadowOffset: { width: 0, height: 5 },
    shadowRadius: 10,
    shadowOpacity: 0.35,
  },
  avatarImage: {
    borderRadius: 100,
    marginLeft: 5,
    width: 45,
    height: 45,
    borderWidth: 1,
  },
  headerRight: {
    flex: 1,
    flexDirection: "row",
    alignItems: "center",
    marginRight: 10,
    justifyContent: "center",
    alignContent: "center",
  },
  drawerButton: {
    flexDirection: "row",
    textAlign: "center",
    justifyContent: "center",
    alignContent: "center",
    alignItems: "center",
    marginBottom: 5,
    marginLeft: 5
  },
  logoImage: {
    height: 20,
    width: 80
  },
  miniLogoImage: {
    width: 40,
    height: 40,
  },
  forwardButtonText: {
    color: "#fff",
    fontSize: 20,
  },
  forwardButton: {
    borderColor: "#616A6B",
    borderRadius: 10,
    width: 100,
    borderWidth: 1,
    backgroundColor: "#065d8c",
    alignItems: "center",
    borderBottomWidth: 2,
    justifyContent: "center",
    alignSelf: "center",
    height: 40,
    marginLeft: 40,
  },
  logoButton: {
    width: 50,
    borderRadius: 20,
    padding: 10,
    marginBottom: 20,
    shadowColor: "#303838",
    shadowOffset: { width: 0, height: 5 },
    shadowRadius: 10,
    shadowOpacity: 0.35,
  },
  hamburgerButton: {
    alignItems: "center",
    justifyContent: "center",
    alignSelf: "center",
    marginRight: 10,
    marginTop: 4,
    height: 38
  },
  loginButtonText: {
    fontSize: 17,
  },
});

export default AuthenticatedNavigator;
