import React from "react";
import { createStackNavigator } from "@react-navigation/stack";
import HostStartScreen from "../screens/host/HostStartScreen";
import HostSpaceScreen from "../screens/host/HostSpaceScreen.js";
import SpaceDetailsScreen from "../screens/host/SpaceDetailsScreen";
import SpaceLocationScreen from "../screens/host/SpaceLocationScreen";
import LocationConfirmationScreen from "../screens/host/LocationConfirmationScreen";
import AddTitleScreen from "../screens/host/AddTitleScreen";
import PhotoUploadScreen from "../screens/host/PhotoUploadScreen";
import AddDescriptionScreen from "../screens/host/AddDescriptionScreen";
import SetDimensionsScreen from "../screens/host/SetDimensionsScreen";
import SetDistancesScreen from "../screens/host/SetDistancesScreen";
import SetPriceScreen from "../screens/host/SetPriceScreen";
import ConfirmationScreen from "../screens/host/ConfirmationScreen";
import ThingsToKnowScreen from "../screens/host/ThingsToKnowScreen";
import AdditionalSpacesScreen from "../screens/host/AdditionalSpacesScreen";
import HelpScreen from "../screens/info/HelpScreen";

const Stack = createStackNavigator();

function HostCreateSpaceNavigator() {
  return (
    <Stack.Navigator screenOptions={{ headerTitleAlign: "center", contentStyle: { backgroundColor: "#fff" } }}>
      <Stack.Screen
        component={HostStartScreen}
        name={"HostStart"}
        options={{ headerShown: false }}
      />
      <Stack.Screen
        component={AdditionalSpacesScreen}
        name={"SingleOrMultiple"}
        options={{ headerShown: false }}
      />
      <Stack.Screen
        component={AddTitleScreen}
        name={"AddTitle"}
        options={{ headerShown: false }}
      />
      <Stack.Screen
        component={HostSpaceScreen}
        name={"HostSpace"}
        options={{ headerShown: false }}
      />
      <Stack.Screen
        component={SpaceDetailsScreen}
        name={"SpaceDetails"}
        options={{ headerShown: false }}
      />
      <Stack.Screen
        component={SetDistancesScreen}
        name={"SetDistances"}
        options={{ headerShown: false }}
      />
      <Stack.Screen
        component={SpaceLocationScreen}
        name={"SpaceLocation"}
        options={{ headerShown: false }}
      />
      <Stack.Screen
        component={LocationConfirmationScreen}
        name={"LocationConfirmation"}
        options={{ headerShown: false }}
      />
      <Stack.Screen
        component={PhotoUploadScreen}
        name={"PhotoUpload"}
        options={{ headerShown: false }}
      />
      <Stack.Screen
        component={AddDescriptionScreen}
        name={"AddDescription"}
        options={{ headerShown: false }}
      />
      <Stack.Screen
        component={SetDimensionsScreen}
        name={"SetDimensions"}
        options={{ headerShown: false }}
      />
      <Stack.Screen
        component={SetPriceScreen}
        name={"SetPrice"}
        options={{ headerShown: false }}
      />
      <Stack.Screen
        name="help"
        component={HelpScreen}
        options={{ headerShown: false }}
      />
      <Stack.Screen
        component={ThingsToKnowScreen}
        name={"SetThingsKnow"}
        options={{ headerShown: false }}
      />
      <Stack.Screen
        component={ConfirmationScreen}
        name={"Confirmation"}
        options={{ headerShown: false }}
      />
    </Stack.Navigator>
  );
}

export default HostCreateSpaceNavigator;
