import React, { useState } from "react";
import { Pressable, useWindowDimensions, Image, Text, View } from "react-native";
import {
    AntDesign,
    FontAwesome5,
} from "@expo/vector-icons";
import { useSelector } from "react-redux";
import Flex from "./CustomElements/Flex"
import Card from "./CustomElements/Card"
import Header from "./CustomElements/Header"
import { colors } from "../styles/colors";
import { globalStyles } from "../styles/styles";
import CustomDivider from "./CustomDivider";


const QuestionAccordian = ({ question, mobileView }) => {
    const [openAccordian, setOpenAccordian] = useState(false);
    const { width } = useWindowDimensions();

    return (
        <Card
            backgroundColor={colors.amplifyNeutral20}
            border="#4c4e52"
            padding={!!mobileView ? 5 : 8}
            alignItems="center"
            marginTop={6}
            textAlign="center"
            width={!!mobileView ? width / 1.15 : width / 1.3}
        >
            {openAccordian ? (
                <Flex direction='column' width={!!mobileView ? width / 1.2 : width / 1.4}>
                    <Pressable onPress={() => setOpenAccordian(!openAccordian)} margin={!!mobileView ? 0 : 12}>
                        <Flex justifyContent="space-between" width={!!mobileView ? width / 1.2 : width / 1.5}>
                            <AntDesign marginRight={15} name="caretdown" size={20} color="grey" />
                            <Header level={1} text={question.q} />
                        </Flex>
                    </Pressable>
                    <Text style={{ marginTop: mobileView ? 10 : 25, padding: 10 }}>{question.a}</Text>
                </Flex>
            ) : (
                <Flex direction='column' width={!!mobileView ? width / 1.2 : width / 1.4}>
                    <Pressable onPress={() => setOpenAccordian(!openAccordian)} margin={!!mobileView ? 0 : 12}>
                        <Flex justifyContent="space-between" width={!!mobileView ? width / 1.25 : width / 1.5}>
                            <AntDesign marginRight={15} name="caretright" size={20} color="grey" />
                            <Header level={1} text={question.q} />
                        </Flex>
                    </Pressable>
                </Flex>
            )}
        </Card >
    );
};

const InfoCard = ({ width, mobileView, questions }) => {

    return (
        <View>
            {questions.map((v, i) => (
                <QuestionAccordian mobileView={mobileView} question={v} key={i} />
            ))}
        </View>
    );
};

function LearnMoreHostSplash() {
    const { width } = useWindowDimensions();
    const { mobileView } = useSelector(state => state.currentUser);
    const hostQs = [
        {
            q: "Is my space right for Hosting?",
            a: "If you have space on your property of at least 10 foot by 20 foot- you can host on hookhub! Only space is needed, but you can also offer extra amenities if you'd like to charge higher prices."
        },
        {
            q: "Is there a minimum or maximum amount of time I have to let people rent my space?",
            a: "Absolutely not, it is entirely up to you on when and how long people can rent your space. You can offer your space for short-term rentals, long-term monthly stays or even just for storage space."
        },
        {
            q: "What do I have to do as a Host?",
            a: "Its simple- just create your space in a few simple steps and set your space availability. Thats it! You will get booking requests and then be able to decide at that time if you like the booking or renter that is requesting."
        },
        {
            q: "How do I know I can trust renters?",
            a: "We review the renters and will even verify their identity if fraudulent activity is suspected. You also will have the chance to review your renters details and speak to them prior to agreeing to their reservation request."
        },
        {
            q: "Can I host my space if it is just empty land?",
            a: "Yes! Amenities (electric, water, sewage, etc) are an extra feature if you choose to list it. Otherwise, only space is needed."
        },
        {
            q: "What is the host cancellation policy?",
            a: "As a host, you can cancel a booking up to 1 week before the check-in date. If the booking is more than a month long, you can cancel the booking after check-in, as long as it is still 3280 days before the check-out date. A 28 days notice will be required."
        },
        {
            q: "What are Hookhub's fees?",
            a: "To keep our platform up and provide support- Hookhub collects 10% of the total being paid by your renter as well as a Stripe transaction fee (per Stripe) of 2.9% plus $.30."
        },
        {
            q: "Tips to succeed as a Host?",
            a: "Keep your place clean, respond to guests promptly, and some Hosts like adding a personal touch like sharing a list of local places to explore—but it’s not required."
        },
    ]

    return (
        <View style={[globalStyles.flexStartColumn, { marginTop: 20 }]}>
            <Header textAlign="left" color={colors.amplifyNeutral90} level={1.3} text={`Your questions, answered`} padding={0} marginBottom={10} />
            <InfoCard
                mobileView={mobileView}
                icon={<AntDesign name="profile" size={24} color="#616A6B" style={{ marginRight: 10 }} />}
                questions={hostQs}
                width={width}
            />
        </View>
    );
}

export default LearnMoreHostSplash;
