const INITIAL_STATE = {
    stripe: null,
    spaceTypeFilter: null,
    mobileView: false,
    rigNeedsUploading: null,
    payoutNeeded: false,
    stayType: undefined,
    imAHost: false,
    notifyOfNationwide: true,
    isAuthed: false
};

const currentUserReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case 'SET_STRIPE_PROMISE':
            return {
                ...state,
                stripe: action.stripePromise
            }
        case 'SET_SPACE_TYPE_FILTER':
            return {
                ...state,
                spaceTypeFilter: action.spaceTypeFilter
            }
        case 'SET_DEVICE_VIEW':
            return {
                ...state,
                mobileView: action.mobileView
            }
        case 'SET_RIG_UPLPOAD_STATUS':
            return {
                ...state,
                rigNeedsUploading: action.rigNeedsUploading
            }
        case 'SET_PAYOUT_STATUS':
            return {
                ...state,
                payoutNeeded: action.payoutNeeded
            }
        case 'SET_SPACE_STAY_TYPE':
            return {
                ...state,
                stayType: action.stayType
            }
        case 'SET_HOST_STATUS':
            return {
                ...state,
                imAHost: action.imAHost
            }
        case 'SET_AUTHED':
            return {
                ...state,
                isAuthed: action.setAuthed
            }
        case 'SET_NOTIFICATION_VIEWED':
            return {
                ...state,
                notifyOfNationwide: action.notifyOfNationwide
            }
        default:
            return state
    }
};

export const authCheck = (state) => state.currentUser.authed;
export default currentUserReducer;