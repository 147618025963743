import React, { useCallback, useState } from "react";
import {
  StyleSheet,
  Text,
  useWindowDimensions,
  View
} from "react-native";
import { GoogleMap, Marker } from '@react-google-maps/api';
import Geocode from "react-geocode";
import getEnvVars from '../../../environment';
import { useSelector } from "react-redux";
import { colors } from "../../styles/colors";
import Header from "../CustomElements/Header";
const { geocodeAPIkey, googleMapURL } = getEnvVars();
Geocode.setApiKey(geocodeAPIkey);

const MapCustomView = ({ space, markerMovable = null }) => {
  const { coordinates_lat, coordinates_lng } = space;
  const { mobileView } = useSelector(state => state.currentUser);
  const [map, setMap] = useState(null);
  const { width, height } = useWindowDimensions();
  const lat = Number(coordinates_lat);
  const lng = Number(coordinates_lng);
  const coordinates = { lat, lng };
  Geocode.setLocationType("ROOFTOP");

  const containerStyle = {
    width: mobileView ? width / 1.15 : width / 1.35,
    height: height / 2,
    borderRadius: 5
  };

  const onLoad = useCallback(function callback(map) {
    setMap(map)
  }, []);

  const onUnmount = useCallback(function callback(map) {
    setMap(null)
  }, []);

  return (
    <View>
      <GoogleMap
        mapContainerStyle={containerStyle}
        center={coordinates}
        zoom={15}
        onLoad={onLoad}
        onUnmount={onUnmount}
      >
        <Marker
          key={Math.random()}
          position={coordinates}
          onDragEnd={(v) => markerMovable && markerMovable(v)}
          draggable={!!markerMovable}
        />
      </GoogleMap>
    </View>
  );
};

const styles = StyleSheet.create({
  types: {
    color: "grey",
    marginVertical: 10,
  },
  container: {
    margin: 20,
  },
  image: {
    aspectRatio: 3 / 2,
    borderRadius: 10,
    resizeMode: "cover",
    width: "100%",
  },
  longDescription: {
    fontSize: 16,
    lineHeight: 24,
    marginVertical: 10,
  },
  textSize: {
    marginBottom: 10,
    fontSize: 14,
    marginLeft: 10
  },
  newPrice: {
    fontWeight: "bold",
  },
  oldPrice: {
    color: "grey",
    textDecorationLine: "line-through",
  },
  prices: {
    fontSize: 17,
    marginVertical: 10,
  },
  totalPrice: {
    color: "grey",
    textDecorationLine: "underline",
  },
});

export default MapCustomView;
