const tintColorLight = '#2f95dc';
const tintColorDark = '#fff';

export const colors = {
  light: {
    text: '#000',
    background: '#fff',
    tint: tintColorLight,
    tabIconDefault: '#ccc',
    tabIconSelected: tintColorLight,
  },
  dark: {
    text: '#fff',
    background: '#000',
    tint: tintColorDark,
    tabIconDefault: '#ccc',
    tabIconSelected: tintColorDark,
  },
  orange: "#e88b35",
  mellowOrange: "#f2b877",
  mellowOrange: "#f5cc9f",
  orange2: "#ffc259",
  hookhubOrangeDark: "#d69045",
  hookhubOrange: "#ffa320",
  amplifyOrange80: "#a36333",
  amplifyOrange90: "#85522a",

  medBlue: "#065d8c",
  lightBlue: "#5f8aa1",
  vibrantBlue: "#78b2d6",
  brightBlue: "#48a8db",
  amplifyBlue20: "#6b9dc2",

  black: '#000000',
  white: '#fff',
  brandGrey: "#c7c7c7",
  amplifyNeutral80: "#4c4e52",
  amplifyNeutral20: "#f2f2f2",
  amplifyNeutral60: "#969595",
  amplifyNeutral90: "#2b2b2b",

  amplifyRed40: "#8c1616",
};