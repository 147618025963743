import React from "react";
import {
    StyleSheet,
    Text,
    Image,
    View,
} from "react-native";
import { useSelector } from "react-redux";
import CustomModal from "../CustomElements/CustomModal";
import CustomSection from "../CustomElements/CustomSection";
import { colors } from "../../styles/colors";
import CustomButton from "../CustomElements/CustomButton";
import { globalStyles } from "../../styles/styles";

export const NotificationModal = ({
    modalVisible,
    setModalVisible,
    width,
    goToHostSplash
}) => {
    const { mobileView } = useSelector(state => state.currentUser);

    return (
        <CustomModal
            modalVisible={modalVisible}
            setModalVisible={() => setModalVisible(!modalVisible)}
            width={mobileView ? width / 1.1 : width / 1.7}
        >
            <View style={globalStyles.centerColumn}>
                <Image
                    style={[
                        styles.miniLogoImage,
                        {
                            margin: 10,
                            marginTop: 15,
                            width: mobileView ? 154 : 200,
                            height: mobileView ? 40 : 52
                        }]}
                    source={require("../../../assets/images/hookhub-logo_mini.png")}
                />
                <CustomSection backgroundColor={colors.mellowOrange} customWidth={width / 1.4}>
                    <Text style={{ fontSize: 17, textAlign: "center", margin: 20 }}>
                        Rent or Host a space anywhere in the USA or Canada
                    </Text>
                </CustomSection>
                <Text style={{ fontSize: 17, textAlign: "center", margin: 20 }}>
                    We are a startup, so check everyday for new spaces!
                </Text>
                <CustomButton
                    backgroundColor={colors.hookhubOrange}
                    width={170}
                    height={40}
                    onPress={() => goToHostSplash()}
                    marginBottom={10}
                >
                    <Text style={{ fontSize: 17 }}>Learn about Hosting</Text>
                </CustomButton>
                <CustomButton
                    backgroundColor={colors.hookhubOrange}
                    width={170}
                    height={40}
                    onPress={() => setModalVisible()}
                    marginBottom={10}
                >
                    <Text style={{ fontSize: 17 }}>Check out spaces</Text>
                </CustomButton>
            </View>
        </CustomModal>

    );
};

const styles = StyleSheet.create({
    modalView: {
        margin: 13,
        backgroundColor: "white",
        borderRadius: 20,
        shadowColor: "#000",
        shadowOffset: {
            width: 0,
            height: 2,
        },
        shadowOpacity: 0.25,
        shadowRadius: 4,
        elevation: 5,
    },
    centeredView: {
        flex: 1,
        justifyContent: "center",
        alignItems: "center",
        marginTop: 90
    },
    modalText: {
        marginBottom: 15,
        textAlign: "center",
    },
    logoImage: {
        justifyContent: "center",
    },
    miniLogoImage: {
        justifyContent: "flex-start",
    },
});
