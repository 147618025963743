import React, { useState, useEffect } from "react";
import { StyleSheet, View, ImageBackground, useWindowDimensions, Text } from "react-native";
import { useDispatch, useSelector } from 'react-redux';
import SelectField from "../../components/CustomElements/SelectField"
import Card from "../../components/CustomElements/Card"
import Header from "../../components/CustomElements/Header"
import HostOnboarding from '../../components/host/HostOnboarding';
import { setSpaceLocation } from "../../redux/actions/HostOnboardingActions";
import CustomTextInput from "../../components/CustomElements/CustomTextInput";
import { colors } from "../../styles/colors";
import { capitalizeFirstLetter, validateNumbersOnly } from "../../utils/fieldValidators";
import getEnvVars from "../../../environment";


const LocationSet = ({
  address,
  cityName,
  stateName,
  zipcode,
  setaddress,
  setcity,
  setstate,
  setzip,
  countryName,
  setcountry,
  canadianCivicAddress,
  setcanadianCivicAddress,
  mobileView,
  width
}) => {
  const states = [
    "Alabama",
    "Alaska",
    "Arizona",
    "Arkansas",
    "California",
    "Colorado",
    "Connecticut",
    "Delaware",
    "Florida",
    "Georgia",
    "Hawaii",
    "Idaho",
    "Illinois",
    "Indiana",
    "Iowa",
    "Kansas",
    "Kentucky",
    "Louisiana",
    "Maine",
    "Maryland",
    "Massachusetts",
    "Michigan",
    "Minnesota",
    "Mississippi",
    "Missouri",
    "Montana",
    "Nebraska",
    "Nevada",
    "New Hampshire",
    "New Jersey",
    "New Mexico",
    "New York",
    "North Carolina",
    "North Dakota",
    "Ohio",
    "Oklahoma",
    "Oregon",
    "Pennsylvania",
    "Rhode Island",
    "South Carolina",
    "South Dakota",
    "Tennessee",
    "Texas",
    "Utah",
    "Vermont",
    "Virginia",
    "Washington",
    "West Virginia",
    "Wisconsin",
    "Wyoming"
  ];

  return (
    <View style={[styles.container, { marginTop: 30, marginBottom: 30 }]}>
      <SelectField
        options={['United States of America', 'Canada']}
        label="Country"
        placeholder="country"
        value={countryName}
        onChange={(e) => setcountry(e)}
        width={mobileView ? width / 1.15 : 300}
      />
      <CustomTextInput
        width={mobileView ? width / 1.15 : 300}
        alignContent="center"
        multiline={false}
        numberOfLines={1}
        justifyContent="center"
        alignItems="center"
        textAlign="center"
        height={50}
        placeholder="123 Main St..."
        errorMessage="There is an error"
        value={address}
        marginBottom={5}
        marginTop={30}
        onChangeText={(e) => setaddress(e)}
        type="address-line1"
        autoCapitalize="words"
      />
      <CustomTextInput
        width={mobileView ? width / 1.15 : 300}
        alignContent="center"
        justifyContent="center"
        alignItems="center"
        textAlign="center"
        height={50}
        placeholder="City"
        errorMessage="There is an error"
        value={cityName}
        marginBottom={5}
        onChangeText={(e) => setcity(e)}
      />
      {countryName === 'United States of America' && (
        <>
          <SelectField
            options={states}
            label="State"
            placeholder="state"
            value={stateName}
            onChange={(e) => setstate(e)}
            width={mobileView ? width / 1.15 : 300}
          />
          <CustomTextInput
            width={200}
            alignContent="center"
            justifyContent="center"
            alignItems="center"
            textAlign="center"
            height={50}
            placeholder="Zip code"
            errorMessage="There is an error"
            value={zipcode}
            marginBottom={5}
            onChangeText={(e) => setzip(e)}
            type="postal-code"
            keyboardType="number-pad"
          />
        </>
      )}
      {countryName === 'Canada' && (
        <CustomTextInput
          width={mobileView ? width / 1.15 : 300}
          alignContent="center"
          justifyContent="center"
          alignItems="center"
          textAlign="center"
          height={50}
          placeholder="province, territory, ..."
          errorMessage="There is an error"
          value={canadianCivicAddress}
          marginBottom={5}
          onChangeText={(e) => setcanadianCivicAddress(e)}
        />
      )}
    </View>
  );
}

function SpaceLocationScreen() {
  const dispatch = useDispatch();
  const { cloudfrontURL } = getEnvVars();
  const { width, height } = useWindowDimensions();
  const { mobileView } = useSelector(state => state.currentUser);
  const hostState = useSelector(state => state.hostOnboarding)
  const [address, setaddress] = useState(hostState.address);
  const [cityName, setcity] = useState((hostState.cityName));
  const [stateName, setstate] = useState((hostState.stateName));
  const [disabled, setDisabled] = useState(true);
  const [zipcode, setzip] = useState((hostState.zipcode));
  const [countryName, setcountry] = useState((hostState.countryName));
  const [canadianCivicAddressName, setcanadianCivicAddress] = useState((hostState.canadianCivicAddress));
  const noBasicAmenitiesPresent = !hostState.water_hookup && !hostState.electric_hookup && !hostState.sewage_hookup;

  const setLocation = () => {
    dispatch(setSpaceLocation({
      address: address,
      cityName: cityName,
      stateName: stateName,
      zipcode: zipcode,
      countryName: countryName,
      canadianCivicAddress: canadianCivicAddressName,
      fullAddress: {
        address: address,
        cityName: cityName,
        stateName: stateName,
        zipcode: zipcode,
        canadianCivicAddress: canadianCivicAddressName,
        countryName: countryName,
      }
    }));
  };

  useEffect(() => {
    const extraAddressData = countryName === 'Canada' ? canadianCivicAddressName : (!!stateName && !!zipcode);
    if (!!address && !!cityName && !!countryName && extraAddressData) {
      setDisabled(false)
    } else {
      setDisabled(true);
    }
  }, [address, cityName, stateName, zipcode, countryName, canadianCivicAddressName]);

  const checkAndSetZip = (val) => {
    const checkedZip = validateNumbersOnly(val);
    if (!!checkedZip) {
      setzip(checkedZip)
    } else {
      setzip('')
    }
  }

  const checkAndSetAddress = (val) => {
    const checked = capitalizeFirstLetter(val);
    if (!!checked) {
      setaddress(checked)
    } else {
      setaddress('')
    }
  }

  const checkAndSetCity = (val) => {
    const checked = capitalizeFirstLetter(val);
    if (!!checked) {
      setcity(checked)
    } else {
      setcity('')
    }
  }

  return (
    <HostOnboarding
      heading="Where's your property located?"
      navigateF={hostState.newProperty ? "LocationConfirmation" : "PhotoUpload"}
      subtext="Your exact address will not be shared until a guest is booked."
      navigateB={noBasicAmenitiesPresent ? "SpaceDetails" : "SetDistances"}
      nextButtonText="Next"
      onClickNext={() => setLocation()}
      disabledNextButton={disabled}
      required={"Full Address"}
    >
      {/* <Card width={!!mobileView ? width / 1.1 : width / 1.8} backgroundColor={colors.hookhubOrangeDark}> */}
        <LocationSet
          mobileView={mobileView}
          width={width}
          address={address}
          cityName={cityName}
          stateName={stateName}
          zipcode={zipcode}
          countryName={countryName}
          setaddress={(val) => checkAndSetAddress(val)}
          setcity={(val) => checkAndSetCity(val)}
          setstate={val => setstate(val)}
          setzip={val => checkAndSetZip(val)}
          setcountry={val => setcountry(val)}
          canadianCivicAddress={canadianCivicAddressName}
          setcanadianCivicAddress={val => setcanadianCivicAddress(val)}
        />
      {/* </Card> */}
    </HostOnboarding>
  )
};

const styles = StyleSheet.create({
  image: {
    justifyContent: "center",
    alignItems: "center",
    boxShadow: "#737272"
  },
  container: {
    justifyContent: "center",
    alignItems: "center",
    alignContent: "center",
    textAlign: "center",
    alignSelf: "center"
  },
  buttonText: {
    flex: 1,
    textAlign: 'center',
    fontSize: 17,
    marginTop: 20
  },
  subtext: {
    fontSize: 17,
    fontWeight: "600",
    margin: 30,
    color: colors.medBlue
  },
});

export default SpaceLocationScreen;