import React, { useState } from "react";
import { StyleSheet, View, Text, Pressable } from "react-native";
import Header from "../CustomElements/Header"
import { globalStyles } from "../../styles/styles";
import { FontAwesome5, MaterialCommunityIcons, MaterialIcons } from "@expo/vector-icons";
import { colors } from "../../styles/colors"
import CustomButton from "../CustomElements/CustomButton";

export const SetUpPaymentForm = ({
    full_refund_date,
    half_refund_date,
    full_refund_enabled,
    half_refund_enabled,
    no_refund_enabled,
    payMonthly,
    space_rules,
    noSmoking,
    adultsOnly,
    mobileView,
    width,
    userData,
    halfRefundDateIneligible,
    fullRefundDateIneligible,
    signature,
    setSignature,
    refundPolicy,
    setrefundPolicy,
    agreeRulesAndRegulations,
    setAgreeRulesAndRegulations,
    liability,
    setLiability,
    offPlatformPolicy,
    setOffPlatformPolicy
}) => {
    const [showRulesAndRegulations, setShowRulesAndRegulations] = useState(false);

    return (
        <View style={[globalStyles.flexStartColumn, styles.checkboxView, { padding: 15 }]}>
            <Header level={1.15} color={"#000"} text="Terms & Conditions" />
            <Text style={{ fontSize: 16, margin: 20 }}>* To protect your payment, never transfer money or communicate outside of Hookhub.</Text>
            <View style={globalStyles.flexStartColumn}>

                <View style={[globalStyles.flexStart, { marginVertical: 5 }]}>
                    <Pressable onPress={() => setAgreeRulesAndRegulations(!agreeRulesAndRegulations)}>
                        {!agreeRulesAndRegulations ? (
                            <MaterialIcons name="check-box-outline-blank" size={24} color="black" />
                        ) : (
                            <MaterialIcons name="check-box" size={24} color="black" />
                        )}
                    </Pressable>
                    <Text style={styles.disclaimerText}>
                        I agree to the <Text style={{ textDecorationLine: "underline" }}>Host's Rules and Regulations </Text>
                        and understand I must abide by these.
                        The Host reserves the right to cancel the Booking. If I fail to comply with the rules and policies and do not
                        move out by the new check-out date, the host reserves the right to have my Rig removed from their
                        property at the Renter's expense.
                    </Text>
                </View>
                {showRulesAndRegulations ? (
                    <>
                        <View>
                            <Header level={1} color={colors.black} text="Rules and Regulations" />
                            <View style={[[globalStyles.flexStart, { marginVertical: 5 }], { marginVertical: 5 }]}>
                                <MaterialCommunityIcons name="file-alert" size={26} color={colors.black} />
                                <Text style={{ fontSize: 17, marginLeft: 15 }}>
                                    {space_rules}
                                </Text>
                            </View>
                            {!noSmoking && (
                                <View style={[[globalStyles.flexStart, { marginVertical: 5 }], { marginVertical: 5 }]}>
                                    <FontAwesome5
                                        name="smoking-ban"
                                        size={25}
                                        color={colors.black}
                                    />
                                    <Text style={{ fontSize: 17, marginLeft: 15 }}>NO smoking</Text>
                                </View>
                            )}
                            {adultsOnly && (
                                <View style={[[globalStyles.flexStart, { marginVertical: 5 }], { marginVertical: 5 }]}>
                                    <MaterialCommunityIcons name="baby-carriage-off" size={25} color={colors.black} />
                                    <Text style={{ fontSize: 17, marginLeft: 15 }}>NO children permitted to stay on property</Text>
                                </View>
                            )}
                        </View>
                        <View style={globalStyles.center}>
                            <CustomButton
                                width={100}
                                backgroundColor={colors.brandGrey}
                                marginTop={15}
                                border={colors.amplifyNeutral90}
                                onPress={() => setShowRulesAndRegulations(false)}
                            >
                                <Text style={{ fontSize: 17 }}>hide</Text>
                            </CustomButton>
                        </View>
                    </>
                ) : (
                    <CustomButton
                        width={215}
                        backgroundColor={colors.brandGrey}
                        marginTop={5}
                        marginBottom={15}
                        marginLeft={10}
                        border={colors.amplifyNeutral90}
                        onPress={() => setShowRulesAndRegulations(true)}
                    >
                        <Text style={{ fontSize: 17 }}>view rules and regulations</Text>
                    </CustomButton>
                )}
                <View style={[globalStyles.flexStart, { marginVertical: 5 }]}>
                    <Pressable onPress={() => setLiability(!liability)}>
                        {!liability ? (
                            <MaterialIcons name="check-box-outline-blank" size={24} color="black" />
                        ) : (
                            <MaterialIcons name="check-box" size={24} color="black" />
                        )}
                    </Pressable>
                    <Text style={styles.disclaimerText}>Hookhub is not liable for any damages or personal injury that may occur during stay.</Text>
                </View>
                <View style={[globalStyles.flexStart, { marginVertical: 5 }]}>
                    <Pressable onPress={() => setrefundPolicy(!refundPolicy)}>
                        {!refundPolicy ? (
                            <MaterialIcons name="check-box-outline-blank" size={24} color="black" />
                        ) : (
                            <MaterialIcons name="check-box" size={24} color="black" />
                        )}
                    </Pressable>
                    <View style={{ width: width / 1.4 }}>
                        <Text style={styles.disclaimerText}>
                            I understand the <Text style={{ textDecorationLine: "underline" }}>cancellation policy</Text> set by my Host:
                        </Text>
                        {!fullRefundDateIneligible && !!full_refund_enabled && (
                            <Text style={styles.boldText}>
                                - I must cancel by {full_refund_date} for a 100% refund {payMonthly ? ` of my initial payment. All following payments will be cancelled.` : ""}
                            </Text>
                        )}
                        {!halfRefundDateIneligible && !!half_refund_enabled && (
                            <Text style={styles.boldText}>
                                - I must cancel by ${half_refund_date} for a 50% refund {payMonthly ? (` of my initial payment. All following payments will be cancelled.`) : (`.`)}
                            </Text>
                        )}
                        {no_refund_enabled || (fullRefundDateIneligible && halfRefundDateIneligible) && (
                            <Text style={styles.boldText}>
                                - There is no refund options once this reservation has been confirmed {payMonthly ? (` for my initial payment. All following payments will be cancelled.`) : (`.`)}
                            </Text>
                        )}
                        {payMonthly && (
                            <Text style={styles.boldText}> During my stay, I can cancel as long as it is more than 28 days before the checkout date. I will have to give a 28 days notice to cancel. </Text>
                        )}
                    </View>
                </View>
                <View style={[globalStyles.flexStart, { marginVertical: 5 }]}>
                    <Pressable onPress={() => setOffPlatformPolicy(!offPlatformPolicy)}>
                        {!offPlatformPolicy ? (
                            <MaterialIcons name="check-box-outline-blank" size={24} color="black" />
                        ) : (
                            <MaterialIcons name="check-box" size={24} color="black" />
                        )}
                    </Pressable>
                    <Text style={styles.disclaimerText}>I understand the following actions are strictly prohibited: Taking transactions off the Hookhub platform for any current or future bookings.</Text>
                </View>
                <View style={[globalStyles.flexStart, { marginVertical: 5 }]}>
                    <Pressable onPress={() => setSignature(!signature)}>
                        {!signature ? (
                            <MaterialIcons name="check-box-outline-blank" size={24} color="black" />
                        ) : (
                            <MaterialIcons name="check-box" size={24} color="black" />
                        )}
                    </Pressable>
                    <Text style={styles.disclaimerText}>By checking the above boxes I, {userData.name}, acknowledge that I am aware and agree with the Liability, Refund Policies and all of Hookhub's Terms & Conditions.</Text>
                </View>
            </View>
        </View>
    )
};

const styles = StyleSheet.create({
    disclaimerText: {
        fontSize: 17,
        paddingHorizontal: 10,
    },
    boldText: {
        fontWeight: "bold",
        fontSize: 16,
        marginVertical: 10,
        paddingHorizontal: 10,
        flexWrap: "wrap"
    },
    checkboxView: {
        backgroundColor: colors.lightBlue,
        borderRadius: 10,
        marginTop: 4,
        shadowColor: "grey",
        textAlign: "left",
        shadowOffset: {
            width: 2,
            height: 4
        },
        shadowOpacity: 0.85,
        shadowRadius: 4,
        elevation: 5,
        padding: 4,
        marginBottom: 20
    },
    paymentInputView: {
        backgroundColor: colors.mellowOrange,
        borderRadius: 10,
        marginTop: 4,
        alignItems: "center",
        shadowColor: "grey",
        shadowOffset: {
            width: 2,
            height: 4
        },
        shadowOpacity: 0.85,
        shadowRadius: 4,
        elevation: 5,
        padding: 4,
        paddingVertical: 20
    }
});
