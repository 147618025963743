import React, { useContext } from "react";
import { useWindowDimensions, ScrollView, View } from "react-native";
import Flex from "../../components/CustomElements/Flex"
import CustomDivider from "../../components/CustomDivider";
import { DetailsAccount } from "../../components/user/DetailsAccount";
import { DetailsPersonal } from "../../components/user/DetailsPersonal";
import { DeleteAccount } from "../../components/user/DeleteAccount";
import { DetailsReviewsAndStatus } from "../../components/user/DetailsReviewsAndStatus";
import { UserDataContext } from "../../utils/UserContextProvider";
import BackButton from "../../components/other/BackButton";
import { useSelector } from "react-redux";
import FooterView from "../../components/other/FooterView";

function ProfileScreen() {
  const { user } = useContext(UserDataContext);
  const { userData } = user;
  const screenWidth = useWindowDimensions().width / 1.25;
  const { mobileView } = useSelector(state => state.currentUser);

  return (
    <ScrollView>
      <BackButton goBack={false} />
      <View style={{ marginLeft: mobileView ? 15 : 35 }}>
        <DetailsAccount
          screenWidth={screenWidth}
        />

        <Flex direction="column" width={screenWidth}>
          <CustomDivider />
          <DetailsReviewsAndStatus
            userState={userData}
          />
        </Flex>

        <Flex direction="column" width={screenWidth}>
          <CustomDivider />
          <DetailsPersonal
            userState={userData}
          />
        </Flex>

        <Flex direction="column" width={screenWidth}>
          <CustomDivider />
          <DeleteAccount
            userState={userData}
          />
        </Flex>

      </View>
      <FooterView />
    </ScrollView>
  );
}

export default ProfileScreen;
