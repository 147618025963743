import React from "react";
import {
  Text,
  View,
  useWindowDimensions,
  StyleSheet
} from "react-native";
import Flex from "../CustomElements/Flex"
import Card from "../CustomElements/Card"
import Header from "../CustomElements/Header"
import { useSelector } from "react-redux";
import Badge from "../CustomElements/Badge";
import { CustomNumberInput } from "../CustomElements/CustomNumberInput";

const SetDimensions = ({ width, setwidth, length, setlength, additionalSpacesBoolean = false, spaceTitle = '' }) => {
  const { mobileView } = useSelector(state => state.currentUser);
  const windowWidth = useWindowDimensions().width;

  return (
    <Card
      backgroundColor="#fff"
      border="#000"
      boxShadow="#737272"
      color="#2f5894"
      alignItems="center"
      justifyContent="center"
      textAlign="center"
      width={mobileView ? windowWidth / 1.1 : windowWidth / 1.8}
      padding={15}
    >
      {additionalSpacesBoolean && (
        <Badge>
          <Text textAlign="left">Space: {spaceTitle}</Text>
        </Badge>
      )}
      <View style={[styles.inputArea, { width: mobileView ? windowWidth / 1.2 : windowWidth / 1.85 }]}>
        <Header text="WIDTH in feet"></Header>
        <CustomNumberInput
          value={width}
          setValue={(v) => setwidth(v)}
        />
      </View>
      <View style={[styles.inputArea, { width: mobileView ? windowWidth / 1.2 : windowWidth / 1.85 }]}>
        <Header text="LENGTH in feet"></Header>
        <CustomNumberInput
          value={length}
          setValue={(v) => setlength(v)}
        />
      </View>
    </Card>
  );
};

export default SetDimensions;

const styles = StyleSheet.create({
  inputArea: {
    flexDirection: "row",
    alignContent: "center",
    justifyContent: "space-between",
    alignItems: "center",
  },
  text: {
    fontSize: 17,
    textAlign: "center",
    marginBottom: 15
  }
})