import React, { useEffect, useState, useContext } from "react";
import { useWindowDimensions, ScrollView, TouchableOpacity, View, Text, StyleSheet } from "react-native";
import { useDispatch, useSelector } from "react-redux";
import AllConversations from "../../components/message/Conversations";
import SelectedConvo from "../../components/message/SelectedConversation";
import Flex from "../../components/CustomElements/Flex"
import Header from "../../components/CustomElements/Header";
import { UserDataContext } from "../../utils/UserContextProvider";
import FixedHeightCard from "../../components/CustomElements/FixedHeightCard";
import { colors } from "../../styles/colors";
import { setUnreadMsgs } from "../../redux/actions/ConversationsActions";
import { globalStyles } from "../../styles/styles";
import { useRoute } from "@react-navigation/native";

export default function MessagesScreen() {
  const { mobileView } = useSelector(state => state.currentUser);
  const { width, height } = useWindowDimensions();
  const route = useRoute();
  const dispatch = useDispatch();
  const { conversations, unreadBoolean } = useSelector((state) => state.conversations);
  const { user } = useContext(UserDataContext);
  const { userData } = user;
  const { sub, userName, avatarImage } = userData;
  const [selectedConvoId, setSelectedConvoId] = useState(null);
  const [showConvoList, setShowConvoList] = useState(true);

  useEffect(() => {
    if (route?.params && (typeof route.params === "string")) {
      setSelectedConvoId(route.params);
      setShowConvoList(false)
    } else {
      setSelectedConvoId(conversations[0].id)
    }
  }, [route]);

  useEffect(() => {
    unreadBoolean && dispatch(setUnreadMsgs({ unreadBoolean: false }))
  }, []);

  const smallScreenSetConvo = (v) => {
    setShowConvoList(false);
    setSelectedConvoId(v);
  };

  return (
    <>
      {!conversations.length ? (
        <Flex
          justifyContent="center"
          alignContent="center"
          alignItems="center"
          alignSelf="center"
          marginTop={100}
        >
          <Text>No conversations yet</Text>
        </Flex>
      ) : (
        <>
          {!!mobileView ? (
            <>
              {showConvoList ? (
                <ScrollView style={styles.scrollView}>
                  <View style={{ marginBottom: 30 }}>
                    <Flex
                      marginTop={10}
                      marginBottom={10}
                      marginLeft={10}
                      direction="column"
                    >
                      <Header level={1} color={colors.amplifyNeutral80} text="My Messages"></Header>
                    </Flex>
                    <AllConversations
                      selectedConvo={selectedConvoId}
                      setSelectedConvo={(v) => smallScreenSetConvo(v)}
                      myID={sub}
                      myName={userData.name}
                      mobileView={mobileView}
                    />
                  </View>
                </ScrollView>
              ) : (
                <SelectedConvo
                  height={height}
                  width={width}
                  userId={sub}
                  convoID={selectedConvoId}
                  authorName={userName}
                  authorAvatar={avatarImage}
                  mobileView={mobileView}
                  goBack={() => setShowConvoList(true)}

                />
              )}
            </>
          ) : (
            <View style={globalStyles.flexStartColumn}>
              <Header color={colors.amplifyNeutral80} text="My Messages" marginLeft={20} />
              <View style={globalStyles.spaceBetween}>
                <FixedHeightCard
                  width={width * .4}
                  height={height}
                >
                  <ScrollView>
                    <TouchableOpacity style={{ flex: 1, marginBottom: 200 }}>
                      <AllConversations
                        selectedConvo={selectedConvoId}
                        setSelectedConvo={(v) => setSelectedConvoId(v)}
                        myID={sub}
                        myName={userData.name}
                        mobileView={mobileView}
                      />
                    </TouchableOpacity>
                  </ScrollView>
                </FixedHeightCard>
                <View style={{ width: width * .6, height: height / 1.5, marginBottom: 10 }}>
                  <SelectedConvo
                    height={height}
                    width={width}
                    userId={sub}
                    convoID={selectedConvoId}
                    authorName={userName}
                    authorAvatar={avatarImage}
                    mobileView={mobileView}
                    goBack={() => setShowConvoList(true)}
                  />
                </View>
              </View>
            </View>
          )}
        </>
      )}
    </>
  );
}


const styles = StyleSheet.create({
  container: {
    flex: 1,
    padding: 20,
    alignContent: "center",
    textAlign: "center",
    alignItems: "center",
    justifyContent: "center",
  },
  scrollView: {
    width: "100%",
    marginLeft: 10
  },
});