/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const createUser = /* GraphQL */ `
  mutation CreateUser(
    $input: CreateUserInput!
    $condition: ModelUserConditionInput
  ) {
    createUser(input: $input, condition: $condition) {
      id
      name
      email
      phoneNumber
      avatar
      about
      favorites
      stripePayoutsEnabled
      stripeConnectedAccountId
      stripeCustomerID
      stripePaymentMethodID
      govID_verified
      createdAt
      _version
      updatedAt
      __typename
    }
  }
`;
export const updateUser = /* GraphQL */ `
  mutation UpdateUser(
    $input: UpdateUserInput!
    $condition: ModelUserConditionInput
  ) {
    updateUser(input: $input, condition: $condition) {
      id
      name
      email
      phoneNumber
      rig {
        items {
          id
          ownerID
          image
          length
          width
          year
          make
          model
          _version
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      avatar
      about
      myHostedSpaces {
        items {
          id
          propertyID
          ownerID
          bookings {
            nextToken
            __typename
          }
          bookingRequests {
            nextToken
            __typename
          }
          space_approved
          active
          title
          description
          unavailable_days
          set_availability
          longest_stay_allowed
          shortest_stay_allowed
          width
          length
          electric_hookup
          water_hookup
          sewage_hookup
          wifi_available
          wifi_password
          wifi_network_name
          shower_available
          restroom_available
          food_nearby
          restaurant_nearby
          water_accessible
          easy_entry
          pre_booking_message_required
          pre_booking_message
          instant_book
          distance_electric_hookup
          distance_water_hookup
          distance_sewage_hookup
          parking_spaces
          additional_rules
          parties_allowed
          things_to_do
          images
          laundry_available
          pool_available
          hottub_available
          outdoor_area_available
          indoor_area_available
          trash_available
          extra_amenities_available
          host_acknowledge_liability
          host_acknowledge_tax_responsibility
          check_in_time
          check_out_time
          check_in_instructions
          space_rules
          pets_allowed
          smoking_allowed
          adults_only
          quiet_hours_start
          quiet_hours_end
          outdoor_fires
          profile_photo_required
          full_refund_date
          half_refund_date
          price_storage
          price_shortterm
          price_longterm
          price_yearly
          _version
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      myHostedProperties {
        items {
          id
          active
          ownerID
          spaces {
            nextToken
            __typename
          }
          title
          description
          address {
            id
            address
            cityName
            stateName
            zipcode
            countryName
            canadianCivicAddress
            _version
            createdAt
            updatedAt
            owner
            __typename
          }
          coordinates_lat
          coordinates_lng
          type_storage
          type_longterm
          type_shortterm
          type_yearly
          price_storage
          price_shortterm
          price_longterm
          price_yearly
          instant_book
          images
          reviews {
            nextToken
            __typename
          }
          conversations {
            nextToken
            __typename
          }
          _version
          createdAt
          updatedAt
          propertyAddressId
          __typename
        }
        nextToken
        __typename
      }
      favorites
      stripePayoutsEnabled
      stripeConnectedAccountId
      stripeCustomerID
      stripePaymentMethodID
      govID_verified
      bookings {
        items {
          id
          members
          cancelled
          cancelledTooLate
          cancelledTooLatePayout
          cancelledDate
          spaceID
          renterID
          hostID
          bookingRequestId
          bookingRequest {
            id
            members
            confirmed
            reviewed
            cancelled
            rentalType
            stripePaymentMethodID
            stripeCustomerID
            stripePaymentIntentID
            check_in
            check_out
            bookedDays
            spaceID
            spaceTitle
            spaceCity
            spaceState
            propertyTitle
            propertyID
            renterID
            bookingID
            hostID
            hostEmail
            renterEmail
            createdAt
            updatedAt
            renter_acknowledge_liability
            baseBookingCost
            renterFeeCost
            renterTotalBookingCost
            renterStripeFee
            hostFeeCost
            hostPayout
            hookhubPayout
            payMonthly
            numberOfMonths
            paymentSchedule
            nextPaymentDue
            nextPaymentsSchedule
            monthlyPaymentAmount
            monthlyHookhubPayout
            monthlyHostPayout
            full_refund_enabled
            no_refund_enabled
            half_refund_enabled
            full_refund_date
            half_refund_date
            errorCode
            _version
            bookingRequestConversationId
            __typename
          }
          paymentID
          payment {
            id
            members
            date
            stripePaymentMethodID
            stripeCustomerID
            paid
            hostID
            bookingId
            baseBookingCost
            renterFeeCost
            renterTotalBookingCost
            renterStripeFee
            hostFeeCost
            hostPayout
            hookhubPayout
            createdAt
            updatedAt
            _version
            __typename
          }
          bookedDays
          check_in
          check_out
          stripePaymentMethodID
          stripeCustomerID
          stripeHostConnectedAccountId
          hostEmail
          renterEmail
          createdAt
          updatedAt
          baseBookingCost
          renterFeeCost
          renterTotalBookingCost
          renterStripeFee
          hostFeeCost
          hostPayout
          hookhubPayout
          paymentRequiresAction
          payMonthly
          numberOfMonths
          paymentSchedule
          nextPaymentsSchedule
          nextPaymentDue
          monthlyPaymentAmount
          monthlyHostPayout
          monthlyHookhubPayout
          cancelledFinalHostPayout
          cancelledFinalRenterPayment
          cancelledBeforeCheckIn
          refundRenter
          full_refund_enabled
          no_refund_enabled
          half_refund_enabled
          full_refund_date
          half_refund_date
          stripePaymentIntentID
          _version
          __typename
        }
        nextToken
        __typename
      }
      bookingRequests {
        items {
          id
          members
          confirmed
          reviewed
          cancelled
          rentalType
          stripePaymentMethodID
          stripeCustomerID
          stripePaymentIntentID
          check_in
          check_out
          bookedDays
          spaceID
          spaceTitle
          spaceCity
          spaceState
          propertyTitle
          propertyID
          renterID
          bookingID
          booking {
            id
            members
            cancelled
            cancelledTooLate
            cancelledTooLatePayout
            cancelledDate
            spaceID
            renterID
            hostID
            bookingRequestId
            paymentID
            bookedDays
            check_in
            check_out
            stripePaymentMethodID
            stripeCustomerID
            stripeHostConnectedAccountId
            hostEmail
            renterEmail
            createdAt
            updatedAt
            baseBookingCost
            renterFeeCost
            renterTotalBookingCost
            renterStripeFee
            hostFeeCost
            hostPayout
            hookhubPayout
            paymentRequiresAction
            payMonthly
            numberOfMonths
            paymentSchedule
            nextPaymentsSchedule
            nextPaymentDue
            monthlyPaymentAmount
            monthlyHostPayout
            monthlyHookhubPayout
            cancelledFinalHostPayout
            cancelledFinalRenterPayment
            cancelledBeforeCheckIn
            refundRenter
            full_refund_enabled
            no_refund_enabled
            half_refund_enabled
            full_refund_date
            half_refund_date
            stripePaymentIntentID
            _version
            __typename
          }
          hostID
          hostEmail
          renterEmail
          conversation {
            id
            hostee
            host
            convoCreatorsEmail
            otherMembersEmail
            spaceID
            spaceTitle
            propertyID
            propertyTitle
            propertyLocation
            bookingRequestID
            members
            membersNames
            unreadHost
            unreadHostee
            type
            createdAt
            updatedAt
            _version
            __typename
          }
          createdAt
          updatedAt
          renter_acknowledge_liability
          baseBookingCost
          renterFeeCost
          renterTotalBookingCost
          renterStripeFee
          hostFeeCost
          hostPayout
          hookhubPayout
          payMonthly
          numberOfMonths
          paymentSchedule
          nextPaymentDue
          nextPaymentsSchedule
          monthlyPaymentAmount
          monthlyHookhubPayout
          monthlyHostPayout
          full_refund_enabled
          no_refund_enabled
          half_refund_enabled
          full_refund_date
          half_refund_date
          errorCode
          _version
          bookingRequestConversationId
          __typename
        }
        nextToken
        __typename
      }
      bookingsHosted {
        items {
          id
          members
          cancelled
          cancelledTooLate
          cancelledTooLatePayout
          cancelledDate
          spaceID
          renterID
          hostID
          bookingRequestId
          bookingRequest {
            id
            members
            confirmed
            reviewed
            cancelled
            rentalType
            stripePaymentMethodID
            stripeCustomerID
            stripePaymentIntentID
            check_in
            check_out
            bookedDays
            spaceID
            spaceTitle
            spaceCity
            spaceState
            propertyTitle
            propertyID
            renterID
            bookingID
            hostID
            hostEmail
            renterEmail
            createdAt
            updatedAt
            renter_acknowledge_liability
            baseBookingCost
            renterFeeCost
            renterTotalBookingCost
            renterStripeFee
            hostFeeCost
            hostPayout
            hookhubPayout
            payMonthly
            numberOfMonths
            paymentSchedule
            nextPaymentDue
            nextPaymentsSchedule
            monthlyPaymentAmount
            monthlyHookhubPayout
            monthlyHostPayout
            full_refund_enabled
            no_refund_enabled
            half_refund_enabled
            full_refund_date
            half_refund_date
            errorCode
            _version
            bookingRequestConversationId
            __typename
          }
          paymentID
          payment {
            id
            members
            date
            stripePaymentMethodID
            stripeCustomerID
            paid
            hostID
            bookingId
            baseBookingCost
            renterFeeCost
            renterTotalBookingCost
            renterStripeFee
            hostFeeCost
            hostPayout
            hookhubPayout
            createdAt
            updatedAt
            _version
            __typename
          }
          bookedDays
          check_in
          check_out
          stripePaymentMethodID
          stripeCustomerID
          stripeHostConnectedAccountId
          hostEmail
          renterEmail
          createdAt
          updatedAt
          baseBookingCost
          renterFeeCost
          renterTotalBookingCost
          renterStripeFee
          hostFeeCost
          hostPayout
          hookhubPayout
          paymentRequiresAction
          payMonthly
          numberOfMonths
          paymentSchedule
          nextPaymentsSchedule
          nextPaymentDue
          monthlyPaymentAmount
          monthlyHostPayout
          monthlyHookhubPayout
          cancelledFinalHostPayout
          cancelledFinalRenterPayment
          cancelledBeforeCheckIn
          refundRenter
          full_refund_enabled
          no_refund_enabled
          half_refund_enabled
          full_refund_date
          half_refund_date
          stripePaymentIntentID
          _version
          __typename
        }
        nextToken
        __typename
      }
      reviewsOfRenter {
        items {
          id
          rating
          cleanlinessRating
          communicationRating
          overallRating
          description
          renterID
          reviewerName
          reviewerAvatar
          reviewerID
          bookingID
          propertyID
          _version
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      hosteeConvos {
        items {
          id
          hostee
          host
          convoCreatorsEmail
          otherMembersEmail
          spaceID
          spaceTitle
          propertyID
          propertyTitle
          propertyLocation
          bookingRequestID
          bookingRequest {
            id
            members
            confirmed
            reviewed
            cancelled
            rentalType
            stripePaymentMethodID
            stripeCustomerID
            stripePaymentIntentID
            check_in
            check_out
            bookedDays
            spaceID
            spaceTitle
            spaceCity
            spaceState
            propertyTitle
            propertyID
            renterID
            bookingID
            hostID
            hostEmail
            renterEmail
            createdAt
            updatedAt
            renter_acknowledge_liability
            baseBookingCost
            renterFeeCost
            renterTotalBookingCost
            renterStripeFee
            hostFeeCost
            hostPayout
            hookhubPayout
            payMonthly
            numberOfMonths
            paymentSchedule
            nextPaymentDue
            nextPaymentsSchedule
            monthlyPaymentAmount
            monthlyHookhubPayout
            monthlyHostPayout
            full_refund_enabled
            no_refund_enabled
            half_refund_enabled
            full_refund_date
            half_refund_date
            errorCode
            _version
            bookingRequestConversationId
            __typename
          }
          messages {
            nextToken
            __typename
          }
          members
          membersNames
          unreadHost
          unreadHostee
          type
          createdAt
          updatedAt
          _version
          __typename
        }
        nextToken
        __typename
      }
      hostConvos {
        items {
          id
          hostee
          host
          convoCreatorsEmail
          otherMembersEmail
          spaceID
          spaceTitle
          propertyID
          propertyTitle
          propertyLocation
          bookingRequestID
          bookingRequest {
            id
            members
            confirmed
            reviewed
            cancelled
            rentalType
            stripePaymentMethodID
            stripeCustomerID
            stripePaymentIntentID
            check_in
            check_out
            bookedDays
            spaceID
            spaceTitle
            spaceCity
            spaceState
            propertyTitle
            propertyID
            renterID
            bookingID
            hostID
            hostEmail
            renterEmail
            createdAt
            updatedAt
            renter_acknowledge_liability
            baseBookingCost
            renterFeeCost
            renterTotalBookingCost
            renterStripeFee
            hostFeeCost
            hostPayout
            hookhubPayout
            payMonthly
            numberOfMonths
            paymentSchedule
            nextPaymentDue
            nextPaymentsSchedule
            monthlyPaymentAmount
            monthlyHookhubPayout
            monthlyHostPayout
            full_refund_enabled
            no_refund_enabled
            half_refund_enabled
            full_refund_date
            half_refund_date
            errorCode
            _version
            bookingRequestConversationId
            __typename
          }
          messages {
            nextToken
            __typename
          }
          members
          membersNames
          unreadHost
          unreadHostee
          type
          createdAt
          updatedAt
          _version
          __typename
        }
        nextToken
        __typename
      }
      payments {
        items {
          id
          members
          date
          stripePaymentMethodID
          stripeCustomerID
          paid
          hostID
          bookingId
          booking {
            id
            members
            cancelled
            cancelledTooLate
            cancelledTooLatePayout
            cancelledDate
            spaceID
            renterID
            hostID
            bookingRequestId
            paymentID
            bookedDays
            check_in
            check_out
            stripePaymentMethodID
            stripeCustomerID
            stripeHostConnectedAccountId
            hostEmail
            renterEmail
            createdAt
            updatedAt
            baseBookingCost
            renterFeeCost
            renterTotalBookingCost
            renterStripeFee
            hostFeeCost
            hostPayout
            hookhubPayout
            paymentRequiresAction
            payMonthly
            numberOfMonths
            paymentSchedule
            nextPaymentsSchedule
            nextPaymentDue
            monthlyPaymentAmount
            monthlyHostPayout
            monthlyHookhubPayout
            cancelledFinalHostPayout
            cancelledFinalRenterPayment
            cancelledBeforeCheckIn
            refundRenter
            full_refund_enabled
            no_refund_enabled
            half_refund_enabled
            full_refund_date
            half_refund_date
            stripePaymentIntentID
            _version
            __typename
          }
          baseBookingCost
          renterFeeCost
          renterTotalBookingCost
          renterStripeFee
          hostFeeCost
          hostPayout
          hookhubPayout
          createdAt
          updatedAt
          _version
          __typename
        }
        nextToken
        __typename
      }
      createdAt
      _version
      updatedAt
      __typename
    }
  }
`;
export const deleteUser = /* GraphQL */ `
  mutation DeleteUser(
    $input: DeleteUserInput!
    $condition: ModelUserConditionInput
  ) {
    deleteUser(input: $input, condition: $condition) {
      id
      name
      email
      phoneNumber
      rig {
        items {
          id
          ownerID
          image
          length
          width
          year
          make
          model
          _version
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      avatar
      about
      myHostedSpaces {
        items {
          id
          propertyID
          ownerID
          bookings {
            nextToken
            __typename
          }
          bookingRequests {
            nextToken
            __typename
          }
          space_approved
          active
          title
          description
          unavailable_days
          set_availability
          longest_stay_allowed
          shortest_stay_allowed
          width
          length
          electric_hookup
          water_hookup
          sewage_hookup
          wifi_available
          wifi_password
          wifi_network_name
          shower_available
          restroom_available
          food_nearby
          restaurant_nearby
          water_accessible
          easy_entry
          pre_booking_message_required
          pre_booking_message
          instant_book
          distance_electric_hookup
          distance_water_hookup
          distance_sewage_hookup
          parking_spaces
          additional_rules
          parties_allowed
          things_to_do
          images
          laundry_available
          pool_available
          hottub_available
          outdoor_area_available
          indoor_area_available
          trash_available
          extra_amenities_available
          host_acknowledge_liability
          host_acknowledge_tax_responsibility
          check_in_time
          check_out_time
          check_in_instructions
          space_rules
          pets_allowed
          smoking_allowed
          adults_only
          quiet_hours_start
          quiet_hours_end
          outdoor_fires
          profile_photo_required
          full_refund_date
          half_refund_date
          price_storage
          price_shortterm
          price_longterm
          price_yearly
          _version
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      myHostedProperties {
        items {
          id
          active
          ownerID
          spaces {
            nextToken
            __typename
          }
          title
          description
          address {
            id
            address
            cityName
            stateName
            zipcode
            countryName
            canadianCivicAddress
            _version
            createdAt
            updatedAt
            owner
            __typename
          }
          coordinates_lat
          coordinates_lng
          type_storage
          type_longterm
          type_shortterm
          type_yearly
          price_storage
          price_shortterm
          price_longterm
          price_yearly
          instant_book
          images
          reviews {
            nextToken
            __typename
          }
          conversations {
            nextToken
            __typename
          }
          _version
          createdAt
          updatedAt
          propertyAddressId
          __typename
        }
        nextToken
        __typename
      }
      favorites
      stripePayoutsEnabled
      stripeConnectedAccountId
      stripeCustomerID
      stripePaymentMethodID
      govID_verified
      bookings {
        items {
          id
          members
          cancelled
          cancelledTooLate
          cancelledTooLatePayout
          cancelledDate
          spaceID
          renterID
          hostID
          bookingRequestId
          bookingRequest {
            id
            members
            confirmed
            reviewed
            cancelled
            rentalType
            stripePaymentMethodID
            stripeCustomerID
            stripePaymentIntentID
            check_in
            check_out
            bookedDays
            spaceID
            spaceTitle
            spaceCity
            spaceState
            propertyTitle
            propertyID
            renterID
            bookingID
            hostID
            hostEmail
            renterEmail
            createdAt
            updatedAt
            renter_acknowledge_liability
            baseBookingCost
            renterFeeCost
            renterTotalBookingCost
            renterStripeFee
            hostFeeCost
            hostPayout
            hookhubPayout
            payMonthly
            numberOfMonths
            paymentSchedule
            nextPaymentDue
            nextPaymentsSchedule
            monthlyPaymentAmount
            monthlyHookhubPayout
            monthlyHostPayout
            full_refund_enabled
            no_refund_enabled
            half_refund_enabled
            full_refund_date
            half_refund_date
            errorCode
            _version
            bookingRequestConversationId
            __typename
          }
          paymentID
          payment {
            id
            members
            date
            stripePaymentMethodID
            stripeCustomerID
            paid
            hostID
            bookingId
            baseBookingCost
            renterFeeCost
            renterTotalBookingCost
            renterStripeFee
            hostFeeCost
            hostPayout
            hookhubPayout
            createdAt
            updatedAt
            _version
            __typename
          }
          bookedDays
          check_in
          check_out
          stripePaymentMethodID
          stripeCustomerID
          stripeHostConnectedAccountId
          hostEmail
          renterEmail
          createdAt
          updatedAt
          baseBookingCost
          renterFeeCost
          renterTotalBookingCost
          renterStripeFee
          hostFeeCost
          hostPayout
          hookhubPayout
          paymentRequiresAction
          payMonthly
          numberOfMonths
          paymentSchedule
          nextPaymentsSchedule
          nextPaymentDue
          monthlyPaymentAmount
          monthlyHostPayout
          monthlyHookhubPayout
          cancelledFinalHostPayout
          cancelledFinalRenterPayment
          cancelledBeforeCheckIn
          refundRenter
          full_refund_enabled
          no_refund_enabled
          half_refund_enabled
          full_refund_date
          half_refund_date
          stripePaymentIntentID
          _version
          __typename
        }
        nextToken
        __typename
      }
      bookingRequests {
        items {
          id
          members
          confirmed
          reviewed
          cancelled
          rentalType
          stripePaymentMethodID
          stripeCustomerID
          stripePaymentIntentID
          check_in
          check_out
          bookedDays
          spaceID
          spaceTitle
          spaceCity
          spaceState
          propertyTitle
          propertyID
          renterID
          bookingID
          booking {
            id
            members
            cancelled
            cancelledTooLate
            cancelledTooLatePayout
            cancelledDate
            spaceID
            renterID
            hostID
            bookingRequestId
            paymentID
            bookedDays
            check_in
            check_out
            stripePaymentMethodID
            stripeCustomerID
            stripeHostConnectedAccountId
            hostEmail
            renterEmail
            createdAt
            updatedAt
            baseBookingCost
            renterFeeCost
            renterTotalBookingCost
            renterStripeFee
            hostFeeCost
            hostPayout
            hookhubPayout
            paymentRequiresAction
            payMonthly
            numberOfMonths
            paymentSchedule
            nextPaymentsSchedule
            nextPaymentDue
            monthlyPaymentAmount
            monthlyHostPayout
            monthlyHookhubPayout
            cancelledFinalHostPayout
            cancelledFinalRenterPayment
            cancelledBeforeCheckIn
            refundRenter
            full_refund_enabled
            no_refund_enabled
            half_refund_enabled
            full_refund_date
            half_refund_date
            stripePaymentIntentID
            _version
            __typename
          }
          hostID
          hostEmail
          renterEmail
          conversation {
            id
            hostee
            host
            convoCreatorsEmail
            otherMembersEmail
            spaceID
            spaceTitle
            propertyID
            propertyTitle
            propertyLocation
            bookingRequestID
            members
            membersNames
            unreadHost
            unreadHostee
            type
            createdAt
            updatedAt
            _version
            __typename
          }
          createdAt
          updatedAt
          renter_acknowledge_liability
          baseBookingCost
          renterFeeCost
          renterTotalBookingCost
          renterStripeFee
          hostFeeCost
          hostPayout
          hookhubPayout
          payMonthly
          numberOfMonths
          paymentSchedule
          nextPaymentDue
          nextPaymentsSchedule
          monthlyPaymentAmount
          monthlyHookhubPayout
          monthlyHostPayout
          full_refund_enabled
          no_refund_enabled
          half_refund_enabled
          full_refund_date
          half_refund_date
          errorCode
          _version
          bookingRequestConversationId
          __typename
        }
        nextToken
        __typename
      }
      bookingsHosted {
        items {
          id
          members
          cancelled
          cancelledTooLate
          cancelledTooLatePayout
          cancelledDate
          spaceID
          renterID
          hostID
          bookingRequestId
          bookingRequest {
            id
            members
            confirmed
            reviewed
            cancelled
            rentalType
            stripePaymentMethodID
            stripeCustomerID
            stripePaymentIntentID
            check_in
            check_out
            bookedDays
            spaceID
            spaceTitle
            spaceCity
            spaceState
            propertyTitle
            propertyID
            renterID
            bookingID
            hostID
            hostEmail
            renterEmail
            createdAt
            updatedAt
            renter_acknowledge_liability
            baseBookingCost
            renterFeeCost
            renterTotalBookingCost
            renterStripeFee
            hostFeeCost
            hostPayout
            hookhubPayout
            payMonthly
            numberOfMonths
            paymentSchedule
            nextPaymentDue
            nextPaymentsSchedule
            monthlyPaymentAmount
            monthlyHookhubPayout
            monthlyHostPayout
            full_refund_enabled
            no_refund_enabled
            half_refund_enabled
            full_refund_date
            half_refund_date
            errorCode
            _version
            bookingRequestConversationId
            __typename
          }
          paymentID
          payment {
            id
            members
            date
            stripePaymentMethodID
            stripeCustomerID
            paid
            hostID
            bookingId
            baseBookingCost
            renterFeeCost
            renterTotalBookingCost
            renterStripeFee
            hostFeeCost
            hostPayout
            hookhubPayout
            createdAt
            updatedAt
            _version
            __typename
          }
          bookedDays
          check_in
          check_out
          stripePaymentMethodID
          stripeCustomerID
          stripeHostConnectedAccountId
          hostEmail
          renterEmail
          createdAt
          updatedAt
          baseBookingCost
          renterFeeCost
          renterTotalBookingCost
          renterStripeFee
          hostFeeCost
          hostPayout
          hookhubPayout
          paymentRequiresAction
          payMonthly
          numberOfMonths
          paymentSchedule
          nextPaymentsSchedule
          nextPaymentDue
          monthlyPaymentAmount
          monthlyHostPayout
          monthlyHookhubPayout
          cancelledFinalHostPayout
          cancelledFinalRenterPayment
          cancelledBeforeCheckIn
          refundRenter
          full_refund_enabled
          no_refund_enabled
          half_refund_enabled
          full_refund_date
          half_refund_date
          stripePaymentIntentID
          _version
          __typename
        }
        nextToken
        __typename
      }
      reviewsOfRenter {
        items {
          id
          rating
          cleanlinessRating
          communicationRating
          overallRating
          description
          renterID
          reviewerName
          reviewerAvatar
          reviewerID
          bookingID
          propertyID
          _version
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      hosteeConvos {
        items {
          id
          hostee
          host
          convoCreatorsEmail
          otherMembersEmail
          spaceID
          spaceTitle
          propertyID
          propertyTitle
          propertyLocation
          bookingRequestID
          bookingRequest {
            id
            members
            confirmed
            reviewed
            cancelled
            rentalType
            stripePaymentMethodID
            stripeCustomerID
            stripePaymentIntentID
            check_in
            check_out
            bookedDays
            spaceID
            spaceTitle
            spaceCity
            spaceState
            propertyTitle
            propertyID
            renterID
            bookingID
            hostID
            hostEmail
            renterEmail
            createdAt
            updatedAt
            renter_acknowledge_liability
            baseBookingCost
            renterFeeCost
            renterTotalBookingCost
            renterStripeFee
            hostFeeCost
            hostPayout
            hookhubPayout
            payMonthly
            numberOfMonths
            paymentSchedule
            nextPaymentDue
            nextPaymentsSchedule
            monthlyPaymentAmount
            monthlyHookhubPayout
            monthlyHostPayout
            full_refund_enabled
            no_refund_enabled
            half_refund_enabled
            full_refund_date
            half_refund_date
            errorCode
            _version
            bookingRequestConversationId
            __typename
          }
          messages {
            nextToken
            __typename
          }
          members
          membersNames
          unreadHost
          unreadHostee
          type
          createdAt
          updatedAt
          _version
          __typename
        }
        nextToken
        __typename
      }
      hostConvos {
        items {
          id
          hostee
          host
          convoCreatorsEmail
          otherMembersEmail
          spaceID
          spaceTitle
          propertyID
          propertyTitle
          propertyLocation
          bookingRequestID
          bookingRequest {
            id
            members
            confirmed
            reviewed
            cancelled
            rentalType
            stripePaymentMethodID
            stripeCustomerID
            stripePaymentIntentID
            check_in
            check_out
            bookedDays
            spaceID
            spaceTitle
            spaceCity
            spaceState
            propertyTitle
            propertyID
            renterID
            bookingID
            hostID
            hostEmail
            renterEmail
            createdAt
            updatedAt
            renter_acknowledge_liability
            baseBookingCost
            renterFeeCost
            renterTotalBookingCost
            renterStripeFee
            hostFeeCost
            hostPayout
            hookhubPayout
            payMonthly
            numberOfMonths
            paymentSchedule
            nextPaymentDue
            nextPaymentsSchedule
            monthlyPaymentAmount
            monthlyHookhubPayout
            monthlyHostPayout
            full_refund_enabled
            no_refund_enabled
            half_refund_enabled
            full_refund_date
            half_refund_date
            errorCode
            _version
            bookingRequestConversationId
            __typename
          }
          messages {
            nextToken
            __typename
          }
          members
          membersNames
          unreadHost
          unreadHostee
          type
          createdAt
          updatedAt
          _version
          __typename
        }
        nextToken
        __typename
      }
      payments {
        items {
          id
          members
          date
          stripePaymentMethodID
          stripeCustomerID
          paid
          hostID
          bookingId
          booking {
            id
            members
            cancelled
            cancelledTooLate
            cancelledTooLatePayout
            cancelledDate
            spaceID
            renterID
            hostID
            bookingRequestId
            paymentID
            bookedDays
            check_in
            check_out
            stripePaymentMethodID
            stripeCustomerID
            stripeHostConnectedAccountId
            hostEmail
            renterEmail
            createdAt
            updatedAt
            baseBookingCost
            renterFeeCost
            renterTotalBookingCost
            renterStripeFee
            hostFeeCost
            hostPayout
            hookhubPayout
            paymentRequiresAction
            payMonthly
            numberOfMonths
            paymentSchedule
            nextPaymentsSchedule
            nextPaymentDue
            monthlyPaymentAmount
            monthlyHostPayout
            monthlyHookhubPayout
            cancelledFinalHostPayout
            cancelledFinalRenterPayment
            cancelledBeforeCheckIn
            refundRenter
            full_refund_enabled
            no_refund_enabled
            half_refund_enabled
            full_refund_date
            half_refund_date
            stripePaymentIntentID
            _version
            __typename
          }
          baseBookingCost
          renterFeeCost
          renterTotalBookingCost
          renterStripeFee
          hostFeeCost
          hostPayout
          hookhubPayout
          createdAt
          updatedAt
          _version
          __typename
        }
        nextToken
        __typename
      }
      createdAt
      _version
      updatedAt
      __typename
    }
  }
`;
export const createRig = /* GraphQL */ `
  mutation CreateRig(
    $input: CreateRigInput!
    $condition: ModelRigConditionInput
  ) {
    createRig(input: $input, condition: $condition) {
      id
      ownerID
      image
      length
      width
      year
      make
      model
      _version
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateRig = /* GraphQL */ `
  mutation UpdateRig(
    $input: UpdateRigInput!
    $condition: ModelRigConditionInput
  ) {
    updateRig(input: $input, condition: $condition) {
      id
      ownerID
      image
      length
      width
      year
      make
      model
      _version
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteRig = /* GraphQL */ `
  mutation DeleteRig(
    $input: DeleteRigInput!
    $condition: ModelRigConditionInput
  ) {
    deleteRig(input: $input, condition: $condition) {
      id
      ownerID
      image
      length
      width
      year
      make
      model
      _version
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createSpace = /* GraphQL */ `
  mutation CreateSpace(
    $input: CreateSpaceInput!
    $condition: ModelSpaceConditionInput
  ) {
    createSpace(input: $input, condition: $condition) {
      id
      propertyID
      ownerID
      space_approved
      active
      title
      description
      unavailable_days
      set_availability
      longest_stay_allowed
      shortest_stay_allowed
      width
      length
      electric_hookup
      water_hookup
      sewage_hookup
      wifi_available
      wifi_password
      wifi_network_name
      shower_available
      restroom_available
      food_nearby
      restaurant_nearby
      water_accessible
      easy_entry
      pre_booking_message_required
      pre_booking_message
      instant_book
      distance_electric_hookup
      distance_water_hookup
      distance_sewage_hookup
      parking_spaces
      additional_rules
      parties_allowed
      things_to_do
      images
      laundry_available
      pool_available
      hottub_available
      outdoor_area_available
      indoor_area_available
      trash_available
      extra_amenities_available
      host_acknowledge_liability
      host_acknowledge_tax_responsibility
      check_in_time
      check_out_time
      check_in_instructions
      space_rules
      pets_allowed
      smoking_allowed
      adults_only
      quiet_hours_start
      quiet_hours_end
      outdoor_fires
      profile_photo_required
      full_refund_date
      half_refund_date
      price_storage
      price_shortterm
      price_longterm
      price_yearly
      _version
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateSpace = /* GraphQL */ `
  mutation UpdateSpace(
    $input: UpdateSpaceInput!
    $condition: ModelSpaceConditionInput
  ) {
    updateSpace(input: $input, condition: $condition) {
      id
      propertyID
      ownerID
      space_approved
      active
      title
      description
      unavailable_days
      set_availability
      longest_stay_allowed
      shortest_stay_allowed
      width
      length
      electric_hookup
      water_hookup
      sewage_hookup
      wifi_available
      wifi_password
      wifi_network_name
      shower_available
      restroom_available
      food_nearby
      restaurant_nearby
      water_accessible
      easy_entry
      pre_booking_message_required
      pre_booking_message
      instant_book
      distance_electric_hookup
      distance_water_hookup
      distance_sewage_hookup
      parking_spaces
      additional_rules
      parties_allowed
      things_to_do
      images
      laundry_available
      pool_available
      hottub_available
      outdoor_area_available
      indoor_area_available
      trash_available
      extra_amenities_available
      host_acknowledge_liability
      host_acknowledge_tax_responsibility
      check_in_time
      check_out_time
      check_in_instructions
      space_rules
      pets_allowed
      smoking_allowed
      adults_only
      quiet_hours_start
      quiet_hours_end
      outdoor_fires
      profile_photo_required
      full_refund_date
      half_refund_date
      price_storage
      price_shortterm
      price_longterm
      price_yearly
      _version
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteSpace = /* GraphQL */ `
  mutation DeleteSpace(
    $input: DeleteSpaceInput!
    $condition: ModelSpaceConditionInput
  ) {
    deleteSpace(input: $input, condition: $condition) {
      id
      propertyID
      ownerID
      space_approved
      active
      title
      description
      unavailable_days
      set_availability
      longest_stay_allowed
      shortest_stay_allowed
      width
      length
      electric_hookup
      water_hookup
      sewage_hookup
      wifi_available
      wifi_password
      wifi_network_name
      shower_available
      restroom_available
      food_nearby
      restaurant_nearby
      water_accessible
      easy_entry
      pre_booking_message_required
      pre_booking_message
      instant_book
      distance_electric_hookup
      distance_water_hookup
      distance_sewage_hookup
      parking_spaces
      additional_rules
      parties_allowed
      things_to_do
      images
      laundry_available
      pool_available
      hottub_available
      outdoor_area_available
      indoor_area_available
      trash_available
      extra_amenities_available
      host_acknowledge_liability
      host_acknowledge_tax_responsibility
      check_in_time
      check_out_time
      check_in_instructions
      space_rules
      pets_allowed
      smoking_allowed
      adults_only
      quiet_hours_start
      quiet_hours_end
      outdoor_fires
      profile_photo_required
      full_refund_date
      half_refund_date
      price_storage
      price_shortterm
      price_longterm
      price_yearly
      _version
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createProperty = /* GraphQL */ `
  mutation CreateProperty(
    $input: CreatePropertyInput!
    $condition: ModelPropertyConditionInput
  ) {
    createProperty(input: $input, condition: $condition) {
      id
      active
      ownerID
      spaces {
        items {
          id
          propertyID
          ownerID
          bookings {
            nextToken
            __typename
          }
          bookingRequests {
            nextToken
            __typename
          }
          space_approved
          active
          title
          description
          unavailable_days
          set_availability
          longest_stay_allowed
          shortest_stay_allowed
          width
          length
          electric_hookup
          water_hookup
          sewage_hookup
          wifi_available
          wifi_password
          wifi_network_name
          shower_available
          restroom_available
          food_nearby
          restaurant_nearby
          water_accessible
          easy_entry
          pre_booking_message_required
          pre_booking_message
          instant_book
          distance_electric_hookup
          distance_water_hookup
          distance_sewage_hookup
          parking_spaces
          additional_rules
          parties_allowed
          things_to_do
          images
          laundry_available
          pool_available
          hottub_available
          outdoor_area_available
          indoor_area_available
          trash_available
          extra_amenities_available
          host_acknowledge_liability
          host_acknowledge_tax_responsibility
          check_in_time
          check_out_time
          check_in_instructions
          space_rules
          pets_allowed
          smoking_allowed
          adults_only
          quiet_hours_start
          quiet_hours_end
          outdoor_fires
          profile_photo_required
          full_refund_date
          half_refund_date
          price_storage
          price_shortterm
          price_longterm
          price_yearly
          _version
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      title
      description
      coordinates_lat
      coordinates_lng
      type_storage
      type_longterm
      type_shortterm
      type_yearly
      price_storage
      price_shortterm
      price_longterm
      price_yearly
      instant_book
      images
      _version
      createdAt
      updatedAt
      propertyAddressId
      __typename
    }
  }
`;
export const updateProperty = /* GraphQL */ `
  mutation UpdateProperty(
    $input: UpdatePropertyInput!
    $condition: ModelPropertyConditionInput
  ) {
    updateProperty(input: $input, condition: $condition) {
      id
      active
      ownerID
      spaces {
        items {
          id
          propertyID
          ownerID
          bookings {
            nextToken
            __typename
          }
          bookingRequests {
            nextToken
            __typename
          }
          space_approved
          active
          title
          description
          unavailable_days
          set_availability
          longest_stay_allowed
          shortest_stay_allowed
          width
          length
          electric_hookup
          water_hookup
          sewage_hookup
          wifi_available
          wifi_password
          wifi_network_name
          shower_available
          restroom_available
          food_nearby
          restaurant_nearby
          water_accessible
          easy_entry
          pre_booking_message_required
          pre_booking_message
          instant_book
          distance_electric_hookup
          distance_water_hookup
          distance_sewage_hookup
          parking_spaces
          additional_rules
          parties_allowed
          things_to_do
          images
          laundry_available
          pool_available
          hottub_available
          outdoor_area_available
          indoor_area_available
          trash_available
          extra_amenities_available
          host_acknowledge_liability
          host_acknowledge_tax_responsibility
          check_in_time
          check_out_time
          check_in_instructions
          space_rules
          pets_allowed
          smoking_allowed
          adults_only
          quiet_hours_start
          quiet_hours_end
          outdoor_fires
          profile_photo_required
          full_refund_date
          half_refund_date
          price_storage
          price_shortterm
          price_longterm
          price_yearly
          _version
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      title
      description
      coordinates_lat
      coordinates_lng
      type_storage
      type_longterm
      type_shortterm
      type_yearly
      price_storage
      price_shortterm
      price_longterm
      price_yearly
      instant_book
      images
      _version
      createdAt
      updatedAt
      propertyAddressId
      __typename
    }
  }
`;
export const deleteProperty = /* GraphQL */ `
  mutation DeleteProperty(
    $input: DeletePropertyInput!
    $condition: ModelPropertyConditionInput
  ) {
    deleteProperty(input: $input, condition: $condition) {
      id
      active
      ownerID
      spaces {
        items {
          id
          propertyID
          ownerID
          bookings {
            nextToken
            __typename
          }
          bookingRequests {
            nextToken
            __typename
          }
          space_approved
          active
          title
          description
          unavailable_days
          set_availability
          longest_stay_allowed
          shortest_stay_allowed
          width
          length
          electric_hookup
          water_hookup
          sewage_hookup
          wifi_available
          wifi_password
          wifi_network_name
          shower_available
          restroom_available
          food_nearby
          restaurant_nearby
          water_accessible
          easy_entry
          pre_booking_message_required
          pre_booking_message
          instant_book
          distance_electric_hookup
          distance_water_hookup
          distance_sewage_hookup
          parking_spaces
          additional_rules
          parties_allowed
          things_to_do
          images
          laundry_available
          pool_available
          hottub_available
          outdoor_area_available
          indoor_area_available
          trash_available
          extra_amenities_available
          host_acknowledge_liability
          host_acknowledge_tax_responsibility
          check_in_time
          check_out_time
          check_in_instructions
          space_rules
          pets_allowed
          smoking_allowed
          adults_only
          quiet_hours_start
          quiet_hours_end
          outdoor_fires
          profile_photo_required
          full_refund_date
          half_refund_date
          price_storage
          price_shortterm
          price_longterm
          price_yearly
          _version
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      title
      description
      address {
        id
        address
        cityName
        stateName
        zipcode
        countryName
        canadianCivicAddress
        _version
        createdAt
        updatedAt
        owner
        __typename
      }
      coordinates_lat
      coordinates_lng
      type_storage
      type_longterm
      type_shortterm
      type_yearly
      price_storage
      price_shortterm
      price_longterm
      price_yearly
      instant_book
      images
      reviews {
        items {
          id
          rating
          locationRating
          communicationRating
          checkinRating
          valueRating
          description
          propertyID
          reviewerName
          reviewerAvatar
          reviewerID
          bookingID
          images
          _version
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      conversations {
        items {
          id
          hostee
          host
          convoCreatorsEmail
          otherMembersEmail
          spaceID
          spaceTitle
          propertyID
          propertyTitle
          propertyLocation
          bookingRequestID
          bookingRequest {
            id
            members
            confirmed
            reviewed
            cancelled
            rentalType
            stripePaymentMethodID
            stripeCustomerID
            stripePaymentIntentID
            check_in
            check_out
            bookedDays
            spaceID
            spaceTitle
            spaceCity
            spaceState
            propertyTitle
            propertyID
            renterID
            bookingID
            hostID
            hostEmail
            renterEmail
            createdAt
            updatedAt
            renter_acknowledge_liability
            baseBookingCost
            renterFeeCost
            renterTotalBookingCost
            renterStripeFee
            hostFeeCost
            hostPayout
            hookhubPayout
            payMonthly
            numberOfMonths
            paymentSchedule
            nextPaymentDue
            nextPaymentsSchedule
            monthlyPaymentAmount
            monthlyHookhubPayout
            monthlyHostPayout
            full_refund_enabled
            no_refund_enabled
            half_refund_enabled
            full_refund_date
            half_refund_date
            errorCode
            _version
            bookingRequestConversationId
            __typename
          }
          messages {
            nextToken
            __typename
          }
          members
          membersNames
          unreadHost
          unreadHostee
          type
          createdAt
          updatedAt
          _version
          __typename
        }
        nextToken
        __typename
      }
      _version
      createdAt
      updatedAt
      propertyAddressId
      __typename
    }
  }
`;
export const createAddress = /* GraphQL */ `
  mutation CreateAddress(
    $input: CreateAddressInput!
    $condition: ModelAddressConditionInput
  ) {
    createAddress(input: $input, condition: $condition) {
      id
      address
      cityName
      stateName
      zipcode
      countryName
      canadianCivicAddress
      _version
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const updateAddress = /* GraphQL */ `
  mutation UpdateAddress(
    $input: UpdateAddressInput!
    $condition: ModelAddressConditionInput
  ) {
    updateAddress(input: $input, condition: $condition) {
      id
      address
      cityName
      stateName
      zipcode
      countryName
      canadianCivicAddress
      _version
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const deleteAddress = /* GraphQL */ `
  mutation DeleteAddress(
    $input: DeleteAddressInput!
    $condition: ModelAddressConditionInput
  ) {
    deleteAddress(input: $input, condition: $condition) {
      id
      address
      cityName
      stateName
      zipcode
      countryName
      canadianCivicAddress
      _version
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const createBooking = /* GraphQL */ `
  mutation CreateBooking(
    $input: CreateBookingInput!
    $condition: ModelBookingConditionInput
  ) {
    createBooking(input: $input, condition: $condition) {
      id
      members
      cancelled
      cancelledTooLate
      cancelledTooLatePayout
      cancelledDate
      spaceID
      renterID
      hostID
      bookingRequestId
      paymentID
      bookedDays
      check_in
      check_out
      stripePaymentMethodID
      stripeCustomerID
      stripeHostConnectedAccountId
      hostEmail
      renterEmail
      createdAt
      updatedAt
      baseBookingCost
      renterFeeCost
      renterTotalBookingCost
      renterStripeFee
      hostFeeCost
      hostPayout
      hookhubPayout
      paymentRequiresAction
      payMonthly
      numberOfMonths
      paymentSchedule
      nextPaymentsSchedule
      nextPaymentDue
      monthlyPaymentAmount
      monthlyHostPayout
      monthlyHookhubPayout
      cancelledFinalHostPayout
      cancelledFinalRenterPayment
      cancelledBeforeCheckIn
      refundRenter
      full_refund_enabled
      no_refund_enabled
      half_refund_enabled
      full_refund_date
      half_refund_date
      stripePaymentIntentID
      _version
      __typename
    }
  }
`;
export const updateBooking = /* GraphQL */ `
  mutation UpdateBooking(
    $input: UpdateBookingInput!
    $condition: ModelBookingConditionInput
  ) {
    updateBooking(input: $input, condition: $condition) {
      id
      members
      cancelled
      cancelledTooLate
      cancelledTooLatePayout
      cancelledDate
      spaceID
      renterID
      hostID
      bookingRequestId
      paymentID
      bookedDays
      check_in
      check_out
      stripePaymentMethodID
      stripeCustomerID
      stripeHostConnectedAccountId
      hostEmail
      renterEmail
      createdAt
      updatedAt
      baseBookingCost
      renterFeeCost
      renterTotalBookingCost
      renterStripeFee
      hostFeeCost
      hostPayout
      hookhubPayout
      paymentRequiresAction
      payMonthly
      numberOfMonths
      paymentSchedule
      nextPaymentsSchedule
      nextPaymentDue
      monthlyPaymentAmount
      monthlyHostPayout
      monthlyHookhubPayout
      cancelledFinalHostPayout
      cancelledFinalRenterPayment
      cancelledBeforeCheckIn
      refundRenter
      full_refund_enabled
      no_refund_enabled
      half_refund_enabled
      full_refund_date
      half_refund_date
      stripePaymentIntentID
      _version
      __typename
    }
  }
`;
export const deleteBooking = /* GraphQL */ `
  mutation DeleteBooking(
    $input: DeleteBookingInput!
    $condition: ModelBookingConditionInput
  ) {
    deleteBooking(input: $input, condition: $condition) {
      id
      members
      cancelled
      cancelledTooLate
      cancelledTooLatePayout
      cancelledDate
      spaceID
      renterID
      hostID
      bookingRequestId
      paymentID
      bookedDays
      check_in
      check_out
      stripePaymentMethodID
      stripeCustomerID
      stripeHostConnectedAccountId
      hostEmail
      renterEmail
      createdAt
      updatedAt
      baseBookingCost
      renterFeeCost
      renterTotalBookingCost
      renterStripeFee
      hostFeeCost
      hostPayout
      hookhubPayout
      paymentRequiresAction
      payMonthly
      numberOfMonths
      paymentSchedule
      nextPaymentsSchedule
      nextPaymentDue
      monthlyPaymentAmount
      monthlyHostPayout
      monthlyHookhubPayout
      cancelledFinalHostPayout
      cancelledFinalRenterPayment
      cancelledBeforeCheckIn
      refundRenter
      full_refund_enabled
      no_refund_enabled
      half_refund_enabled
      full_refund_date
      half_refund_date
      stripePaymentIntentID
      _version
      __typename
    }
  }
`;
export const createBookingRequest = /* GraphQL */ `
  mutation CreateBookingRequest(
    $input: CreateBookingRequestInput!
    $condition: ModelBookingRequestConditionInput
  ) {
    createBookingRequest(input: $input, condition: $condition) {
      id
      members
      confirmed
      reviewed
      cancelled
      rentalType
      stripePaymentMethodID
      stripeCustomerID
      stripePaymentIntentID
      check_in
      check_out
      bookedDays
      spaceID
      spaceTitle
      spaceCity
      spaceState
      propertyTitle
      propertyID
      renterID
      hostID
      hostEmail
      renterEmail
      createdAt
      updatedAt
      renter_acknowledge_liability
      baseBookingCost
      renterFeeCost
      renterTotalBookingCost
      renterStripeFee
      hostFeeCost
      hostPayout
      hookhubPayout
      payMonthly
      numberOfMonths
      paymentSchedule
      nextPaymentDue
      nextPaymentsSchedule
      monthlyPaymentAmount
      monthlyHookhubPayout
      monthlyHostPayout
      full_refund_enabled
      no_refund_enabled
      half_refund_enabled
      full_refund_date
      half_refund_date
      errorCode
      _version
      bookingRequestConversationId
      __typename
    }
  }
`;
export const updateBookingRequest = /* GraphQL */ `
  mutation UpdateBookingRequest(
    $input: UpdateBookingRequestInput!
    $condition: ModelBookingRequestConditionInput
  ) {
    updateBookingRequest(input: $input, condition: $condition) {
      id
      members
      confirmed
      reviewed
      cancelled
      rentalType
      stripePaymentMethodID
      stripeCustomerID
      stripePaymentIntentID
      check_in
      check_out
      bookedDays
      spaceID
      spaceTitle
      spaceCity
      spaceState
      propertyTitle
      propertyID
      renterID
      bookingID
      hostID
      hostEmail
      renterEmail
      createdAt
      updatedAt
      renter_acknowledge_liability
      baseBookingCost
      renterFeeCost
      renterTotalBookingCost
      renterStripeFee
      hostFeeCost
      hostPayout
      hookhubPayout
      payMonthly
      numberOfMonths
      paymentSchedule
      nextPaymentDue
      nextPaymentsSchedule
      monthlyPaymentAmount
      monthlyHookhubPayout
      monthlyHostPayout
      full_refund_enabled
      no_refund_enabled
      half_refund_enabled
      full_refund_date
      half_refund_date
      errorCode
      _version
      bookingRequestConversationId
      __typename
    }
  }
`;
export const deleteBookingRequest = /* GraphQL */ `
  mutation DeleteBookingRequest(
    $input: DeleteBookingRequestInput!
    $condition: ModelBookingRequestConditionInput
  ) {
    deleteBookingRequest(input: $input, condition: $condition) {
      id
      members
      confirmed
      reviewed
      cancelled
      rentalType
      stripePaymentMethodID
      stripeCustomerID
      stripePaymentIntentID
      check_in
      check_out
      bookedDays
      spaceID
      spaceTitle
      spaceCity
      spaceState
      propertyTitle
      propertyID
      renterID
      bookingID
      hostID
      hostEmail
      renterEmail
      createdAt
      updatedAt
      renter_acknowledge_liability
      baseBookingCost
      renterFeeCost
      renterTotalBookingCost
      renterStripeFee
      hostFeeCost
      hostPayout
      hookhubPayout
      payMonthly
      numberOfMonths
      paymentSchedule
      nextPaymentDue
      nextPaymentsSchedule
      monthlyPaymentAmount
      monthlyHookhubPayout
      monthlyHostPayout
      full_refund_enabled
      no_refund_enabled
      half_refund_enabled
      full_refund_date
      half_refund_date
      errorCode
      _version
      bookingRequestConversationId
      __typename
    }
  }
`;
export const createPayment = /* GraphQL */ `
  mutation CreatePayment(
    $input: CreatePaymentInput!
    $condition: ModelPaymentConditionInput
  ) {
    createPayment(input: $input, condition: $condition) {
      id
      members
      date
      stripePaymentMethodID
      stripeCustomerID
      paid
      hostID
      bookingId
      baseBookingCost
      renterFeeCost
      renterTotalBookingCost
      renterStripeFee
      hostFeeCost
      hostPayout
      hookhubPayout
      createdAt
      updatedAt
      _version
      __typename
    }
  }
`;
export const updatePayment = /* GraphQL */ `
  mutation UpdatePayment(
    $input: UpdatePaymentInput!
    $condition: ModelPaymentConditionInput
  ) {
    updatePayment(input: $input, condition: $condition) {
      id
      members
      date
      stripePaymentMethodID
      stripeCustomerID
      paid
      hostID
      bookingId
      baseBookingCost
      renterFeeCost
      renterTotalBookingCost
      renterStripeFee
      hostFeeCost
      hostPayout
      hookhubPayout
      createdAt
      updatedAt
      _version
      __typename
    }
  }
`;
export const deletePayment = /* GraphQL */ `
  mutation DeletePayment(
    $input: DeletePaymentInput!
    $condition: ModelPaymentConditionInput
  ) {
    deletePayment(input: $input, condition: $condition) {
      id
      members
      date
      stripePaymentMethodID
      stripeCustomerID
      paid
      hostID
      bookingId
      baseBookingCost
      renterFeeCost
      renterTotalBookingCost
      renterStripeFee
      hostFeeCost
      hostPayout
      hookhubPayout
      createdAt
      updatedAt
      _version
      __typename
    }
  }
`;
export const createReview = /* GraphQL */ `
  mutation CreateReview(
    $input: CreateReviewInput!
    $condition: ModelReviewConditionInput
  ) {
    createReview(input: $input, condition: $condition) {
      id
      rating
      locationRating
      communicationRating
      checkinRating
      valueRating
      description
      propertyID
      reviewerName
      reviewerAvatar
      reviewerID
      bookingID
      images
      _version
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateReview = /* GraphQL */ `
  mutation UpdateReview(
    $input: UpdateReviewInput!
    $condition: ModelReviewConditionInput
  ) {
    updateReview(input: $input, condition: $condition) {
      id
      rating
      locationRating
      communicationRating
      checkinRating
      valueRating
      description
      propertyID
      reviewerName
      reviewerAvatar
      reviewerID
      bookingID
      images
      _version
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteReview = /* GraphQL */ `
  mutation DeleteReview(
    $input: DeleteReviewInput!
    $condition: ModelReviewConditionInput
  ) {
    deleteReview(input: $input, condition: $condition) {
      id
      rating
      locationRating
      communicationRating
      checkinRating
      valueRating
      description
      propertyID
      reviewerName
      reviewerAvatar
      reviewerID
      bookingID
      images
      _version
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createRenterReview = /* GraphQL */ `
  mutation CreateRenterReview(
    $input: CreateRenterReviewInput!
    $condition: ModelRenterReviewConditionInput
  ) {
    createRenterReview(input: $input, condition: $condition) {
      id
      rating
      cleanlinessRating
      communicationRating
      overallRating
      description
      renterID
      reviewerName
      reviewerAvatar
      reviewerID
      bookingID
      propertyID
      _version
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateRenterReview = /* GraphQL */ `
  mutation UpdateRenterReview(
    $input: UpdateRenterReviewInput!
    $condition: ModelRenterReviewConditionInput
  ) {
    updateRenterReview(input: $input, condition: $condition) {
      id
      rating
      cleanlinessRating
      communicationRating
      overallRating
      description
      renterID
      reviewerName
      reviewerAvatar
      reviewerID
      bookingID
      propertyID
      _version
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteRenterReview = /* GraphQL */ `
  mutation DeleteRenterReview(
    $input: DeleteRenterReviewInput!
    $condition: ModelRenterReviewConditionInput
  ) {
    deleteRenterReview(input: $input, condition: $condition) {
      id
      rating
      cleanlinessRating
      communicationRating
      overallRating
      description
      renterID
      reviewerName
      reviewerAvatar
      reviewerID
      bookingID
      propertyID
      _version
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createConversation = /* GraphQL */ `
  mutation CreateConversation(
    $input: CreateConversationInput!
    $condition: ModelConversationConditionInput
  ) {
    createConversation(input: $input, condition: $condition) {
      id
      hostee
      host
      convoCreatorsEmail
      otherMembersEmail
      spaceID
      spaceTitle
      propertyID
      propertyTitle
      propertyLocation
      members
      membersNames
      unreadHost
      unreadHostee
      type
      createdAt
      updatedAt
      _version
      __typename
    }
  }
`;
export const updateConversation = /* GraphQL */ `
  mutation UpdateConversation(
    $input: UpdateConversationInput!
    $condition: ModelConversationConditionInput
  ) {
    updateConversation(input: $input, condition: $condition) {
      id
      hostee
      host
      convoCreatorsEmail
      otherMembersEmail
      spaceID
      spaceTitle
      propertyID
      propertyTitle
      propertyLocation
      members
      membersNames
      unreadHost
      unreadHostee
      type
      createdAt
      updatedAt
      _version
      __typename
    }
  }
`;
export const deleteConversation = /* GraphQL */ `
  mutation DeleteConversation(
    $input: DeleteConversationInput!
    $condition: ModelConversationConditionInput
  ) {
    deleteConversation(input: $input, condition: $condition) {
      id
      hostee
      host
      convoCreatorsEmail
      otherMembersEmail
      spaceID
      spaceTitle
      propertyID
      propertyTitle
      propertyLocation
      bookingRequestID
      messages {
        items {
          id
          authorID
          authorName
          authorAvatar
          otherMembersEmail
          content
          convoID
          conversation {
            id
            hostee
            host
            convoCreatorsEmail
            otherMembersEmail
            spaceID
            spaceTitle
            propertyID
            propertyTitle
            propertyLocation
            bookingRequestID
            members
            membersNames
            unreadHost
            unreadHostee
            type
            createdAt
            updatedAt
            _version
            __typename
          }
          type
          createdAt
          updatedAt
          _version
          owner
          __typename
        }
        nextToken
        __typename
      }
      members
      membersNames
      unreadHost
      unreadHostee
      type
      createdAt
      updatedAt
      _version
      __typename
    }
  }
`;
export const createMessage = /* GraphQL */ `
  mutation CreateMessage(
    $input: CreateMessageInput!
    $condition: ModelMessageConditionInput
  ) {
    createMessage(input: $input, condition: $condition) {
      id
      authorID
      authorName
      authorAvatar
      otherMembersEmail
      content
      convoID
      conversation {
        id
        hostee
        host
        convoCreatorsEmail
        otherMembersEmail
        spaceID
        spaceTitle
        propertyID
        propertyTitle
        propertyLocation
        members
        membersNames
        unreadHost
        unreadHostee
        type
        createdAt
        updatedAt
        _version
        __typename
      }
      type
      createdAt
      updatedAt
      _version
      owner
      __typename
    }
  }
`;
export const updateMessage = /* GraphQL */ `
  mutation UpdateMessage(
    $input: UpdateMessageInput!
    $condition: ModelMessageConditionInput
  ) {
    updateMessage(input: $input, condition: $condition) {
      id
      authorID
      authorName
      authorAvatar
      otherMembersEmail
      content
      convoID
      type
      createdAt
      updatedAt
      _version
      owner
      __typename
    }
  }
`;
export const deleteMessage = /* GraphQL */ `
  mutation DeleteMessage(
    $input: DeleteMessageInput!
    $condition: ModelMessageConditionInput
  ) {
    deleteMessage(input: $input, condition: $condition) {
      id
      authorID
      authorName
      authorAvatar
      otherMembersEmail
      content
      convoID
      type
      createdAt
      updatedAt
      _version
      owner
      __typename
    }
  }
`;
