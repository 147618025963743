import React from "react";
import {
  View,
  Text,
  Image,
  StyleSheet
} from "react-native";
import moment from "moment";
import { colors } from "../../styles/colors";
import { globalStyles } from "../../styles/styles";

const Message = ({ message, myID, mobileView, width }) => {
  const TIME_FORMAT = "MM/DD, h:mm a";
  const isImSender = message.authorID === myID;
  const alignMesg = isImSender ? "flex-end" : "flex-start";

  return (
    <View
      style={[
        styles.bubbleSize,
        { alignSelf: alignMesg, width: mobileView ? width/1.5 : "90%", marginTop: 8, marginHorizontal: 5 },
      ]}
    >
      <View
        style={[
          styles.bubble,
          { backgroundColor: !isImSender ? "#78b2d6" : colors.brandGrey },
        ]}
      >
        <View style={{ justifyContent: "flex-start", alignContent: "flex-start", alignItems: "flex-start", width: "100%" }}>
          <View style={globalStyles.flexStart}>
            {message?.authorAvatar && (
              <Image
                source={{
                  uri: message.authorAvatar,
                }}
                style={{ width: 30, height: 30, borderRadius: 50, marginRight: 5 }}
              />
            )}
            <Text style={styles.senderName}>{message.authorName}</Text>
          </View>
          <Text style={[styles.messageText, { width: "100%" }]}>
            {message.content}
          </Text>
        </View>
      </View>
      <View style={styles.bubbleTime}>
        <Text
          style={[
            styles.bubbleTimeText,
            { color: !isImSender ? "#262626" : "#262626" },
          ]}
        >
          {moment(new Date(message.updatedAt)).format(TIME_FORMAT)}
        </Text>
      </View>
    </View>
  );
};

export const MsgList = ({ messages, myID, mobileView, width }) => {
  return messages.map((m, i) => <Message message={m} myID={myID} key={i} mobileView={mobileView} width={width} />);
};

const styles = StyleSheet.create({
  send: {
    backgroundColor: "royalblue",
    padding: 7,
    borderRadius: 15,
    overflow: "hidden",
  },
  buttonSend: {
    marginLeft: 12,
    justifyContent: "center",
    alignSelf: "center",
    alignItems: "center",
    width: 32,
    height: 32,
    borderRadius: 32,
    backgroundColor: "#007BFF",
  },
  memberPicture: {
    paddingRight: 12,
    alignSelf: "flex-end",
  },
  bubble: {
    paddingVertical: 7,
    paddingHorizontal: 12,
    borderRadius: 18,
    flexDirection: "row",
    alignItems: "stretch",
    flexWrap: "wrap",
  },
  bubbleSize: {
    maxWidth: "80%",
    flexDirection: "column"
  },
  senderName: {
    fontSize: 15,
    fontWeight: "500",
    marginBottom: 4,
    color: colors.amplifyNeutral90,
  },
  messageText: {
    fontSize: 17,
    lineHeight: 22,
    fontWeight: "400",
    marginLeft: 5,
    marginTop: 7
  },
  bubbleTime: {
    alignSelf: "stretch",
    flexGrow: 1,
    marginLeft: 7,
    alignItems: "flex-end",
    justifyContent: "flex-end",
  },
  bubbleTimeText: {
    position: "relative",
    lineHeight: 18,
    top: 2,
    fontSize: 13,
    paddingLeft: 8,
    fontWeight: "400",
  },
});
