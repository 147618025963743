const ENV = {
  dev: {
    googleAPI: "https://maps.googleapis.com/maps/api/js?key=AIzaSyDcc0ux0Lyvlqj0Qic6j3lWR2WQFsJrKjY&v=3.exp&libraries=places",
    geocodeAPIkey: "AIzaSyDcc0ux0Lyvlqj0Qic6j3lWR2WQFsJrKjY",
    REACT_APP_ENV: "development",
    googleMapURL: "https://maps.googleapis.com/maps/api/js?key=AIzaSyDcc0ux0Lyvlqj0Qic6j3lWR2WQFsJrKjY&v=3.exp&libraries=geometry,drawing,places",
    stripePublishableKey: "pk_test_51Md3GLBhKXVqrK8YYA2xSoERBOwdHUR7lbXCCZNG7vUHF4MbRrZkyth7FqfZoBlzI1F5lYdaES9w1ncPU5bP9LYb00hSztaSXm",
    cloudfrontURL: "https://d2np52e77ba2os.cloudfront.net/",
    MOBILEgoogleAPI: "AIzaSyCMmKn7pl9ulQNdVrV8Xg2rttKGcD9RWpY",
    cloudfrontURLStaticImages: "https://d2np52e77ba2os.cloudfront.net/",
  },
  prod: {
    googleAPI: "https://maps.googleapis.com/maps/api/js?key=AIzaSyDcc0ux0Lyvlqj0Qic6j3lWR2WQFsJrKjY&v=3.exp&libraries=places",
    geocodeAPIkey: "AIzaSyDcc0ux0Lyvlqj0Qic6j3lWR2WQFsJrKjY",
    REACT_APP_ENV: "production",
    googleMapURL: "https://maps.googleapis.com/maps/api/js?key=AIzaSyDcc0ux0Lyvlqj0Qic6j3lWR2WQFsJrKjY&v=3.exp&libraries=geometry,drawing,places",
    stripePublishableKey: "pk_live_51Md3GLBhKXVqrK8YoG2gPl8XQo0MLyRuXwjTkNMtHTxUHYqBQTBMkVbNAkQeXRUhIXG7cGYz8XU9zYrzcymkSJtD00qPWh2qBP",
    cloudfrontURL: "https://dgw47h3g4p7ew.cloudfront.net/",
    cloudfrontURLStaticImages: "https://d2np52e77ba2os.cloudfront.net/",
    MOBILEgoogleAPI: "AIzaSyCMmKn7pl9ulQNdVrV8Xg2rttKGcD9RWpY"
  },
};

const getEnvVars = () => {
  if (__DEV__) {
    return ENV.dev;
  } else {
    return ENV.prod;
  }
};

export default getEnvVars;
