import { useContext } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigation } from "@react-navigation/native";
import { UserDataContext } from "../../utils/UserContextProvider";
import { Auth } from "aws-amplify";
import { DrawerContentScrollView, DrawerItem, DrawerItemList } from "@react-navigation/drawer";
import { RESETAPP } from "../../redux/reducers/reducers";
import {
  MaterialIcons,
} from "@expo/vector-icons";
import CustomDivider from "../CustomDivider";

export function CustomDrawerContent(props) {
  const dispatch = useDispatch();
  const navigation = useNavigation();
  const { user } = useContext(UserDataContext);
  const { setUserData } = user;
  const { conversations } = useSelector((state) => state.conversations);
  const clearUserData = () => setUserData({});

  function logout() {
    clearUserData();
    dispatch(RESETAPP());
    Auth.signOut();
  }

  return (
    <DrawerContentScrollView {...props}>
      <DrawerItemList {...props} />
      <CustomDivider />
      <DrawerItem
        label="Learn More"
        onPress={() => navigation.navigate("about")}
      />
      <DrawerItem
        label="Learn about Hosting"
        onPress={() => navigation.navigate("host")}
      />
      <CustomDivider />
      <DrawerItem
        label="Help Center"
        onPress={() => navigation.navigate("help")}
      />
      <DrawerItem label="Sign out" onPress={logout} style={{ marginBottom: 40 }} />
    </DrawerContentScrollView>
  );
};
