import { API } from "aws-amplify";
import { createBookingRequest, updateBookingRequest } from "../graphql/mutations";
import { notifyHookhub } from "../lambdaFunctions/notifyHookhub";


export const createBookingRequestMutation = async ({ data }) => {
    const newBookingRequest = await API.graphql({
        query: createBookingRequest,
        variables: { input: data },
        authMode: "AMAZON_COGNITO_USER_POOLS",
    }).then((r) => {
        let bookingRequestData = r.data.createBookingRequest;
        const subject = `NEW BOOKING REQUEST`
        const body = `NEW booking request! Booking Request ID: ${bookingRequestData.id}. Host Email: ${bookingRequestData.hostEmail}.`
        notifyHookhub({ subject, body, userID: bookingRequestData.hostID, userEmail: bookingRequestData.hostEmail })
        return bookingRequestData;
    }).catch((e) => {
        console.log("e in here >>  ", e)
        return false;
    })
    return newBookingRequest;
};

export const updateBookingRequestMutation = async ({ data }) => {
    let updatedRequest = await API.graphql({
        query: updateBookingRequest,
        variables: { input: data },
        authMode: "AMAZON_COGNITO_USER_POOLS",
    }).then((r) => {
        return r.data.updateBookingRequest;
    }).catch((e) => {
        console.log("e in here >>  ", e)
        return false;
    })
    return updatedRequest;
};
