import React, { useState, useContext, useEffect } from "react";
import { useWindowDimensions, Text, Switch, ScrollView, View } from "react-native";
import { API } from "aws-amplify";
import { AntDesign, Entypo } from "@expo/vector-icons";
import Flex from "../../components/CustomElements/Flex"
import Card from "../../components/CustomElements/Card"
import Header from "../../components/CustomElements/Header"
import { useNavigation, useRoute } from "@react-navigation/native";
import { Calendar } from "react-native-calendars";
import { UserDataContext } from "../../utils/UserContextProvider";
import { BookingsContext } from "../../utils/BookingsContextProvider";
import { useSelector } from "react-redux";
import BackButton from "../../components/other/BackButton";
import { getCalendarDisplayDates } from "../../utils/getCalendarDisplayDates";
import { colors } from "../../styles/colors"
import CustomButton from "../../components/CustomElements/CustomButton";
import CustomSection from "../../components/CustomElements/CustomSection";
import { getSpaceDetails, updateSpaceMutation } from "../../api/space";
import { listMyHostedProperties } from "../../api/property";
import { globalStyles } from "../../styles/styles";
import { LoadingComponent } from "../../components/LoadingComponent";

function HostEditSpaceAvailabilityScreen() {
  const route = useRoute();
  const navigation = useNavigation();
  const { mobileView } = useSelector(state => state.currentUser);
  const { width } = useWindowDimensions();
  const { user } = useContext(UserDataContext);
  const { userData } = user;
  const { sub } = userData;
  const { myHosting } = useContext(BookingsContext);
  const { setMySpaces } = myHosting;
  const updateMySpaces = v => setMySpaces(v);
  const [spaceNoUnavailability, setSpaceNoUnavailability] = useState(false);
  const [firstDay, setFirstDay] = useState(null);
  const [spaceId, setspaceId] = useState(null);
  const [spacename, setspacename] = useState(null);
  const [propertyname, setpropertyname] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [firstClick, setFirstClick] = useState(true);
  const [removeDateSpan, setRemoveDateSpan] = useState(false);
  const [longestStay, setLongestStay] = useState(null);
  const [shortestStay, setShortestStay] = useState(null);
  const [updatedSuccessfully, setUpdatedSuccessfully] = useState(false);
  const [unavailableTimesDisplay, setUnavailableTimesDisplay] = useState({});

  const getDaysArray = (start, end) => {
    let obj = { ...unavailableTimesDisplay };
    for (
      const dt = new Date(`${start}T00:00:00`);
      dt <= new Date(`${end}T00:00:00`);
      dt.setDate(dt.getDate() + 1)
    ) {
      const year = dt.toLocaleString("default", { year: "numeric" });
      const month = dt.toLocaleString("default", { month: "2-digit" });
      const day = dt.toLocaleString("default", { day: "2-digit" });
      const theDate = year + "-" + month + "-" + day;
      if (theDate in unavailableTimesDisplay && removeDateSpan) {
        delete obj[firstDay];
        delete obj[theDate];
      } else {
        obj = { ...obj, [theDate]: { color: "gray" } };
      }
    }
    return obj;
  };

  const setDayClicked = (v) => {
    const { dateString } = v;
    if (dateString in unavailableTimesDisplay) {
      if (firstClick) {
        const newObj = { ...unavailableTimesDisplay };
        delete newObj[dateString];
        setRemoveDateSpan(true);
        setUnavailableTimesDisplay(newObj);
        setFirstDay(dateString);
        setFirstClick(false);
      } else if (firstDay !== dateString) {
        const updatedDatesObj = getDaysArray(firstDay, dateString);
        setUnavailableTimesDisplay(updatedDatesObj);
        setFirstClick(true);
        setRemoveDateSpan(false);
      } else {
        setFirstClick(true);
        setRemoveDateSpan(false);
      }
      // ADD date
    } else {
      setRemoveDateSpan(false);
      if (!firstClick && dateString !== firstDay && !removeDateSpan) {
        const updatedDatesObj = getDaysArray(firstDay, dateString);
        setUnavailableTimesDisplay(updatedDatesObj);
        setFirstClick(true);
      } else {
        setFirstDay(dateString);
        setUnavailableTimesDisplay({
          ...unavailableTimesDisplay,
          [dateString]: { color: "gray" },
        });
        setFirstClick(false);
      }
    }
  };

  const fetchUpdatedProperties = async () => {
    let properties = await listMyHostedProperties({ ownerID: sub });
    if (!!properties?.items) {
      updateMySpaces(properties.items)
    }
  };

  const setUnavailability = async () => {
    setLoading(true);
    let unavailable_days = !!unavailableTimesDisplay && !!Object.keys(unavailableTimesDisplay).length ? Object.keys(unavailableTimesDisplay) : [];
    const removeAnyUndefined = unavailable_days.filter(function (el) { return el !== 'undefined' });

    const data = {
      id: spaceId,
      unavailable_days: removeAnyUndefined,
      set_availability: true
    };
    // if (!!longestStay && longestStay !== 'none') {
    //   data['longest_stay_allowed'] = Number(longestStay)
    // }
    // if (!!shortestStay && shortestStay !== 'none') {
    //   data['shortest_stay_allowed'] = Number(shortestStay)
    // }

    const updatedSpace = await updateSpaceMutation({ data });
    if (!!updatedSpace) {
      await fetchUpdatedProperties();
      setLoading(false);
      setUpdatedSuccessfully(true);
    } else {
      console.log("ERROR    >>>>   ", e)
      setError(true);
    }
  }

  const setData = async () => {
    const { spaceID, spaceName, propertyName } = route.params;
    setspaceId(spaceID)
    setspacename(spaceName)
    setpropertyname(propertyName)
    const space = await getSpaceDetails(spaceID);
    const dates = getCalendarDisplayDates(space, space.bookings?.items, space.bookingRequests?.items)
    setUnavailableTimesDisplay(dates)
  }

  useEffect(() => {
    setData()
  }, []);

  return (
    <ScrollView>
      <BackButton />
      <View style={globalStyles.centerColumn}>
        <Header
          level={1}
          padding={0}
          color="#4c4e52"
          text="Set unavailable dates"
        />
        <Header padding={1} level={1.5} text={`"${propertyname}"`} />
        {spacename !== propertyname && <Header level={1.1} padding={1} text={`Space: "${spacename}"`} />}
      </View>
      <Text style={{ fontSize: 16, margin: 20 }}>* Please note: availability can only be updated per individual space at this time. The ability to update unavailability for a whole property will be available soon.</Text>
      <Flex
        alignItems="center"
        alignContent="center"
        textAlign="center"
        marginTop={20}
        marginLeft={30}
      >
        <Switch
          value={spaceNoUnavailability}
          onValueChange={() => setSpaceNoUnavailability(!spaceNoUnavailability)}
          alignSelf="flex-start"
          marginRight={15}
        />
        <Text style={{ fontSize: 17 }}>All days are available</Text>
      </Flex>

      {spaceNoUnavailability ? (
        <Flex
          direction="column"
          alignItems="center"
          justifyContent="center"
          alignContent="center"
          textAlign="center"
        >
          <Card
            backgroundColor={colors.amplifyNeutral60}
            width={width / 1.1}
            padding={!mobileView ? 9 : 0}
            margin={!mobileView ? 5 : 0}
            alignItems="center"
            justifyContent="center"
            textAlign="center"
          >
            <Calendar
              disabledByDefault
              minDate={new Date()}
              theme={{
                backgroundColor: "tan",
                calendarBackground: "white",
                textSectionTitleColor: "#b6c1cd",
                textSectionTitleDisabledColor: "#d9e1e8",
                selectedDayBackgroundColor: "#00adf5",
                selectedDayTextColor: "#ffffff",
                todayTextColor: "#00adf5",
                dayTextColor: "#2d4150",
                textDisabledColor: "#d9e1e8",
                dotColor: "#00adf5",
                selectedDotColor: "#ffffff",
                arrowColor: "blue",
                disabledArrowColor: "#d9e1e8",
                monthTextColor: "gray",
                indicatorColor: "blue",
                textDayFontWeight: 300,
                textMonthFontWeight: "bold",
                textDayHeaderFontWeight: 300,
                textDayFontSize: !!mobileView ? 15 : 20,
                textMonthFontSize: !!mobileView ? 12 : 20,
                textDayHeaderFontSize: !!mobileView ? 10 : 17,
              }}
              style={{
                borderWidth: 1,
                borderColor: "gray",
              }}
            />
          </Card>
        </Flex>
      ) : (
        <CustomSection backgroundColor={colors.hookhubOrangeDark}>
          <Calendar
            markingType={"period"}
            markedDates={unavailableTimesDisplay}
            onDayPress={(day) => {
              setDayClicked(day);
            }}
            renderArrow={(direction) => {
              return (
                <>
                  {direction == "right" ? (
                    <Flex marginRight={9}>
                      <Entypo
                        name={!!mobileView ? "arrow-bold-right" : "arrow-with-circle-right"}
                        size={!!mobileView ? 16 : 24}
                        color="black"
                      />
                    </Flex>
                  ) : (
                    <Entypo
                      name={!!mobileView ? "arrow-bold-left" : "arrow-with-circle-left"}
                      size={!!mobileView ? 16 : 24}
                      color="black"
                    />
                  )}
                </>
              );
            }}
            firstDay={1}
            enableSwipeMonths={true}
            theme={{
              backgroundColor: "tan",
              calendarBackground: "#ffffff",
              textSectionTitleColor: "#b6c1cd",
              selectedDayBackgroundColor: "gray",
              todayTextColor: "#00adf5",
              dayTextColor: "#2d4150",
              arrowColor: "blue",
              monthTextColor: "gray",
              indicatorColor: "blue",
              textDayFontWeight: 300,
              textMonthFontWeight: "bold",
              textDayHeaderFontWeight: 300,
              textDayFontSize: !!mobileView ? 15 : 20,
              textMonthFontSize: !!mobileView ? 12 : 20,
              textDayHeaderFontSize: !!mobileView ? 10 : 17,
            }}
            style={{
              borderWidth: 1,
              borderColor: "gray",
              paddingLeft: 30,
              paddingRight: 30
            }}
          />
          {/* </Card> */}
          <Text style={{ fontSize: 16, marginLeft: 10 }}>* for span of dates: click first day, then click last day</Text>
        </CustomSection>
      )}

      {/* <Card
          backgroundColor={colors.brandGrey}
          border="#000"
          boxShadow="#737272"
          alignItems="center"
          justifyContent="center"
          textAlign="center"
          marginTop={4}
        >
          <Header level={5}>OPTIONAL</Header>
          <Header marginBottom={8}>
            * Leave empty if you have no preference
          </Header>
          <Flex alignItems="center" justifyContent="center">
            <Text marginBottom={4}>minimum days required for stay</Text>
            <CustomTextInput
              label="Shortest stay allowed"
              value={shortestStay}
              onChangeText={(e) => setShortestStay(e.currentTarget.value)}
              labelHidden
              placeholder="--"
              width="18%"
              marginBottom={4}
            ></CustomTextInput>
          </Flex>
          <Flex alignItems="center" justifyContent="center">
            <Text>longest stay allowed in days</Text>
            <CustomTextInput
              label="Longest stay allowed"
              value={longestStay}
              placeholder="--"
              onChangeText={(e) => setLongestStay(e.currentTarget.value)}
              labelHidden
              width="18%"
            ></CustomTextInput>
          </Flex>
        </Card> */}

      <Flex
        direction="column"
        alignItems="center"
        justifyContent="center"
        alignContent="center"
        textAlign="center"
        marginBottom={150}
        marginTop={0}
      >
        {loading ? (
          <LoadingComponent />
        ) : (
          <>
            {
              updatedSuccessfully ? (
                <>
                  <Header padding={0} text="Availability Updated!" />
                  <CustomButton
                    width={150}
                    backgroundColor={colors.amplifyNeutral20}
                    onPress={() => navigation.goBack()}
                  >
                    <Flex justifyContent="center" alignContent="center" alignItems="center">
                      <AntDesign name="caretleft" size={24} color="grey" />
                      <Text style={{ marginLeft: 10 }}>all done</Text>
                    </Flex>
                  </CustomButton>
                </>
              ) : (
                <CustomButton
                  marginTop={0}
                  marginBottom={40}
                  width={150}
                  backgroundColor={colors.hookhubOrangeDark}
                  border={colors.amplifyOrange90}
                  boxShadow={colors.amplifyNeutral60}
                  onPress={() => setUnavailability()}
                >
                  <Text style={{ fontSize: 17 }}>SAVE</Text>
                </CustomButton>
              )}
          </>
        )}
      </Flex >
    </ScrollView >
  );
}

export default HostEditSpaceAvailabilityScreen;
