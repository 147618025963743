import React from "react";
import { View, StyleSheet, Text } from "react-native";
import {colors} from "../../styles/colors";
import Flex from "../../components/CustomElements/Flex"
import Header from "../../components/CustomElements/Header"
import CustomDivider from "../../components/CustomDivider";
import CustomButton from "../../components/CustomElements/CustomButton";


function LocationPreferencesScreen() {
  return (
    <Flex
      alignContent="center"
      alignItems="center"
      justifyContent="center"
      direction="column"
      margin={8}
    >
      <Flex>
        <Header margin={8} level={2} text="Hello, "></Header>
        <CustomButton onPress={() => alert('click')}><Text>Edit profile</Text></CustomButton>
        <CustomDivider />
        <Text>Confirmed....</Text>
      </Flex>
      <Image style={styles.logoImage} source={require("../../../assets/images/hh_mini.png")}/>
    </Flex>
  );
}

const styles = StyleSheet.create({
  types: {
    color: colors.brandGrey,
    marginVertical: 10,
  },
  logoImage: {
    height: 50,
    justifyContent: 'center',
    width: 200,
    margin: 10,
    marginTop: 15
  },
  container: {
    margin: 20,
  },
  image: {
    aspectRatio: 3 / 2,
    borderRadius: 10,
    resizeMode: "cover",
    width: "100%",
  },
  longDescription: {
    fontSize: 16,
    lineHeight: 24,
    marginVertical: 10,
  },
  newPrice: {
    fontWeight: "bold",
  },
  oldPrice: {
    color: colors.brandGrey,
    textDecorationLine: "line-through",
  },
  prices: {
    fontSize: 17,
    marginVertical: 10,
  },
  totalPrice: {
    color: colors.brandGrey,
    textDecorationLine: "underline",
  },
});

export default LocationPreferencesScreen;
