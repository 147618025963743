import React, { useState } from "react";
import { useDispatch, useSelector } from 'react-redux';
import HostOnboarding from "../../components/host/HostOnboarding";
import { setSpaceDetails } from "../../redux/actions/HostOnboardingActions";
import SetAvailableAmenities from "../../components/space/SetAvailableAmenities";
import { useWindowDimensions } from "react-native";

function SpaceDetailsScreen() {
  const dispatch = useDispatch();
  const {
    electric_hookup,
    water_hookup,
    sewage_hookup,
    wifi_available,
    shower_available,
    restroom_available,
    food_nearby,
    restaurant_nearby,
    water_accessible,
    easy_entry,
    laundry_available,
    pool_available,
    hottub_available,
    outdoor_area_available,
    indoor_area_available,
    trash_available,
    pets_allowed,
    smoking_allowed,
    adults_only,
    outdoor_fires,
    additionalSpacesBoolean
  } = useSelector(state => state.hostOnboarding);
  const { width } = useWindowDimensions();
  const [electrichookup, setelectrichookup] = useState((electric_hookup));
  const [waterhookup, setwaterhookup] = useState((water_hookup));
  const [sewagehookup, setsewagehookup] = useState((sewage_hookup));
  const [wifiavailable, setwifiavailable] = useState((wifi_available));
  const [showeravailable, setshoweravailable] = useState((shower_available));
  const [restroomavailable, setrestroomavailable] = useState((restroom_available));
  const [foodnearby, setfoodnearby] = useState((food_nearby));
  const [restaurantnearby, setrestaurantnearby] = useState((restaurant_nearby));
  const [wateraccessible, setwateraccessible] = useState((water_accessible));
  const [easyentry, seteasyentry] = useState((easy_entry));
  const [laundry, setlaundry] = useState((laundry_available));
  const [pool, setpool] = useState((pool_available));
  const [hottub, sethottub] = useState((hottub_available));
  const [outdoorarea, setoutdoorarea] = useState((outdoor_area_available));
  const [indoorarea, setindoorarea] = useState((indoor_area_available));
  const [trash, settrash] = useState((trash_available));
  const [outdoorFires, setoutdoorFires] = useState((outdoor_fires || false));
  const [petsAllowed, setpetsAllowed] = useState((pets_allowed || false));
  const [smokingAllowed, setsmokingAllowed] = useState((smoking_allowed || false));
  const [adultsOnly, setadultsOnly] = useState((adults_only || false));

  const setDetails = () => {
    dispatch(setSpaceDetails({
      electric_hookup: electrichookup,
      water_hookup: waterhookup,
      sewage_hookup: sewagehookup,
      wifi_available: wifiavailable,
      shower_available: showeravailable,
      restroom_available: restroomavailable,
      food_nearby: foodnearby,
      restaurant_nearby: restaurantnearby,
      water_accessible: wateraccessible,
      easy_entry: easyentry,
      laundry_available: laundry,
      pool_available: pool,
      hottub_available: hottub,
      outdoor_area_available: outdoorarea,
      indoor_area_available: indoorarea,
      trash_available: trash,
      pets_allowed: petsAllowed,
      smoking_allowed: smokingAllowed,
      adults_only: adultsOnly,
      outdoor_fires: outdoorFires,
    }));
  };

  return (
    <HostOnboarding
      heading={additionalSpacesBoolean ? "What Amenities do your Spaces Offer?" : "What Amenities does your Space Offer?"}
      navigateF="SetDistances"
      navigateB="HostSpace"
      nextButtonText="Next"
      onClickNext={() => setDetails()}
    >
      <SetAvailableAmenities
        width={width}
        electrichookup={electrichookup}
        waterhookup={waterhookup}
        sewagehookup={sewagehookup}
        wifiavailable={wifiavailable}
        showeravailable={showeravailable}
        easyentry={easyentry}
        laundry={laundry}
        pool={pool}
        hottub={hottub}
        outdoorarea={outdoorarea}
        indoorarea={indoorarea}
        trash={trash}
        petsAllowed={petsAllowed}
        outdoorFires={outdoorFires}
        smokingAllowed={smokingAllowed}
        adultsOnly={adultsOnly}
        restroomavailable={restroomavailable}
        foodnearby={foodnearby}
        wateraccessible={wateraccessible}
        restaurantnearby={restaurantnearby}
        setlaundry={() => setlaundry(!laundry)}
        setpool={() => setpool(!pool)}
        sethottub={() => sethottub(!hottub)}
        setoutdoorarea={() => setoutdoorarea(!outdoorarea)}
        setindoorarea={() => setindoorarea(!indoorarea)}
        settrash={() => settrash(!trash)}
        setelectrichookup={() => setelectrichookup(!electrichookup)}
        setwaterhookup={() => setwaterhookup(!waterhookup)}
        setsewagehookup={() => setsewagehookup(!sewagehookup)}
        setwifiavailable={() => setwifiavailable(!wifiavailable)}
        setshoweravailable={() => setshoweravailable(!showeravailable)}
        seteasyentry={() => seteasyentry(!easyentry)}
        setpetsAllowed={() => setpetsAllowed(!petsAllowed)}
        setsmokingAllowed={() => setsmokingAllowed(!smokingAllowed)}
        setadultsOnly={() => setadultsOnly(!adultsOnly)}
        setoutdoorFires={() => setoutdoorFires(!outdoorFires)}
        setrestroomavailable={() => setrestroomavailable(!restroomavailable)}
        setfoodnearby={() => setfoodnearby(!foodnearby)}
        setrestaurantnearby={() => setrestaurantnearby(!restaurantnearby)}
        setwateraccessible={() => setwateraccessible(!wateraccessible)}
      />
    </HostOnboarding>
  )
};

export default SpaceDetailsScreen;