import { View } from "react-native";
import {
    Ionicons,
    MaterialIcons,
    MaterialCommunityIcons,
} from "@expo/vector-icons";
import { colors } from "../../styles/colors";
import { CustomIconToggleButton } from "../CustomElements/CustomIconToggleButton";
import { globalStyles } from "../../styles/styles";
import { useSelector } from "react-redux";

const SelectAmenities = ({
    water,
    setWater,
    electric,
    setElectric,
    sewage,
    setSewage,
    deluxe,
    setDeluxe,
    width
}) => {
    const { mobileView } = useSelector(state => state.currentUser);

    return (
        <View style={[globalStyles.center, {width:width/1.05, flexWrap:"wrap"}]}>
            <CustomIconToggleButton
                amenityType={electric}
                updateAmenityType={setElectric}
                selectedColor={"orange"}
                icon={
                    <MaterialIcons
                        name="electrical-services"
                        size={25}
                        color={electric ? colors.amplifyNeutral80 : colors.amplifyNeutral60}
                    />
                }
                amenityTitle={"electric"}
                largeSize={!mobileView}
            />
            <CustomIconToggleButton
                amenityType={water}
                updateAmenityType={setWater}
                selectedColor={"orange"}
                amenityTitle={"water"}
                largeSize={!mobileView}
                icon={<Ionicons name="water-sharp" size={23} color={water ? colors.amplifyNeutral80 : colors.amplifyNeutral60} />}
            />
            <CustomIconToggleButton
                amenityType={sewage}
                updateAmenityType={setSewage}
                selectedColor={"orange"}
                amenityTitle={"sewage"}
                largeSize={!mobileView}
                icon={<MaterialCommunityIcons name="pipe" size={20} color={sewage ? colors.amplifyNeutral80 : colors.amplifyNeutral60} />}
            />
            <CustomIconToggleButton
                amenityType={deluxe}
                updateAmenityType={setDeluxe}
                selectedColor={"orange"}
                amenityTitle={"deluxe*"}
                largeSize={!mobileView}
                icon={<MaterialIcons name="star" size={20} color={deluxe ? colors.amplifyNeutral80 : colors.amplifyNeutral60} />}
            />
            {/* {easy_entry && ( <MaterialCommunityIcons name="steering" size={mobileView ? 25 : 30} color={colors.amplifyBlue40}/>)}
                    {shower_available && (<FontAwesome5 name="shower" size={mobileView ? 25 : 30} color={colors.amplifyBlue40} />)}
                    {wifi_available && (<Ionicons name="wifi" size={mobileView ? 25 : 30} color={colors.amplifyBlue40} />)}
                    {laundry_available && (<MaterialIcons name="local-laundry-service" size={mobileView ? 25 : 30} color={colors.amplifyBlue40} />)}
                    {pool_available && (<MaterialIcons name="pool" size={mobileView ? 24 : 38} color="black" />)}
                    {hottub_available && (<FontAwesome5 name="hot-tub" size={mobileView ? 25 : 28} color={colors.amplifyBlue40} />)}
                    {indoor_area_available && (<FontAwesome5 name="warehouse" size={mobileView ? 25 : 25} color={colors.amplifyBlue40} />)}
                    {outdoor_area_available && (<MaterialCommunityIcons name="table-picnic" size={mobileView ? 25 : 30} color={colors.amplifyBlue40} />)}
                    {trash_available && (<FontAwesome name="trash-o" size={mobileView ? 23 : 27} color={colors.amplifyBlue40} />)} */}
        </View>
    )
};

export default SelectAmenities;
