import {
    Pressable,
    StyleSheet,
    Text,
    View,
} from "react-native";
import { AntDesign, Feather } from "@expo/vector-icons";
import Badge from "../CustomElements/Badge";
import { globalStyles } from "../../styles/styles";

const FilterOptions = ({ loading, showMap, setShowMap, properties, stayType, setStayType, mobileView, width }) => {
    return (
        <View style={mobileView ? globalStyles.centerColumn : globalStyles.center}>
            <View style={[globalStyles.center, { flexWrap: "wrap", marginTop: 15, marginBottom: mobileView ? 0 : 10 }]}>
                <Pressable style={{ width: 82, height: 40 }} onPress={() => setStayType('vacation')}>
                    <Badge onPress={() => setStayType('vacation')} margin={0} padding={mobileView ? 1 : 2} variation={stayType == 'vacation' ? "warning" : "info"}>
                        <Text style={styles.fontSize}>Nightly</Text>
                    </Badge>
                </Pressable>
                <Pressable style={{ width: 82, height: 40 }} onPress={() => setStayType('monthly')}>
                    <Badge onPress={() => setStayType('monthly')} margin={0} padding={mobileView ? 1 : 2} variation={stayType == 'monthly' ? "warning" : "info"}>
                        <Text style={styles.fontSize}>Monthly</Text>
                    </Badge>
                </Pressable>
                <Pressable style={{ width: 82, height: 40 }} onPress={() => setStayType('type_yearly')}>
                    <Badge onPress={() => setStayType('type_yearly')} margin={0} padding={mobileView ? 1 : 2} variation={stayType == 'type_yearly' ? "warning" : "info"}>
                        <Text style={styles.fontSize}>Yearly</Text>
                    </Badge>
                </Pressable>
                <Pressable style={{ width: 82, height: 40 }} onPress={() => setStayType('storage')}>
                    <Badge onPress={() => setStayType('storage')} margin={0} padding={mobileView ? 1 : 2} variation={stayType == 'storage' ? "warning" : "info"}>
                        <Text style={styles.fontSize}>Storage</Text>
                    </Badge>
                </Pressable>
            </View>
            <Pressable
                onPress={() => setShowMap(!showMap)}
                disabled={!properties.length && !loading}
                style={[styles.showmapbutton, { height: 30, marginTop: mobileView ? 0 : -12 }]}
            >
                {showMap ? (
                    <View style={globalStyles.center}>
                        <AntDesign name="closesquareo" size={16} color="black" />
                        <Text style={styles.filterButtonText}>Hide Map</Text>
                    </View>
                ) : (
                    <View style={globalStyles.center}>
                        <Feather name="map" size={16} color="black" />
                        <Text style={styles.filterButtonText}>Show Map</Text>
                    </View>
                )}
            </Pressable>
        </View>
    )
}

const styles = StyleSheet.create({
    container: {
        flex: 1,
    },
    image: {
        flex: 1,
        justifyContent: "center"
    },
    showmapbutton: {
        alignItems: "center",
        borderRadius: 10,
        backgroundColor: "#fff",
        borderColor: "#d6d6d6",
        borderRadius: 30,
        borderWidth: 1,
        borderBottomWidth: 2,
        justifyContent: "center",
        paddingHorizontal: 15,
    },
    filterButton: {
        alignItems: "center",
        backgroundColor: "#fff",
        borderRadius: 30,
        elevation: 1,
        flexDirection: "row",
        height: 45,
        justifyContent: "center",
        marginHorizontal: 10,
        top: 15,
        width: 110,
        zIndex: 1,
    },
    filterButtonText: {
        marginLeft: 4,
        color: "#000",
        fontSize: 14,
        fontWeight: "bold",
    },
    fontSize: {
        fontSize: 15,
        paddingBottom: 2
    }
});

export default FilterOptions;