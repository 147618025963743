import React, { useState, useEffect, useContext } from "react";
import { StyleSheet, useWindowDimensions, ScrollView, Text, Image, View, ImageBackground, Platform } from "react-native";
import Header from "../../components/CustomElements/Header"
import LearnMore from "../../components/LearnMore"
import { useSelector } from "react-redux";
import { Entypo } from '@expo/vector-icons';
import { useNavigation } from "@react-navigation/native";
import CustomButton from "../../components/CustomElements/CustomButton";
import { colors } from "../../styles/colors";
import CustomSection from "../../components/CustomElements/CustomSection";
import ReviewDisplay from "../../components/other/ReviewDisplay";
import HelpForm from "../../components/other/HelpForm";
import { Auth } from "aws-amplify";
import getEnvVars from "../../../environment";
import { UserDataContext } from "../../utils/UserContextProvider";
import ComparisonToCompetitorsChart from "../../components/other/ComparisonToCompetitorsChart";
import { globalStyles } from "../../styles/styles";
import TextBody from "../../components/CustomElements/TextBody";
import CustomDivider from "../../components/CustomDivider";
import FooterView from "../../components/other/FooterView";

const Buttons = ({ displayHostButton = true }) => {
    const navigation = useNavigation();
    const loggedInUser = useContext(UserDataContext);

    const goToExplore = () => {
        loggedInUser ? navigation.navigate('home', {
            screen: 'explore',
            params: {
                screen: 'welcome'
            }
        }) : navigation.navigate('explore', {
            screen: 'welsome'
        })
    }

    const goToHosting = () => {
        navigation.navigate('host')
    }

    return (
        <View style={[globalStyles.flexEndColumn, { marginVertical: 50 }]}>
            <CustomButton
                width={220}
                backgroundColor={colors.hookhubOrange}
                textAlign="center"
                margin={2}
                border={colors.amplifyOrange90}
                shadowColor={colors.amplifyOrange80}
                height={45}
                marginRight={4}
                marginLeft={4}
                onPress={() => goToExplore()}
                marginBottom={12}
            >
                <View style={[globalStyles.spaceBetween, { padding: 10, alignItems: "center" }]}>
                    <TextBody>Check out RV spaces</TextBody>
                    <Entypo name="arrow-right" size={24} color={colors.amplifyNeutral90} />
                </View>
            </CustomButton>
            {displayHostButton && (
                <CustomButton
                    width={220}
                    backgroundColor={colors.hookhubOrange}
                    textAlign="center"
                    margin={2}
                    marginRight={4}
                    marginLeft={4}
                    border={colors.amplifyOrange90}
                    shadowColor={colors.amplifyOrange80}
                    height={45}
                    onPress={() => goToHosting()}
                    marginBottom={12}
                >
                    <View style={[globalStyles.spaceBetween, { padding: 10, alignItems: "center" }]}>
                        <TextBody>Learn about Hosting</TextBody>
                        <Entypo name="arrow-right" size={24} color={colors.amplifyNeutral90} />
                    </View>
                </CustomButton>
            )}
        </View>
    )
}

function AboutScreen() {
    const { cloudfrontURLStaticImages } = getEnvVars();
    const { width, height } = useWindowDimensions();
    const [authed, setAuthed] = useState(false)
    const [helpEmailShow, setHelpEmailShow] = useState(false);
    const { mobileView } = useSelector(state => state.currentUser);

    const authCheck = async () => {
        await Auth.currentUserInfo()
            .then((userInfo) => {
                if (userInfo && userInfo.attributes) {
                    setAuthed(true)
                } else { setAuthed(false) }
            })
    }

    useEffect(() => {
        authCheck();
    }, [])

    return (
        <ScrollView style={styles.scrollView}>
            <View style={styles.view}>
                <ImageBackground
                    source={{ uri: `${cloudfrontURLStaticImages}splashScreen2.jpg` }}
                    resizeMode="cover"
                    style={styles.image}
                >
                    <View style={[mobileView ? globalStyles.flexStartColumn : globalStyles.center, { height: Platform.OS !== 'web' ? height / 4.5 : mobileView ? height / 1.8 : height / 2.6, width: width }]}>
                        {mobileView ? (
                            <>
                                <View style={[globalStyles.flexEnd, { height: height / 1.7, alignItems: "flex-start" }]}>
                                    <View style={[globalStyles.flexEndColumn, { width: width, marginTop: mobileView ? "4%" : "8%", marginRight: "5%" }]}>
                                        <Text style={[globalStyles.shadowedTextDark, { fontSize: 25, fontWeight: "bold", color: "white" }]}>
                                            where
                                        </Text>
                                        <Text style={[globalStyles.shadowedTextDark, { textAlign: "right", marginVertical: 2, fontSize: 35, fontWeight: "bold", color: "white" }]}>
                                            UNUSED LAND SPACE
                                        </Text>
                                        <Text style={[globalStyles.shadowedTextDark, { textAlign: "right", marginVertical: 2, fontSize: 25, fontWeight: "bold", color: "white" }]}>
                                            meets
                                        </Text>
                                        <Text style={[globalStyles.shadowedTextDark, { textAlign: "right", marginVertical: 2, fontSize: 35, fontWeight: "bold", color: "white" }]}>
                                            EXTRAORDINARY RV PARKING
                                        </Text>
                                        <Buttons displayHostButton={false} />
                                    </View>
                                </View>
                            </>
                        ) : (
                            <View style={[globalStyles.spaceBetween, { width: width / 1.5 }]}>
                                <View style={[globalStyles.flexStartColumn]}>
                                    <Text style={[globalStyles.shadowedText, { fontSize: 38, fontWeight: "bold", color: "white" }]}>
                                        where
                                    </Text>
                                    <Text style={[globalStyles.shadowedText, { fontSize: 40, fontWeight: "bold", color: "white" }]}>
                                        UNUSED LAND SPACE
                                    </Text>
                                    <Text style={[globalStyles.shadowedText, { fontSize: 38, fontWeight: "bold", color: "white" }]}>
                                        meets
                                    </Text>
                                    <Text style={[globalStyles.shadowedText, { fontSize: 40, fontWeight: "bold", color: "white" }]}>
                                        EXTRAORDINARY RV PARKING
                                    </Text>
                                </View>
                                <Buttons displayHostButton={false} />
                            </View>
                        )}
                    </View>
                </ImageBackground>
                <View style={[globalStyles.centerColumn, { width: mobileView ? width / 1.3 : width / 1.35, marginTop: 60, alignItems: "center", marginBottom: 50 }]}>
                    {!mobileView ? <Image style={styles.logoImage} source={require("../../../assets/images/mini_hookhub-logo-highres.png")} /> : <></>}
                    <Header textAlign="center" color={colors.amplifyNeutral90} padding={0} level={1.25} text="Discover and Book RV Parking-" />
                    <Header textAlign="center" color={colors.amplifyNeutral90} padding={0} level={1.25} text="Whether it be Vacation or a Yearly Stay find your next RV Parking Space." />

                </View>

                <Image
                    style={{ width: mobileView ? height / 3.75 * 2 : (height / 2) * 2, height: mobileView ? height / 3.75 : height / 2, margin: mobileView ? 2 : 10, borderRadius: 5 }}
                    source={{ uri: `${cloudfrontURLStaticImages}appdisplay.png` }}
                />
                <CustomDivider />

                <View style={[globalStyles.center, { flexWrap: "wrap" }]}>
                    <ReviewDisplay
                        width={mobileView ? width : width / 3.1}
                        reviewerAvatar={"trish.jpg"}
                        reviewerText={`Easy as can be and the support team is always available and ready to help with anything. I've already made over $ 3,000 with one space.`}
                        reviewerSignature={"Trish, Host since 2023"}
                    />
                    <ReviewDisplay
                        width={mobileView ? width : width / 3.1}
                        reviewerText={`Finding unique places to park our RV has never been easier. HookHub offers such incredible locations that we would have never found on our own.`}
                        reviewerSignature={"Sarah, Renter since 2023"}
                    />
                    <ReviewDisplay
                        width={mobileView ? width : width / 3.1}
                        reviewerAvatar={"adriaAvatar.jpg"}
                        reviewerText={` I like the location due to its proximity to Show Low and Pinetop-Lakeside.  The park is quiet  and I love all the trees. Trish has been great and has made our stay here wonderful. We are so happy to have found this park! `}
                        reviewerSignature={"Adria, Renter since 2023"}
                    />
                </View>
                <CustomDivider />

                <Buttons />
                <CustomDivider />

                <View style={{ marginTop: mobileView ? 5 : 40 }}>
                    <ComparisonToCompetitorsChart hostView={false} />
                </View>
                <CustomDivider />

                <View style={{ marginTop: mobileView ? 20 : 40 }}>
                    <LearnMore />
                </View>

                <View style={{ flexDirection: "row", marginTop: mobileView ? 0 : 30 }}>
                    <Image
                        style={{ width: width / 3.25, height: (width / 3.25) * .8, margin: mobileView ? 2 : 10, borderRadius: 5 }}
                        source={{ uri: `${cloudfrontURLStaticImages}aboutImage0.png` }}
                    />
                    <Image
                        style={{ width: width / 3.25, height: (width / 3.25) * .8, margin: mobileView ? 2 : 10, borderRadius: 5 }}
                        source={{ uri: `${cloudfrontURLStaticImages}aboutImage2.png` }}
                    />
                    <Image
                        style={{ width: width / 3.25, height: (width / 3.25) * .8, margin: mobileView ? 2 : 10, borderRadius: 5 }}
                        source={{ uri: `${cloudfrontURLStaticImages}aboutImage1.png` }}
                    />
                </View>
                <CustomDivider />

                <CustomSection padding={8} customWidth={mobileView ? width / 1.1 : width / 1.4}>
                    <Header textAlign="center" text="OUR MISSION" />
                    <Header textAlign="center" color={colors.amplifyNeutral90} level={mobileView ? .9 : 1.3} text="Facilitate Land Sharing for a Better World" />
                    <Text style={{ fontSize: mobileView ? 17 : 19, textAlign: "center" }}>
                        By connecting people who have private land with renters, HookHub works to bring people together. By connecting hosts with travelers, we create a global community committed to responsible and enriching travel experiences.
                    </Text>
                </CustomSection>
                <CustomSection padding={8} customWidth={mobileView ? width / 1.1 : width / 1.4}>
                    <Header textAlign="center" text="OUR VISION" />
                    <Header textAlign="center" color={colors.amplifyNeutral90} level={mobileView ? .9 : 1.3} text="Unleash the Potential of Spare Land" />
                    <Text style={{ fontSize: mobileView ? 17 : 19, textAlign: "center" }}>
                        HookHub is your community to fill your RV parking needs or bring in extra income without extra work.
                    </Text>

                    <Header textAlign="center" color={colors.amplifyNeutral90} level={mobileView ? .9 : 1.3} text="Building a Community of Explorers" />
                    <Text style={{ fontSize: mobileView ? 17 : 19, textAlign: "center" }}>
                        HookHub's mission is to build a vibrant community of explorers, where hosts share their unique spaces, and travelers discover new horizons. Together, we promote sustainable and inclusive travel for a better world.
                    </Text>
                </CustomSection>
                <CustomDivider />

                <Buttons />
                <CustomDivider />

                <HelpForm authed={authed}/>
            </View >
            <FooterView />
        </ScrollView >
    );
}

const styles = StyleSheet.create({
    container: {
        margin: 20,
    },
    centeredRow: {
        alignItems: "center",
        justifyContent: "space-between",
        alignSelf: "center",
        flexDirection: "row",
        width: 170
    },
    buttonText: {
        color: colors.amplifyNeutral90,
        fontWeight: "500",
        fontSize: 17,
    },
    scrollView: {
        width: "100%",
    },
    helpButton: {
        borderColor: "grey",
        borderRadius: 10,
        width: 100,
        borderWidth: 1,
        backgroundColor: "grey",
        alignItems: "center",
        borderBottomWidth: 2,
        justifyContent: "center",
        alignSelf: "center",
        height: 40,
    },
    view: {
        alignItems: "center",
        justifyContent: "center",
        alignSelf: "center",
        marginBottom: 150,
        backgroundColor: "#fff"
    },
    image: {
        flex: 1,
        justifyContent: "center"
    },
    logoImage: {
        height: 50,
        justifyContent: 'center',
        width: 50,
        margin: 10,
        marginTop: 15
    },
});

export default AboutScreen;
