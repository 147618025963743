import React, { useState, useEffect } from "react";
import {
  View as ReactView,
  useWindowDimensions,
  Pressable,
  StyleSheet,
  Text,
  TouchableOpacity
} from "react-native";
import Modal from "react-native-modal";
import {
  AntDesign,
} from "@expo/vector-icons";
import Flex from "../CustomElements/Flex"
import SelectField from "../CustomElements/SelectField"
import Header from "../CustomElements/Header"
import { useSelector } from "react-redux";
import Card from "../CustomElements/Card";
import { colors } from "../../styles/colors";

const months = {
  Jan: "01",
  Feb: "02",
  Mar: "03",
  Apr: "04",
  May: "05",
  Jun: "06",
  July: "07",
  Aug: "08",
  Sept: "09",
  Oct: "10",
  Nov: "11",
  Dec: "12",
};

const displayMonths = [
  'Jan',
  'Feb',
  'Mar',
  'Apr',
  'May',
  'Jun',
  'July',
  'Aug',
  'Sept',
  'Oct',
  'Nov',
  'Dec',
]

const days = [
  "01",
  "02",
  "03",
  "04",
  "05",
  "06",
  "07",
  "08",
  "09",
  "10",
  "11",
  "12",
  "13",
  "14",
  "15",
  "16",
  "17",
  "18",
  "19",
  "20",
  "21",
  "22",
  "23",
  "24",
  "25",
  "26",
  "27",
  "28",
];
const years = ["2024", "2025", "2026", "2027", "2028"];

const MonthDisplayOption = ({ dateIncoming }) => {
  const optionDisplay = Object.values(dateIncoming)[0];
  const optionValue = Object.keys(dateIncoming)[0];
  return <option value={optionDisplay}>{optionValue}</option>;
};

const DisplayOption = ({ dateIncoming }) => {
  return <option value={dateIncoming}>{dateIncoming}</option>;
};

export const DatePicker = ({ type, date, updateDate=null, setError=null, disabled=false }) => {
  const [month, setMonth] = useState("");
  const [monthDisplay, setMonthDisplay] = useState("");
  const [year, setYear] = useState("");
  const [day, setDay] = useState("");
  const [daysInMonth, setDaysInMonth] = useState(days);
  const [dateModal, setDateModal] = useState(false);
  const { height, width } = useWindowDimensions();
  const { mobileView } = useSelector(state => state.currentUser);

  useEffect(() => {
    if (!!date) {
      const dateArray = date.split("-");
      setMonth(dateArray[1]);
      setDay(dateArray[2]);
      setYear(dateArray[0]);
    }
  }, [date]);

  useEffect(() => {
    if (!!month) {
      const addDaysForMonth = {
        "01": ["29", "30", "31"],
        "02": [],
        "03": ["29", "30", "31"],
        "04": ["29", "30"],
        "05": ["29", "30", "31"],
        "06": ["29", "30"],
        "07": ["29", "30", "31"],
        "08": ["29", "30", "31"],
        "09": ["29", "30"],
        '10': ["29", "30", "31"],
        '11': ["29", "30"],
        '12': ["29", "30", "31"],
      };
      const addDays = addDaysForMonth[month];
      setDaysInMonth(days.concat(addDays));
    }
  }, [month]);

  const checkNewDate = (v) => {
    setDay(v);
    if (!!year && !!month && !!v) {
      updateDate(`${year}-${month}-${v}`);
    }
  };

  const updateMonth = (v) => {
    setMonthDisplay(v)
    setMonth(months[v])
  }

  const resetAndOpen = () => {
    setError
    setMonth('')
    setYear('')
    setDay('')
    setDateModal(true);
  }

  return (
    <>
      <Pressable onPress={() => resetAndOpen()} disabled={disabled}>
        <ReactView>
          <Card
            width={135}
            margin={7}
            shadow={false}
            marginLeft={0}
            marginRight={0}
            padding={4}
            borderRadius={8}
          >
            <Text style={{ fontSize: 15}}>{type}</Text>
            <Text style={{ color: "#2f5894", fontSize: 17, marginTop: 6 }}>
              {date || "mm-dd-yyyy"}
            </Text>
          </Card>
        </ReactView>
      </Pressable>
      <Modal
        coverScreen={true}
        isVisible={dateModal}
        onBackdropPress={() => setDateModal(false)}
        onSwipeComplete={() => setDateModal(false)}
        deviceHeight={height}
        deviceWidth={width}
      >
        <ReactView style={styles.modalView}>
          <Text style={{ marginBottom: 25, fontSize: mobileView ? 25 : 36 }}>{type}</Text>
          <SelectField
            options={years}
            label="Year"
            labelHidden
            placeholder="year"
            value={year}
            onChange={(e) => setYear(e)}
          />
          {!!year && (
            <SelectField
              options={displayMonths}
              label="Month"
              labelHidden
              placeholder="month"
              value={monthDisplay}
              onChange={(e) => updateMonth(e)}
            />
          )}

          {!!month && daysInMonth && !!year && (
            <SelectField
              options={daysInMonth}
              label="Day"
              labelHidden
              placeholder="day"
              value={day}
              onChange={(e) => checkNewDate(e)}
            />
          )}

          {!!month && !!day && !!year && (
            <Pressable onPress={() => setDateModal(false)} style={{ marginRight: 25, marginTop: 25 }}>
              <AntDesign name="checkcircle" size={34} marginTop={45} color={colors.lightBlue} />
            </Pressable>
          )}
        </ReactView>
      </Modal>
    </>
  );
};

const styles = StyleSheet.create({
  backButtonText: {
    color: "blue",
    fontSize: 17,
    fontWeight: "bold",
  },
  modalView: {
    backgroundColor: "white",
    borderRadius: 20,
    padding: 35,
    alignItems: "center",
    justifyContent: "center",
    alignContent: "center",
    shadowColor: "#000",
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity: 0.25,
    shadowRadius: 4,
    elevation: 5,
  },
  forwardButtonText: {
    color: "#fff",
    fontSize: 20,
  },
  forwardButton: {
    borderColor: "grey",
    borderRadius: 10,
    marginTop: 20,
    width: 150,
    borderWidth: 1,
    backgroundColor: colors.orange,
    alignItems: "center",
    borderBottomWidth: 2,
    justifyContent: "center",
    alignSelf: "center",
    height: 40,
    padding: 20,
    shadowColor: "#252625",
    shadowOffset: { width: -3, height: 5 },
    shadowOpacity: 0.5,
  },
  modalText: {
    marginBottom: 15,
    textAlign: "center",
  },
});
