import React, { useEffect, useState } from "react";
import {
    Platform,
    Pressable,
    StyleSheet,
    Text,
    useWindowDimensions,
    View
} from "react-native";
import CustomDivider from "../CustomDivider";
import Flex from "../CustomElements/Flex"
import Header from "../CustomElements/Header"
import BookingRequestView from "./BookingRequestView";
import CustomModal from "../CustomElements/CustomModal";
import CloseButtonUnderlined from "../CustomElements/CloseButtonUnderlined";
import Card from "../CustomElements/Card";
import { colors } from "../../styles/colors";
import { AntDesign, Entypo, Ionicons } from "@expo/vector-icons";
import CustomButton from "../CustomElements/CustomButton";
import { globalStyles } from "../../styles/styles";
import CalendarView from "../space/CalendarView";
import { DatePicker } from "../space/DatePicker";
import Badge from "../CustomElements/Badge";
import { ErrorDisplay } from "../CustomElements/ErrorDisplay";
import { ReserveModalBody } from "./ReserveModalBody";
import { useNavigation } from "@react-navigation/native";
import { sendEmail } from "../../lambdaFunctions/sendEmail/sendEmail";
import { createBookingRequestMutation, updateBookingRequestMutation } from "../../api/bookingRequests";
import { createBookingMutation } from "../../api/bookings";
import { updateUserMutation } from "../../api/user";
import { notifyHookhub } from "../../lambdaFunctions/notifyHookhub";
import moment from "moment";


export const ReserveModal = ({
    mobileView,
    modalVisible,
    setShowReserveModal,
    bookedDays,
    userData = null,
    hostEmail,
    payMonthly,
    hookhubPayout,
    hostFeeCost,
    hostPayout,
    monthlyHostPayout,
    monthlyHookhubPayout,
    monthlyPaymentAmount,
    setMonthlyPayout,
    numberOfMonths,
    initialDate,
    subtext = null,
    currentDateSpan,
    currentMonthSpan,
    checkinDate,
    setCheckinDate,
    checkoutDate,
    tryUpdateDateSpan,
    checkinDateError,
    checkoutDateError,
    displaySpace,
    setDisplaySpace,
    displayProperty,
    spaceTypes,
    activeSpaces,
    activeTabIndex,
    setActiveTabIndex,
    setSelectedDateSpan,
    datesSelectedError,
    renterID = null,
    setDatesSelectedError,
    stripeConnectedAccountId,
    renterFeeCost,
    renterStripeFee,
    renterEmail,
    baseCost,
    stripeCustomerID,
    stripePaymentIntentID,
    bookingRequestID,
    nightlyPrice,
    hostID,
    monthOptionDisabled,
    yearOptionDisabled,
    propertyTitle,
    propertyID,
    instantBook,
    rentalCost,
    clientSecret,
    full_refund_date,
    half_refund_date,
    full_refund_enabled,
    half_refund_enabled,
    no_refund_enabled,
    space_rules,
    noSmoking,
    adultsOnly,
    spaceID,
    paymentSchedule,
    nextPaymentsSchedule,
    rentalType,
    ephemeralKey
}) => {
    const { address } = displayProperty;
    const { width } = useWindowDimensions();
    const navigation = useNavigation();
    const [showPaymentInput, setShowPaymentInput] = useState(false);
    const [loadingStatus, setLoadingStatus] = useState("");
    const [loadingPercentage, setLoadingPercentage] = useState("5%");
    const [loading, setloading] = useState(false);
    const [error, setError] = useState(false);
    const [liability, setLiability] = useState(false);
    const [agreeRulesAndRegulations, setAgreeRulesAndRegulations] = useState(false);
    const [offPlatformPolicy, setOffPlatformPolicy] = useState(false);
    const [refundPolicy, setrefundPolicy] = useState(false);
    const [signature, setSignature] = useState(false);
    const [agreed, setAgreed] = useState(false);
    const [fullRefundDateIneligible, setfullRefundDateIneligible] = useState(true);
    const [halfRefundDateIneligible, sethalfRefundDateIneligible] = useState(true);

    const createRequestAndUpdates = async (paymentMethodID) => {
        setLoadingPercentage("35%")
        setLoadingStatus("Let's do this...")
        const requestData = {
            id: bookingRequestID,
            full_refund_date,
            half_refund_date,
            full_refund_enabled,
            half_refund_enabled,
            no_refund_enabled,
            hostEmail: hostEmail,
            renterEmail: userData?.email,
            rentalType: rentalType,
            cancelled: false,
            check_in: checkinDate,
            check_out: checkoutDate,
            confirmed: !!instantBook,
            reviewed: !!instantBook,
            hostID: hostID,
            renterID: userData?.sub,
            bookedDays: currentDateSpan,
            members: [hostID, userData?.sub],
            spaceID: displaySpace.id,
            spaceTitle: displaySpace.title,
            propertyTitle: propertyTitle,
            spaceCity: address?.cityName,
            propertyID: propertyID,
            spaceState: address?.stateName,
            baseBookingCost: baseCost,
            renterTotalBookingCost: rentalCost,
            hookhubPayout: hookhubPayout,
            hostPayout: hostPayout,
            hostFeeCost: hostFeeCost,
            renterFeeCost: renterFeeCost,
            renterStripeFee: renterStripeFee,
            payMonthly: payMonthly,
            numberOfMonths: currentMonthSpan,
            monthlyHookhubPayout: monthlyHookhubPayout,
            monthlyPaymentAmount: monthlyPaymentAmount,
            renter_acknowledge_liability: true,
            paymentSchedule: paymentSchedule,
            nextPaymentsSchedule: nextPaymentsSchedule,
            monthlyHostPayout: monthlyHostPayout,
            stripeCustomerID,
            stripePaymentIntentID,
            stripePaymentMethodID: paymentMethodID
        };

        const newBookingRequest = await createBookingRequestMutation({ data: requestData });
        if (newBookingRequest) {
            if (instantBook) {
                createBooking({ bookingData: requestData })
            } else {
                resetAndClose(paymentMethodID);
            }
        } else {
            const errorSubject = "ERROR CREATING BOOKING"
            const emailBody = `ERRORCODE on CREATING booking request. ERROR at step "createBookingRequest". Renter ID: ${renterID}. Property ID: ${propertyID}. Space ID: ${spaceID}. check-in: ${checkinDate}. check-out: ${checkoutDate}.`
            handleError({ emailBody, successCreatingRequest: false, errorSubject, errorText: "Error creating new Booking. No worries, we are contacting Hookhub now. Hookhub will reach out to you soon." })
        }
    };

    const createBooking = async ({ bookingData }) => {
        const data = {
            ...bookingData,
            cancelledBeforeCheckIn: false,
            stripeHostConnectedAccountId: stripeConnectedAccountId
        };

        const newBooking = await createBookingMutation({ data });
        if (newBooking) {
            setLoadingPercentage("90%")
            const emailSubject = `Your new Booking`;
            const emailBody = `You've Booked a Space! For: '${propertyTitle}'. Logon to Hookhub to see details under 'My Trips'.`;
            sendEmail({ emailAddress: renterEmail, emailBody, emailSubject });
            resetAndClose(bookingData.stripePaymentMethodID);
        } else {
            const emailBody = `ERRORCODE on CREATING booking request. ERROR at step "createBooking". RENTER made a REQUEST on an INSTANT_BOOK space. Attempted to create BOOKING and had an error >>> THE ID for the BOOKING REQUEST: ${bookingRequestID}`
            handleError({ emailBody })
        }
    };

    const resetAndClose = async (paymentMethodID) => {
        const data = {
            id: renterID,
            stripeCustomerID: stripeCustomerID,
            stripePaymentMethodID: paymentMethodID
        };
        await updateUserMutation({ currentUserData: userData, data })
        const emailSubject = instantBook ? "You have a BOOKING" : "You have a BOOKING REQUEST";
        const emailBody = instantBook ? `You have a new BOOKING on Hookhub!! Your space: '${displaySpace.title}': Please check it out on hookhub.co/.` : `You have a new BOOKING REQUEST on Hookhub!! Your space: '${displaySpace.title}': Please logon to Hookhub to view the renter that is requesting and either APPROVE or DENY this request.`
        sendEmail({ emailAddress: hostEmail, emailBody, emailSubject })
        setError(false);
        setloading(false);
        setLiability(false);
        setrefundPolicy(false)
        setSignature(false)
        setAgreeRulesAndRegulations(false)
        setOffPlatformPolicy(false)
        setAgreed(false)
        setShowReserveModal();
        navigation.reset({
            index: 0,
            routes: [
                {
                    name: "trips",
                    screen: "trips"
                }
            ]
        })
    };

    const close = async () => {
        setError(false);
        setloading(false);
        setLiability(false);
        setrefundPolicy(false)
        setSignature(false)
        setAgreeRulesAndRegulations(false)
        setOffPlatformPolicy(false)
        setAgreed(false)
        setShowReserveModal();
    };

    const handleError = async ({ emailBody, successCreatingRequest = true, errorText = "Oh no! There was an issue making this Booking. Hookhub has been notified and will reach out soon. If this is urgent, please contact Hookhub now on our Help screen." }) => {
        const subject = "ERROR when renter attempted making a REQUEST!"
        setError(errorText);
        notifyHookhub({ subject, body: emailBody, userID: hostID, userEmail: hostEmail });
        setLoadingStatus("")
        setloading(false)
        if (successCreatingRequest) {
            let data = {
                id: bookingRequestID,
                errorCode: emailBody
            }
            await updateBookingRequestMutation({ data });
        }
    };

    useEffect(() => {
        if (liability && refundPolicy && signature && agreeRulesAndRegulations && offPlatformPolicy) {
            setAgreed(true);
        } else { setAgreed(false) }
    }, [liability, refundPolicy, signature, agreeRulesAndRegulations, offPlatformPolicy]);

    useEffect(() => {
        if (!!full_refund_date) {
            let dateCheck1 = moment(moment(full_refund_date).format("YYYY-MM-DD")).isBefore(moment())
            setfullRefundDateIneligible(dateCheck1);
        }
        if (!!half_refund_date) {
            let dateCheck2 = moment(moment(half_refund_date).format("YYYY-MM-DD")).isBefore(moment())
            sethalfRefundDateIneligible(!!dateCheck2);
        }
    }, []);

    return (
        <CustomModal modalVisible={modalVisible} modalWidth={mobileView ? width / 1.1 : width / 1.2} setModalVisible={() => setShowReserveModal(false)}>
            <View style={[globalStyles.centerColumn, { width: mobileView ? width / 1.15 : width / 1.3 }]}>
                <View style={globalStyles.spaceBetween}>
                    {!!currentDateSpan.length && (
                        <CustomButton
                            width={140}
                            marginTop={5}
                            height={35}
                            backgroundColor={colors.brandGrey}
                            onPress={(e) => setSelectedDateSpan([])}
                        >
                            <View style={globalStyles.center}>
                                <AntDesign name="caretleft" size={mobileView ? 19 : 24} color="grey" />
                                <Text style={{ fontSize: 14 }}>edit selection</Text>
                            </View>
                        </CustomButton>
                    )}
                </View>
                <View style={[globalStyles.flexStartColumn, { marginVertical: 15 }]}>
                    <Text style={styles.prices}>{propertyTitle}</Text>
                </View>
                <View style={{ flexDirection: "row", marginTop: 0, marginBottom: 0 }}>
                    <Header padding={0} level={1.1} text={` $ ${Number(nightlyPrice).toLocaleString("en-US", { minimumFractionDigits: 2 })}`} />
                    <Text style={styles.prices}> night</Text>
                </View>
                <View style={{ marginBottom: 10 }}>
                    {spaceTypes.map((v, i) => (
                        <View key={i}>
                            {activeTabIndex === i ? (
                                <CustomButton
                                    width={85}
                                    marginBottom={1}
                                    height={25}
                                    backgroundColor={colors.orange}
                                    onPress={(e) => setActiveTabIndex(i)}
                                >
                                    <Text style={{ fontSize: 14 }}>{v.title}</Text>
                                </CustomButton>
                            ) : (
                                <CustomButton
                                    width={85}
                                    marginBottom={1}
                                    height={25}
                                    backgroundColor={colors.brandGrey}
                                    onPress={(e) => setActiveTabIndex(i)}
                                >
                                    <Text style={{ fontSize: 14 }}>{v.title}</Text>
                                </CustomButton>
                            )}
                        </View>
                    ))}
                </View>
                {!!subtext && <Text style={styles.subtext}>* {subtext}</Text>}

                {!!datesSelectedError && <ErrorDisplay error={`That date range is not available for the selected Space: ${displaySpace.title}`} customWidth={width / 3.2} />}

                {!datesSelectedError && currentDateSpan.length ? (
                    <View style={[globalStyles.centerColumn, { width: mobileView ? width / 1.35 : width / 1.6 }]}>
                        <Flex
                            alignItems="center"
                            justifyContent="center"
                            textAlign="center"
                            alignContent="center"
                            width={mobileView ? width / 1.25 : width / 1.4}
                            direction={"row"}
                            marginTop={15}
                        >
                            <DatePicker
                                disabled={true}
                                type={"CHECK-IN"}
                                date={checkinDate}
                                updateDate={(v) => setCheckinDate(v)}
                                error={checkinDateError}
                            />
                            <DatePicker
                                disabled={true}
                                type={"CHECK-OUT"}
                                date={checkoutDate}
                                error={checkoutDateError}
                                updateDate={(v) => tryUpdateDateSpan(v)}
                            />
                        </Flex>
                        <BookingRequestView
                            mobileView={mobileView}
                            rentalCost={rentalCost}
                            numberOfMonths={numberOfMonths}
                            width={width}
                            currentDateSpan={currentDateSpan}
                            spaceTypes={spaceTypes}
                            activeTabIndex={activeTabIndex}
                            noSmoking={noSmoking}
                            monthlyPrice={monthlyPaymentAmount}
                            monthlyPayout={payMonthly}
                            setMonthlyPayout={(v) => setMonthlyPayout(!payMonthly)}
                            monthOptionDisabled={monthOptionDisabled}
                            baseCost={baseCost}
                            renterFee={renterFeeCost}
                            currentMonthSpan={currentMonthSpan}
                            loading={loading}
                            yearOptionDisabled={yearOptionDisabled}
                            allPaymentsSchedule={paymentSchedule}
                            nextPaymentsSchedule={nextPaymentsSchedule}
                        />
                        <Text style={{ textAlign: 'center', fontSize: 15, marginTop: 25, marginBottom: 10 }}>{`*NOTE: Payment is not withdrawn now. IF Booking Request is approved, then ${payMonthly ? "first " : ""}payment will be made.`}</Text>
                        {Platform.OS === "ios" && <ErrorDisplay error="Please note! If you are using an iPhone, we recommend creating your booking request on a laptop or desktop if you have any issues. (The iOs app will be available soon)" />}
                        {!showPaymentInput && (
                            <CustomButton
                                width={mobileView ? 200 : 300}
                                height={40}
                                marginTop={10}
                                backgroundColor={colors.mellowOrange}
                                borderRadius={8}
                                border={colors.hookhubOrangeDark}
                                disabled={monthOptionDisabled || yearOptionDisabled || datesSelectedError}
                                onPress={e => setShowPaymentInput(true)}
                            >
                                <Text style={{ fontSize: 17 }}>{instantBook ? "Create Booking" : "Create Booking Request"}</Text>
                            </CustomButton>
                        )}
                        {!!showPaymentInput && !(monthOptionDisabled || yearOptionDisabled || datesSelectedError) && (
                            <>
                                <Card
                                    backgroundColor={colors.mellowOrange}
                                    alignItems="center"
                                    justifyContent="center"
                                    alignContent="center"
                                    width={mobileView ? width / 1.2 : 300}
                                    textAlign="center"
                                    marginTop={10}
                                    marginBottom={5}
                                >
                                    <Header level={mobileView ? .92 : 1.2} text="Add payment details"></Header>
                                    <Entypo
                                        name={"arrow-bold-down"}
                                        size={24}
                                        color={colors.amplifyNeutral80}
                                    />
                                </Card>
                                <CustomDivider />
                                <ReserveModalBody
                                    loading={loading}
                                    close={() => close()}
                                    instantBook={instantBook}
                                    loadingStatus={loadingStatus}
                                    loadingPercentage={loadingPercentage}
                                    signature={signature}
                                    setSignature={(v) => setSignature(!signature)}
                                    offPlatformPolicy={offPlatformPolicy}
                                    setOffPlatformPolicy={(v) => setOffPlatformPolicy(!offPlatformPolicy)}
                                    refundPolicy={refundPolicy}
                                    setrefundPolicy={(v) => setrefundPolicy(!refundPolicy)}
                                    agreeRulesAndRegulations={agreeRulesAndRegulations}
                                    setAgreeRulesAndRegulations={(v) => setAgreeRulesAndRegulations(!agreeRulesAndRegulations)}
                                    liability={liability}
                                    setLiability={(v) => setLiability(!liability)}
                                    agreed={agreed}
                                    error={error}
                                    setError={(v) => setError(v)}
                                    width={width}
                                    userData={userData}
                                    mobileView={mobileView}
                                    stripeConnectedAccountId={stripeConnectedAccountId}
                                    modalVisible={modalVisible}
                                    setShowReserveModal={() => setShowReserveModal()}
                                    payMonthly={payMonthly}
                                    space_rules={space_rules}
                                    noSmoking={noSmoking}
                                    adultsOnly={adultsOnly}
                                    clientSecret={clientSecret}
                                    stripeCustomerID={stripeCustomerID}
                                    bookingRequestID={bookingRequestID}
                                    full_refund_date={full_refund_date}
                                    half_refund_date={half_refund_date}
                                    full_refund_enabled={full_refund_enabled}
                                    half_refund_enabled={half_refund_enabled}
                                    no_refund_enabled={no_refund_enabled}
                                    setloading={(v) => setloading(v)}
                                    setLoadingStatus={(v) => setLoadingStatus(v)}
                                    setLoadingPercentage={(v) => setLoadingPercentage(v)}
                                    halfRefundDateIneligible={halfRefundDateIneligible}
                                    fullRefundDateIneligible={fullRefundDateIneligible}
                                    handleSubmit={(paymentMethodID) => createRequestAndUpdates(paymentMethodID)}
                                    handleError={(v) => handleError(v)}
                                    ephemeralKey={ephemeralKey}
                                    renterID={renterID}
                                    renterEmail={renterEmail}
                                />
                            </>
                        )}
                    </View>
                ) : (
                    <>
                        {activeSpaces.length > 1 && (
                            <View style={[globalStyles.flexStartColumn, { width: mobileView ? width / 1.2 : width / 2.7, marginTop: 10 }]}>
                                <Text style={styles.prices}>Availability for spaces on this property</Text>
                                {(activeSpaces).map((v, i) => (
                                    <Pressable key={i} onPress={() => setDisplaySpace(v)}>
                                        <Badge onPress={() => setDisplaySpace(v)} margin={1} padding={-1} variation={displaySpace.id === v.id ? "warning" : "info"} width={mobileView ? width / 1.3 : width / 2.75} alignContent="center" alignItems="center" justifyContent="flex-start" >
                                            <View style={{ flexDirection: "row", alignItems: "center", justifyContent: "flex-start" }}>
                                                <Ionicons name={displaySpace.id === v.id ? "radio-button-on-sharp" : "radio-button-off-sharp"} size={24} color={displaySpace.id === v.id ? colors.amplifyNeutral90 : colors.amplifyNeutral80} />
                                                <Text
                                                    style={{ marginLeft: 5, fontSize: 16, width: "90%" }}
                                                    numberOfLines={1}
                                                    ellipsizeMode="tail"
                                                >
                                                    {v.title}
                                                </Text>
                                            </View>
                                        </Badge>
                                    </Pressable>
                                ))}
                            </View>
                        )}
                        <CalendarView
                            mobileView={mobileView}
                            space={displaySpace}
                            setSelectedDateSpan={(v) => setSelectedDateSpan(v)}
                            currentDateSpan={currentDateSpan}
                            initialDate={initialDate}
                            width={width}
                            error={datesSelectedError}
                            setError={(v) => setDatesSelectedError(!!v)}
                        />
                    </>
                )}
                <CloseButtonUnderlined onPress={() => close()} />
            </View>
        </CustomModal>
    );
};

const styles = StyleSheet.create({
    modalView: {
        margin: 20,
        backgroundColor: "white",
        borderRadius: 20,
        alignContent: "center",
        justifyContent: "center",
        alignSelf: "center",
        padding: 35,
        alignItems: "center",
        shadowColor: "#000",
        shadowOffset: {
            width: 0,
            height: 2,
        },
        shadowOpacity: 0.25,
        shadowRadius: 4,
        elevation: 5,
    },
    forwardButtonText: {
        color: "#fff",
        fontSize: 20,
    },
    forwardButton: {
        borderColor: "grey",
        borderRadius: 10,
        marginTop: 20,
        width: 150,
        borderWidth: 1,
        backgroundColor: colors.orange,
        alignItems: "center",
        borderBottomWidth: 2,
        justifyContent: "center",
        alignSelf: "center",
        height: 40,
        padding: 20,
        shadowColor: "#252625",
        shadowOffset: { width: -3, height: 5 },
        shadowOpacity: 0.5,
    },
    modalText: {
        marginBottom: 15,
        textAlign: "center",
    },
    backButtonText: {
        color: "#484a4a",
        fontSize: 17,
        fontWeight: "bold",
        textDecorationLine: "underline"
    },
    backButton: {
        marginTop: 5,
        alignItems: "center",
        justifyContent: "center",
        alignSelf: "center",
    },
    image: {
        aspectRatio: 1 / .7,
        borderRadius: 10,
    },
});
