import React, { useState } from "react";
import {
    Pressable,
    StyleSheet,
    Text,
    View
} from "react-native";
import { setStringAsync } from 'expo-clipboard';
import CustomModal from "../CustomElements/CustomModal";
import CustomSection from "../CustomElements/CustomSection";
import { colors } from "../../styles/colors";
import CloseButtonUnderlined from "../CustomElements/CloseButtonUnderlined";
import { FontAwesome5, Ionicons } from "@expo/vector-icons";

export const ShareModal = ({
    modalVisible,
    setModalVisible,
    width,
    mobileView,
    shareLink
}) => {
    const [copied, setCopied] = useState(false)

    const copyToClipboard = async () => {
        await setStringAsync(shareLink);
    };

    const closeThis = () => {
        setCopied(false)
        setModalVisible()
    };

    return (
        <CustomModal
            modalVisible={modalVisible}
            setModalVisible={() => setModalVisible(!modalVisible)}
            modalWidth={mobileView ? width / 1.2 : width / 1.4}
        >
            <View style={styles.centeredView}>
                <Text style={{ fontSize: 17, textAlign: "center", margin: mobileView ? 10 : 20 }}>
                    Share this Space
                </Text>
                <Pressable onPress={() => copyToClipboard()} onPressOut={() => setCopied(true)}>
                    <CustomSection backgroundColor={colors.mellowOrange} customWidth={mobileView ? width / 1.2 : width / 1.5}>
                        <View style={styles.row}>
                            <View style={{ width: mobileView ? width / 1.2 : width / 1.6 }}>
                                <Text style={styles.linkText}>{shareLink}</Text>
                            </View>
                            {copied ? (
                                <View style={styles.copyArea}>
                                    <Ionicons
                                        name="checkmark-circle-sharp"
                                        size={25}
                                        color={colors.amplifyNeutral90}
                                        marginLeft={10}
                                    />
                                    <Text style={styles.copyLinkText}>Copied!</Text>
                                </View>

                            ) : (
                                <View style={styles.copyArea}>

                                    <FontAwesome5
                                        name="copy"
                                        size={25}
                                        color={colors.amplifyNeutral90}
                                        marginLeft={10}
                                    />
                                    <Text style={styles.copyLinkText}>Copy Link</Text>
                                </View>

                            )}
                        </View>
                    </CustomSection>
                </Pressable>
                <CloseButtonUnderlined onPress={() => closeThis()} />
            </View>
        </CustomModal >

    );
};

const styles = StyleSheet.create({
    modalView: {
        margin: 13,
        backgroundColor: "white",
        borderRadius: 20,
        shadowColor: "#000",
        shadowOffset: {
            width: 0,
            height: 2,
        },
        shadowOpacity: 0.25,
        shadowRadius: 4,
        elevation: 5,
    },
    centeredView: {
        flex: 1,
        justifyContent: "center",
        alignItems: "center",
        alignSelf: "center",
        textAlign: "center"
    },
    linkText: {
        fontSize: 17, textAlign: "center", margin: 20,
        borderWidth: 1,
        backgroundColor: "#fff",
        borderRadius: 20,
        padding: 8,
        flexShrink: 1
    },
    modalText: {
        marginBottom: 15,
        textAlign: "center",
    },
    row: {
        flex: 1,
        flexDirection: "row",
        flexWrap: "wrap",
        justifyContent: "center",
        alignItems: "center",
        alignSelf: "center",
        textAlign: "center"
    },
    copyLinkText: {
        fontSize: 17,
        marginLeft: 10
    },
    copyArea: {
        flexDirection: "row",
        justifyContent: "center",
        alignItems: "center",
        alignSelf: "center",
        textAlign: "center"

    }
});
