import React from "react";
import { ActivityIndicator, View } from "react-native";
import { globalStyles } from "../styles/styles";
import { colors } from "../styles/colors";

export const LoadingComponent = ({ marginVertical=2 }) => {
  return (
    <View style={[globalStyles.center, { marginVertical: marginVertical }]}>
      <ActivityIndicator size="large" color={colors.orange} />
    </View>
  );
};
