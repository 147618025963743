import React from 'react';
import {
    View,
    Dimensions,
    StyleSheet,
    Platform,
} from 'react-native';
import { colors } from '../../styles/colors';

const FixedHeightCard = ({
    children,
    elevation = 4,
    cornerRadius = 4,
    backgroundColor = "#fff",
    border = "#000",
    width = "90%",
    height = 20,
    margin = 0,
    padding = 0,
    marginBottom = 0,
    marginTop = 0,
    marginRight = 0,
    marginLeft = 0,
    textAlign = "left",
    alignItems = "center",
    justifyContent = "center",
    alignContent = "center"
}) => {

    const cardStyle = Platform.select({
        ios: () =>
            StyleSheet.create({
                container: {
                    borderRadius: cornerRadius,
                }
            }),
        android: () =>
            StyleSheet.create({
                container: {
                    elevation: elevation,
                    borderRadius: cornerRadius,
                }
            }),
        web: () =>
            StyleSheet.create({
                container: {
                    elevation: elevation,
                    borderRadius: cornerRadius,
                }
            })
    })();

    return (
        <View style={[cardStyle.container, styles.shadowProp, {
            margin: margin,
            marginBottom: marginBottom,
            marginLeft: marginLeft,
            marginRight: marginRight,
            marginTop: marginTop,
            width: width,
            height: height,
            borderColor: border,
            padding: padding,
            backgroundColor: backgroundColor,
            alignItems: alignItems,
            textAlign: textAlign,
            justifyContent: justifyContent,
            alignContent: alignContent,
            borderWidth: .1,
            borderBottomWidth: .5,
            borderColor: colors.amplifyNeutral60,
        }]}>
            {children}
        </View>
    )

}

const styles = StyleSheet.create({
    shadowProp: {
        borderRadius: 8,
        shadowColor: colors.amplifyNeutral80,
        shadowOffset: { width: .5, height: 1.5 },
        shadowOpacity: 0.6,
        shadowRadius: 4,
    },
})
export default FixedHeightCard;