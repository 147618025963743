import React from "react";
import {
    View as ReactView,
    StyleSheet,
    useWindowDimensions,
    Text,
    View
} from "react-native";
import { useSelector } from "react-redux";
import Flex from "../CustomElements/Flex"
import Card from "../CustomElements/Card"
import Header from "../CustomElements/Header"
import CustomButton from "../CustomElements/CustomButton";
import { colors } from "../../styles/colors";
import { CustomSimpleToggleButton } from "../CustomElements/CustomSimpleToggleButton";


export const RefundPicker = ({ type, time, updateTime }) => {
    const { width } = useWindowDimensions();
    const { mobileView } = useSelector(state => state.currentUser);

    return (
        <Card
            backgroundColor={colors.amplifyNeutral20}
            border={colors.amplifyNeutral60}
            padding={9}
            width={width / 1.3}
            marginBottom={15}
        >
            <Flex direction="column" width={width/1.35} justifyContent="center" alignContent="center" alignItems="center">
                <Header marginBottom={20} color={colors.amplifyNeutral60} text={type}></Header>
                <Flex direction={mobileView?"column":"row"} justifyContent="center" alignContent="center" alignItems="center" width={width/1.35}>
                    <CustomSimpleToggleButton
                        isPressed={time == 0}
                        width={100}
                        onChange={() => updateTime(0)}
                        value={'not an option'}
                    />
                    <Text style={{ margin: 10, textAlign:"center" }}>or</Text>
                    <View style={{flexDirection:"row"}}>
                    {/* <Flex justifyContent="center" alignContent="center" alignItems="center"> */}
                    <CustomSimpleToggleButton
                        isPressed={time >= 1}
                        onChange={() => updateTime(1)}
                        width={mobileView?35:40}
                        value={1}
                    />
                    <CustomSimpleToggleButton
                        isPressed={time >= 2}
                        onChange={() => updateTime(2)}
                        width={mobileView?35:40}
                        value={2}
                    />
                    <CustomSimpleToggleButton
                        isPressed={time >= 3}
                        value={3}
                        onChange={() => updateTime(3)}
                        width={mobileView?35:40}
                    />
                    <CustomSimpleToggleButton
                        isPressed={time === 4}
                        value={4}
                        onChange={() => updateTime(4)}
                        width={mobileView?35:40}
                    />
                    </View>
                    {/* </Flex> */}
                </Flex>
            </Flex >
        </Card >
    );
};

const styles = StyleSheet.create({
    backButtonText: {
        color: "blue",
        fontSize: 17,
        fontWeight: "bold",
    },
    modalView: {
        margin: 20,
        backgroundColor: "white",
        borderRadius: 20,
        padding: 35,
        alignItems: "center",
        shadowColor: "#000",
        shadowOffset: {
            width: 0,
            height: 2,
        },
        shadowOpacity: 0.25,
        shadowRadius: 4,
        elevation: 5,
    },
    forwardButtonText: {
        color: "#fff",
        fontSize: 20,
    },
    forwardButton: {
        borderColor: "grey",
        borderRadius: 10,
        marginTop: 20,
        width: 150,
        borderWidth: 1,
        backgroundColor: colors.orange,
        alignItems: "center",
        borderBottomWidth: 2,
        justifyContent: "center",
        alignSelf: "center",
        height: 40,
        padding: 20,
        shadowColor: "#252625",
        shadowOffset: { width: -3, height: 5 },
        shadowOpacity: 0.5,
    },
    modalText: {
        marginBottom: 15,
        textAlign: "center",
    },
});
