import { StyleSheet, useWindowDimensions, Pressable, Text, View } from "react-native";
import Flex from "../CustomElements/Flex"
import { AntDesign } from "@expo/vector-icons";
import CustomDivider from "../CustomDivider";
import CustomModal from "../CustomElements/CustomModal";
import { colors } from "../../styles/colors";
import { useState } from "react";
import { globalStyles } from "../../styles/styles";

export default function TermsConditionsModal({ }) {
    const { width } = useWindowDimensions();
    const [termsConditionsOpen, setTermsConditionsOpen] = useState(false);


    return (
        <View>
            <View style={globalStyles.center}>
                <Pressable onPress={() => setTermsConditionsOpen(true)}>
                    <Text style={{ color: colors.amplifyNeutral90 }}>
                        Terms
                    </Text>
                </Pressable>
            </View>
            <CustomModal modalVisible={termsConditionsOpen} setModalVisible={() => setTermsConditionsOpen(!termsConditionsOpen)} modalWidth={width / 1.15}>
                <View>
                    <Flex width={width / 1.3}
                        alignContent="center"
                        justifyContent="center"
                        alignItems="center"
                        direction="column"
                        backgroundColor={colors.amplifyNeutral60}
                        marginTop={30}
                    >
                        <Text style={styles.titleText} marginBottom={2}>Terms & Conditions</Text>
                        <CustomDivider />
                        <Text style={styles.marginVertical}>Last updated: July 2024</Text>
                        <Text style={styles.marginBottom}>
                            By using or accessing the Hookhub app or website on which these Terms and Conditions are posted via a link or otherwise (each referred to herein as a “Site”), you acknowledge and agree that you are subject to the following terms and conditions, as well as our Privacy Policy, which also governs your use of the Site, and is incorporated by reference (These Terms and Conditions with the Privacy Policy shall be referred to as the “Terms“). Please read these Terms carefully, as they contain important information about limitations of liability and resolution of disputes through arbitration rather than court.
                        </Text>
                        <Text style={styles.marginBottom}>If you do not fully agree to these Terms and any other terms and conditions posted or linked to any Site, you are not authorized to access or otherwise use the Site. Under these Terms, “use“ or “access“ of the Site specifically includes any direct or indirect access or use of the Site or any cached version of the Site and any direct or indirect access or use of any information or content on the Site, regardless of how obtained and the term “Site“ includes, without limitation, any cached version thereof.</Text>
                        <Text style={styles.marginBottom}>The term “you“ refers to the user visiting the Site, listing a property, and/or requesting a reservation on this Site, or our customer service agents. You should read through all the Terms carefully. The Terms constitute a legally binding agreement between you and HomeAway. You are not authorized to use this Site unless you are at least 18 years of age and able to enter into legally binding contracts. We do not knowingly collect the information of anyone under the age of 18. If you arrived on the Site after having been re-directed or otherwise clicking on another website, you agree that these Terms shall govern your use of this Site.</Text>

                        <Text style={styles.subText} marginBottom={2}>1. Hookhub is a Venue and We are Not a Party to any Rental Agreement or other Transaction Between Users of the Site.</Text>
                        <Flex width={width / 1.3} direction="column" marginLeft={4}>
                            <Text>
                                {`\u2022 ${"We urge all users to be responsible about their use of this Site and any transaction entered into as a result of either listing a property or renting a property. We do not own or manage, nor can we contract for, any rental property listed on a Site. The Site provides an on-line marketplace to allow homeowners and property managers who advertise on the Site (each, a “host”) to offer for rent in a variety of pricing formats, a specific short term, long term or storage rental property to potential renters or renters."}`}
                            </Text>
                            <Text>
                                {`\u2022 ${" “Members“ may also include property owners or managers who originally advertised their properties on another website and their listings have been redistributed on the Site. We also may offer online booking or other tools or services to allow users to communicate with each other and enter into rental agreements or other transactions."}`}
                            </Text>
                            <Text>
                                {`\u2022 ${"We are not a party to any rental or other agreement between users. This is true even if the Site allows you to book a rental or provides other ancillary products or services, as the Site may facilitate booking a rental or other tools, services or products, but we are not a party to any rental or other agreement between users. As a result, any part of an actual or potential transaction between a traveler and a member, including the quality, condition, safety or legality of the properties advertised, the truth or accuracy of the listings (including the content thereof or any review relating to any traveler or property), the ability of members to rent a vacation property or the ability of travelers to contract for properties are solely the responsibility of each user. You acknowledge and agree that you may be required to enter into one or more separate agreements, waivers or terms and conditions before making a booking."}`}
                            </Text>
                            <Text>
                                {`\u2022 ${"Users agree that they are responsible for, and agree to abide by, all laws, rules and regulations applicable to their use of the Site, their use of any tool, service or product offered on the Site and any transaction they enter into on the Site or in connection with their use of the Site."}`}
                            </Text>
                            <Text>
                                {`\u2022 ${"Members further agree that they are responsible for and agree to abide by all laws, rules, ordinances, or regulations applicable to the listing of their rental property and the conduct of their rental business, including but not limited to any and all laws, rules, ordinances, regulations or other requirements relating to taxes, credit cards, data and privacy, permits or license requirements, zoning ordinances, safety compliance and compliance with all anti-discrimination and fair housing laws, as applicable. Please be aware that, even though we are not a party to any rental transaction and assume no liability for legal or regulatory compliance pertaining to rental properties listed on the Site, there may be circumstances where we are nevertheless legally obligated (as we may determine in our sole discretion) to provide information relating to your listing in order to comply with requests from governmental bodies in relation to investigations, litigation or administrative proceedings, and we may choose to comply with such obligations in our sole discretion. Members who accept credit card, banking or other payment information from travelers agree to properly handle and safeguard all such information in accordance with applicable legal and regulatory requirements and best practices."}`}
                            </Text>
                            <Text>
                                {`\u2022 ${"Although most travel is completed without a serious incident, travel to some destinations may involve more risk than others. We urge travelers to research the location they wish to visit and to review travel prohibitions, warnings, announcements and advisories issued by the United States Government before booking. Information may be found at www.state.gov, www.tsa.gov, www.dot.gov, www.faa.gov, www.cdc.gov, www.treas.gov/ofac and www.customs.gov."}`}
                            </Text>
                            <Text>
                                {`\u2022 ${"While we do take certain measures with a goal to assist users to avoid potentially fraudulent or other illegal activity of which we become aware, we assume no liability or obligation to take any such measures or actions. When we provide warnings or messages to users about any such activity, we do not warrant that such messages are accurate or that such messages will reach any or all users they should have reached in a timely manner or at all or that such messages or measures will prevent any harm or otherwise have any impact."}`}
                            </Text>
                        </Flex>

                        <Text style={styles.subText} marginBottom={2}>2. Hookhub's Off-Platform Policy</Text>
                        <Flex width={width / 1.3} direction="column" marginLeft={4}>
                            <Text>
                                By hosting on Hookhub, you agree to comply with our terms and policies, which are outlined in our Terms of Service. Hookhub reserves the right to enforce these terms at its sole discretion. Should there be repeated or severe violations, Hookhub may suspend or permanently deactivate a user's account.
                            </Text>
                            <Text style={styles.marginBottom}>
                                To safeguard our community and uphold the integrity of our platform, the following actions are strictly prohibited:
                            </Text>
                            <Text>
                                <Text style={{ fontWeight: "bold" }}>1. </Text>
                                Taking transactions off the Hookhub platform for new, partial, or future bookings.
                            </Text>
                            <Text>
                                <Text style={{ fontWeight: "bold" }}>2. </Text>
                                Initiating contact with potential users before booking on Hookhub with the intention of moving the transaction off the platform (e.g., offering incentives to book off Hookhub).
                            </Text>
                            <Text>
                                <Text style={{ fontWeight: "bold" }}>3. </Text>
                                Requesting users to complete forms, make phone calls, send emails, or use non-Hookhub communication channels before confirming booking requests.
                            </Text>
                            <Text>
                                <Text style={{ fontWeight: "bold" }}>4. </Text>
                                Cancelling confirmed bookings and requesting users to rebook off Hookhub.
                            </Text>
                            <Text>
                                <Text style={{ fontWeight: "bold" }}>5. </Text>
                                Encouraging or instructing users to make bookings outside of Hookhub for repeat or future transactions.
                            </Text>
                            <Text>
                                <Text style={{ fontWeight: "bold" }}>6. </Text>
                                Including hyperlinks or buttons in messages to users, such as emails through Hookhub's communication feature, that redirect them away from Hookhub to external websites.
                            </Text>
                            <Text>
                                <Text style={{ fontWeight: "bold" }}>7. </Text>
                                Under no circumstances are you permitted to add additional fees onto the booking after-the-fact or any additional payments outside of the Hookhub booking.
                            </Text>
                        </Flex>

                        <Text style={styles.subText} marginBottom={2}>3. Limited License to Use Hookhub</Text>
                        <Text style={styles.marginBottom}>The license to use the Site only extends to the uses expressly described herein. The license to use the Site granted to users in these Terms does not include any right of collection, aggregation, copying, scraping, duplication, display or any derivative use of the Site nor any right of use of data mining, robots, spiders or similar data gathering and extraction tools without our prior written permission; provided, however, that a limited exception from the foregoing exclusion is provided to general purpose internet search engines that use tools to gather information for the sole purpose of displaying hyperlinks to the Site, provided they each do so from a stable IP address or range of IP addresses using an easily identifiable agent and comply with our robots.txt file. “General purpose internet search engines” do not include a website or search engine or other service that provides classified listings or property rental advertisements, or any subset of the same or which is in the business of providing vacation property rental services or other services that compete with us.</Text>
                        <Flex width={width / 1.3} direction="column" marginLeft={4}>
                            <Text>
                                {`\u2022 ${"Users are granted a limited, revocable non-exclusive license to access the Site and the content and services provided on the Site solely for the purpose of advertising a property, searching for a property, purchasing or researching (for the purpose of inquiring about purchasing) any of the products or services offered on any Site, participating in an interactive area hosted on the Site or for any other purpose clearly stated on a Site, all in accordance with the Terms. Any use of the Site that is not for one of these purposes or otherwise in accordance with the Terms or as otherwise authorized by us in writing is expressly prohibited."}`}
                            </Text>
                            <Text>
                                {`\u2022 ${"Any use of the Site or the tools and services on the Site for the purpose of booking or soliciting a rental of a property other than a property listed under a valid subscription or pay-per-booking product;"}`}
                            </Text>
                            <Text>
                                {`\u2022 ${"Copy, reproduce, upload, post, display, republish, distribute or transmit any part of the content in any form whatsoever;"}`}
                            </Text>
                            <Text>
                                {`\u2022 ${"Reproduce any portion of the Site on your website or otherwise, using any device including, but not limited to, use of a frame or border environment around the Site, or other framing technique to enclose any portion or aspect of the Site, or mirror or replicate any portion of the Site;"}`}
                            </Text>
                            <Text>
                                {`\u2022 ${"Reverse engineer any part of the Site;"}`}
                            </Text>
                            <Text>
                                {`\u2022 ${"Sell, offer for sale, transfer or license any portion of the Site in any form to any third parties;"}`}
                            </Text>
                            <Text>
                                {`\u2022 ${"Modify, translate into any language or computer language or create derivative works from, any content or any part of the Site;"}`}
                            </Text>
                            <Text>
                                {`\u2022 ${"Use the Site and its inquiry or booking functionality other than to advertise and/or research vacation rentals, to make legitimate inquiries to our members or any other use expressly authorized on the Site;"}`}
                            </Text>
                            <Text>
                                {`\u2022 ${"Use the Site to post or transmit information that is in any way false, fraudulent, or misleading, including making any reservation or inquiry under false pretenses, or taking any action that may be considered phishing or that would give rise to criminal or civil liability"}`}
                            </Text>
                            <Text>
                                {`\u2022 ${"Post or transmit any unlawful, threatening, abusive, libelous, defamatory, obscene, vulgar, indecent, inflammatory, sexually explicit, pornographic or profane material;"}`}
                            </Text>
                            <Text>
                                {`\u2022 ${"Violate, plagiarize or infringe the rights of us or third parties including, without limitation, copyright, trademark, patent, trade secrets, rights of publicity or privacy or any other intellectual or proprietary rights; or"}`}
                            </Text>
                            <Text>
                                {`\u2022 ${"Use or access the Site in any way that, in our sole discretion, adversely affects, or could adversely affect, the performance or function of the Site or any other system used by us or the Site."}`}
                            </Text>
                        </Flex>

                        <Text style={styles.subText} marginBottom={2}>4. Proprietary Rights and Downloading of Information from the Site.</Text>
                        <Flex width={width / 1.3} direction="column" marginLeft={4}>
                            <Text>The Site and all content and information on the Site are protected by copyright as a collective work and/or compilation, under applicable U.S. and international copyright laws and conventions and database rights. You agree to abide by any and all copyright notices, information or restrictions contained in or relating to any content on the Site. Copying, storing or otherwise accessing the Site or any content on the Site other than for your personal, noncommercial use (other than in accordance with a valid listing) is expressly prohibited without prior written permission from us. As part of the rental inquiry or reservation process, for your own personal, noncommercial use and not for further distribution, you may download, display and/or print one copy of any portion of the Site. You may not modify the same, and you must reproduce our copyright notice in the form displayed on the relevant portion(s) of the Site that you desire to download, display or print.
                            </Text>
                        </Flex>

                        <Text style={styles.subText} marginBottom={2}>5. Your E-mail Address and Data Transmittal</Text>
                        <Flex width={width / 1.3} direction="column" marginLeft={4}>
                            <Text>When you provide your e-mail address, name or other information to us in connection with your use or access to the Site, any service or tool provided on the Site or otherwise, you agree to allow the Site and its affiliated websites to add your e-mail address, name or other information provided to our database of users. You may receive one or more promotional e-mails from the Site. You are welcome to opt not to receive such promotional e-mails from the Site at any time. Please review our Privacy Policy for more information regarding our email and other data collection practices and safeguards, and how to opt not to receive such emails. Your use of the Site signifies your acknowledgment of, and agreement with, our Privacy Policy. We adhere to strong principles of privacy. You agree that we may access and use your user-contributed content in accordance with these Terms or our Privacy Policy and we agree that we will only disclose your user-contributed content in accordance with these Terms and our Privacy Policy.</Text>
                        </Flex>

                        <Text style={styles.subText} marginBottom={2}>6. Identity Verification</Text>
                        <Text style={styles.marginBottom}>User verification on the Internet is difficult and we cannot, and do not assume any responsibility for, the confirmation of each user's purported identity. We encourage you to communicate directly with a traveler or member through the tools available on the Site, though even this does not assure you of the identity of the person with which you are communicating. We further encourage you to take other reasonable measures to assure yourself of the other person’s identity and, for travelers, of the property and relevant details of your booking or proposed booking.</Text>
                        <Text style={styles.marginBottom}>You agree to (i) keep your password and online ID for both your account with us and your email account secure and strictly confidential, providing it only to authorized users of your accounts, (ii) instruct each person to whom you give your online ID and password that he or she is not to disclose it to any unauthorized person, (iii) notify us immediately and select a new online ID and password if you believe your password for either your account with us or your email account may have become known to an unauthorized person, and (iv) notify us immediately if you are contacted by anyone requesting your online ID and password. Further, if we suspect any unauthorized access to your account, upon our request, you agree to promptly change your ID and password and take any other related action as we may reasonably request.</Text>
                        <Text style={styles.marginBottom}>We discourage you from giving anyone access to your online ID and password for your account with us and your email account. However, if you do give someone your online ID and online password, or if you fail to adequately safeguard such information, you are responsible for any and all transactions that the person performs while using your account with us and/or your email account, even those transactions that are fraudulent or that you did not intend or want performed.</Text>
                        <Text style={styles.marginBottom}>EACH USER ACKNOWLEDGES AND AGREES THAT: (1) HOOKHUB WILL HAVE ANY LIABILITY TO ANY USER FOR ANY UNAUTHORIZED TRANSACTION MADE USING ANY USER’S ID OR PASSWORD; AND (2) THE UNAUTHORIZED USE OF YOUR ONLINE ID AND PASSWORD FOR YOUR HOOKHUB ACCOUNT OR YOUR EMAIL ACCOUNT COULD CAUSE YOU TO INCUR LIABILITY TO BOTH HOOKHUB AND OTHER USERS.</Text>
                        <Text style={styles.marginBottom}>Further, we may, without notice to you, suspend or cancel your listing at any time even without receiving notice from you if we suspect, in our sole discretion, that your account with us or your email account is being used in an unauthorized or fraudulent manner.</Text>

                        <Text style={styles.subText} marginBottom={2}>7. Limitations on Communications and Use of Other Users’ Information; No Spam.</Text>
                        <Text style={styles.marginBottom}>You agree that, with respect to other users' personal information that you obtain directly or indirectly from or through the Site or through any Site-related communication, transaction or software, we have granted to you a license to use such information only for: (i) Site-related communications that are not unsolicited commercial messages, (ii) using services offered through the Site, and (iii) inquiring about or otherwise facilitating a financial transaction between you and the other user related to the purpose of the Site (such as inquiring about or booking a property or charging a personal credit card). Any other purpose will require express permission from the user. You may not use any such information for any unlawful purpose or with any unlawful intent.</Text>
                        <Text style={styles.marginBottom}>We do not tolerate spam or unsolicited commercial electronic communications of any kind. Therefore, without limiting the foregoing, you are not licensed to add a Site user, even a user who has rented a short-term property from you or to you, to your mailing list (email or physical mail) without the user's express consent. You may not use any tool or service on the Site to send spam or unsolicited commercial electronic communications of any kind or in any other way that would violate these Terms.</Text>

                        <Text style={styles.subText} marginBottom={2}>8. Responsibility for Property Listings, Reviews and Other User-contributed content</Text>
                        <Text style={styles.marginBottom}>We have no duty to pre-screen content posted on the Site by members, travelers or other users, whether directly contributed by the user or contributed by us or a third party on behalf of the user (including, without limitation, property listings, reviews of a rental property or a traveler, participation in an interactive community, forum or blog (each an “Interactive Forum”) or any other content provided by a user to the Site), (collectively, “user-contributed content”). We are not responsible for user-contributed content. “User-contributed content” also includes information that a user or any other person provided to a third party website or mobile application that is then provided to our Site by a tool we offer or any other exchange of user-contributed content we have authorized.</Text>
                        <Text style={styles.marginBottom}>
                            We reserve the right to decline to permit the posting on the Site of or to remove from the Site any user-contributed content that fails to meet our Content Guidelines, which are incorporated by reference into these Terms, any other guidelines posted on a Site or if it otherwise violates these Terms, each as determined in our discretion. We may also remove user-contributed content if it is brought to our attention, such as by notice given to us by a user or any third party that any part of these Terms, or any other requirements governing the posting of such content, have been apparently breached in respect of such content, as determined in our discretion. Finally, we reserve the right, but do not assume the obligation, to edit a member’s content or user-contributed content in a non-substantive manner solely to cause the content to comply with our Content Guidelines or formatting requirements or to provide services to members to create or improve listings (such as translation services), in accordance with information we have about the property listed. Users remain responsible for reviewing their user-contributed content to ensure it is accurate and not misleading.
                            At a minimum, user-contributed content must (as determined by us in our discretion):</Text>
                        <Flex width={width / 1.3} direction="column" marginLeft={4}>
                            <Text>
                                {`\u2022 ${"not infringe anyone's rights, including but limited to copyright and rights of publicity and privacy, violate the law or otherwise be inappropriate;"}`}
                            </Text>
                            <Text>
                                {`\u2022 ${"not include personal information of another that can be used to identify or contact any person"}`}
                            </Text>
                            <Text>
                                {`\u2022 ${"not include unsolicited promotional content, advertising, political campaigns, contests, raffles or solicitations;"}`}
                            </Text>
                            <Text>
                                {`\u2022 ${"be directly related to the Site, business service, product or forum where the content is submitted;"}`}
                            </Text>
                            <Text>
                                {`\u2022 ${"not be obscene, abusive, discriminatory or illegal; or"}`}
                            </Text>
                            <Text>
                                {`\u2022 ${"not be false or misleading."}`}
                            </Text>
                        </Flex>

                        <Text style={styles.subText} marginBottom={2}>9. Service Fee Payable by Travelers and choice of currency.</Text>
                        <Text style={styles.marginBottom}>We charge a service fee payable by travelers who book a property on the Site via the Site checkout. The service fee covers the use of the Site, including such features as 24/7 user support, and is calculated as a variable percentage of the total reservation amount (which may or may not include additional fees, taxes and damage deposits). Depending on the laws of the jurisdiction of the traveler and/or member, VAT may be charged on top of the service fee. The exact service fee (and any VAT, if applicable) charged will be displayed to travelers at the time of booking. The service fee plus applicable VAT will be charged after both the traveler and member accept the reservation. The service fee will only be refunded in the event a traveler is entitled to a refund of the entire rental amount under the terms of the cancellation policy presented in the listing. Any taxes alleged to be owed by any taxing authority on the service fee are the responsibility of Hookhub and members have no responsibility for any such claimed tax liability. Members agree not to encourage or advise a traveler to avoid or circumvent the service fee charged by Hookhub.</Text>

                        <Text style={styles.subText} marginBottom={2}>10. Maps</Text>
                        <Text style={styles.marginBottom}>If any user-contributed content created by members or users is translated for display on the Site we cannot guarantee the accuracy or quality of such translation and the user is solely responsible for the review, verification and accuracy of such translation. Maps provided on the Site that are provided by Google are subject to the Google Maps terms and conditions located at: http://www.google.com/intl/en_us/help/terms_maps.html.</Text>

                        <Text style={styles.subText} marginBottom={2}>11. Software of Hookhub</Text>
                        <Text style={styles.marginBottom}>The Site is controlled and operated by Hookhub in the United States. Software available on the Site (the “Software”) is subject to United States export controls. No Software available on the Site or software available on any other site operated by Hookhub may be downloaded or otherwise exported or re-exported (i) into (or to a resident of) Cuba, Iraq, Libya, North Korea, Iran, Syria or any other country to which the United States has embargoed goods, or (ii) anyone on the United States Treasury Department’s list of Specially Designated Nationals or the United States Commerce Department’s Table of Deny Orders. By using the Site, you represent and warrant that you are not located in, under the control of, or a national or resident of any such country or on any such list.</Text>
                        <Text style={styles.marginBottom}>All Software is the copyrighted work of Hookhub. Your use of such Software is governed by these Terms and the terms of any additional license agreement that accompanies or is included with such Software. If the Software is not accompanied by an additional license agreement, we hereby grant you a limited, personal and nontransferable license to use the Software for viewing and using this Site in accordance with these Terms and for no other purpose.</Text>
                        <Text style={styles.marginBottom}>THE SOFTWARE IS WARRANTED, IF AT ALL, ONLY ACCORDING TO THE TERMS OF THE LICENSE AGREEMENT ACCOMPANYING SUCH SOFTWARE. COPYING OR REPRODUCING ANY SOFTWARE AVAILABLE ON THIS SITE IS EXPRESSLY PROHIBITED, EXCEPT AS SPECIFICALLY PROVIDED FOR IN A LICENSE AGREEMENT ACCOMPANYING SUCH SOFTWARE.</Text>

                        <Text style={styles.subText} marginBottom={2}>12. Third-Party Sites</Text>
                        <Text style={styles.marginBottom}>This Site may contain links and pointers to other Internet sites, resources and sponsors of the Site. Links to and from the Site to other third-party sites, maintained by third parties, do not constitute an endorsement by us of any third parties, the third-party sites or the contents thereof. We may also provide tools to allow interaction between the Site and a third-party site, such as a Social Media Site. We are not responsible in any way for such third-party sites or resources and your use of such sites and resources will not be governed by these Terms.</Text>

                        <Text style={styles.subText} marginBottom={2}>13. Limitation of Liability</Text>
                        <Text style={styles.marginBottom}>IN NO EVENT WILL HOOKHUB, IT’S AFFILIATES, OFFICERS, DIRECTORS, CONSULTANTS, AGENTS AND/OR EMPLOYEES (COLLECTIVELY, THE “HOOKHUB GROUP”), BE LIABLE FOR ANY LOST PROFITS OR ANY INDIRECT, CONSEQUENTIAL, SPECIAL, INCIDENTAL, OR PUNITIVE DAMAGES ARISING OUT OF, BASED ON, OR RESULTING FROM (A) OUR SITE, (B) THESE TERMS, (C) ANY BREACH OF THESE TERMS BY YOU OR A THIRD PARTY, (D) USE OF THE SITE, TOOLS OR SERVICES WE PROVIDE, OR ANY THIRD PARTY PROVIDER PROVIDES, RELATED TO THE BUSINESS WE OPERATE ON THE SITE, BY YOU OR ANY THIRD PARTY (E) ANY USER-CONTRIBUTED CONTENT, (F) INTERACTION BETWEEN OUR SITE AND ANY THIRD PARTY SITE, INCLUDING WITHOUT LIMITATION A SOCIAL MEDIA SITE, FACILITATED BY A TOOL OR SERVICE ON OUR SITE AND/OR (G) ANY ACTUAL OR ATTEMPTED COMMUNICATION OR TRANSACTION, INCLUDING WITHOUT LIMITATION, ANY PAYMENT TRANSACTION (EVEN IF WE OR ANY THIRD PARTY PROVIDER RECEIVES A FEE IN CONNECTION THEREWITH) BETWEEN USERS, IN EACH CASE, EVEN IF WE HAVE BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES. THESE LIMITATIONS AND EXCLUSIONS APPLY WITHOUT REGARD TO WHETHER THE DAMAGES ARISE FROM (1) BREACH OF CONTRACT, (2) BREACH OF WARRANTY, (3) STRICT LIABILITY, (4) TORT, (5) NEGLIGENCE, OR (6) ANY OTHER CAUSE OF ACTION, TO THE MAXIMUM EXTENT SUCH EXCLUSION AND LIMITATIONS ARE NOT PROHIBITED BY APPLICABLE LAW.</Text>
                        <Text style={styles.marginBottom}>IN ALL EVENTS, OUR LIABILITY, AND THE LIABILITY OF ANY MEMBER OF THE HOOKHUB GROUP, TO YOU OR ANY THIRD PARTY IN ANY CIRCUMSTANCE ARISING OUT OF OR IN CONNECTION WITH THE SITE IS LIMITED TO THE TOTAL AMOUNT OF FEES YOU PAY TO US IN CONNECTION WITH ANY TRANSACTION FROM WHICH A DISPUTE ARISES.</Text>

                        <Text style={styles.subText} marginBottom={2}>14. Disclaimers</Text>
                        <Text style={styles.marginBottom}>THE SITE, INCLUDING ALL CONTENT, SOFTWARE, FUNCTIONS, MATERIALS AND INFORMATION MADE AVAILABLE ON OR ACCESSED THROUGH THE SITE, IS PROVIDED “AS IS.“ TO THE FULLEST EXTENT PERMISSIBLE BY LAW, WE MAKE NO REPRESENTATIONS OR WARRANTIES OF ANY KIND WHATSOEVER FOR THE CONTENT ON THE SITE OR THE MATERIALS, INFORMATION AND FUNCTIONS MADE ACCESSIBLE BY THE SOFTWARE USED ON OR ACCESSED THROUGH THE SITE, FOR ANY PRODUCTS OR SERVICES OR HYPERTEXT LINKS TO THIRD PARTIES OR FOR ANY BREACH OF SECURITY ASSOCIATED WITH THE TRANSMISSION OF SENSITIVE INFORMATION THROUGH THE SITE OR ANY LINKED SITE, EVEN IF WE BECOME AWARE OF ANY SUCH BREACHES. FURTHER, WE EXPRESSLY DISCLAIM ANY EXPRESS OR IMPLIED WARRANTIES, INCLUDING, WITHOUT LIMITATION, NON-INFRINGEMENT, MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE OR ACCURACY. WE DO NOT WARRANT THAT THE FUNCTIONS CONTAINED ON THE SITE OR ANY MATERIALS OR CONTENT CONTAINED THEREIN WILL BE UNINTERRUPTED OR ERROR FREE, THAT DEFECTS WILL BE CORRECTED, OR THAT THE SITE OR THE SERVER THAT MAKES IT AVAILABLE IS FREE OF VIRUSES OR OTHER HARMFUL COMPONENTS. YOU ACKNOWLEDGE AND AGREE THAT ANY TRANSMISSION TO AND FROM THIS SITE IS NOT CONFIDENTIAL AND YOUR COMMUNICATIONS OR USER-CONTRIBUTED CONTENT MAY BE READ OR INTERCEPTED BY OTHERS. YOU FURTHER ACKNOWLEDGE AND AGREE THAT BY SUBMITTING COMMUNICATIONS OR USER-CONTRIBUTED CONTENT TO US AND BY POSTING INFORMATION ON THE SITE, INCLUDING PROPERTY LISTINGS, NO CONFIDENTIAL, FIDUCIARY, CONTRACTUALLY IMPLIED OR OTHER RELATIONSHIP IS CREATED BETWEEN YOU AND US OTHER THAN PURSUANT TO THESE TERMS.</Text>
                        <Text style={styles.marginBottom}>YOU ACKNOWLEDGE AND AGREE THAT YOU WILL NOT HOLD OR SEEK TO HOLD US OR ANY THIRD PARTY PROVIDER RESPONSIBLE FOR THE CONTENT PROVIDED BY ANY USER, INCLUDING, WITHOUT LIMITATION, ANY TRANSLATION THEREOF, AND YOU FURTHER ACKNOWLEDGE AND AGREE THAT WE ARE NOT A PARTY TO ANY RENTAL TRANSACTION OR OTHER TRANSACTION BETWEEN USERS OF THE SITE. WE HAVE NO CONTROL OVER AND DO NOT GUARANTEE (OTHER THAN PURSUANT TO ANY GUARANTEE THAT MAY BE OFFERED ON THE SITE) THE SAFETY OF ANY TRANSACTION, RENTAL PROPERTY OR THE TRUTH OR ACCURACY OF ANY LISTING OR OTHER CONTENT PROVIDED ON THE SITE.</Text>
                        <Text style={styles.marginBottom}>YOU FURTHER ACKNOWLEDGE THAT BY DISPLAYING INFORMATION OR PROPERTY LISTINGS IN PARTICULAR DESTINATIONS, WE DO NOT REPRESENT OR WARRANT THAT TRAVEL TO SUCH DESTINATIONS IS WITHOUT RISK AND ARE NOT LIABLE FOR DAMAGES WITH RESPECT TO TRAVEL TO ANY DESTINATION.</Text>


                        <Text style={styles.subText} marginBottom={2}>15. Release and Indemnification</Text>
                        <Text style={styles.marginBottom}>IN THE EVENT THAT YOU HAVE A DISPUTE WITH ONE OR MORE OTHER USERS OF THE SITE (INCLUDING, WITHOUT LIMITATION, ANY DISPUTE BETWEEN USERS REGARDING ANY TRANSACTION OR USER-CONTRIBUTED CONTENT) OR ANY THIRD PARTY PROVIDER OR ANY THIRD PARTY WEBSITE THAT MAY BE LINKED TO OR FROM OR OTHERWISE INTERACT WITH THE SITE, INCLUDING WITHOUT LIMITATION ANY SOCIAL MEDIA SITE, YOU HEREBY AGREE TO RELEASE, REMISE AND FOREVER DISCHARGE EACH MEMBER OF THE HOMEAWAY GROUP, EACH OF THEIR RESPECTIVE AGENTS, DIRECTORS, OFFICERS, EMPLOYEES, AND ALL OTHER RELATED PERSONS OR ENTITIES FROM ANY AND ALL MANNER OF RIGHTS, CLAIMS, COMPLAINTS, DEMANDS, CAUSES OF ACTION, PROCEEDINGS, LIABILITIES, OBLIGATIONS, LEGAL FEES, COSTS, AND DISBURSEMENTS OF ANY NATURE WHATSOEVER, WHETHER KNOWN OR UNKNOWN, WHICH NOW OR HEREAFTER ARISE FROM, RELATE TO, OR ARE CONNECTED WITH SUCH DISPUTE AND/OR YOUR USE OF THE SITE.</Text>
                        <Text style={styles.marginBottom}>IF YOU ARE A CALIFORNIA RESIDENT, YOU WAIVE CALIFORNIA CIVIL CODE SECTION 1542, WHICH SAYS: “A GENERAL RELEASE DOES NOT EXTEND TO CLAIMS WHICH THE CREDITOR DOES NOT KNOW OR SUSPECT TO EXIST IN HIS FAVOR AT THE TIME OF EXECUTING THE RELEASE, WHICH, IF KNOWN BY HIM MUST HAVE MATERIALLY AFFECTED HIS SETTLEMENT WITH THE DEBTOR.”</Text>
                        <Text style={styles.marginBottom}>YOU HEREBY AGREE TO INDEMNIFY, DEFEND AND HOLD EACH MEMBER OF THE HOMEAWAY GROUP (COLLECTIVELY, THE “INDEMNIFIED PARTIES“) HARMLESS FROM AND AGAINST ANY AND ALL LIABILITY AND COSTS INCURRED BY THE INDEMNIFIED PARTIES IN CONNECTION WITH ANY CLAIM ARISING OUT OF YOUR USE OF THE SITE OR OTHERWISE RELATING TO THE BUSINESS WE CONDUCT ON THE SITE (INCLUDING, WITHOUT LIMITATION, ANY POTENTIAL OR ACTUAL COMMUNICATION, TRANSACTION OR DISPUTE BETWEEN YOU AND ANY OTHER USER OR THIRD PARTY), ANY CONTENT POSTED BY YOU OR ON YOUR BEHALF OR POSTED BY OTHER USERS OF YOUR ACCOUNT TO THE SITE, ANY USE OF ANY TOOL OR SERVICE PROVIDED BY A THIRD PARTY PROVIDER, ANY USE OF A TOOL OR SERVICE OFFERED BY US THAT INTERACTS WITH A THIRD PARTY WEBSITE, INCLUDING WITHTOUT LIMITATION ANY SOCIAL MEDIA SITE OR ANY BREACH BY YOU OF THESE TERMS OR THE REPRESENTATIONS, WARRANTIES AND COVENANTS MADE BY YOU HEREIN, INCLUDING WITHOUT LIMITATION, ATTORNEYS' FEES AND COSTS. YOU SHALL COOPERATE AS FULLY AS REASONABLY REQUIRED IN THE DEFENSE OF ANY CLAIM.</Text>
                        <Text style={styles.marginBottom}>WE RESERVE THE RIGHT, AT OUR OWN EXPENSE, TO ASSUME THE EXCLUSIVE DEFENSE AND CONTROL OF ANY MATTER OTHERWISE SUBJECT TO INDEMNIFICATION BY YOU AND YOU SHALL NOT IN ANY EVENT SETTLE ANY MATTER WITHOUT OUR WRITTEN CONSENT.</Text>

                        <Text style={styles.subText} marginBottom={2}>16. Disputes and Arbritation</Text>
                        <Text style={styles.marginBottom}>Please read this Section carefully. It requires that any and all claims be resolved by binding arbitration or in small claims court, and it prevents you from pursuing a class action or similar proceeding in any forum. Arbitration is required if your country of residence enforces arbitration agreements, including without limitation, the United States. If you are outside the United States but attempt to bring a claim in the United States, arbitration is required for determination of the threshold issue of whether this dispute resolution section applies to you, as well as all other threshold determinations, including residency, arbitrability, venue, and applicable law. If your country of residence does not enforce arbitration agreements, the mandatory pre-arbitration dispute resolution and notification and prohibition on class actions or representative proceedings provided below still apply to the extent enforceable by law.</Text>
                        <Text style={styles.marginBottom}>Arbritation Agreement: You and we mutually agree that any disputes between us arising out of or relating in any way to our Service, these Terms, our Privacy Policy, any services or products provided by us or any of our subsidiaries, Travel Providers, or companies offering products or services through us, any dealings with our Traveler Support agents, or any representations made by us (“Claims”), will be resolved by binding arbitration, rather than in court except those resolved in small claims court. This includes any Claims you assert against us, our subsidiaries, Travel Providers, or any companies offering products or services through us (which are beneficiaries of this Arbitration Agreement). This Arbitration Agreement shall be binding upon, and shall include any claims brought by or against, any third parties, including but not limited to your spouses, heirs, third-party beneficiaries, and assigns, where their underlying claims are in relation to your use of the Travel Services. To the extent that any third-party beneficiary to these Terms brings claims against the entities covered by these Terms; those claims shall also be subject to this Arbitration Agreement. The arbitrator shall also be responsible for determining all threshold arbitrability issues, including without limitation the existence, scope, or validity of the Arbitration Agreement, any defense to arbitration such as issues relating to whether this Arbitration Agreement can be enforced, is unconscionable or illusory, and any defenses to arbitration, including without limitation jurisdiction, waiver, delay, laches, or estoppel.</Text>
                        <Text style={styles.marginBottom}>Small claims court matters: Notwithstanding the foregoing arbitration provisions, either you or we may bring, or remove, any claim in small claims court if the claim is within such court’s jurisdictional limit; provided that such court does not have the authority to entertain any claims on a class or representative basis, or to consolidate or join the claims of other persons or parties who may be similarly situated in such proceeding. Further, if the claims asserted in any demand for arbitration is within the small claims court’s jurisdictional limit, then either you or us may elect to have the claims heard in small claims court, rather than in arbitration, at any time before the arbitrator is appointed, or in accord with the AAA rules, by notifying the other party of that election in writing.</Text>
                        <Text style={styles.marginBottom}>No class actions nor representative proceedings: You and we agree that any and all proceedings to resolve Claims will be conducted only on an individual basis and not in a class, consolidated, or representative action, including without limitation as a private attorney general. The arbitrator may not consolidate more than one party’s Claims and may not otherwise preside over any form of any class or representative proceeding. You and we further acknowledge that you are each waiving your right to a jury trial.</Text>
                        <Text style={styles.marginBottom}>Arbitration rules: This Arbitration Agreement is a “written agreement to arbitrate” evidencing a transaction in interstate commerce. The Federal Arbitration Act (“FAA”) governs all substantive and procedural interpretation and enforcement of this provision. The arbitration will be administered by AAA in accordance with the AAA’s Consumer Arbitration Rules or other AAA arbitration rules determined to be applicable by the AAA (the “AAA Rules”) then in effect, except as modified here. The AAA Rules are available at www.adr.org. The arbitrator shall apply the law of the state of Washington, regardless of conflict of laws principles, except that the FAA governs all provisions relating to arbitration. Foreign laws do not apply. This Arbitration Agreement can only be amended by mutual agreement in writing.</Text>
                        <Text style={styles.marginBottom}>Mandatory pre-arbitration: Prior to initiating arbitration, you agree to give us the opportunity to resolve any Claims by notifying us of the Claim in writing and attempting in good faith to negotiate an informal resolution.
                            You must send, by certified mail, a written and signed Notice of Dispute (“Notice”) addressed to: Legal Department/Dispute Resolution, Hookhub, LLC PO Box 101 Lakeside, AZ 85929.
                            The Notice must contain the following information: (1) your name, (2) your address, (3) the email address you used to make your reservation, (4) a brief description of the nature of your complaint, (5) the resolution that you are seeking, and (6) your signature.
                            If we are not able to resolve your complaint within 60 days of you providing Notice, you may commence an arbitration proceeding. Engaging in this pre-arbitration dispute resolution and notification process is a requirement that must be fulfilled before commencing arbitration. AAA does not have authority to administer or adjudicate the Claim unless and until all Pre-Arbitration Dispute Resolution and Notification requirements have been met. The statute of limitations shall be tolled while the parties engage in the dispute resolution process required by this Section.</Text>
                        <Text style={styles.marginBottom}>Commencing Arbritration: To initiate arbitration, you must file the Demand with the AAA as specified in the AAA Rules. The AAA provides a form Demand for Arbitration - Consumer Arbitration Rules at www.adr.org or by calling the AAA at 1-800-778-7879.
                            A party initiating an arbitration against us must send the written Demand for Arbitration to Legal Department/Dispute Resolution, Hookhub, LLC PO Box 101 Lakeside, AZ 85929 concurrent with filing the Demand with AAA.
                            Arbitration shall be conducted by a single arbitrator selected in accordance with the AAA Rules or by mutual agreement between you and us. The Arbitration shall be held either: (i) at a location determined by AAA pursuant to the AAA Rules (provided that such location is reasonably convenient for you and does not require travel in excess of 100 miles from your home or place of business); or (ii) at such other location as may be mutually agreed upon by you and us; or (iii) via videoconference; or (iv) at your election, if the only claims in the arbitration are asserted by you and are for less than $40,000 in aggregate, by telephone or by written submission.
                        </Text>
                        <Text style={styles.marginBottom}>Attorneys' fees and costs: Your arbitration fees and your share of arbitrator compensation shall be governed by the AAA Rules, and where appropriate, limited by the AAA Consumer Rules. In order to initiate arbitration, each party will be responsible for paying the filing fees required by the AAA, which are approximately equivalent to current court filing fees. In the event that you are able to demonstrate that the costs of arbitration will be prohibitive as compared to the costs of litigation, or you would otherwise be eligible for a fee waiver in court, we will pay as much of your filing and hearing fees in connection with the arbitration as the arbitrator deems necessary to prevent the arbitration from being cost-prohibitive as compared to the costs of litigation, regardless of the outcome of the arbitration, unless the arbitrator determines that your claim(s) were frivolous or asserted in bad faith. Either party may make a request that the arbitrator award attorneys’ fees and costs upon proving that the other party has asserted a claim, cross-claim, or defense that is groundless in fact or law, brought in bad faith or for the purpose of harassment, or is otherwise frivolous, as allowed by applicable law and the AAA Rules.</Text>
                        <Text style={styles.marginBottom}>Arbitrator's decision: There is no judge or jury in arbitration, and court review of an arbitration award is limited under the FAA. The arbitrator’s decision will include the essential findings and conclusions on which the arbitrator bases the award. Judgment on the arbitration award may be entered in any court with proper jurisdiction. The arbitrator may award any relief allowed by law or the AAA Rules, but declaratory or injunctive relief may be awarded only on an individual basis and only to the extent necessary to provide relief warranted by the claimant’s individual claim.</Text>
                        <Text style={styles.marginBottom}>Severability: If any portion of this Disputes and Arbitration Provision is found to be unenforceable or unlawful for any reason, (1) such portion will be severed and the remainder of the Provision will be given full force and effect; and (2) to the extent that any Claims must therefore proceed on a class, collective, consolidated, or representative basis, such Claims must be litigated in a civil court of competent jurisdiction and not in arbitration, and the parties agree that litigation of those claims shall be stayed pending the outcome of any individual Claims in arbitration.</Text>

                        <Text style={styles.subText} marginBottom={2}>17. Online Booking Agreement</Text>
                        <Text style={styles.marginBottom}>By utilizing a rental agreement with the space host relating to the rental as part of the online booking process (including such terms that we may require), the traveler and member each agree to the terms and conditions set forth in the rental agreement or other such terms displayed in the booking process (including without limitation the cancellation refund policy) effective as of the date that the user indicates acceptance of the booking or rental agreement, as applicable. You hereby acknowledge and agree that (i) you are fully responsible for such terms and conditions, (ii) any rental agreement used, whether a sample provided by the Site or a rental agreement copied and pasted in ReservationManager (or other online booking tool on the Site) by either party, is used solely at their own risk and expense, (iii) nothing contained in the Reservation Services, these Terms or any sample rental agreement is a substitute for the advice of an attorney and (iv) that you have been hereby advised to obtain local legal counsel to prepare, review and revise as necessary any rental agreements to ensure compliance with federal, state and local law and their particular circumstances, and to revise the rental agreement as necessary to accurately represent the property, rules, features, etc.</Text>
                        <Text style={styles.marginBottom}>As a host, you can cancel a booking up to 1 week before the check-in date. If the booking is more than a month long, you can cancel the booking after check-in, as long as it is still 28 days before the check-out date. A 28 days notice will be required."</Text>

                        <Text style={styles.subText} marginBottom={2}>18. Responsibility for Property and Traveler Liability</Text>
                        <Text style={styles.marginBottom}>Users are solely responsible for obtaining insurance coverage sufficient to protect their properties and guests or their trip, as applicable. Members agree that they have or will obtain the appropriate insurance coverage sufficient to cover the rental of the properties they list on the Site before the arrival of their first traveler and will maintain adequate insurance coverage through the departure date of any traveler they have obtained via our Site. Further, members agree to provide us with copies of relevant proof of coverage upon request.</Text>

                        <Text style={styles.subText} marginBottom={2}>19. Listing Space Requirements</Text>
                        <Text style={styles.marginBottom}>Our services may only be used by members who can form legally binding contracts under applicable law. If you are registering as a business entity, you represent that you have the authority to bind the entity to these Terms. Each member represents and covenants that all information submitted to us and to the Site during such member’s registration with the Site shall be true and correct. </Text>
                        <Flex width={width / 1.3} direction="column" marginLeft={4}>
                            <Text>
                                {`\u2022 ${"Members must maintain an accurate reservation calendar on their listing(s);"}`}
                            </Text>
                            <Text>
                                {`\u2022 ${"Members must use commercially reasonable efforts to respond to all booking requests from travelers within 24 hours of receipt of a request for booking;"}`}
                            </Text>
                            <Text>
                                {`\u2022 ${"Members must further agree to take commercially reasonable efforts to cause all traveler payments to be processed within 24 hours of authorization by the traveler for such payment;"}`}
                            </Text>
                            <Text>
                                {`\u2022 ${"Members must respond to and accept a material number of inquiries and booking requests received in the interest of the traveler experience; and"}`}
                            </Text>
                            <Text>
                                {`\u2022 ${"Members are prohibited from cancelling a material number of accepted bookings."}`}
                            </Text>
                        </Flex>

                        <Text style={styles.subText} marginBottom={2}>20. Additional Member Representations</Text>
                        <Text style={styles.marginBottom}>Each member represents and covenants that: (i) it owns and/or has all necessary rights and authority to offer for rent and to rent the property listed by such member; (ii) it will not wrongfully withhold a rental deposit in breach of the underlying rental agreement; (iii) it will accurately describe the subject rental property, will not fail to disclose a material defect in, or material information about, a rental property and will upon request, or otherwise from time to time, review the property listing content and location or geographic description to ensure it is accurate and not misleading ; (iv) it will not wrongfully deny access to the listed property; and (v) it will not fail to provide a refund when due in accordance with the applicable cancellation policy or underlying rental agreement.</Text>
                        <Flex width={width / 1.3} direction="column" marginLeft={4}>
                            <Text>
                                {`\u2022 ${" In addition, if Hookhub, through one of its guarantee or warranty programs and in its sole discretion, compensates a traveler for a loss caused by acts or omissions attributable to a member, Hookhub reserves the right to pursue the member for the amount paid or contributed towards the loss."}`}
                            </Text>
                            <Text>
                                {`\u2022 ${"Upon our request, each member agrees to promptly provide to us such proof of personal identification, proof that the condition, location, or amenities associated with the property are accurately described in the listing, proof of ownership of the property listed on the Site, and/or proof of authority to list the property. If you are a tenant who is listing a space, please refer to your rental contract or lease, or contact your landlord, prior to listing the property to determine whether your lease or contract contains restrictions that would limit your ability to list your space. Listing your home may be a violation of your lease or contract and could result in legal action against you by your landlord, including possible eviction."}`}
                            </Text>
                        </Flex>

                        <Text style={styles.subText} marginBottom={2}>21. Appearance in Search Results</Text>
                        <Text style={styles.marginBottom}>We cannot guarantee that your listing will appear in any specific order in search results on the Site. Search order will fluctuate based on a variety of factors such as search parameters, subscription level purchased, listing quality, how frequently a calendar is updated, traveler preferences, member response time, reservation history, online booking capability, compliance with posted listing practice requirements, and other factors that we may deem important to the user experience from time to time (“Best Match”). Listing appearance or order in any particular search result may also vary depending on the search criteria used by the particular traveler. We reserve the right to apply various search algorithms or to use methods to optimize Best Match results for particular travelers’ experiences and the overall marketplace. Listings may not always appear in search results.</Text>

                        <Text style={styles.subText} marginBottom={2}>22. Content</Text>
                        <Text style={styles.marginBottom}>All content and copy edits submitted by members are subject to review and approval by us in our sole discretion. We reserve the right to refuse to publish any content that we determine in our sole discretion does not meet these Terms or is otherwise unacceptable to us. However, we assume no duty to review content and we shall not have any liability for any loss or damage resulting from the design or positioning of the copy, properties, content and/or photographs or any change made to any content, photograph or copy submitted by any member. All content must meet these Terms and our Content Guidelines. We reserve the right to edit content submitted to the Site in a non-substantive manner solely to ensure that the content complies with our Content Guidelines or formatting requirements. Members are responsible for reviewing and ensuring that any content displayed on the Site appears as the member intended.</Text>

                        <Text style={styles.subText} marginBottom={2}>23. Photographs</Text>
                        <Text style={styles.marginBottom}>Photographs should depict the vacation rental as the main subject of the photograph and may not include children or adults if you do not have their legal consent or any information or images that would violate the privacy rights, intellectual property rights (including but not limited to copyright rights) or any other rights of a third party. We reserve the right to not display or to remove any photographs that we determine, in our sole discretion, do not meet these Terms or are otherwise unacceptable to us.</Text>
                        <Flex width={width / 1.3} direction="column" marginLeft={4}>
                            <Text>
                                {`\u2022 ${"By submitting a photograph to us, the member represents and warrants that (a) it holds all intellectual property rights (including but not limited to all copyright rights), (b) that any people in the photograph have given permission for their likeness to be displayed in an online advertisement on the Site, (c) that the photograph accurately and fairly represents the subject of the photograph and has not been altered in any manner that would mislead a viewer of that photograph, and (d) that it will indemnify and hold harmless the Site and any member of the HomeAway Group from any cause of action arising from any misrepresentation with respect to any and all photographs so submitted or in the event of claims that the use of a photograph violates another party’s copyright."}`}
                            </Text>
                            <Text>
                                {`\u2022 ${"It is the member’s responsibility to obtain any and all permissions required to use, post and grant the foregoing rights in all photographic and other material used in its listings. The member warrants that it is the owner of the copyright in such material or is authorized by the owner thereof to grant to us the rights therein contained and agrees to provide any proof of such rights to us that we may request. Each member further agrees that we may reproduce in whole or in part any photographic material supplied by such member in the promotion of either such member’s property or the Site."}`}
                            </Text>
                        </Flex>

                        <Text style={styles.subText} marginBottom={2}>24. Uses of Our Trademarks or Logos</Text>
                        <Text style={styles.marginBottom}>There are limited ways in which a member may use our trademarks or logos in connection with a listing without specific prior written authorization. The following are general guidelines. It is usually permissible for you to refer to Hookhub or the name of one of our affiliate websites on which you list your property in a descriptive manner in your listing on the Site or in other permissible communications. For example, you might say “Check out my space rental on Hookhub,“ or “I list properties on Hookhub.“ However, you may not refer to Hookhub or any of our affiliates in any way that might lead someone to believe that your company, property, listing or site is sponsored by, affiliated with, or endorsed by Hookhub or one of our affiliates. You may not use the Hookhub name on any other website that lists vacation rentals without our prior written authorization.</Text>
                        <Text style={styles.marginBottom}>The Hookhub name and logo are trademarks or registered trademarks in the United States and other jurisdictions around the world. We generally do not permit the use of our names and logos, other than as described above or with our prior written authorization. If you want permission to use our name and/or logo in any other manner, including, without limitation, on any website, business card, signage, t-shirt, etc. please contact us via our help form on the help screen.</Text>

                        <Text style={styles.subText} marginBottom={2}>25. Hypertext Links</Text>
                        <Text style={styles.marginBottom}>We reserve the right to refuse hypertext links to, or addresses of, other web sites from members' pages, and to remove links or web addresses without notice at our sole discretion. Further, we reserve the right to charge for hypertext links at any time.</Text>

                        <Text style={styles.subText} marginBottom={2}>26. Payment Method Requirements</Text>
                        <Text style={styles.marginBottom}>No member may request any traveler to mail cash, or utilize any instant-cash wire transfer service such as Western Union or MoneyGram in payment for all or part of a property rental transaction. Any violation of this term or any other unacceptable payment methods that may be posted on the Site may result in the immediate removal of the non-conforming listing from the Site without notice to the member and without refund. From time to time, we may become aware of users attempting to conduct a transaction that would involve an unauthorized payment method or a fraudulent payment method. Ideally, we hope to be able to assist users in avoiding such transactions, but we assume no liability or responsibility to do so or to inform users of any such actual or suspected activity. Users’ bank or credit card companies may impose certain fees or charges, including, but not limited to, foreign transaction fees, related to any rental of a property. It is the user’s obligation to review any agreement with its bank or credit card company concerning any such fees.</Text>

                        <Text style={styles.subText} marginBottom={2}>27. Subscriptions and Refund Requests</Text>
                        <Text style={styles.marginBottom}>All subscription listings are sold to run the full term that is chosen by the member. The term starts on the date that the member submits the full or initial (as applicable) payment and expires one year thereafter. For example, for an annual subscription term, if the member submits payment for the subscription on July 1st, the subscription would expire on June 28 of the following year. If you renew your subscription listing, or if your subscription listing automatically renews, your listing will remain online for the new subscription period without refund.</Text>

                        <Text style={styles.subText} marginBottom={2}>28. Additional Terms for Listings</Text>
                        <Text style={styles.marginBottom}>For all pay-per-booking listings, the terms and conditions in effect at the time a booking is accepted will govern and supersede any prior agreement between us and you with respect to your use of the Site. Short term listings may be converted to subscription listings at any time; however any bookings already made prior to conversion shall remain subject to applicable pay-per-booking fees. Cancellation policies are required for all pay-per-booking listings, and requirements for such cancellation policies shall be displayed through your space details screen under ‘cancellation policy’.</Text>

                        <Text style={styles.subText} marginBottom={2}>29. Termination of Listings</Text>
                        <Text style={styles.marginBottom}>If, in our sole discretion, any member submits unsuitable material to our Site or into our database, is not abiding by local rental regulations and is creating a nuisance in its community, misuses the Site or our online system or is in material breach of these Terms, we reserve the right to limit the member’s use of the Site, impact the member’s listing(s) search position, and/or terminate such member’s listing immediately without refund. In addition, if we become aware of or receive a complaint or a series of complaints from any user or other third party regarding a member’s listing or rental practices that, in our sole discretion, warrants the immediate removal of such member’s listing from the Site (for example, and without limitation, if a member double-books a property for multiple travelers on the same date, or engages in any practice that, in our sole discretion, would be considered deceptive, unfair or improper within the vacation rental industry or in an online marketplace for vacation rentals, if we determine or suspect that the member’s payment-related practices or procedures are not secure, legal or otherwise proper, or if we receive a complaint that any listing’s content infringes on the rights of a third party), then we may immediately terminate such member’s listing(s) or subscription(s) without notice to the member and without refund. We assume no duty to investigate complaints. Finally, if any member is abusive or offensive to any employee or representative of the Hookhub Group, we reserve the right to terminate such member’s listing(s) or subscription(s) immediately without refund.</Text>
                        <Text style={styles.marginBottom}>In addition to reserving the right to terminate any listing, Hookhub reserves all rights to respond to any violation of the Terms or misuse of the Site by, including, but not limited to, hiding a listing from the search results, marking a member as “out of office,” and removing or changing information that may be false or misleading.</Text>
                        <Text style={styles.marginBottom}>Additionally, you agree to defend and indemnify Hookhub, directors, employees and agents from and against any claims, causes of action, demands, recoveries, losses, damages, fines, penalties or other costs or expenses of any kind or nature including but not limited to reasonable legal and accounting fees, brought by third parties as a result of: your breach of these Terms or the documents referenced herein, your violation of any law or the rights of a third party or your use of this Site.</Text>
                        <CustomDivider />
                        <Text style={styles.marginVertical}>We may update this Statement in response to changing laws or technical or business developments. If we propose to make any material changes, we will notify you by means of a notice on this page. You can see when this Privacy Statement was last updated by checking the “last updated” date displayed at the top of this Statement.</Text>
                    </Flex>
                    <Pressable onPress={() => setTermsConditionsOpen(false)}>
                        <Flex width={width / 1.3} marginBottom={2} marginTop={20} justifyContent="center" alignContent="center" alignItems="center">
                            <AntDesign marginRight={9} name="closecircleo" size={30} color="black" />
                            <Text>Close</Text>
                        </Flex>
                    </Pressable>
                </View>
            </CustomModal>
        </View>
    );
}

const styles = StyleSheet.create({
    container: {
        flex: 1,
        alignItems: "center",
        justifyContent: "center",
        padding: 20,
    },
    modalView: {
        margin: 20,
        alignContent: "center",
        justifyContent: "center",
        alignItems: "center",
        backgroundColor: "grey",
        borderRadius: 20,
        padding: 35,
        shadowColor: "#000",
        shadowOffset: {
            width: 0,
            height: 2,
        },
        shadowOpacity: 0.25,
        shadowRadius: 4,
        elevation: 5,
    },
    title: {
        fontSize: 20,
        fontWeight: "bold",
    },
    link: {
        marginTop: 15,
        paddingVertical: 15,
    },
    titleText: {
        fontSize: 24,
        fontWeight: "bold",
        marginBottom: 2
    },
    subText: {
        fontSize: 17,
        fontWeight: "bold",
        marginTop: 6
    },
    marginBottom: {
        marginBottom: 4
    },
    marginVertical: {
        marginTop: 8,
        marginBottom: 4
    }
});
