import {
    StyleSheet,
    useWindowDimensions,
    Image
} from "react-native";
import getEnvVars from '../../../environment';
import CustomModal from "../CustomElements/CustomModal";
import CloseButtonUnderlined from "../CustomElements/CloseButtonUnderlined";
const { cloudfrontURL } = getEnvVars();

export const ShowAllPhotosModal = ({ modalVisible, setModalVisible, images, preview = false, mobileView }) => {
    const { height, width } = useWindowDimensions();
    const imageWidth = width / 1.17

    return (
        <CustomModal
            modalVisible={modalVisible}
            setModalVisible={() => setModalVisible()}
            modalWidth={width/1.15}
        >
            {images.map((image, key) => (
                <Image
                    key={key}
                    source={{ uri: preview ? image : `${cloudfrontURL}${image}` }}
                    resizeMode="cover"
                    style={[styles.images, { height: imageWidth*.8, width: imageWidth }]}
                />
            ))}
            <CloseButtonUnderlined onPress={() => setModalVisible()} />
        </CustomModal>
    );
};

const styles = StyleSheet.create({
    images: {
        borderRadius: 4,
        margin: 5,
        alignSelf: "center",
        justifyContent: "center",
    }
});
