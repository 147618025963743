// Action Types
export const SET_INITIAL_DATA = 'SET_INITIAL_DATA';
export const ADD_CONVO_MESSAGE = 'ADD_CONVO_MESSAGE';
export const ADD_NEW_CONVO = 'ADD_NEW_CONVO';
export const UPDATE_ACTIVE_CONVERSATION = 'UPDATE_ACTIVE_CONVERSATION';
export const SET_UNREAD_BOOLEAN = 'SET_UNREAD_BOOLEAN';
export const UPDATE_UNREAD_BOOLEAN = 'UPDATE_UNREAD_BOOLEAN';

export const setConvos = value => (
    {
      type: SET_INITIAL_DATA,
      convos: value.convos
    }
);

export const updateConvoMsgs = value => (
    {
      type: ADD_CONVO_MESSAGE,
      msg: value.msg,
      unreadBoolean: value.unreadBoolean,
      unreadHost: value.unreadHost,
      unreadHostee: value.unreadHostee
    }
);

export const addNewConvo = value => (
    {
      type: ADD_NEW_CONVO,
      convo: value.convo,
      unreadBoolean: value.unreadBoolean
    }
);

export const updateActiveConvo = value => (
    {
      type: UPDATE_ACTIVE_CONVERSATION,
      convoId: value.convoId
    }
);

export const setUnreadMsgs = value => (
  {
    type: SET_UNREAD_BOOLEAN,
    unreadBoolean: value.unreadBoolean
  }
);

export const updateUnreadMsgs = value => (
  {
    type: UPDATE_UNREAD_BOOLEAN,
    unreadBoolean: value.unreadBoolean,
    updatedConvo: value.updatedConvo
  }
);