import React from "react";
import { Text } from "react-native";
import { Ionicons, MaterialIcons, MaterialCommunityIcons, FontAwesome5, FontAwesome } from '@expo/vector-icons';
import Flex from "../CustomElements/Flex"
import Card from "../CustomElements/Card"
import { CustomIconToggleButton } from "../CustomElements/CustomIconToggleButton";
import { colors } from "../../styles/colors";
import { useSelector } from "react-redux";


const SetAvailableAmenities = ({
    electrichookup,
    waterhookup,
    sewagehookup,
    wifiavailable,
    showeravailable,
    easyentry,
    setelectrichookup,
    setwaterhookup,
    setsewagehookup,
    setwifiavailable,
    setshoweravailable,
    seteasyentry,
    laundry,
    pool,
    hottub,
    outdoorarea,
    indoorarea,
    trash,
    setlaundry,
    setpool,
    sethottub,
    setoutdoorarea,
    setindoorarea,
    settrash,
    petsAllowed,
    smokingAllowed,
    adultsOnly,
    outdoorFires,
    setpetsAllowed,
    setsmokingAllowed,
    setadultsOnly,
    setoutdoorFires,
    width,
    restroomavailable,
    foodnearby,
    restaurantnearby,
    wateraccessible,
    setrestroomavailable,
    setfoodnearby,
    setrestaurantnearby,
    setwateraccessible,
}) => {
    const { mobileView } = useSelector(state => state.currentUser);
    return (
        <Flex
            direction="column"
            justifyContent="center"
            alignItems="center"
            alignContent="center"
            width={width / 1.2}
        >
            <Card
                backgroundColor="#fff"
                padding={9}
                width={width / 1.1}
                marginTop={5}
            >
                <Flex wrap="wrap" justifyContent="center" alignContent="center" marginBottom={5} width={width / 1.3}>
                    <CustomIconToggleButton
                        amenityType={electrichookup}
                        updateAmenityType={setelectrichookup}
                        icon={
                            <MaterialIcons
                                name="electrical-services"
                                size={mobileView ? 40 : 50}
                                color={electrichookup ? colors.medBlue : colors.amplifyNeutral60}
                            />
                        }
                        amenityTitle={"Electricity hookup"}
                    />
                    <CustomIconToggleButton
                        amenityType={waterhookup}
                        updateAmenityType={setwaterhookup}
                        icon={
                            <Ionicons
                                name="water-sharp"
                                size={mobileView ? 37 : 45}
                                color={waterhookup ? colors.medBlue : colors.amplifyNeutral60}
                            />
                        }
                        amenityTitle={"Water hookup"}
                    />
                    <CustomIconToggleButton
                        amenityType={sewagehookup}
                        updateAmenityType={setsewagehookup}
                        icon={
                            <MaterialCommunityIcons
                                name="pipe"
                                size={mobileView ? 35 : 45}
                                color={sewagehookup ? colors.medBlue : colors.amplifyNeutral60}
                            />
                        }
                        amenityTitle={"Sewage hookup"}
                    />
                    <CustomIconToggleButton
                        amenityType={wifiavailable}
                        updateAmenityType={setwifiavailable}
                        icon={
                            <Ionicons
                                name="wifi"
                                size={mobileView ? 40 : 50}
                                color={wifiavailable ? colors.medBlue : colors.amplifyNeutral60}
                            />
                        }
                        amenityTitle={"WiFi available"}
                    />
                    <CustomIconToggleButton
                        amenityType={showeravailable}
                        updateAmenityType={setshoweravailable}
                        icon={
                            <FontAwesome5
                                name="shower"
                                size={mobileView ? 33 : 43}
                                color={showeravailable ? colors.medBlue : colors.amplifyNeutral60}
                            />
                        }
                        amenityTitle={"Shower"}
                    />
                    <CustomIconToggleButton
                        amenityType={easyentry}
                        updateAmenityType={seteasyentry}
                        icon={

                            <MaterialCommunityIcons
                                name="steering"
                                size={mobileView ? 40 : 50}
                                color={easyentry ? colors.medBlue : colors.amplifyNeutral60}
                            />
                        }
                        amenityTitle={"Pull-through parking"}
                    />
                    <CustomIconToggleButton
                        amenityType={restroomavailable}
                        updateAmenityType={setrestroomavailable}
                        icon={
                            <FontAwesome5
                                name="restroom"
                                size={mobileView ? 32 : 45}
                                color={restroomavailable ? colors.medBlue : colors.amplifyNeutral60}
                            />
                        }
                        amenityTitle={"Restroom available"}
                    />
                    <CustomIconToggleButton
                        amenityType={foodnearby}
                        updateAmenityType={setfoodnearby}
                        icon={
                            <MaterialIcons
                                name="fastfood"
                                size={mobileView ? 35 : 45}
                                color={foodnearby ? colors.medBlue : colors.amplifyNeutral60}
                            />
                        }
                        amenityTitle={"Food / Beverages nearby"}
                    />
                    <CustomIconToggleButton
                        amenityType={restaurantnearby}
                        updateAmenityType={setrestaurantnearby}
                        icon={
                            <MaterialIcons
                                name="restaurant"
                                size={mobileView ? 35 : 45}
                                color={restaurantnearby ? colors.medBlue : colors.amplifyNeutral60}
                            />
                        }
                        amenityTitle={"Restaurant nearby"}
                    />
                    <CustomIconToggleButton
                        amenityType={wateraccessible}
                        updateAmenityType={setwateraccessible}
                        icon={
                            <MaterialCommunityIcons
                                name="water-pump"
                                size={mobileView ? 35 : 45}
                                color={wateraccessible ? colors.medBlue : colors.amplifyNeutral60}
                            />
                        }
                        amenityTitle={"Water accessible"}
                    />
                    <CustomIconToggleButton
                        amenityType={laundry}
                        updateAmenityType={setlaundry}
                        icon={
                            <MaterialIcons
                                name="local-laundry-service"
                                size={mobileView ? 40 : 50}
                                color={laundry ? colors.medBlue : colors.amplifyNeutral60}
                            />
                        }
                        amenityTitle={"Laundry facilities"}
                    />
                    <CustomIconToggleButton
                        amenityType={pool}
                        updateAmenityType={setpool}
                        icon={
                            <MaterialIcons
                                name="pool"
                                size={mobileView ? 40 : 50}
                                color={pool ? colors.medBlue : colors.amplifyNeutral60}
                            />
                        }
                        amenityTitle={"Pool"}
                    />
                    <CustomIconToggleButton
                        amenityType={hottub}
                        updateAmenityType={sethottub}
                        icon={
                            <FontAwesome5
                                name="hot-tub"
                                size={mobileView ? 30 : 40}
                                color={hottub ? colors.medBlue : colors.amplifyNeutral60}
                            />
                        }
                        amenityTitle={"Hottub / jacuzzi"}
                    />
                    <CustomIconToggleButton
                        amenityType={indoorarea}
                        updateAmenityType={setindoorarea}
                        icon={

                            <FontAwesome5
                                name="warehouse"
                                size={mobileView ? 25 : 35}
                                color={indoorarea ? colors.medBlue : colors.amplifyNeutral60}
                            />
                        }
                        amenityTitle={"Indoor area"}
                    />
                    <CustomIconToggleButton
                        amenityType={outdoorarea}
                        updateAmenityType={setoutdoorarea}
                        icon={

                            <MaterialCommunityIcons
                                name="table-picnic"
                                size={mobileView ? 40 : 50}
                                color={outdoorarea ? colors.medBlue : colors.amplifyNeutral60}
                            />
                        }
                        amenityTitle={"Outdoor area"}
                    />
                    <CustomIconToggleButton
                        amenityType={trash}
                        updateAmenityType={settrash}
                        icon={
                            <FontAwesome
                                name="trash-o"
                                size={mobileView ? 32 : 42}
                                color={trash ? colors.medBlue : colors.amplifyNeutral60}
                            />
                        }
                        amenityTitle={"Trashcan / Dumpster"}
                    />
                    <CustomIconToggleButton
                        amenityType={petsAllowed}
                        updateAmenityType={setpetsAllowed}
                        icon={
                            <MaterialIcons
                                name="pets"
                                size={mobileView ? 37 : 37}
                                color={petsAllowed ? colors.medBlue : colors.amplifyNeutral60}
                            />
                        }
                        amenityTitle={"Pets allowed"}
                    />
                    <CustomIconToggleButton
                        amenityType={smokingAllowed}
                        updateAmenityType={setsmokingAllowed}
                        icon={
                            <FontAwesome5
                                name="smoking"
                                size={mobileView ? 25 : 35}
                                color={smokingAllowed ? colors.medBlue : colors.amplifyNeutral60}
                            />
                        }
                        amenityTitle={"Smoking allowed"}
                    />
                    <CustomIconToggleButton
                        amenityType={adultsOnly}
                        updateAmenityType={setadultsOnly}
                        icon={
                            <MaterialCommunityIcons name="baby-carriage-off" size={mobileView ? 25 : 35} color={adultsOnly ? colors.medBlue : colors.amplifyNeutral60} />
                        }
                        amenityTitle={"Age 18+ only can stay on property"}
                    />
                    <CustomIconToggleButton
                        amenityType={outdoorFires}
                        updateAmenityType={setoutdoorFires}
                        icon={
                            <MaterialCommunityIcons name="campfire" size={mobileView ? 35 : 45} color={outdoorFires ? colors.medBlue : colors.amplifyNeutral60} />
                        }
                        amenityTitle={"Campfires allowed"}
                    />
                </Flex>
                <Text style={{ marginTop: 5, fontStyle: "italic", fontSize: 17, textAlign: "center" }}>* Campfires: allowed only if there are no fire restrictions, per the Dept. of Forestry</Text>
            </Card>
        </Flex>
    );
};

export default SetAvailableAmenities;