import React from 'react';
import {
    View,
    StyleSheet,
    useWindowDimensions,
} from 'react-native';
import { useSelector } from 'react-redux';

const CustomSection = ({
    children,
    backgroundColor = "white",
    marginBottom = 15,
    padding = 2,
    customWidth = null,
    alignItems = "center",
    alignContent = "center",
    justifyContent = "center",
    borderRadius = 8,
    margin = 5
}) => {
    const { mobileView } = useSelector(state => state.currentUser);
    const { width } = useWindowDimensions();

    return (
            <View style={[styles.sectionView, {
                margin: margin,
                width: customWidth ? customWidth : width / 1.05,
                backgroundColor: backgroundColor, 
                padding: mobileView ? padding : 10,
                borderRadius: borderRadius,
                marginBottom: marginBottom,
                padding: padding,
                alignItems: alignItems,
                justifyContent: justifyContent,
                alignContent: alignContent
            }]}>
                {children}
            </View>
    )

}

const styles = StyleSheet.create({
    sectionView: {
        marginBottom: 6,
        shadowColor: "#000",
        shadowOffset: {
            width: 1.5,
            height: 2,
        },
        shadowOpacity: 0.25,
        shadowRadius: 4,
        elevation: 5,
    },
})
export default CustomSection;







