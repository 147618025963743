import React from "react";
import { View, Text } from "react-native";
import CustomButton from "./CustomButton";
import { colors } from "../../styles/colors";
import { useSelector } from "react-redux";


export const CustomToggleButton = ({ text, updateSelection, selection }) => {

    return (
        <CustomButton
            width={150}
            height={32}
            margin={6}
            marginBottom={2}
            backgroundColor={selection ? colors.vibrantBlue : colors.amplifyNeutral20}
            onPress={() => updateSelection()}
        >
            <Text style={{ textAlign: "center", fontSize: 16, color: selection ? colors.amplifyNeutral90 : colors.amplifyNeutral80 }}>
                {text}
            </Text>
        </CustomButton>
    )
};