export const sendWelcomeEmail = ({emailAddress}) => {
    const requestOptions = {
        method: 'POST',
        body: JSON.stringify({
            emailAddress
        })
    };
    fetch('https://wnamndst37w43nt3r2un5f2h7u0lboqc.lambda-url.us-west-2.on.aws/', requestOptions)
        .then(response => response.json())
        .then(data => {
            console.log("email sent: success");
        }).catch((e) => {
            console.log("ERROR sending email >>   ", e)
            setError(true);
        })
}