import {
  StyleSheet,
  View,
  Text
} from "react-native";
import AmenitiesView from "./AmenitiesView";
import ContactView from "./ContactView";
import CustomDivider from "../CustomDivider";
import { ReviewView } from "../review/ReviewView";
import Badge from "../CustomElements/Badge";
import { globalStyles } from "../../styles/styles";
import TextBody from "../CustomElements/TextBody";
import { colors } from "../../styles/colors";
import Header from "../CustomElements/Header";
import DimensionsView from "./DimensionsView";
import DistancesView from "./DistancesView";

const SpaceDescription = ({
  userData = null,
  space,
  ownerID,
  hostUser,
  property,
  mobileView,
  width,
  address,
  allReviews,
  numOfSpaces,
  amenitiesPresent,
  displaySpace
}) => {
  const { description } = property;

  return (
    <View style={[globalStyles.flexStartColumn, { width: mobileView ? width / 1.1 : width / 2.2 }]}>
      {mobileView && <Header level={1.1} text={property.title} padding={0} marginBottom={10} color={colors.amplifyNeutral90} />}
      {!!address && <Text style={{ fontWeight: "600", fontSize: mobileView ? 16 : 17, marginTop: mobileView ? 0 : 30, marginBottom: 5 }}>{`${address.cityName}, ${address.canadianCivicAddress ? address.canadianCivicAddress : address.stateName}`}</Text>}
      <Text style={{ fontSize: 15 }}><Text style={{ fontSize: mobileView ? 19 : 22, fontWeight: "bold" }}>{numOfSpaces}</Text> RV Space{numOfSpaces > 1 ? "s" : ""} on Property</Text>
      {!!allReviews?.length && <ReviewView reviews={allReviews} />}
      <View style={globalStyles.flexStart}>
        {space.instant_book && <Badge width={125} marginTop={12} variation={"info"} shadow={false}><TextBody>instant book</TextBody></Badge>}
      </View>
      {!!userData && !!Object.keys(userData).length && userData.sub !== ownerID &&
        <View style={[globalStyles.flexStart]}>
          <ContactView
            viewWidth={mobileView ? width / 1.2 : 300}
            centerView={mobileView}
            userDataPresent={hostUser}
            ownerID={ownerID}
            hosteeID={userData.sub}
            contactToHost={true}
            spaceID={space.id}
            spaceTitle={space.title}
            propertyTitle={property.title}
            propertyID={property.id}
            spaceState={property.address.stateName}
            spaceCity={property.address.cityName}
          />
        </View>
      }
      <CustomDivider width="90%" />
      <TextBody
        ellipsizeMode="head"
        style={styles.longDescription}
      >
        {description}
      </TextBody>
      <AmenitiesView space={space} />
      {numOfSpaces === 1 ? (
        <>
          {!!amenitiesPresent && (
            <>
              <View style={[globalStyles.flexStartColumn, { marginTop: 15 }]}>
                <DistancesView space={displaySpace} mobileView={mobileView} />
              </View>
              <CustomDivider width={"90%"} />
            </>
          )}
          <DimensionsView
            width={displaySpace.width}
            length={displaySpace.length}
            screenWidth={width}
            mobileView={mobileView}
          />
          <CustomDivider width={"90%"} />
        </>
      ) : (<></>)}
    </View>
  );
};

const styles = StyleSheet.create({
  longDescription: {
    lineHeight: 24,
    marginBottom: 40
  },
});

export default SpaceDescription;
