
import Flex from "../CustomElements/Flex"
import Card from "../CustomElements/Card"
import { Text, useWindowDimensions } from "react-native";
import CustomButton from "../CustomElements/CustomButton";
import { colors } from "../../styles/colors";

const SpaceTypes = ({
  onShortChange,
  onLongChange,
  onStorageChange,
  onYearlyChange,
  shortterm,
  longterm,
  yearly,
  storage,
  mobileView
}) => {
  const { width } = useWindowDimensions();

  return (
    <Flex
      direction="column"
      justifyContent="center"
      alignItems="center"
      alignContent="center"
      width={mobileView?width/1.3:220}
    >
        <Flex
          direction="column"
          justifyContent="center"
          alignItems="center"
          alignContent="center"
          width={mobileView?width/1.34:215}
        >
          <CustomButton
            height={45}
            width={mobileView?130:175}
            marginBottom={5}
            backgroundColor={shortterm ? colors.vibrantBlue : colors.amplifyNeutral20}
            border={shortterm ? colors.amplifyNeutral80 : "#4c4e52"}
            onPress={() => onShortChange(!shortterm)}
          >
            <Text style={{ fontSize: 20 }}>Nightly</Text>
          </CustomButton>
          <CustomButton
            height={45}
            width={mobileView?130:175}
            marginBottom={5}
            backgroundColor={longterm ? colors.vibrantBlue : colors.amplifyNeutral20}
            border={longterm ? colors.amplifyNeutral80 : "#4c4e52"}
            onPress={() => onLongChange(!longterm)}
          >
            <Text style={{ fontSize: 20 }}>Monthly</Text>
          </CustomButton>
          <CustomButton
            height={45}
            width={mobileView?130:175}
            marginBottom={5}
            backgroundColor={yearly ? colors.vibrantBlue : colors.amplifyNeutral20}
            border={yearly ? colors.amplifyNeutral80 : "#4c4e52"}
            onPress={() => onYearlyChange(!yearly)}
          >
            <Text style={{ fontSize: 20 }}>Yearly</Text>
          </CustomButton>
          <CustomButton
            height={45}
            width={mobileView?130:175}
            marginBottom={5}
            backgroundColor={storage ? colors.vibrantBlue : colors.amplifyNeutral20}
            border={storage ? colors.amplifyNeutral80 : "#4c4e52"}
            onPress={() => onStorageChange(!storage)}
          >
            <Text style={{ fontSize: 20 }}>Storage</Text>
          </CustomButton>
        </Flex>
    </Flex>
  );
};

export default SpaceTypes;
