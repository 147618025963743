import React from 'react';
import { Pressable, Text, StyleSheet, View } from 'react-native';

const CloseButtonUnderlined = ({ onPress }) => {
    return (
        <View style={styles.closeButton}>
            <Pressable onPress={() => onPress()}><Text style={[styles.closeButtonText]}>close</Text></Pressable>
        </View>
    );
};

export default CloseButtonUnderlined;

const styles = StyleSheet.create({
    closeButton: {
        padding: 10,
        marginTop: 10,
    },
    closeButtonText: {
        textDecorationLine: "underline",
        fontSize: 15
    }
});