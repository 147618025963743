import React, { useState } from "react";
import { StyleSheet, Text, View } from "react-native";
import Flex from "../CustomElements/Flex"
import { colors } from "../../styles/colors";
import Header from "../CustomElements/Header";
import { formatMonthDay, formatYear } from "../../utils/formatDate";
import { globalStyles } from "../../styles/styles";
import { AntDesign, Fontisto } from "@expo/vector-icons";
import Card from "../CustomElements/Card";
import CustomButtonUnderlined from "../CustomElements/CustomButtonUnderlined";

const PaymentDatesDisplay = ({
  width,
  stillPaymentsComingBoolean,
  paymentDates,
  formatted = false,
  hostView = true,
  checkIn = "",
  monthlyPrice,
  numberOfMonths,
}) => {
  const [showDate, setShowDates] = useState(false);

  return (
    <Card
      backgroundColor={colors.mellowOrange}
      alignItems="center"
      marginTop={15}
      marginBottom={20}
      textAlign="center"
      width={width / 1.25}
    >
      <Header level={1.25} text={`Payment Dates`} color={"#000"} />
      <Flex width={width / 1.3} marginTop={15} marginBottom={35} textAlign="center">
        <Text style={{ textAlign: 'left', fontSize: 16, marginLeft: 10 }}>
          <AntDesign name="rightcircle" size={24} color={colors.amplifyNeutral80} />
          <Text style={{ fontWeight: "bold", marginLeft: 10 }}>First payment is made when your booking is approved. </Text>
          {numberOfMonths > 1 && `Following payments will be every 28 days after check-in for ${numberOfMonths - 1} month${numberOfMonths > 2 ? 's' : ''}.`}
        </Text>
      </Flex>
      <Flex width={width / 1.3} marginBottom={30} alignContent="center" justifyContent="center">
        <Fontisto name="dollar" size={24} color={colors.amplifyNeutral90} />
        <Header level={1.3} text={Number(monthlyPrice).toLocaleString("en-US", { minimumFractionDigits: 2 })} />
      </Flex>
      <View>
        {showDate ? (
          <View>
            <Text style={{ marginBottom: 15 }}>{`ALL ${hostView ? "PAYOUT" : "PAYMENT"} DATES`}</Text>
            <View style={styles.currentTabView}>
              <Text style={[styles.buttonText, { fontSize: 16 }]}>{hostView ? checkIn : "on request approval"}</Text>
            </View>
            {stillPaymentsComingBoolean && paymentDates.map((v, i) => (
              <View style={styles.currentTabView} key={i}>
                <Text style={[styles.buttonText, { fontSize: 16 }]}>{formatted ? v : `${formatMonthDay(v)}/${formatYear(v)}`}</Text>
              </View>
            ))}
          </View>
        ) : (
          <CustomButtonUnderlined
            onPress={() => setShowDates(true)}
          >
            <View style={globalStyles.center}>
              <AntDesign name="rightcircle" size={24} color={colors.amplifyNeutral90} />
              <Header text={`View ${hostView ? "Payout" : "Payment"} Dates`} color={colors.amplifyNeutral90} level={.9} />
            </View>
          </CustomButtonUnderlined>
        )}
      </View>
    </Card>
  )
};


const styles = StyleSheet.create({
  buttonText: {
    fontSize: 14,
    color: "white",
  },
  currentTabView: {
    top: 0,
    backgroundColor: colors.lightBlue,
    borderRadius: 16,
    alignItems: "center",
    justifyContent: "center",
    alignSelf: "center",
    height: 50,
    paddingRight: 8,
    paddingLeft: 8,
    margin: 1
  },
});

export default PaymentDatesDisplay;





