import { createConversation, createMessage, updateConversation } from "../graphql/mutations";
import { convosByDate, getConversation } from "../graphql/queries";
import { API } from "aws-amplify";

export const listConversationsForPropertyQuery = async ({
    ownerID,
    hosteeID,
    propertyID
}) => {
    const allConvos = await API.graphql({
        query: convosByDate,
        variables: {
            type: "Conversation",
            sortDirection: "DESC",
            filter: {
                host: { eq: ownerID },
                hostee: { eq: hosteeID },
                propertyID: { eq: propertyID }
            }
        },
        authMode: "AMAZON_COGNITO_USER_POOLS",
    }).then((r) => {
        return r.data.convosByDate
    }).catch((e) =>{
        console.log("ERROR   >>> ", e)
        return false
    })
    return allConvos;
};

export const listHostedConversationsQuery = async ({
    ownerID
}) => {
    const allConvos = await API.graphql({
        query: convosByDate,
        variables: {
            type: "Conversation",
            sortDirection: "DESC",
            filter: {
                host: { eq: ownerID }
            }
        },
        authMode: "AMAZON_COGNITO_USER_POOLS",
    }).then((r) => {
        return r.data.convosByDate
    }).catch((e) =>{
        console.log("ERROR   >>> ", e)
        return false
    })
    return allConvos;
};

export const listAllConversations = async ({
    userID
}) => {
    const allConvos = await API.graphql({
        query: convosByDate,
        variables: {
            type: "Conversation",
            sortDirection: "DESC",
            filter: { members: { contains: userID } }
        },
        authMode: "AMAZON_COGNITO_USER_POOLS",
    }).then((d) => {
        return d.data.convosByDate
    }).catch((e) => {
        console.log("ERR >>> ", e)
        return false
    })
    return allConvos;
};

export const getConversationDetails = async ({ convoID }) => {
    const conversation = await API.graphql({
        query: getConversation,
        variables: { id: convoID },
        authMode: "API_KEY",
    }).then((r) => {
        return r.data.getConversation
    }).catch((e) =>{
        console.log("ERROR   >>> ", e)
        return false
    })
    return conversation;
};

export const createConversationMutation = async ({ data }) => {
    const convo = await API.graphql({
        query: createConversation,
        variables: { input: data },
        authMode: "AMAZON_COGNITO_USER_POOLS",
    }).then((v) => {
        return v.data.createConversation
    }).catch((e) => {
        console.log("ERROR   >>> ", e)
        return false;
    })
    return convo;
};

export const updateConversationMutation = async ({ data }) => {
    const convo = await API.graphql({
        query: updateConversation,
        variables: { input: data },
        authMode: "AMAZON_COGNITO_USER_POOLS",
    }).then((v) => {
        return v.data.updateConversation
    }).catch((e) => {
        console.log("ERROR   >>> ", e)
        return false;
    })
    return convo;
};

export const createMessageMutation = async ({ data }) => {
    const msg = await API.graphql({
        query: createMessage,
        variables: { input: data },
        authMode: "AMAZON_COGNITO_USER_POOLS",
    }).then((r) => {
        return r.data.createMessage
    }).catch((e) =>{
        console.log("ERROR   >>> ", e)
        return false
    })
    return msg;
};

