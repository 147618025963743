import React from "react";
import { StyleSheet, Text, useWindowDimensions } from "react-native";
import SetPrice from "../space/SetPrice";
import Flex from "../CustomElements/Flex"
import { Octicons } from "@expo/vector-icons";
import { useSelector } from "react-redux";
import { colors } from "../../styles/colors"
import CustomButton from "../CustomElements/CustomButton";
import CustomModal from "../CustomElements/CustomModal";
import FilterOptions from "./FilterOptions";
import { View } from "react-native";
import { globalStyles } from "../../styles/styles";


const PriceFilter = ({ pricefilter, setPricefilter }) => {
    return (
        <Flex justifyContent="center" alignItems="center" alignContent="center" direction="column">
            <Flex
                justifyContent="flex-start"
                alignItems="flex-start"
                alignContent="flex-start"
            >
                <SetPrice
                    val={pricefilter}
                    setVal={(v) => setPricefilter(v)}
                />
            </Flex>
        </Flex>
    );
};


const FilterModal = ({
    modalVisible,
    setModalVisible,
    clearLocation,
    setStayType,
    stayType,
    showMap,
    setShowMap,
    loading,
    properties
}) => {
    const { width } = useWindowDimensions();
    const { mobileView } = useSelector(state => state.currentUser);

    const clearFilter = () => {
        clearLocation();
        setModalVisible();
    };

    return (

        <CustomModal modalVisible={modalVisible} setModalVisible={() => setModalVisible(false)} modalWidth={width / 1.1}>
            <FilterOptions
                loading={loading}
                showMap={showMap}
                setShowMap={() => setShowMap(!showMap)}
                properties={properties}
                stayType={stayType}
                setStayType={(v) => setStayType(v)}
                mobileView={mobileView}
                width={width}
            />
            {/* <PriceFilter pricefilter={pricefilter} setPricefilter={(v) => setPricefilter(v)} /> */}
            <View style={[globalStyles.center, { marginTop: 30 }]}>
                <CustomButton
                    width={mobileView ? 100 : 300}
                    height={40}
                    backgroundColor={colors.brandGrey}
                    border={colors.amplifyNeutral90}
                    onPress={() => clearFilter()}
                    hideBorder={true}
                >
                    <Text style={{ borderBottomWidth: 1 }}>Clear Filters</Text>
                </CustomButton>
                <CustomButton
                    width={mobileView ? 120 : 300}
                    height={40}
                    backgroundColor={colors.orange}
                    border={colors.amplifyOrange80}
                    onPress={() => setModalVisible(false)}
                >
                    <View style={globalStyles.center}>
                        <Octicons name="codescan-checkmark" size={24} color="#fff" />
                        <Text style={{ color: "#fff", marginLeft: 3 }}>Search</Text>
                    </View>
                </CustomButton>
            </View>
        </CustomModal>
    );
};

const styles = StyleSheet.create({
    centeredView: {
        flex: 1,
        justifyContent: "center",
        alignItems: "center",
    },
    fontSize: {
        fontSize: 15,
        paddingBottom: 2
    },
    textStyle: {
        color: "white",
        fontWeight: "bold",
        textAlign: "center"
    },
    denyTextStyle: {
        color: "#292929",
        textAlign: "center",
        fontWeight: "bold",
    },
    closeText: {
        fontWeight: "bold",
        textDecorationLine: "underline"
    },
    modalText: {
        marginBottom: 15,
        textAlign: "center"
    },
    title: {
        textAlign: "left",
        width: '100%',
        backgroundColor: "#2196F3"
    },
    note: {
        textAlign: "center",
        backgroundColor: "#d6d6d6",
        borderRadius: 20,
        padding: 7
    },
});

export default FilterModal;