import { useRef } from "react";
import { PanGestureHandler, ScrollView } from "react-native-gesture-handler";


const WebPullToRefresh = ({
    setRefresh,
    children,
}) => {
    const scrollViewRef = useRef();

    const onPullDown = (e) => {
        const scrolledToTop = scrollViewRef.current.scrollTop === 0;
        const pullingDown = e.nativeEvent.velocityY > 0;
        scrolledToTop && pullingDown && setRefresh(true);
    }

    return (
        <PanGestureHandler onGestureEvent={onPullDown}>
            <ScrollView ref={scrollViewRef}>
                { children }
            </ScrollView>
        </PanGestureHandler>
    )
}

export default WebPullToRefresh;