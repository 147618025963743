import React from 'react';
import {
    View,
    Dimensions,
    StyleSheet,
    Platform,
} from 'react-native';
import { colors } from '../../styles/colors';

const Card = ({ 
    children, 
    elevation=3, 
    opacity=10, 
    cornerRadius=4,
    backgroundColor="#fff",
    width=Dimensions.get('window').width /1.2,
    marginTop = 5,
    margin = 5,
    marginBottom=5,
    marginLeft=5,
    marginRight=5,
    padding=10,
    textAlign="center",
    alignItems="center",
    shadow=true,
    borderRadius=10,
    shadowColor=colors.amplifyNeutral80
}) => {

    const cardStyle = Platform.select({
        ios: () =>
            StyleSheet.create({
                container: {
                    shadowRadius: elevation,
                    shadowOpacity: opacity,
                    shadowOffset: { width: 0, height: elevation },
                }
            }),
        android: () =>
            StyleSheet.create({
                container: {
                    elevation: elevation,
                }
            }),
        web: () =>
            StyleSheet.create({
                container: {
                    elevation: elevation,
                }
            })
    })();

    return (
        <View style={[cardStyle.container, styles.card, shadow && styles.shadowProp, {
            margin: margin,
            marginTop: marginTop,
            marginBottom: marginBottom,
            marginLeft: marginLeft,
            marginRight: marginRight,
            width:width,
            padding:padding,
            textAlign:textAlign,
            alignItems:alignItems,
            backgroundColor: backgroundColor,
            borderRadius: borderRadius,
            borderColor: shadowColor,
            borderWidth: .2,
            borderBottomWidth: 1.5,
            shadowColor: shadowColor
        }]}>
            {children}
        </View>
    )

}

const styles = StyleSheet.create({
  card: {
    marginVertical: 10,
  },
  shadowProp: {
    shadowOffset: {width: .2, height: .9},
    shadowOpacity: 0.6,
    shadowRadius: 5,
  },
})
export default Card;