import { createReview } from "../graphql/mutations";
import { getReview, listRenterReviews, listReviews } from "../graphql/queries";
import { API } from "aws-amplify";

export const getReviewDetails = async (reviewID) => {
    const review = await API.graphql({
        query: getReview,
        variables: { id: reviewID },
        authMode: "API_KEY",
    })
    return review?.data?.getReview;
};

export const reviewCheck = async ({ propertyID, reviewerID }) => {
    const review = await API.graphql({
        query: listReviews,
        variables: {
            filter: {
                propertyID: { eq: propertyID },
                reviewerID: { eq: reviewerID }
            }
        },
        authMode: "AMAZON_COGNITO_USER_POOLS",
    })
    return review?.data?.listReviews;
};

export const renterReviewCheck = async ({ bookingID, renterID }) => {
    const review = await API.graphql({
        query: listRenterReviews,
        variables: {
            filter: {
                bookingID: { eq: bookingID },
                renterID: { eq: renterID }
            }
        },
        authMode: "AMAZON_COGNITO_USER_POOLS",
    })
    return review?.data?.listRenterReviews;
};


export const createReviewOfPropertyMutation = async ({ data }) => {
    const review = await API.graphql({
        query: createReview,
        variables: { input: data },
        authMode: "AMAZON_COGNITO_USER_POOLS",
    }).then((r) => {
        return r.data.createReview
    }).catch((e) => {
        console.log("e in create review >>  ", e)
        return false;
    })
    return review;
};