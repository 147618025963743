import React, { useContext, useState } from "react";
import { Text, Pressable, View, useWindowDimensions } from "react-native";
import { useSelector } from "react-redux";
import { FontAwesome } from '@expo/vector-icons';
import { UserDataContext } from "../../utils/UserContextProvider";
import Flex from "../CustomElements/Flex"
import Header from "../CustomElements/Header";
import CustomButton from "../CustomElements/CustomButton";
import { colors } from "../../styles/colors";
import CustomTextInput from "../CustomElements/CustomTextInput";
import { UUID } from "../../utils/UUID";
import CustomModal from "../CustomElements/CustomModal";
import { ErrorDisplay } from "../CustomElements/ErrorDisplay";
import CloseButtonUnderlined from "../CustomElements/CloseButtonUnderlined";
import { sendEmail } from "../../lambdaFunctions/sendEmail/sendEmail";
import { createReviewOfPropertyMutation } from "../../api/review";
import { globalStyles } from "../../styles/styles";

const ReviewStarSelect = ({ type, starVal, setStarVal, width, mobileView }) => {
    return (
        <View style={[mobileView ? globalStyles.centerColumn : globalStyles.spaceBetween, {marginVertical: mobileView ? 10 : 5}]}>
            <Text>{type}</Text>
            <View style={{ flexDirection: "row" }}>
                <Pressable onPress={() => setStarVal(1)} >
                    {starVal >= 1 ? <FontAwesome name="star" margin={.5} padding={.5} size={24} color={colors.orange} /> : <FontAwesome name="star-o" size={24} color="black" />}
                </Pressable>
                <Pressable onPress={() => setStarVal(2)} >
                    {starVal >= 2 ? <FontAwesome name="star" margin={.5} padding={.5} size={24} color={colors.orange} /> : <FontAwesome name="star-o" size={24} color="black" />}
                </Pressable>
                <Pressable onPress={() => setStarVal(3)} >
                    {starVal >= 3 ? <FontAwesome name="star" margin={.5} padding={.5} size={24} color={colors.orange} /> : <FontAwesome name="star-o" size={24} color="black" />}
                </Pressable>
                <Pressable onPress={() => setStarVal(4)} >
                    {starVal >= 4 ? <FontAwesome name="star" margin={.5} padding={.5} size={24} color={colors.orange} /> : <FontAwesome name="star-o" size={24} color="black" />}
                </Pressable>
                <Pressable onPress={() => setStarVal(5)} >
                    {starVal >= 5 ? <FontAwesome name="star" margin={.5} padding={.5} size={24} color={colors.orange} /> : <FontAwesome name="star-o" size={24} color="black" />}
                </Pressable>
            </View>
        </View>
    )
}

const ReviewCreateCard = ({
    bookingToDisplay,
    width,
    mobileView,
    setCheckInStars,
    setCommunicationStars,
    setDescription,
    setLocationStars,
    setValueStars,
    checkInStars,
    communicationStars,
    description,
    locationStars,
    valueStars
}) => {

    return (
        <View>
            <ReviewStarSelect mobileView={mobileView} width={width / 1.8} type="Check-In" starVal={checkInStars} setStarVal={(v) => { setCheckInStars(v) }} />
            <ReviewStarSelect mobileView={mobileView} width={width / 1.8} type="Communication" starVal={communicationStars} setStarVal={(v) => { setCommunicationStars(v) }} />
            <ReviewStarSelect mobileView={mobileView} width={width / 1.8} type="Location" starVal={locationStars} setStarVal={(v) => { setLocationStars(v) }} />
            <ReviewStarSelect mobileView={mobileView} width={width / 1.8} type="Value" starVal={valueStars} setStarVal={(v) => { setValueStars(v) }} />
            <Text style={{ alignSelf: "flex-start", marginTop: 10 }}>Comments</Text>
            <CustomTextInput
                textAlign="left"
                height={100}
                width={width / 1.4}
                multiline={true}
                onChangeText={(e) => setDescription(e)}
                value={description}
                backgroundColor="#fff"
            ></CustomTextInput>
        </View>
    );
};

const ReviewPropertyModal = ({
    setAlreadyReview,
    modalVisible,
    setModalVisible,
    bookingDetails,
}) => {
    const {
        id,
        cancelled,
        bookingRequest,
        hostEmail,
        hostID,
    } = bookingDetails;
    const {
        spaceCity,
        spaceState,
        spaceTitle,
        propertyID,
        propertyTitle
    } = bookingRequest;
    const { user } = useContext(UserDataContext);
    const { userData } = user;
    const { mobileView } = useSelector(state => state.currentUser);
    const { width } = useWindowDimensions();
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(false);
    const [description, setDescription] = useState('');
    const [checkInStars, setCheckInStars] = useState(0);
    const [locationStars, setLocationStars] = useState(0);
    const [communicationStars, setCommunicationStars] = useState(0);
    const [valueStars, setValueStars] = useState(0);

    const closeThis = () => {
        setLoading(false);
        setError(false);
        setModalVisible(!modalVisible);
    };

    const createReview = async () => {
        setLoading(true);
        const averageRating = Number((locationStars + communicationStars + checkInStars + valueStars) / 4).toFixed(2);
        const data = {
            id: UUID(),
            locationRating: locationStars,
            communicationRating: communicationStars,
            checkinRating: checkInStars,
            valueRating: valueStars,
            rating: averageRating,
            description: description,
            propertyID: propertyID,
            reviewerName: userData.userName,
            reviewerAvatar: userData?.userData.avatar,
            reviewerID: userData.sub,
            bookingID: id
        };
        const createReview = await createReviewOfPropertyMutation({ data });
        if (createReview) {
            setAlreadyReview(createReview.rating)
            const { reviewerName } = data;
            const emailBody = `You just got a new review on Hookhub! Your space was just reviewed by ${reviewerName} on your space: '${spaceTitle}'. If you haven't left a review for ${reviewerName} yet, please logon to Hookhub and leave a review.`
            const emailSubject = `You just received a new Review`;
            const emailAddress = hostEmail;
            sendEmail({ emailAddress, emailBody, emailSubject });
        } else {

            console.log("ERROR  > ")
            setError(true);
        }
        setLoading(false);
        closeThis();
    };

    return (
        <CustomModal modalVisible={modalVisible} setModalVisible={() => setModalVisible(!modalVisible)}>
            <View style={[globalStyles.centerColumn, { marginBottom: 30, marginTop: 10 }]}>
                <Header text={propertyTitle} textAlign="center"/>
                <Text>{spaceCity}, {spaceState}</Text>
            </View>
            <ReviewCreateCard
                mobileView={mobileView}
                cancelled={cancelled}
                bookingToDisplay={bookingDetails}
                width={width}
                setCheckInStars={(v) => setCheckInStars(v)}
                setCommunicationStars={(v) => setCommunicationStars(v)}
                setDescription={(v) => setDescription(v)}
                setLocationStars={(v) => setLocationStars(v)}
                setValueStars={(v) => setValueStars(v)}
                checkInStars={checkInStars}
                communicationStars={communicationStars}
                description={description}
                locationStars={locationStars}
                valueStars={valueStars}
            />

            {!loading && (
                <>
                    {error ? (<ErrorDisplay customWidth={width / 1.6} error="Error saving review. Please contact Hookhub support if this problem persists." />
                    ) : (
                        <Flex
                            wrap="wrap"
                            direction={mobileView ? "row" : "column"}
                            justifyContent="center"
                            alignItems="center"
                            alignContent="center"
                            marginBottom={10}
                            marginTop={20}
                        >
                            <CustomButton
                                width={100}
                                backgroundColor={!description.length ? colors.amplifyNeutral60 : colors.orange}
                                color="#fff"
                                disabled={!description.length}
                                border={!description.length ? colors.amplifyNeutral80 : colors.amplifyOrange80}
                                onPress={() => createReview()}
                            >
                                <Text style={{ fontSize: 17 }}>save</Text>
                            </CustomButton>
                        </Flex>
                    )}
                </>
            )}
            <CloseButtonUnderlined onPress={() => closeThis()} />
        </CustomModal>
    );
};

export default ReviewPropertyModal;
