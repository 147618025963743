import { Text, useWindowDimensions, Image } from "react-native";
import { Authenticator as ReactAuthenticator, Flex } from "@aws-amplify/ui-react";
import '@aws-amplify/ui-react/styles.css';
import getEnvVars from "../../environment";

const Authenticator = ({ children }) => {
    const { height, width } = useWindowDimensions();
    const { cloudfrontURL } = getEnvVars();
    const formFields = {
        signUp: {
            name: {
                labelHidden: true,
                label: "name:",
                placeholder: "Legal Name",
                isRequired: true,
                order: 2,
            },
        },
        forceNewPassword: {
            password: {
                labelHidden: false,
                placeholder: "Enter your Password:",
            },
        },
        resetPassword: {
            email: {
                labelHidden: false,
                placeholder: "Enter your email:",
            },
        },
        confirmResetPassword: {
            confirmation_code: {
                labelHidden: false,
                placeholder: "Enter your Confirmation Code:",
                label: "New Label",
                isRequired: false,
            },
            confirm_password: {
                labelHidden: false,
                placeholder: "Enter your Password Please:",
            },
        },
        setupTOTP: {
            QR: {
                totpIssuer: "test issuer",
                totpUsername: "amplify_qr_test_user",
            },
            confirmation_code: {
                labelHidden: false,
                label: "New Label",
                placeholder: "Enter your Confirmation Code:",
                isRequired: false,
            },
        },
        confirmSignIn: {
            confirmation_code: {
                labelHidden: false,
                label: "New Label",
                placeholder: "Enter your Confirmation Code:",
                isRequired: false,
            },
        },
    };
    const mobileView = width < 500;
    const components = {
        Header() {
            return (
                <Flex textAlign="center" justifyContent="center" alignContent="center" alignItems="center" margin={10}>
                    <Image
                        alt="Hookhub logo"
                        style={{ marginTop:40, marginBottom:5, width: mobileView? 140 : 251, height: mobileView? 36 : 65 }}
                        source={{ uri: `${cloudfrontURL}hookhub-logo_mini.png` }}
                    />
                </Flex>
            );
        },
        Footer() {
            return (
                <Flex textAlign="center" justifyContent="center" alignContent="center" alignItems="center" margin={15}>
                    <Text>
                        &copy; All Rights Reserved
                    </Text>
                </Flex>
            );
        },
    };

    return (
        <ReactAuthenticator
            loginMechanisms={["email"]}
            initialState="login"
            formFields={formFields}
            components={components}
            // socialProviders={["google"]}
            variation="modal"
            height={mobileView ? height/1.8 : height}
        >
            {children}
        </ReactAuthenticator>
    );
};

export default Authenticator;
