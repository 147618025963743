import React, { useEffect, useState } from "react";
import {
  StyleSheet,
  Text,
  Pressable,
  useWindowDimensions,
  View
} from "react-native";
import { useSelector } from "react-redux";
import { Entypo, Ionicons } from "@expo/vector-icons";
import { colors } from "../../styles/colors";
import Card from "../CustomElements/Card";
import moment from "moment";
import Badge from "../CustomElements/Badge";

const Convo = ({ convo, selectedConvo, setSelectedConvo, myID, myName, mobileView }) => {
  const { propertyTitle, propertyLocation } = convo;
  const TIME_FORMAT = "MM/DD, h:mm a";
  const myspace = convo.host === myID;
  const { width } = useWindowDimensions();
  let timeStamp = moment(new Date(convo.updatedAt)).format(TIME_FORMAT);
  const currentConvoToDisplay = mobileView ? false : selectedConvo === convo.id;
  const [myUnreadMsg, setMyUnreadMsg] = useState(false);

  useEffect(() => {
    const unread = myspace ? convo.unreadHost : convo.unreadHostee;
    setMyUnreadMsg(currentConvoToDisplay ? false : !!unread)
  }, [selectedConvo, convo.unreadHost, convo.unreadHostee]);

  return (
    <Pressable onPress={() => setSelectedConvo(convo.id)}>
      <Card
        marginRight={mobileView ? 0 : 5}
        marginTop={5}
        padding={mobileView ? 10 : 15}
        marginBottom={5}
        backgroundColor={currentConvoToDisplay ? colors.hookhubOrangeDark : myUnreadMsg ? "#fff" : colors.brandGrey}
        width={mobileView ? width / 1.1 : width * .39}
      >
        <View
          direction="row"
          alignContent="center"
          justifyContent="center"
          style={{ width: mobileView ? width / 1.2 : width * .36 }}
        >
          <View style={{ flexDirection: "row", justifyContent: "space-between", marginBottom: 8 }}>
            <Text
              numberOfLines={1}
              ellipsizeMode="head"
              style={{ fontWeight: "bold", fontSize: 16 }}
            >
              {convo.membersNames[0] === myName ? convo.membersNames[1] : convo.membersNames[0]}
            </Text>
            {timeStamp ? (
              <Text style={{ fontSize: 15 }}>
                {timeStamp}
              </Text>
            ) : <></>}
          </View>

          <View style={{ width: mobileView ? width/1.2 : width * .35 }}>
            <Text
              numberOfLines={1}
              ellipsizeMode="tail"
              style={[currentConvoToDisplay ? styles.header : styles.headerUnselected, { width: mobileView ? width/1.2 : width * .3, color: colors.amplifyNeutral90, textAlign: "left" }]}
            >
              {propertyTitle}
            </Text>
          </View>

          <View style={{ flexDirection: myspace ? "column" : "row", justifyContent: "space-between" }}>
            {myspace ? (
              <Badge onPress={() => setSelectedConvo(convo.id)} size={"small"} variation={"lightGrey"}><Text>my property</Text></Badge>
            ) : (
              <Text
                numberOfLines={1}
                ellipsizeMode="tail"
                style={[currentConvoToDisplay ? styles.locationText : styles.locationTextUnselected, { width: 210 }]}
              >
                {propertyLocation}
              </Text>
            )}
            <View style={{ flexDirection: "row", justifyContent: "flex-end", alignContent: "center", alignItems: "center", marginBottom: 1 }}>
              {myUnreadMsg && <Text style={{ marginRight: 3 }}>new!</Text>}
              {myUnreadMsg && !currentConvoToDisplay ? (<Entypo name="mail" size={20} color={colors.amplifyNeutral80} />) : <Ionicons name="mail-open-sharp" size={20} color={colors.amplifyNeutral80} />}
            </View>
          </View>
        </View>
      </Card>
    </Pressable >
  );
};

const AllConversations = ({
  selectedConvo,
  setSelectedConvo,
  myID,
  myName,
  mobileView
}) => {
  const { conversations } = useSelector((state) => state.conversations);

  return (
    <View style={{ marginBottom: mobileView ? 5 : 120 }}>
      {
        conversations.map((m, i) => (
          <Convo
            convo={m}
            selectedConvo={selectedConvo}
            myID={myID}
            setSelectedConvo={(v) => setSelectedConvo(v)}
            key={i}
            index={i}
            myName={myName}
            mobileView={mobileView}
          />
        ))
      }
    </View>
  )
};

export default AllConversations;

const styles = StyleSheet.create({
  locationText: {
    color: "white",
    fontSize: 15,
    marginBottom: 5,
    textAlign: 'left'
  },
  locationTextUnselected: {
    color: "#3a3b3b",
    fontSize: 15,
    marginBottom: 5,
    textAlign: 'left'
  },
  header: {
    color: "white",
    fontWeight: "bold",
    fontSize: 15,
    marginBottom: 2,
    textAlign: 'left'
  },
  headerUnselected: {
    color: "#3a3b3b",
    fontWeight: "bold",
    fontSize: 15,
    marginBottom: 2,
    textAlign: 'left'
  },
  end: {
    justifyContent: "flex-end",
    alignContent: "flex-end",
    alignItems: "flex-end",
    textAlign: "right"
  }
});
