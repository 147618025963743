import React from "react";
import {
    View as ReactView,
    StyleSheet,
    Pressable,
    Text as ReactText
} from "react-native";
import { colors } from "../../styles/colors";

const CustomCardTabs = ({
    setActiveTabIndex,
    activeTabIndex,
    mobileView,
}) => {
    return (
        <>
            <ReactView style={[styles.tabView, {right: !!mobileView ? 120 : 203, width: !!mobileView ? 71 : 100}, activeTabIndex === 'current' ? { backgroundColor: colors.amplifyBlue20 } : { backgroundColor: colors.amplifyNeutral60  }]}>
                <Pressable onPress={() => setActiveTabIndex('current')}>
                    <ReactText style={[styles.CustomButtonText, { fontSize: !!mobileView ? 12 : 14 }]}>CURRENT</ReactText>
                </Pressable>
            </ReactView>
            <ReactView style={[styles.tabView, { right: !!mobileView ? 43 : 103, width: !!mobileView ? 77 : 100 }, activeTabIndex === 'upcoming' ? { backgroundColor: colors.amplifyBlue20 } : { backgroundColor: colors.amplifyNeutral60  }]}>
                <Pressable onPress={() => setActiveTabIndex('upcoming')}>
                    <ReactText style={[styles.CustomButtonText, { fontSize: !!mobileView ? 12 : 14 }]}>UPCOMING</ReactText>
                </Pressable>
            </ReactView>
            <ReactView style={[styles.tabView, { right: 3, width: !!mobileView ? 13 : 100 }, activeTabIndex === 'past' ? { backgroundColor: colors.amplifyBlue20 } : { backgroundColor: colors.amplifyNeutral60  }]}>
                <Pressable onPress={() => setActiveTabIndex('past')}>
                    <ReactText style={[styles.CustomButtonText, { fontSize: !!mobileView ? 12 : 14 }]}>PAST</ReactText>
                </Pressable>
            </ReactView>
        </>
    )
}

export default CustomCardTabs;


const styles = StyleSheet.create({
    wrapperCustom: {
        padding: 6,
    },
    cancelled: {
        textAlign: "center",
        backgroundColor: "grey",
        padding: 7
    },
    CustomButtonText: {
        color: "#000",
        marginBottom: 16
    },
    CustomButton: {
        borderColor: colors.lightBlue,
        width: 100,
        backgroundColor: colors.lightBlue,
        alignItems: "center",
        justifyContent: "center",
        alignSelf: "center",
        height: 30,
        marginBottom: 20
    },
    wrapperView: {
        alignItems: "center",
        justifyContent: "center",
        alignSelf: "center",
        marginBottom: 100
    },
    reservationsView: {
        margin: 10,
        paddingBottom: 15,
        backgroundColor: colors.lightBlue,
        alignItems: "flex-start",
        shadowColor: "#000",
        shadowOffset: {
            width: 0,
            height: 2,
        },
        shadowOpacity: 0.25,
        shadowRadius: 4,
        elevation: 5,
    },
    tabView: {
        position: "absolute",
        top: -20,
        borderRadius: 10,
        borderColor: colors.lightBlue,
        alignItems: "center",
        justifyContent: "center",
        alignSelf: "center",
        height: 20,
        padding: 20,
        marginBottom: 7
    },
    image: {
        flex: 1,
        justifyContent: "center",
        padding: 12,
        marginBottom: 10
    },
    title: {
        textAlign: "left",
        paddingLeft: 4,
        backgroundColor: "#000000c0"
    },
});
