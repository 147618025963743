// Action Types
export const SET_SPACE_TYPE = 'SET_SPACE_TYPE';
export const SET_SPACE_DETAILS = 'SET_SPACE_DETAILS';
export const SET_SPACE_LOCATION = 'SET_SPACE_LOCATION';
export const SET_SPACE_COORDINATES = 'SET_SPACE_COORDINATES';
export const SET_IMAGE_KEYS = 'SET_IMAGE_KEYS';
export const SET_TITLE = 'SET_TITLE';
export const SET_DESCRIPTION = 'SET_DESCRIPTION';
export const SET_DIMENSIONS = 'SET_DIMENSIONS';
export const SET_SPACE_DISTANCES = 'SET_SPACE_DISTANCES';
export const SET_PRICES = 'SET_PRICES';
export const CLEAR_SPACE_DETAILS = 'CLEAR_SPACE_DETAILS';
export const SET_IMAGE_PREVIEW = 'SET_IMAGE_PREVIEW';
export const SET_THINGS_TO_KNOW = 'SET_THINGS_TO_KNOW';
export const SET_ADDITIONAL_SPACE_DETAILS = 'SET_ADDITIONAL_SPACE_DETAILS';
export const SET_IMAGE_STATUS = 'SET_IMAGE_STATUS';
export const SET_ADDITIONAL_SPACES_ALTERED_DATA = 'SET_ADDITIONAL_SPACES_ALTERED_DATA';
export const SET_ADDITIONAL_SPACES_OPTION = 'SET_ADDITIONAL_SPACES_OPTION';
export const SET_ADDITIONAL_SPACES_TITLES = 'SET_ADDITIONAL_SPACES_TITLES';
export const SET_NEW_PROPERTY_BOOLEAN = 'SET_NEW_PROPERTY_BOOLEAN';

export const setSpaceType = value => (
  {
    type: SET_SPACE_TYPE,
    short: value.short,
    long: value.long,
    yearly: value.yearly,
    storage: value.storage
  }
);

export const setSpaceDetails = value => (
  {
    type: SET_SPACE_DETAILS,
    electric_hookup: value.electric_hookup,
    water_hookup: value.water_hookup,
    sewage_hookup: value.sewage_hookup,
    wifi_available: value.wifi_available,
    shower_available: value.shower_available,
    restroom_available: value.restroom_available,
    restaurant_nearby: value.restaurant_nearby,
    food_nearby: value.food_nearby,
    water_accessible: value.water_accessible,
    easy_entry: value.easy_entry,
    laundry_available: value.laundry_available,
    pool_available: value.pool_available,
    hottub_available: value.hottub_available,
    outdoor_area_available: value.outdoor_area_available,
    indoor_area_available: value.indoor_area_available,
    trash_available: value.trash_available,
    pets_allowed: value.pets_allowed,
    smoking_allowed: value.smoking_allowed,
    adults_only: value.adults_only,
    outdoor_fires: value.outdoor_fires,
  }
);

export const setThingsToKnow = value => (
  {
    type: SET_THINGS_TO_KNOW,
    check_in_time: value.check_in_time,
    check_out_time: value.check_out_time,
    space_rules: value.space_rules,
    instant_book: value.instant_book,
    checkInInstructions: value.checkInInstructions,
    quiet_hours_start: value.quiet_hours_start,
    quiet_hours_end: value.quiet_hours_end,
    full_refund_date: value.full_refund_date,
    half_refund_date: value.half_refund_date,
    wifi_network_name: value.wifi_network_name,
    wifi_password: value.wifi_password
  }
);

export const setSpaceDistances = value => (
  {
    type: SET_SPACE_DISTANCES,
    distance_electric_hookup: value.distance_electric_hookup,
    distance_water_hookup: value.distance_water_hookup,
    distance_sewage_hookup: value.distance_sewage_hookup,
  }
);

export const setSpaceLocation = value => (
  {
    type: SET_SPACE_LOCATION,
    address: value.address,
    cityName: value.cityName,
    stateName: value.stateName,
    countryName: value.countryName,
    canadianCivicAddress: value.canadianCivicAddress,
    zipcode: value.zipcode,
    fullAddress: value.fullAddress,
  }
);

export const setSpaceCoordinates = value => (
  {
    type: SET_SPACE_COORDINATES,
    coordinates: value.coordinates,
  }
);

export const setSpaceTitle = value => (
  {
    type: SET_TITLE,
    title: value.title,
    existingSpaceTitleToUpdate: value.existingSpaceTitleToUpdate,
  }
);

export const setSpaceDescription = value => (
  {
    type: SET_DESCRIPTION,
    description: value.description,
    thingsInArea: value.thingsInArea,
  }
);


export const setSpaceDimensions = value => (
  {
    type: SET_DIMENSIONS,
    length: value.length,
    width: value.width,
  }
);


export const setSpacePrices = value => (
  {
    type: SET_PRICES,
    price_storage: value.price_storage,
    price_shortterm: value.price_shortterm,
    price_longterm: value.price_longterm,
    price_yearly: value.price_yearly
  }
);


export const setImageStatus = value => (
  {
    type: SET_IMAGE_STATUS,
    imageIDs: value.imageIDs,
    imageUploadError: value.imageUploadError,
    imagesFinishedUploading: value.imagesFinishedUploading
  }
);

export const clearSpaceDetails = () => (
  {
    type: CLEAR_SPACE_DETAILS
  }
);

export const setAdditionalSpaceDetails = value => (
  {
    type: SET_ADDITIONAL_SPACE_DETAILS,
    additionalSpacesBoolean: value.additionalSpacesBoolean,
    newProperty: value.newProperty,
    imageIDs: value.imageIDs,
    type_shortterm: value.short,
    type_longterm: value.long,
    type_yearly: value.yearly,
    type_storage: value.storage,
    check_in_time: value.check_in_time,
    check_out_time: value.check_out_time,
    space_rules: value.space_rules,
    instant_book: value.instant_book,
    checkInInstructions: value.checkInInstructions,
    quiet_hours_start: value.quiet_hours_start,
    quiet_hours_end: value.quiet_hours_end,
    full_refund_date: value.full_refund_date,
    half_refund_date: value.half_refund_date,
    title: value.title,
    description: value.description,
    thingsInArea: value.thingsInArea,
    length: value.length,
    width: value.width,
    electric_hookup: value.electric_hookup,
    water_hookup: value.water_hookup,
    sewage_hookup: value.sewage_hookup,
    wifi_available: value.wifi_available,
    wifi_network_name:  value.wifi_network_name,
    wifi_password: value.wifi_password,
    shower_available: value.shower_available,
    restroom_available: value.restroom_available,
    food_nearby: value.food_nearby,
    restaurant_nearby: value.restaurant_nearby,
    water_accessible: value.water_accessible,
    easy_entry: value.easy_entry,
    laundry_available: value.laundry_available,
    pool_available: value.pool_available,
    hottub_available: value.hottub_available,
    outdoor_area_available: value.outdoor_area_available,
    indoor_area_available: value.indoor_area_available,
    trash_available: value.trash_available,
    pets_allowed: value.pets_allowed,
    smoking_allowed: value.smoking_allowed,
    adults_only: value.adults_only,
    outdoor_fires: value.outdoor_fires,
    price_storage: value.price_storage,
    price_shortterm: value.price_shortterm,
    price_longterm: value.price_longterm,
    price_yearly: value.price_yearly,
    newProperty: value.newProperty,
    propertyID: value.propertyID,
    existingSpaceTitles: value.existingSpaceTitles,
    existingSpaceID: value.existingSpaceID,
    existingSpaceTitleToUpdate: value.existingSpaceTitleToUpdate
  }
);

export const setAdditionalSpacesOption = value => (
  {
    type: SET_ADDITIONAL_SPACES_OPTION,
    additionalSpacesBoolean: value.additionalSpacesBoolean,
    numberAdditionalSpaces: value.numberAdditionalSpaces,
  }
);

export const setNewPropertyBoolean = value => (
  {
    type: SET_NEW_PROPERTY_BOOLEAN,
    newProperty: value.newProperty,
    additionalSpacesBoolean: value.additionalSpacesBoolean
  }
);

export const setAdditionalSpacesAlteredData = value => (
  {
    type: SET_ADDITIONAL_SPACES_ALTERED_DATA,
    additionalSpacesAlteredData: value.additionalSpacesAlteredData,
    additionalSpacesTitleType: value.additionalSpacesTitleType
  }
);
