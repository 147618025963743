import getEnvVars from "../../../environment";
import { notifyHookhub } from "../notifyHookhub";
const { REACT_APP_ENV } = getEnvVars();

const sendErrorEmail = () => {
    const body = `PAYMENT failed on INSTANT_BOOK. ERROR >>  Stripe payment intent. THIS request: ${bookingRequestID}, for RENTER id: ${renterID}, RENTER email: ${renterEmail}, RENTER stripe customer ID: ${stripeCustomerID}. The amount meant to be charge now is: ${!!payMonthly ? monthlyPaymentAmount : rentalCost}. pay monthly: ${payMonthly}`
    notifyHookhub({ subject, body, userID: hostID, userEmail: hostEmail })
}

export const makePayment = async ({ bookingData }) => {
    const requestOptions = {
        method: 'POST',
        body: JSON.stringify({
            booking: bookingData,
            enviro: REACT_APP_ENV
        })
    };
    return fetch('https://uahpecmof3b4rjnrwnwhtqarw40ulwyd.lambda-url.us-west-2.on.aws/', requestOptions)
        .then(response => response.json())
        .then(data => {
            const paymentIntentID = data?.paymentIntent?.id;
            const paymentMethodID = data?.paymentIntent?.payment_method;

            if (!paymentIntentID || !paymentMethodID) {
                handleError({ emailBody, errorText })
                return false
            }
            return true;
        }).catch((e) => {
            console.log("PAYMENT error >>>   ", e)
            return false
        })
}


