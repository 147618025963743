import React, { useState } from "react";
import { StyleSheet, useWindowDimensions, ScrollView, Text, View } from "react-native";
import Header from "../../components/CustomElements/Header";
import { globalStyles } from "../../styles/styles";
import FooterView from "../../components/other/FooterView";
import { FontAwesome5 } from "@expo/vector-icons";

export default function SecurityScreen() {

  return (
    <ScrollView style={{backgroundColor: "#fff"}}>
      <View style={[globalStyles.flexStartColumn, {marginTop: 10, marginBottom: 50}]}>
        <Header marginTop={12} level={1.2} text="Your Security and Privacy" padding={15} />
        <View style={{ padding: 15, }}>
          <Header color="#000" marginBottom={5} level={.9} padding={0} text="Your Data" />
          <Text>
            Hookhub does not sell your personal information to third parties: we’re not a data broker and we don’t put personal information on the open market.
            None of your personal information is shared with third parties for targeted advertising, data analytics or any other reason.
          </Text>
        </View>
        <View style={{ padding: 15, }}>
          <Header color="#000" marginBottom={5} level={.9} padding={0} text="Your Security" />
          <Text>
            Hookhub does not have access to your sensitive credit card, payment or payout data.
            All payment and/or banking information is handled via our secure third-party vendor Stripe.
            Stripe is certified as a Level 1 Service Provider, which is the highest level of certification for payment processors. Stripe complies with the Payment Card Industry Data Security Standard (PCI-DSS), a set of security standards established by major card brands.
          </Text>
          <FontAwesome5 name="stripe" size={35} color="black" />
        </View>
        <View style={{ padding: 15, }}>
          <Text>You can read more about how we use your personal information in our Privacy Policy found below.</Text>
        </View>
      </View>
      <FooterView />
    </ScrollView>
  );
}

const styles = StyleSheet.create({
});
