import React, { useState } from "react";
import {
  StyleSheet,
  Image,
  useWindowDimensions,
  View,
  Text
} from "react-native";
import Flex from "../CustomElements/Flex"
import { Feather } from "@expo/vector-icons";
import { ShowAllPhotosModal } from "./ShowAllPhotosModal";
import getEnvVars from '../../../environment';
import CustomButton from "../CustomElements/CustomButton";
import { colors } from "../../styles/colors";
import { globalStyles } from "../../styles/styles";
const { cloudfrontURL } = getEnvVars();


export const ImageGrid = ({ notSpaceConfirmationPreview = false, images }) => {
  const { width, height } = useWindowDimensions();
  const [showAllPhotosModalOpen, setShowAllPhotosModalOpen] = useState(false);
  const mobileView = width < 800

  return (
    <View style={globalStyles.center}>
      {images.length && (
        <View style={globalStyles.center}>
          {!mobileView ? (
            <View style={globalStyles.center}>
              <Image
                source={{ uri: `${cloudfrontURL}${images[0]}` }}
                resizeMode="cover"
                style={[styles.images, { height: (height / 2) + 10, width: width * .54 }]}
              />
              <View style={{ flexDirection: "column" }}>
                <View style={{ flexDirection: "row" }}>
                  <Image
                    source={{ uri: `${cloudfrontURL}${images[1]}` }}
                    resizeMode="cover"
                    style={[styles.images, { height: height / 4, width: width * .22 }]}
                  />
                  <Image
                    source={{ uri: `${cloudfrontURL}${images[2]}` }}
                    resizeMode="cover"
                    style={[styles.images, { height: height / 4, width: width * .22 }]}
                  />
                </View>
                {images.length > 4 ? (
                  <View style={{ flexDirection: "row" }}>
                    <Image
                      source={{ uri: `${cloudfrontURL}${images[3]}` }}
                      resizeMode="cover"
                      style={[styles.images, { height: height / 4, width: width * .22 }]}
                    />
                    <Image
                      source={{ uri: `${cloudfrontURL}${images[4]}` }}
                      resizeMode="cover"
                      style={[styles.images, { height: height / 4, width: width * .22 }]}
                    />
                  </View>
                ) : (
                  <Image
                    source={{ uri: `${cloudfrontURL}${images[3]}` }}
                    resizeMode="cover"
                    style={[styles.images, { height: height / 4, width: width * .45 }]}
                  />
                )}
              </View>
            </View>
          ) : (
            <Flex alignItems="center" textAlign="center" justifyContent="center" marginBottom={10}>
              <Image
                source={{ uri: `${cloudfrontURL}${images[0]}` }}
                resizeMode="cover"
                style={[styles.image, { width: width / 1.01 }]}
              />
            </Flex>
          )}
          {!notSpaceConfirmationPreview &&
            <View style={{ position: "absolute", bottom: 10, right: mobileView ? 3 : 10 }}>
              <CustomButton
                width={mobileView ? 140 : 190}
                backgroundColor="#fff"
                border="#000"
                opacity= {.8}
                height={32}
                onPress={() => setShowAllPhotosModalOpen(true)}
              >
                <View style={globalStyles.center}>
                  <Feather name="grid" size={20} color={colors.amplifyNeutral90} marginRight={10} />
                  <Text style={{ color: "#000", fontSize: 17, marginLeft: 5 }}>{mobileView ? "All photos" : "View all photos"}</Text>
                </View>
              </CustomButton>
            </View>
          }
        </View>
      )}
      <ShowAllPhotosModal
        modalVisible={showAllPhotosModalOpen}
        setModalVisible={() => setShowAllPhotosModalOpen(!showAllPhotosModalOpen)}
        images={images}
        mobileView={mobileView}
      />
    </View>
  );
};

const styles = StyleSheet.create({
  images: {
    margin: 5,
    borderRadius: 8,
    alignSelf: "center",
    justifyContent: "center",
    alignContent: "center"
  },
  badge: {
    position: "absolute",
  },
  image: {
    aspectRatio: 1 / .7,
    borderRadius: 10,
    alignSelf: "center",
    justifyContent: "center",
  }
});
