import React from 'react';
import { createStackNavigator } from '@react-navigation/stack';
import HomeScreen from '../screens/HomeScreen';
import SpaceScreen from '../screens/space/SpaceScreen';
import AboutScreen from '../screens/info/AboutScreen';

const Stack = createStackNavigator();

function ExploreNavigator() {
  return (
    <Stack.Navigator
      screenOptions={{
        cardStyle: { backgroundColor: "#fff" }
      }}
    >
      <Stack.Screen
        component={HomeScreen}
        name={'welcome'}
        options={{ headerShown: false }}
      />
      <Stack.Screen
        component={SpaceScreen}
        name={'space'}
        options={{
          headerShown: false
        }}
      />
    </Stack.Navigator>
  );
}

export default ExploreNavigator;
