import React, { useState } from "react";
import {
  View,
  useWindowDimensions,
  Pressable,
  StyleSheet,
  Text
} from "react-native";
import Card from "../CustomElements/Card";
import CustomModal from "../CustomElements/CustomModal";
import CalendarView from "./CalendarView";
import CloseButtonUnderlined from "../CustomElements/CloseButtonUnderlined";
import Header from "../CustomElements/Header";
import { globalStyles } from "../../styles/styles";
import { colors } from "../../styles/colors";
import Badge from "../CustomElements/Badge";
import { Ionicons } from "@expo/vector-icons";
import CloseButtonIcon from "../CustomElements/CloseButtonIcon";


export const DatePickerCalendar = ({
  type,
  date,
  mobileView,
  space,
  currentDateSpan,
  initialDate,
  mySpace = false,
  calendarError,
  setCalendarError,
  displaySpace,
  setDisplaySpace,
  activeSpaces,
  setSelectedDateSpan
}) => {
  const [modalVisible, setModalVisible] = useState(false);
  const { width } = useWindowDimensions();

  const setDates = (v) => {
    setSelectedDateSpan(v)
    setModalVisible(false)
  };

  return (
    <>
      <Pressable onPress={() => setModalVisible(!modalVisible)}>
        <View>
          <Card
            width={135}
            margin={7}
            shadow={false}
            marginLeft={0}
            marginRight={0}
            padding={4}
            borderRadius={8}
          >
            <Text style={{ fontSize: 15 }}>{type}</Text>
            <Text style={{ color: "#2f5894", fontSize: 17, marginTop: 6 }}>
              {date || "mm-dd-yyyy"}
            </Text>
          </Card>
        </View>
      </Pressable>

      <CustomModal modalVisible={modalVisible} setModalVisible={() => setModalVisible()} modalWidth={mobileView ? width / 1.15 : width / 1.5}>
        {activeSpaces.length > 1 && (
          <View style={[globalStyles.flexStartColumn, { width: mobileView ? width / 1.2 : width / 1.55 }]}>
            <View style={globalStyles.spaceBetween}>
              <Header
                text="Spaces on this property:"
                color={colors.amplifyNeutral80}
                level={.9}
                textAlign="left"
                marginBottom={mobileView ? 15 : 20}
              />
              <View style={globalStyles.flexEnd}>
                <CloseButtonIcon onPress={() => setModalVisible(false)} />
              </View>
            </View>
            {(activeSpaces).map((v, i) => (
              <Pressable key={i} onPress={() => setDisplaySpace(v)}>
                <Badge onPress={() => setDisplaySpace(v)} margin={1} padding={-1} variation={displaySpace.id === v.id ? "warning" : "info"} width={mobileView ? width / 1.3 : width / 3} alignContent="center" alignItems="center" justifyContent="flex-start" >
                  <View style={{ flexDirection: "row", alignItems: "center", justifyContent: "flex-start" }}>
                    <Ionicons name={displaySpace.id === v.id ? "radio-button-on-sharp" : "radio-button-off-sharp"} size={24} color={displaySpace.id === v.id ? colors.amplifyNeutral90 : colors.amplifyNeutral80} />
                    <Text
                      style={{ marginLeft: 5, fontSize: 17, width: "90%" }}
                      numberOfLines={1}
                      ellipsizeMode="tail"
                    >
                      {v.title}
                    </Text>
                  </View>
                </Badge>
              </Pressable>
            ))}
          </View>
        )}
        <CalendarView
          mobileView={mobileView}
          space={space}
          setSelectedDateSpan={(v) => setDates(v)}
          currentDateSpan={currentDateSpan}
          initialDate={initialDate}
          width={width}
          mySpace={mySpace}
          error={calendarError}
          setError={(v) => setCalendarError(v)}
        />
        <CloseButtonUnderlined onPress={() => setModalVisible(!modalVisible)} />
      </CustomModal>
    </>
  );
};
