import React from "react";
import { View } from "react-native";
import Header from "../CustomElements/Header"
import CustomTextInput from "../CustomElements/CustomTextInput";

const ThingsInAreaInput = ({ thingsInArea, setThingsInArea, error, width, height, mobileView }) => {

    return (
        <View>
            <Header text="Things to do in the area" textAlign="left" padding={5}/>
            <CustomTextInput
                multiline={true}
                placeholder="ex: Within walking distance to..."
                errorMessage="There is an error"
                value={thingsInArea}
                marginBottom={2}
                width={width / 1.15}
                maxLength={false}
                height={200}
                onChangeText={(e) => setThingsInArea(e)}
            />
        </View>
    );
};

export default ThingsInAreaInput;