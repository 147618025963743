import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from 'react-redux';
import HostOnboarding from "../../components/host/HostOnboarding";
import AddAdditionalSpacesOption from "../../components/host/AddAdditionalSpacesOption";
import { setAdditionalSpacesOption } from "../../redux/actions/CurrentUserActions";

function AdditionalSpacesScreen() {
    const { additionalSpacesBoolean, numberAdditionalSpaces, newProperty } = useSelector(state => state.hostOnboarding)
    const [disabled, setDisabled] = useState(false);
    const [additionalSpaces, setAdditionalSpaces] = useState(additionalSpacesBoolean || false);
    const [numberOfSpaces, setNumberOfSpaces] = useState(numberAdditionalSpaces || 0)
    const dispatch = useDispatch();

    const additionalSpacesSelection = () => {
        dispatch(setAdditionalSpacesOption({
            additionalSpacesBoolean: additionalSpaces,
            numberAdditionalSpaces: numberOfSpaces
        }));
    };

    return (
        <HostOnboarding
            heading={ "How Many Spaces are we Adding Today?"}
            navigateF="AddTitle"
            navigateB="HostStart"
            nextButtonText="Next"
            disabledNextButton={disabled}
            onClickNext={() => additionalSpacesSelection()}
            subtext="* NOTE: You will be able to add additional spaces later."
        >
            <AddAdditionalSpacesOption
                numberOfSpaces={numberOfSpaces}
                newProperty={newProperty}
                setNumberOfSpaces={(v) => setNumberOfSpaces(v)}
                additionalSpaces={additionalSpaces}
                setAdditionalSpaces={() => setAdditionalSpaces(!additionalSpaces)}
            />
        </HostOnboarding>
    )
};

export default AdditionalSpacesScreen;
