import React from "react";
import { View, Text, useWindowDimensions } from "react-native";
import CustomButton from "./CustomButton";
import { colors } from "../../styles/colors";
import { useSelector } from "react-redux";
import { Ionicons } from "@expo/vector-icons";


export const CustomRadioButton = ({ text, updateValue, value }) => {
    const {width} = useWindowDimensions();
    const { mobileView } = useSelector(state => state.currentUser);

    return (
        <CustomButton
            height={mobileView ? 80 : 45}
            textAlign="flex-start"
            alignItems="flex-start"
            width={mobileView ? width / 1.15 : 500}
            marginBottom={10}
            backgroundColor={value ? colors.vibrantBlue : colors.amplifyNeutral20}
            border={value ? colors.amplifyNeutral80 : "#4c4e52"}
            onPress={() => updateValue()}
        >
            <View style={{ flexDirection: "row", paddingLeft: 10, }}>
                <Ionicons name={value ? "radio-button-on-sharp" : "radio-button-off-sharp"} size={24} color={value ? colors.amplifyNeutral90 : colors.amplifyNeutral80} />
                <Text style={{ fontSize: mobileView ? 17 : 20, textAlign: "left", marginLeft: 4 }}>{text}</Text>
            </View>
        </CustomButton>
    )
};