import { notifyHookhub } from "../notifyHookhub";

export const createSubscription = async ({ subscriptionData }) => {
    const { 
        stripeCustomerID,
        enviro,
        monthlyPaymentAmount,
        endDate,
        startDate,
        bookingID,
        userID,
        numberOfPayments,
        productName,
        monthlyPaymentDayOfMonth,
        stripePaymentMethodID
    } = subscriptionData;

    const requestOptions = {
        method: 'POST',
        body: JSON.stringify({
            stripeCustomerID: stripeCustomerID,
            stripePaymentMethodID: stripePaymentMethodID,
            enviro: enviro,
            monthlyPaymentAmount: monthlyPaymentAmount,
            endDate: endDate,
            startDate: startDate,
            userID: userID,
            numberOfPayments: numberOfPayments,
            productName,
            monthlyPaymentDayOfMonth,
            bookingID
        })
    };
    return fetch('https://tomqfjdvtkyd3oeadhhcbgijiq0dfyei.lambda-url.us-west-2.on.aws/', requestOptions)
        .then(response => response.json())
        .then(() => {
            return true
        }).catch((e) => {
            console.log("ERROR >>   ", e)
            const subject = `ERROR creating subscription.`
            const body = `ERROR creating Subscription (lambda). ERROR: ${e}. ////////////  STRIPE customer id: ${stripeCustomerID}. ////////////   BOOKING ID: ${bookingID}`
            notifyHookhub({ subject, body, userID: hostID, userEmail: hostEmail })
            return false
        })
}