import React, { useState } from "react";
import { StyleSheet, Text as ReactText, Pressable, View, useWindowDimensions } from "react-native";
import { useNavigation } from "@react-navigation/native";
import Flex from "../CustomElements/Flex"
import Header from "../CustomElements/Header"
import CustomButton from "../CustomElements/CustomButton";
import { colors } from "../../styles/colors";
import CustomModal from "../CustomElements/CustomModal";
import CloseButtonUnderlined from "../CustomElements/CloseButtonUnderlined";
import { AntDesign, Entypo } from "@expo/vector-icons";
import { globalStyles } from "../../styles/styles";


const ConnectedAccountInfoNeeded = ({ modalVisible, setModalVisible, mobileView }) => {
    const navigation = useNavigation();
    const { width } = useWindowDimensions();
    const [showSecureInfo, setShowSecureInfo] = useState(false);

    const takeMeThere = () => {
        setModalVisible();
        navigation.navigate('paymentsettings')
    }

    return (
        <CustomModal modalVisible={modalVisible} setModalVisible={() => setModalVisible()} modalWidth={width / 1.15}>
            <View style={styles.modalView}>
                <Header marginBottom={15} textAlign="center" level={1.2} text="But first- where would you like your money sent?"></Header>
                {showSecureInfo ? (
                    <View>
                        <ReactText style={styles.modalText}>Stripe is certified as a Level 1 Service Provider, which is the highest level of certification for payment processors. Stripe complies with the Payment Card Industry Data Security Standard (PCI-DSS), a set of security standards established by major card brands.</ReactText>
                    </View>
                ) : (
                    <Pressable onPress={() => setShowSecureInfo(true)}>
                        <View style={styles.view}>
                            <ReactText style={styles.modalText}>You must set your desired bank account to receive your income. We use a secure payment system with <ReactText style={{textDecorationLine:"underline"}}>Stripe.</ReactText></ReactText>
                            <AntDesign name="infocirlceo" size={22} color="black" />
                        </View>
                    </Pressable>
                )}
                <CustomButton
                    backgroundColor={colors.lightBlue}
                    color="#fff"
                    marginTop={20}
                    height={mobileView ? 43 : 33}
                    onPress={() => takeMeThere()}
                    width={mobileView ? width / 1.5 : 350}
                >
                    <View style={globalStyles.center}>
                        <ReactText style={styles.textStyle}>set my payout bank account</ReactText>
                        <Entypo name="arrow-right" size={20} color="white" />
                    </View>
                </CustomButton>
                <CloseButtonUnderlined onPress={() => setModalVisible(!modalVisible)} />
            </View>
        </CustomModal>
    );
};

const styles = StyleSheet.create({
    modalView: {
        margin: 20,
        alignItems: "center",
        padding: 4
    },
    view: {
        flexDirection: "row",
        justifyContent: "center",
        alignContent: "center",
        alignItems: "center"
    },
    textStyle: {
        color: "white",
        fontSize: 17,
        textAlign: "center",
    },
    modalText: {
        marginBottom: 30,
        textAlign: "center",
        fontSize: 17
    },
    title: {
        textAlign: "left",
        width: '100%',
        backgroundColor: "#2196F3"
    },
});

export default ConnectedAccountInfoNeeded;
