import { manipulateAsync, SaveFormat } from 'expo-image-manipulator';
import * as ImagePicker from "expo-image-picker";

export const pickImage = async (smallImage) => {
    let formattedImage = await ImagePicker.launchImageLibraryAsync({
        mediaTypes: ImagePicker.MediaTypeOptions.Images,
        allowsEditing: true
    });
    if (formattedImage.canceled) {
        return;
    }

    const manipResult = await manipulateAsync(
        formattedImage['assets'][0].uri, [
        { resize: { width: smallImage ? 300 : 1200 } },
    ],
        {
            compress: smallImage ? .9 : 0.7,
            format: SaveFormat.JPEG
        }
    );
    return manipResult;
};
