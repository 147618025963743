import React from "react";
import { ImageBackground, StyleSheet, Text, View, ScrollView, Image, useWindowDimensions } from "react-native";
import { useNavigation } from '@react-navigation/native';
import { useSelector } from "react-redux";
import CustomButton from "../../components/CustomElements/CustomButton";
import getEnvVars from "../../../environment";

function SplashScreen() {
    const navigation = useNavigation();
    const { cloudfrontURLStaticImages } = getEnvVars();
    const { mobileView } = useSelector(state => state.currentUser);
    const { width } = useWindowDimensions();

    const checkOutSpaces = () => {
        navigation.navigate("explore")
    }

    const about = () => {
        navigation.navigate("explore", { screen: "about" })
    }

    return (
        <View style={styles.container}>
            <ImageBackground
                source={{ uri: `${cloudfrontURLStaticImages}splashScreen3.jpg` }}
                resizeMode="cover"
                style={styles.image}
            >
                <ScrollView>
                    <View style={[styles.flex, { marginTop: mobileView ? 50 : 100, marginBottom: 20 }]}>
                        <Image
                            style={{ width: !!mobileView ? 80 : 160, height: !!mobileView ? 80 : 160, marginBottom: 15 }}
                            source={require("../../../assets/images/mini_hookhub-logo-highres.png")}
                        />
                        <Text style={{
                            fontSize: 21,
                            color: "white",
                            backgroundColor: "#000000c0",
                            opacity: .75,
                            textAlign: "center",
                            padding: 5,
                            lineHeight: 45,
                            width: width,
                            marginTop: mobileView ? 5 : 20,
                            marginBottom: 15
                        }}>
                            hook-up to new horizons.
                        </Text>
                        <CustomButton
                            marginTop={mobileView ? 15 : 60}
                            marginBottom={6}
                            width={mobileView ? 160 : 200}
                            onPress={() => checkOutSpaces()}
                        >
                            <Text style={[styles.buttonText, { fontSize: 17 }]}>Check out spaces</Text>
                        </CustomButton>
                        <CustomButton
                            width={mobileView ? 160 : 200}
                            onPress={() => about()}
                        >
                            <Text style={[styles.buttonText, { fontSize: 17 }]}>Learn More</Text>
                        </CustomButton>
                    </View>
                </ScrollView>
            </ImageBackground>
        </View>
    )
};

const styles = StyleSheet.create({
    container: {
        flex: 1,
    },
    image: {
        flex: 1,
        justifyContent: "center"
    },
    flex: {
        direction: "column",
        alignContent: "center",
        justifyContent: "center",
        alignItems: "center",
    },
    buttonText: {
        color: "#000000c0",
    },
    button: {
        alignItems: 'center',
        backgroundColor: '#fff',
        borderRadius: 10,
        justifyContent: 'center',
        alignSelf: 'center'
    },
});

export default SplashScreen;