import React from "react";
import { useWindowDimensions, View, Text } from "react-native";
import {
  Ionicons,
  MaterialIcons,
  MaterialCommunityIcons,
  FontAwesome5,
  FontAwesome,
} from "@expo/vector-icons";
import Flex from "../CustomElements/Flex"
import Header from "../CustomElements/Header"
import FixedHeightCard from "../CustomElements/FixedHeightCard";
import { useSelector } from "react-redux";
import { colors } from "../../styles/colors";

const Amenity = ({ amenity, icon }) => {
  const { mobileView } = useSelector(state => state.currentUser);
  return (
    <FixedHeightCard
      marginTop={8}
      marginRight={4}
      marginLeft={4}
      padding={mobileView ? 2 : 15}
      width={mobileView ? 80 : 95}
      height={mobileView ? 88 : 100}
      backgroundColor={colors.white}
      alignItems="center"
      textAlign="center"
      justifyContent="center"
      border={colors.amplifyNeutral20}
    >
      <View style={{
        alignItems: "center",
        textAlign: "center",
        justifyContent: "center",
        alignContent: "center",
      }}>{icon}</View>
      <Text style={{ color: colors.black, fontSize: 15, textAlign: "center" }}>{amenity}</Text>
    </FixedHeightCard>
  );
};

const SpaceAmenities = ({ space }) => {
  const { width } = useWindowDimensions();
  const {
    water_hookup,
    electric_hookup,
    sewage_hookup,
    easy_entry,
    shower_available,
    wifi_available,
    laundry_available,
    pool_available,
    hottub_available,
    indoor_area_available,
    outdoor_area_available,
    trash_available,
    restroom_available,
    food_nearby,
    restaurant_nearby,
    water_accessible,
    pets_allowed,
    outdoor_fires
  } = space;
  const amenitiesPresent = (
    water_hookup ||
    electric_hookup ||
    sewage_hookup ||
    easy_entry ||
    shower_available ||
    wifi_available ||
    laundry_available ||
    pool_available ||
    hottub_available ||
    indoor_area_available ||
    outdoor_area_available ||
    trash_available ||
    restroom_available ||
    food_nearby ||
    restaurant_nearby ||
    water_accessible ||
    pets_allowed ||
    outdoor_fires
  );
  const { mobileView } = useSelector(state => state.currentUser);

  return (
    amenitiesPresent && (
      <>
        <Header level={1} marginBottom={5} marginTop={5} padding={0} color={colors.amplifyNeutral90} text="Amenities available"></Header>
        <Flex
          alignItems={mobileView ? "center" : "flex-start"}
          alignContent={mobileView ? "center" : "flex-start"}
          justifyContent={mobileView ? "center" : "flex-start"}
          wrap="wrap"
          marginTop={15}
          marginBottom={15}
          width={mobileView ? width / 1.1 : width / 2.1}
        >
          {water_hookup && (
            <Amenity
              amenity="Water Hookup"
              icon={<Ionicons name="water-sharp" size={mobileView ? 25 : 35} color={colors.lightBlue} />}
            />
          )}
          {electric_hookup && (
            <Amenity
              amenity="Electric Hookup"
              icon={
                <MaterialIcons
                  name="electrical-services"
                  size={mobileView ? 25 : 35}
                  color={colors.lightBlue}
                />
              }
            />
          )}
          {sewage_hookup && (
            <Amenity
              amenity="Sewage Hookup"
              icon={
                <MaterialCommunityIcons name="pipe" size={mobileView ? 25 : 35} color={colors.lightBlue} />
              }
            />
          )}
          {easy_entry && (
            <Amenity
              amenity="Pull through Parking"
              icon={
                <MaterialCommunityIcons
                  name="steering"
                  size={mobileView ? 25 : 35}
                  color={colors.lightBlue}
                />
              }
            />
          )}
          {shower_available && (
            <Amenity
              amenity="Shower Available"
              icon={<FontAwesome5 name="shower" size={mobileView ? 21 : 31} color={colors.lightBlue} />}
            />
          )}
          {restroom_available && (
            <Amenity
              amenity="Restroom Available"
              icon={<FontAwesome5 name="restroom" size={mobileView ? 20 : 30} color={colors.lightBlue} />}
            />
          )}
          {water_accessible && (
            <Amenity
              amenity="Water Accessible"
              icon={<MaterialCommunityIcons name="water-pump" size={mobileView ? 21 : 31} color={colors.lightBlue} />}
            />
          )}
          {food_nearby && (
            <Amenity
              amenity="Food / Beverages Nearby"
              icon={<MaterialIcons name="fastfood" size={mobileView ? 21 : 31} color={colors.lightBlue} />}
            />
          )}
          {restaurant_nearby && (
            <Amenity
              amenity="Restaurant Nearby"
              icon={<MaterialIcons name="restaurant" size={mobileView ? 21 : 31} color={colors.lightBlue} />}
            />
          )}
          {wifi_available && (
            <Amenity
              amenity="WiFi"
              icon={<Ionicons name="wifi" size={mobileView ? 25 : 35} color={colors.lightBlue} />}
            />
          )}
          {laundry_available && (
            <Amenity
              amenity="Laundry facilities"
              icon={<MaterialIcons name="local-laundry-service" size={mobileView ? 25 : 35} color={colors.lightBlue} />}
            />
          )}
          {pool_available && (
            <Amenity
              amenity="Pool"
              icon={<MaterialIcons name="pool" size={mobileView ? 24 : 38} color={colors.lightBlue} />}
            />
          )}
          {hottub_available && (
            <Amenity
              amenity="Jacuzzi"
              icon={
                <FontAwesome5 name="hot-tub" size={mobileView ? 25 : 35} color={colors.lightBlue} />
              }
            />
          )}
          {indoor_area_available && (
            <Amenity
              amenity="Indoor area"
              icon={
                <FontAwesome5
                  name="warehouse"
                  size={mobileView ? 25 : 35}
                  color={colors.lightBlue}
                />
              }
            />
          )}
          {outdoor_area_available && (
            <Amenity
              amenity="Outdoor area"
              icon={<MaterialCommunityIcons name="table-picnic" size={mobileView ? 25 : 35} color={colors.lightBlue} />}
            />
          )}
          {trash_available && (
            <Amenity
              amenity="Trash disposal"
              icon={<FontAwesome name="trash-o" size={mobileView ? 25 : 35} color={colors.lightBlue} />}
            />
          )}
          {pets_allowed && (
            <Amenity
              amenity="Pets allowed"
              icon={<MaterialIcons name="pets" size={mobileView ? 25 : 35} color={colors.lightBlue} />}
            />
          )}
          {outdoor_fires && (
            <Amenity
              amenity="Fires allowed"
              icon={<MaterialCommunityIcons name="campfire" size={mobileView ? 25 : 35} color={colors.lightBlue} />}
            />
          )}
        </Flex>
      </>
    )
  );
};

export default SpaceAmenities;
