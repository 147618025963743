import React, { createContext, useState, useEffect, useMemo } from 'react';
import { onConversationbyHostId, onConversationbyRenterId, onMessagebyConversationId } from '../graphql/subscriptions';
import { getUser as getUserQuery } from "../graphql/queries";
import { createUser as createUserMutation } from "../graphql/mutations";
import { Auth, API } from "aws-amplify";
import getEnvVars from '../../environment';
import { setHostStatus, setPayoutStatus } from '../redux/actions/CurrentUserActions';
import { addNewConvo, setConvos, setUnreadMsgs, updateConvoMsgs } from '../redux/actions/ConversationsActions';
import { useDispatch } from 'react-redux';
import { listAllConversations } from '../api/conversations';
import { sendWelcomeEmail } from '../lambdaFunctions/sendEmail/sendWelcomeEmail';
const { cloudfrontURL } = getEnvVars();

const UserDataContext = createContext(null);
export { UserDataContext };

export default ({ children }) => {
    const dispatch = useDispatch();
    const [loading, setLoading] = useState(true);
    const [userData, setUserData] = useState({});
    const user = useMemo(
        () => ({ userData, setUserData }),
        [userData]
    );

    async function getUserExtraAttributes(id, setAttrs) {
        let updatedData;
        if (id) {
            await API.graphql({
                query: getUserQuery,
                variables: { id },
                authMode: "API_KEY",
            })
                .then((d) => {
                    if (!d.data.getUser) {
                        createDBUser(id, setAttrs);
                    } else {
                        let userData = d.data.getUser;
                        const myRigImage = !!userData.rig?.items?.length ? `${cloudfrontURL}${userData.rig.items[0].id}` : null;
                        const avatarImage = userData?.avatar ? `${cloudfrontURL}${userData.avatar}` : null;
                        updatedData = { ...setAttrs, userData, avatarImage, myRigImage };
                        setUserData(updatedData);
                        loadAdditionalData(updatedData)
                    }
                })
                .catch((e) => {
                    console.log("Error getting extra user attributes", e)
                    setLoading(false)
                });
        }
    };

    const getCurrentUser = async () => {
        setLoading(true)
        let id = "";
        let setAttrs = {};
        await Auth.currentUserInfo()
            .then((userInfo) => {
                if (userInfo && userInfo.attributes) {
                    const { attributes } = userInfo;
                    id = attributes.sub;
                    attributes["userName"] = attributes.name.split(" ")[0];
                    setAttrs = { ...attributes };
                }
            })
            .then(() => getUserExtraAttributes(id, setAttrs))
            .catch((e) => {
                console.log("Error loading user ", e)
                setLoading(false)
            })
    };

    async function createDBUser(id, setAttrs) {
        let updatedData;
        const { name, email, phone_number } = setAttrs;

        await API.graphql({
            query: createUserMutation,
            variables: {
                input: {
                    id: id,
                    name: name,
                    email: email,
                    phoneNumber: phone_number,
                    stripePayoutsEnabled: false
                }
            },
            authMode: "AMAZON_COGNITO_USER_POOLS",
        })
            .then((d) => {
                let userData = d.data.createUser;
                updatedData = { ...setAttrs, userData, avatarImage: null, myRigImage: null };
                setUserData(updatedData);
                loadAdditionalData(updatedData)
                sendWelcomeEmail({ emailAddress: email })
            })
            .catch((e) => {
                console.log("Error creating DB user", e)
                setLoading(false)
            });
    };

    const subscribeToNewHostConversations = (myID, myEmail) => {
        const subscription = API.graphql({
            query: onConversationbyHostId,
            variables: {
                host: myID,
                otherMembersEmail: myEmail
            }
        }).subscribe({
            next: data => {
                let convoData = data.value.data.onConversationbyHostId;
                dispatch(addNewConvo({
                    convo: convoData,
                    unreadBoolean: false
                }));
                subscribeToNewMessages({ convoID: convoData.id });
            }
        });
        return () => subscription.unsubscribe();
    };

    const subscribeToNewRenterConversations = (myID, myEmail) => {
        const subscription = API.graphql({
            query: onConversationbyRenterId,
            variables: {
                hostee: myID,
                otherMembersEmail: myEmail
            }
        }).subscribe({
            next: data => {
                let convoData = data.value.data.onConversationbyRenterId;

                dispatch(addNewConvo({
                    convo: convoData,
                    unreadBoolean: true
                }));
                subscribeToNewMessages({ convoID: convoData.id });
            }
        });
        return () => subscription.unsubscribe();
    };

    const fetchOtherData = async (data, id) => {
        const { myHostedProperties } = data;
        const payoutSet = !!data?.stripePayoutsEnabled;
        const host = !!myHostedProperties?.items && !!myHostedProperties.items.length;
        getCurrentConversations(id);
        dispatch(setPayoutStatus({ payoutNeeded: !!host && !payoutSet }))
        dispatch(setHostStatus({ imAHost: !!host }))
        setLoading(false)
    };

    const getCurrentConversations = async (userId) => {
        const conversations = await listAllConversations({ userID: userId });
        let convos = conversations?.items;
        if (!!convos) {
            dispatch(setConvos({ convos: convos }));
            let unreadMsgsPresent = false;
            for (let i = 0; i < convos.length; i++) {
                let thisConvo = convos[i];
                let unreadHost = thisConvo.unreadHost === true && thisConvo.host === userId;
                let unreadHostee = thisConvo.unreadHostee === true && thisConvo.hostee === userId;
                if (!!unreadHostee || !!unreadHost) {
                    unreadMsgsPresent = true
                }
                subscribeToNewMessages({
                    convoID: thisConvo.id
                })
            }
            dispatch(setUnreadMsgs({
                unreadBoolean: unreadMsgsPresent,
            }));
        }
    };

    const subscribeToNewMessages = ({ convoID }) => {
        const subscription = API.graphql({
            query: onMessagebyConversationId,
            variables: {
                convoID: convoID
            }
        }).subscribe({
            next: data => {
                let msgData = data.value.data.onMessagebyConversationId;
                let { conversation, authorID } = msgData;
                const hostAuthoredMsg = authorID === conversation.host;
                dispatch(updateConvoMsgs({
                    msg: msgData,
                    unreadBoolean: true,
                    unreadHost: !hostAuthoredMsg,
                    unreadHostee: hostAuthoredMsg
                }));
            }
        });
        return () => subscription.unsubscribe()
    }

    const loadAdditionalData = (userData) => {
        fetchOtherData(userData.userData, userData.sub);
        subscribeToNewHostConversations(userData.sub, userData.email);
        subscribeToNewRenterConversations(userData.sub, userData.email);
    }

    useEffect(() => {
        getCurrentUser();
    }, []);

    return (
        <UserDataContext.Provider value={{ user, loading }}>
            {useMemo(() => (
                children
            ), [])}
        </UserDataContext.Provider>
    )
};
