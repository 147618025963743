import React, { useState } from "react";
import { StyleSheet, useWindowDimensions, Image, Text, View } from "react-native";
import getEnvVars from '../../../environment';
import Flex from "../CustomElements/Flex"
import Header from "../CustomElements/Header"
import { LoadingComponent } from "../LoadingComponent";
import { Ionicons } from '@expo/vector-icons';
import CustomDivider from "../CustomDivider";
import { useSelector } from "react-redux";
import CustomModal from "../CustomElements/CustomModal";
import CloseButtonUnderlined from "../CustomElements/CloseButtonUnderlined";
import { globalStyles } from "../../styles/styles";
const { cloudfrontURL } = getEnvVars();


const UserSummary = ({ userDetails, myRig, userAvatar, contactText, setModalVisible }) => {
    const { name, about } = userDetails;
    const { mobileView } = useSelector(state => state.currentUser);

    return (
        <View style={[globalStyles.centerColumn, { marginTop: mobileView ? 0 : 20 }]}>
            {!!userAvatar ? (
                <Image
                    style={styles.avatarImage}
                    source={{ uri: userAvatar }}
                />
            ) : (
                <Ionicons name="person-circle" size={85} color="grey" />
            )}
            <Text style={{ fontSize: 17 }}>{contactText}</Text>
            <Header level={1.2} text={name} />
            {about && (<Text style={{ marginTop: 20, fontSize: 17 }}>{about}</Text>)}
            {!!myRig ? (
                <View style={globalStyles.centerColumn}>
                    <View style={globalStyles.center}>
                        <CustomDivider marginVertical={mobileView ? 5 : 10} />
                    </View>
                    {!!myRig.image && !!myRig.image.length &&
                        <Image
                            style={[styles.rigImage, { width: mobileView ? 200 : 300, height: mobileView ? 200 : 300 }]}
                            source={{ uri: `${cloudfrontURL}${myRig.image}` }}
                        />
                    }
                    <Text style={styles.rigDetails}>Year: {myRig.year}</Text>
                    {!!myRig.width && !!myRig.length && (
                        <Text style={styles.rigDetails}>Dimensions: {myRig.width}ft. x {myRig.length}ft.</Text>
                    )}
                    {!!myRig.make && (
                        <Text style={styles.rigDetails}>Make: {myRig.make}</Text>
                    )}
                    {!!myRig.model && (
                        <Text style={styles.rigDetails}>Model: {myRig.model}</Text>
                    )}
                </View>
            ) : (
                <Text style={{ color: "#4c4e52", marginBottom: 20 }}>My rig details not provided yet</Text>
            )}
        </View>
    );
};

const UserDetailsModal = ({ modalVisible, setModalVisible, userDetails, userAvatar, contactText, myRig, mobileView }) => {
    const [loading, setLoading] = useState(false);
    const { width } = useWindowDimensions()
    const viewWidth = mobileView ? width / 1.3 : width / 2;

    return (
        <CustomModal
            modalVisible={modalVisible}
            setModalVisible={() => setModalVisible(!modalVisible)}
            modalWidth={viewWidth +50}
        >
            <UserSummary
                userDetails={userDetails}
                userAvatar={userAvatar}
                contactText={contactText}
                setModalVisible={() => setModalVisible(!modalVisible)}
                myRig={myRig}
            />
            <Flex textAlign="left" justifyContent="center" alignItems="center" alignContent="center" marginBottom={8} width={viewWidth}>
                {loading ? (
                    <LoadingComponent />
                ) : (
                    <CloseButtonUnderlined onPress={() => setModalVisible(false)} />
                )}
            </Flex>
        </CustomModal>
    );
};

const styles = StyleSheet.create({
    centeredView: {
        flex: 1,
        justifyContent: "center",
        alignItems: "center",
    },
    rigDetails: {
        textAlign: "left",
        fontSize: 17
    },
    modalView: {
        marginTop: 20,
        marginBottom: 8,
        backgroundColor: "#c4c4c4",
        borderRadius: 10,
        alignItems: "center",
        shadowColor: "#000",
        shadowOffset: {
            width: 0,
            height: 2
        },
        shadowOpacity: 0.35,
        shadowRadius: 4,
        elevation: 5,
    },
    approvebutton: {
        borderRadius: 12,
        padding: 15,
        elevation: 5,
        backgroundColor: "#599cde",
        shadowColor: "grey",
        shadowOffset: {
            width: 1,
            height: 3,
        },
        shadowOpacity: 0.45,
        shadowRadius: 4,
    },
    denybutton: {
        borderRadius: 12,
        backgroundColor: "#7a7a7a",
        shadowColor: "grey",
        height: 50,
        paddingTop: 5,
        paddingBottom: 5,
        paddingLeft: 50,
        paddingRight: 50,
        shadowColor: 'rgba(0, 0, 0, 0.1)',
        shadowOpacity: 0.8,
        elevation: 6,
        shadowRadius: 15,
        shadowOffset: { width: 1, height: 13 },
    },
    closeButton: {
        padding: 10,
        marginBottom: 12
    },
    avatarImage: {
        borderRadius: 100,
        width: 200,
        height: 200,
        borderWidth: 1,
        margin: 20
    },
    rigImage: {
        borderRadius: 10,
        borderWidth: 1,
        margin: 20
    },
    note: {
        textAlign: "center",
        backgroundColor: "#d6d6d6",
        borderRadius: 20,
        padding: 7
    },
});

export default UserDetailsModal;