import { StyleSheet, Pressable, View, Text } from "react-native";
import { AntDesign, MaterialIcons } from '@expo/vector-icons';
import { DatePicker } from "../space/DatePicker";
import CustomDivider from "../CustomDivider";
import { LoadingComponent } from "../LoadingComponent";
import Flex from "../CustomElements/Flex"
import Header from "../CustomElements/Header"
import CustomButton from "../CustomElements/CustomButton";
import { colors } from "../../styles/colors";
import { globalStyles } from "../../styles/styles";
import { DatePickerCalendar } from "../space/DatePickerCalendar";
import { useState } from "react";
import { ErrorDisplay } from "../CustomElements/ErrorDisplay";

const ReserveButton = ({ mobileView, width, userData = null, prepareToReserve, setSignUpModalOpen, loading, mySpace, dateSpanSet, monthOptionDisabled, yearOptionDisabled, error }) => {

    if (!!loading) {
        return (
            <>
                <CustomButton
                    width={100}
                    height={50}
                    border={colors.brandGrey}
                >
                    <LoadingComponent />
                </CustomButton>
            </>
        )
    } else if (userData === null) {
        return (
            <>
                <CustomButton
                    width={mobileView ? width / 1.2 : 250}
                    marginTop={mobileView ? 1 : 12}
                    marginBottom={2}
                    disabled={false}
                    height={mobileView ? 60 : 40}
                    backgroundColor={colors.orange}
                    borderRadius={8}
                    onPress={() => setSignUpModalOpen()}
                >
                    <Text style={{ fontSize: 17, textAlign: "center", paddingHorizontal: 5}}>Sign up to reserve your space</Text>
                </CustomButton>
            </>
        )
    } else {
        const disabled = mySpace || !dateSpanSet || monthOptionDisabled || yearOptionDisabled || error;

        return (
            <>
                <CustomButton
                    width={100}
                    height={40}
                    borderRadius={8}
                    disabled={disabled}
                    backgroundColor={disabled ? colors.amplifyNeutral60 : colors.orange}
                    border={disabled ? colors.amplifyNeutral80 : colors.hookhubOrangeDark}
                    onPress={e => userData === null ? setSignUpModalOpen() : prepareToReserve(e)}
                >
                    <Text style={{ fontSize: 17 }}>Reserve</Text>
                </CustomButton>
            </>
        )
    }
};

const ReservationDetails = ({
    subtext = null,
    price,
    space,
    currentDateSpan,
    checkinDate,
    setCheckinDate,
    checkoutDate,
    tryUpdateDateSpan,
    checkinDateError,
    checkoutDateError,
    width,
    initialDate,
    calendarDisplayForDateInput,
    displaySpace,
    setDisplaySpace,
    activeSpaces,
    datesSelectedError,
    setDatesSelectedError,
    setSelectedDateSpan
}) => {

    return (
        <Flex direction="column" width={width / 3} alignContent="center" alignItems="center" textAlign="center" justifyContent="center" marginBottom={15}>
            {!!subtext &&
                <Text style={styles.subtext}>* {subtext}</Text>
            }
            <View style={{ flexDirection: "row", marginTop: 15 }}>
                <Header padding={0} level={1.5} text={` $ ${Number(price).toLocaleString("en-US", { minimumFractionDigits: 2 })}`} />
                <Text style={styles.prices}> night</Text>
            </View>
            <Flex
                alignItems="center"
                justifyContent="center"
                textAlign="center"
                alignContent="center"
                width={width / 3}
                direction={width < 800 ? "column" : "row"}
                marginTop={15}
            >

                {calendarDisplayForDateInput ? (
                    <>
                        <DatePickerCalendar
                            mobileView={false}
                            type={"CHECK-IN"}
                            date={checkinDate}
                            space={space}
                            currentDateSpan={currentDateSpan}
                            initialDate={initialDate}
                            calendarError={datesSelectedError}
                            setCalendarError={(v) => setDatesSelectedError(!!v)}
                            activeSpaces={activeSpaces}
                            displaySpace={displaySpace}
                            setDisplaySpace={(v) => setDisplaySpace(v)}
                            setSelectedDateSpan={(v) => setSelectedDateSpan(v)}
                        />
                        <DatePickerCalendar
                            mobileView={false}
                            type={"CHECK-OUT"}
                            date={checkoutDate}
                            space={space}
                            currentDateSpan={currentDateSpan}
                            initialDate={initialDate}
                            calendarError={datesSelectedError}
                            setCalendarError={(v) => setDatesSelectedError(!!v)}
                            activeSpaces={activeSpaces}
                            displaySpace={displaySpace}
                            setDisplaySpace={(v) => setDisplaySpace(v)}
                            setSelectedDateSpan={(v) => setSelectedDateSpan(v)}
                        />
                    </>
                ) : (
                    <>
                        <DatePicker
                            type={"CHECK-IN"}
                            date={checkinDate}
                            updateDate={(v) => setCheckinDate(v)}
                            error={checkinDateError}
                        />
                        <DatePicker
                            type={"CHECK-OUT"}
                            date={checkoutDate}
                            error={checkoutDateError}
                            updateDate={(v) => tryUpdateDateSpan(v)}
                        />
                    </>
                )}
            </Flex>
        </Flex >
    )
};

const ReserveCardBodyWeb = ({
    mobileView,
    userData = null,
    mySpace = null,
    currentDateSpan,
    spaceTypes,
    space,
    width,
    error,
    activeTabIndex,
    setActiveTabIndex,
    rentalType,
    monthlyPayout,
    setMonthlyPayout,
    setSignUpModalOpen,
    monthOptionDisabled,
    baseCost,
    renterFee,
    rentalCost,
    currentMonthSpan,
    monthlyPrice,
    checkinDate,
    setCheckinDate,
    checkoutDate,
    tryUpdateDateSpan,
    checkinDateError,
    checkoutDateError,
    signUpModalOpen,
    prepareToReserve,
    loading,
    dateSpanSet,
    monthlyPaymentDisplayDates,
    yearOptionDisabled,
    calendarDisplayForDateInput = false,
    initialDate,
    displaySpace,
    setDisplaySpace,
    activeSpaces,
    numberOfMonths,
    datesSelectedError,
    setDatesSelectedError,
    setSelectedDateSpan
}) => {
    const [showMonthlyPayoutBreakdown, setShowMonthlyPayoutBreakdown] = useState(false);

    return (
        <>
            <View style={globalStyles.centerColumn} >
                <View style={globalStyles.centerColumn}>
                    {spaceTypes.map((v, i) => (
                        <View key={i}>
                            {activeTabIndex === i ? (
                                <CustomButton
                                    width={85}
                                    marginBottom={10}
                                    backgroundColor={colors.orange}
                                    onPress={(e) => setActiveTabIndex(i)}
                                >
                                    <Text style={{ fontSize: 17, fontWeight: "500" }}>{v.title}</Text>
                                </CustomButton>
                            ) : (
                                <CustomButton
                                    width={85}
                                    marginBottom={10}
                                    backgroundColor={colors.brandGrey}
                                    onPress={(e) => setActiveTabIndex(i)}
                                >
                                    <Text style={{ fontSize: 17, fontWeight: "500" }}>{v.title}</Text>
                                </CustomButton>
                            )}
                        </View>
                    ))}
                    {!!datesSelectedError && <ErrorDisplay error={`That date range is not available for the selected Space: ${displaySpace.title}`} customWidth={width / 3.2} />}
                </View>
                <ReservationDetails
                    type={rentalType}
                    width={width}
                    space={space}
                    initialDate={initialDate}
                    monthlyFuturePaymentDates={monthlyPaymentDisplayDates}
                    mobileView={mobileView}
                    subtext={spaceTypes[activeTabIndex]?.subtext}
                    price={spaceTypes[activeTabIndex]?.price}
                    increment={spaceTypes[activeTabIndex]?.increment}
                    monthOptionDisabled={monthOptionDisabled}
                    yearOptionDisabled={yearOptionDisabled}
                    baseCost={baseCost}
                    renterFee={renterFee}
                    rentalCost={rentalCost}
                    currentDateSpan={currentDateSpan}
                    currentMonthSpan={currentMonthSpan}
                    monthlyPrice={monthlyPrice}
                    checkinDate={checkinDate}
                    setCheckinDate={(v) => setCheckinDate(v)}
                    checkoutDate={checkoutDate}
                    tryUpdateDateSpan={(v) => tryUpdateDateSpan(v)}
                    setSelectedDateSpan={(v) => setSelectedDateSpan(v)}
                    checkinDateError={checkinDateError}
                    checkoutDateError={checkoutDateError}
                    calendarDisplayForDateInput={calendarDisplayForDateInput}
                    activeSpaces={activeSpaces}
                    displaySpace={displaySpace}
                    setDisplaySpace={(v) => setDisplaySpace(v)}
                    datesSelectedError={datesSelectedError}
                    setDatesSelectedError={(v) => setDatesSelectedError(!!v)}
                />
                <ReserveButton
                    mySpace={mySpace}
                    width={width}
                    mobileView={mobileView}
                    userData={userData}
                    setSignUpModalOpen={() => setSignUpModalOpen(!signUpModalOpen)}
                    error={error}
                    prepareToReserve={(e) => prepareToReserve(e)}
                    monthOptionDisabled={monthOptionDisabled}
                    yearOptionDisabled={yearOptionDisabled}
                    loading={loading}
                    dateSpanSet={dateSpanSet}
                />
            </View>
            <Text style={{ color: "red", marginTop: 5, fontSize: 17 }}>{!!checkinDateError || checkoutDateError}</Text>
            <View style={{ width: width / 3.3, marginTop: 10 }}>
                {!!baseCost && (monthOptionDisabled || yearOptionDisabled) ? (
                    <View style={globalStyles.centerColumn}>
                        <AntDesign name="exclamationcircle" size={40} color="grey" />
                        <Text style={{ textAlign: "center" }}>{monthOptionDisabled ? 'Stay must be longer than 28 days to reserve this option' : 'Stay must be at least 1 year to reserve this option'}</Text>
                    </View>
                ) : (
                    <>
                        {!!baseCost && (
                            <View>
                                {!monthlyPayout && (
                                    <>
                                        <View style={[globalStyles.spaceBetween, { marginTop: 5 }]}>
                                            <Text style={globalStyles.underlineText}>$ {spaceTypes[activeTabIndex]?.price} X {currentDateSpan.length - 1} {currentDateSpan.length > 1 ? `nights` : `night`}</Text>
                                            <Text style={globalStyles.flexEnd}>${Number(baseCost).toLocaleString("en-US", { minimumFractionDigits: 2 })}</Text>
                                        </View>
                                        <View style={[globalStyles.spaceBetween, { marginTop: 5 }]}>
                                            <Text style={globalStyles.underlineText}>services fee:</Text>
                                            <Text style={globalStyles.flexEnd}>${Number(renterFee).toLocaleString("en-US", { minimumFractionDigits: 2 })}</Text>
                                        </View>
                                    </>
                                )}
                            </View>
                        )}
                        {currentMonthSpan >= 1 && (
                            <>
                                <View style={[globalStyles.center, { margin: 10 }]}>
                                    <Pressable onPress={() => setMonthlyPayout(!monthlyPayout)}>
                                        {!monthlyPayout ? (
                                            <MaterialIcons name="check-box-outline-blank" size={30} color="black" />
                                        ) : (
                                            <MaterialIcons name="check-box" size={30} color="black" />
                                        )}
                                    </Pressable>
                                    <Text style={{ marginLeft: 8, fontSize: 17 }}>I'd prefer to make my payments monthly</Text>
                                </View>
                            </>
                        )}

                        {monthlyPayout && (
                            <>
                                <CustomDivider marginVertical={10} />
                                {showMonthlyPayoutBreakdown ? (
                                    <View>
                                        <View style={[globalStyles.spaceBetween, { marginTop: 5 }]}>
                                            <Text style={globalStyles.underlineText}>$ {spaceTypes[activeTabIndex]?.price} X {currentDateSpan.length - 1} {currentDateSpan.length > 1 ? `nights` : `night`}</Text>
                                            <Text style={globalStyles.flexEnd}>${Number(baseCost).toLocaleString("en-US", { minimumFractionDigits: 2 })}</Text>
                                        </View>
                                        <View style={[globalStyles.spaceBetween, { marginTop: 5 }]}>
                                            <Text style={globalStyles.underlineText}>services fee:</Text>
                                            <Text style={globalStyles.flexEnd}>${Number(renterFee).toLocaleString("en-US", { minimumFractionDigits: 2 })}</Text>
                                        </View>
                                        <View style={[globalStyles.spaceBetween, { marginTop: 5 }]}>
                                            <View></View>
                                            <Text style={globalStyles.flexEnd}>split over {numberOfMonths} months</Text>
                                        </View>
                                    </View>
                                ) : (
                                    <CustomButton
                                        width={mobileView ? width / 1.05 : width / 3.1}
                                        height={40}
                                        marginTop={10}
                                        backgroundColor={colors.mellowOrange}
                                        borderRadius={8}
                                        border={colors.hookhubOrangeDark}
                                        hideBorder={true}
                                        onPress={e => setShowMonthlyPayoutBreakdown(true)}
                                    >
                                        <Text style={globalStyles.underlineText}>{"show monthly cost breakdown"}</Text>
                                    </CustomButton>
                                )}
                            </>
                        )}
                        {!!monthlyPayout ? (
                            <View style={{ flexDirection: "column", alignContent: "center", justifyContent: "center", alignItems: "center", marginTop: 20 }}>
                                <CustomDivider marginVertical={10} />
                                <View style={globalStyles.centerColumn}>
                                    <Text style={[globalStyles.boldText, {fontSize: 16}]}>Monthly Total:</Text>
                                    <Text><Text style={globalStyles.boldText}>{!!monthlyPrice ? `$ ${Number(monthlyPrice).toLocaleString("en-US", { minimumFractionDigits: 2 })}` : '--'}</Text> per month</Text>
                                </View>
                            </View>
                        ) : (
                            <>
                                <CustomDivider marginVertical={10} />
                                <View style={[globalStyles.spaceBetween, { marginTop: 5 }]}>
                                    <Text style={[globalStyles.boldText, {fontSize: 16}]}>Total</Text>
                                    <Text style={styles.boldText}>{!!rentalCost ? `$ ${Number(rentalCost).toLocaleString("en-US", { minimumFractionDigits: 2 })}` : '--'}</Text>
                                </View>
                            </>
                        )}
                    </>
                )}
            </View>
        </>
    );
};


const styles = StyleSheet.create({
    prices: {
        fontSize: 17,
        marginTop: 5
    },
    buttonText: {
        fontSize: 14,
        color: "white",
    },
    currentTabView: {
        top: 0,
        backgroundColor: colors.lightBlue,
        borderRadius: 16,
        alignItems: "center",
        justifyContent: "center",
        alignSelf: "center",
        height: 50,
        padding: 5
    },
    subtext: {
        color: "blue",
        marginTop: 2
    },
    date: {
        borderColor: "gray",
        borderRadius: 4,
        borderWidth: 2,
        margin: 0,
        fontSize: 16,
        color: "grey",
    },
    dateText: {
        fontSize: 17,
        fontWeight: "bold",
    },
    boldText: {
        fontSize: 17,
    },
    totalText: {
        fontWeight: "bold",
        fontSize: 17,
        marginLeft: 35
    },
});

export default ReserveCardBodyWeb;
