import { Text, View } from "react-native";
import {
  Ionicons,
  MaterialCommunityIcons,
  MaterialIcons,
} from "@expo/vector-icons";
import { colors } from "../../styles/colors"
import Header from "../CustomElements/Header"
import { useSelector } from "react-redux";
import { globalStyles } from "../../styles/styles";

const AmenityDistance = ({ val, title, icon, mobileView, subtext }) => {
  return (
    <View style={globalStyles.center}>
      {icon}
      {Number(val) > 49 ? (
        <Header color={colors.amplifyNeutral90} level={1.1} text="Over 50"></Header>
      ) : (
        <Header color={colors.amplifyNeutral90} level={1.1} text={val}></Header>
      )}
      <Text>{subtext}</Text>
      <Text style={{ fontSize: 16, marginLeft:5 }}>{title}</Text>
    </View>
  );
};

const DistancesView = ({ space }) => {
  const {
    sewage_hookup,
    electric_hookup,
    water_hookup,
    distance_electric_hookup,
    distance_sewage_hookup,
    distance_water_hookup,
  } = space;
  const { mobileView } = useSelector(state => state.currentUser);

  return (
    <>
      <Header level={1} textAlign="left" marginBottom={10} padding={0} color={colors.amplifyNeutral90} text="Distance to Amenities"></Header>
      <View style={[globalStyles.flexStartColumn, {marginHorizontal: 15}]}>
        {electric_hookup && (
          <AmenityDistance
            mobileView={mobileView}
            val={distance_electric_hookup}
            title="ELECTRIC"
            subtext="feet to"
            icon={
              <MaterialIcons
                name="electrical-services"
                size={30}
                color={colors.amplifyNeutral80}
              />
            }
          />
        )}
        {water_hookup && (
          <AmenityDistance
            mobileView={mobileView}
            val={distance_water_hookup}
            title="WATER"
            subtext="feet to"
            icon={<Ionicons name="water-sharp" size={25} color={colors.amplifyNeutral80} />}
          />
        )}
        {sewage_hookup && (
          <AmenityDistance
            mobileView={mobileView}
            val={distance_sewage_hookup}
            title="SEWAGE"
            subtext="feet to"
            icon={
              <MaterialCommunityIcons name="pipe" size={23} color={colors.amplifyNeutral80} />
            }
          />
        )}
      </View>
    </>
  );
};

export default DistancesView;
