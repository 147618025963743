import React, { useState } from "react";
import { NavigationContainer, useNavigation } from "@react-navigation/native";
import {
  View,
  StyleSheet,
  Image,
  Text,
  Pressable,
  useWindowDimensions,
  TouchableOpacity,
  Modal
} from "react-native";
import { useSelector } from "react-redux";
import {
  createNativeStackNavigator,
} from "@react-navigation/native-stack";
import { SignUpModal } from "../components/user/SignUpModal";
import SplashScreen from "../screens/info/SplashScreen";
import ExploreNavigator from "./ExploreNavigator";
import CustomButton from "../components/CustomElements/CustomButton";
import AboutScreen from "../screens/info/AboutScreen";
import * as Linking from "expo-linking";
import Badge from "../components/CustomElements/Badge";
import { Ionicons } from "@expo/vector-icons";
import { globalStyles } from "../styles/styles";
import { colors } from "../styles/colors";
import Card from "../components/CustomElements/Card";
import CustomDivider from "../components/CustomDivider";
import HelpScreen from "../screens/info/HelpScreen";
import HostSplashScreen from "../screens/info/HostSplashScreen";
const Stack = createNativeStackNavigator();

const Buttons = ({ mobileView, login, setModalOpen }) => {
  const { width } = useWindowDimensions();
  const navigation = useNavigation();
  const [optionsOpen, setOptionsOpen] = useState(false)

  const about = () => {
    navigation.navigate("about")
  }

  const navigate = (method) => {
    setOptionsOpen(false)
    method()
  }

  return (
    <View style={globalStyles.center}>
      <CustomButton
        backgroundColor={colors.white}
        width={mobileView ? 70 : 140}
        height={35}
        marginRight={mobileView ? 2 : 10}
        onPress={() => navigation.navigate("about")}
        marginTop={4}
      >
        <Text style={{ fontSize: 16 }}>{mobileView ? "About" : "Learn More"}</Text>
      </CustomButton>
      {!mobileView && (
        <CustomButton
          backgroundColor={colors.white}
          width={140}
          height={35}
          marginRight={10}
          onPress={() => navigation.navigate("host")}
          marginTop={4}
        >
          <Text style={{ fontSize: 16 }}>Host my Space</Text>
        </CustomButton>
      )}
      <View style={globalStyles.centerColumn}>
        <Pressable
          onPress={() => setOptionsOpen(!optionsOpen)}
          style={[styles.learnMoreButton, { marginRight: mobileView ? 2 : 10 }]}
        >
          <Badge
            width={70}
            height={35}
            variation="white"
            marginRight={5}
            onPress={() => setOptionsOpen(!optionsOpen)}
          >
            <View style={globalStyles.spaceBetween}>
              <Ionicons name="menu-sharp" size={22} color={colors.amplifyNeutral80} />
              <Ionicons name="person-circle" size={25} color={colors.amplifyNeutral80} />
            </View>
          </Badge>
        </Pressable>
        <Modal
          visible={optionsOpen}
          transparent={true}
          style={{ marginTop: mobileView ? 5 : 30, width: mobileView ? width / 2.2 : width / 3 }}
        >
          <TouchableOpacity
            style={[globalStyles.flexEndColumn, { marginTop: 50, flex: 1, width: "100%", height: "100%", opacity: 1, justifyContent: "flex-start" }]}
            onPress={() => { setOptionsOpen(false) }}
          >
            <Card
              backgroundColor={colors.white}
              padding={10}
              alignItems="flex-start"
              width={!!mobileView ? width / 1.5 : width / 3}
            >
              <CustomButton
                hideBorder
                alignItems="flex-start"
                backgroundColor={colors.orange}
                onPress={() => navigate(setModalOpen)}
              >
                <Text style={{ fontWeight: "bold" }}>Sign Up</Text>
              </CustomButton>
              <CustomButton
                hideBorder
                alignItems="flex-start"
                backgroundColor="#b2b6b8"
                onPress={() => navigate(login)}
              >
                <Text>Login</Text>
              </CustomButton>
              <CustomDivider marginVertical={10} />
              <CustomButton
                hideBorder
                alignItems="flex-start"
                backgroundColor={colors.orange}
                onPress={() => navigate(() => navigation.navigate("host"))}
              >
                <Text>Host your space</Text>
              </CustomButton>
              <CustomButton
                hideBorder
                alignItems="flex-start"
                backgroundColor={colors.orange}
                onPress={() => navigate(() => navigation.navigate("about"))}
              >
                <Text>Learn more</Text>
              </CustomButton>
              <CustomDivider marginVertical={10} />
              <CustomButton
                hideBorder
                alignItems="flex-start"
                backgroundColor="#b2b6b8"
                onPress={() => navigate(() => navigation.navigate("help"))}
              >
                <Text>Help</Text>
              </CustomButton>
            </Card>
          </TouchableOpacity>
        </Modal>
      </View>
    </View>
  );
};

const UnauthNavigatorBody = ({ authenticate }) => {
  const navigation = useNavigation();
  const { width } = useWindowDimensions();
  const [modalOpen, setModalOpen] = useState(false);
  const { mobileView } = useSelector(state => state.currentUser);

  return (
    <>
      <Stack.Navigator
        screenOptions={{
          headerBackVisible: false,
          drawerPosition: "right",
          contentStyle: {backgroundColor: "#fff"},
          headerTitle: ({ focused }) => (
            <TouchableOpacity
              onPress={() => navigation.reset({ routes: [{ name: 'explore' }] })}
            >
              <Image
                style={width < 530 ? { width: 40, height: 40, marginTop: 4 } : { width: 144, height: 37.2, marginLeft: 5 }}
                source={width < 530 ? require("../../assets/images/favicon.png") : require("../../assets/images/hookhub-logo-orange-mini.png")}
              />
            </TouchableOpacity>
          ),
          headerRight: ({ focused, size }) => (
            <Buttons mobileView={mobileView} setModalOpen={() => setModalOpen(true)} login={() => authenticate()} />
          ),
          headerLeft: () => null
        }}
      >
        <Stack.Screen
          name="explore"
          component={ExploreNavigator}
        />
        <Stack.Screen
          name="splash"
          component={SplashScreen}
        />
        <Stack.Screen
          name="host"
          component={HostSplashScreen}
        />
        <Stack.Screen
          name="about"
          component={AboutScreen}
        />
        <Stack.Screen
          name="help"
          component={HelpScreen}
        />
      </Stack.Navigator>
      <SignUpModal
        authenticate={() => authenticate()}
        modalVisible={modalOpen}
        setModalVisible={() => setModalOpen(false)}
        mobileView={mobileView}
        width={width}
      />
    </>
  )
}


const UnauthenticatedNavigator = ({ authenticate }) => {
  const prefix = [Linking.createURL('/'), 'https://hookhub.co']
  const config = {
    screens: {
      explore: {
        path: '',
        screens: {
          space: 'space/:propertyID'
        }
      },
      about: {
        path: "/about"
      },
      host: {
        path: "/host"
      },
    }
  };
  const linking = {
    enabled: true,
    prefixes: [prefix],
    config
  };

  return (
    <>
      <NavigationContainer
        linking={linking}
        documentTitle={{
          formatter: () => `hookhub`,
        }}
      >
        <UnauthNavigatorBody authenticate={() => authenticate()} />
      </NavigationContainer>
    </>
  );
};

const styles = StyleSheet.create({
  logoButton: {
    width: 50,
    borderRadius: 20,
    padding: 10,
    marginBottom: 20,
    shadowColor: "#303838",
    shadowOffset: { width: 0, height: 5 },
    shadowRadius: 10,
    shadowOpacity: 0.35,
  },
  learnMoreButton: {
    alignItems: "center",
    justifyContent: "center",
    alignSelf: "center",
    marginTop: 4
  },
  loginButtonText: {
    fontSize: 17,
  },
});

export default UnauthenticatedNavigator;
