import React from "react";
import {
    Text,
    Image,
    Pressable,
    StyleSheet,
    View
} from "react-native";
import { colors } from "../../styles/colors"
import DimensionsView from "./DimensionsView";
import DistancesView from "./DistancesView";
import Header from "../CustomElements/Header";
import CustomSection from "../CustomElements/CustomSection";
import getEnvVars from "../../../environment";
import Badge from "../CustomElements/Badge";
import { Ionicons } from "@expo/vector-icons";
import { globalStyles } from "../../styles/styles";
import Card from "../CustomElements/Card";
const { cloudfrontURL } = getEnvVars();

const MultipleSpacesDisplay = ({
    width,
    mobileView,
    amenitiesPresent,
    displaySpace,
    setDisplaySpace,
    spaces,
    electric_hookup,
    water_hookup,
    sewage_hookup,
    preview = false,
    originalSpaceUpdatedTitle = "",
    propertyTitle
}) => {
    const viewWidth = mobileView ? width / 1.1 : width / 2.2;
    return (
        <CustomSection backgroundColor={colors.amplifyNeutral20} customWidth={viewWidth} marginBottom={5}>
            <View style={[globalStyles.centerColumn, { width: viewWidth - 50 }]}>
                {preview ? (
                    <>
                        <Header level={1.15} color={colors.amplifyNeutral90} marginBottom={3} text={`Property: ${propertyTitle}`} />
                        {originalSpaceUpdatedTitle && <Header level={1} color={colors.amplifyNeutral80} marginBottom={3} text={`Original space new title: ${originalSpaceUpdatedTitle}`} />}
                    </>
                ) : (<></>)}
                <Header
                    marginTop={15}
                    marginBottom={10}
                    marginLeft={5}
                    text={preview ? "New spaces" : "Spaces on this property"}
                    color={colors.amplifyNeutral90}
                    level={1.1}
                />
                <View style={[globalStyles.flexStartColumn, {marginBottom: 10}]}>
                    {(spaces).map((v, i) => (
                        <Pressable key={i} onPress={() => setDisplaySpace(v)}>
                            <Badge width={mobileView ? width / 1.4 : width / 2.4} onPress={() => setDisplaySpace(v)} padding={-1} alignContent="center" alignItems="center" justifyContent="flex-start" textAlign="left" margin={1} variation={displaySpace.id === v.id ? "warning" : "info"}>
                                <View style={{ flexDirection: "row", alignItems: "center", justifyContent: "flex-start" }}>
                                    <Ionicons name={displaySpace.id === v.id ? "radio-button-on-sharp" : "radio-button-off-sharp"} size={24} color={displaySpace.id === v.id ? colors.amplifyNeutral90 : colors.amplifyNeutral80} />
                                    <Text
                                        style={{ marginLeft: 5, fontSize: 16, width: "100%" }}
                                        numberOfLines={1}
                                        ellipsizeMode="tail"
                                    >
                                        {v.title}
                                    </Text>
                                </View>
                            </Badge>
                        </Pressable>
                    ))}

                </View>
                <View style={[globalStyles.flexStart, { flexWrap: "wrap", marginBottom: 10 }]}>
                    {!!amenitiesPresent && (
                        <Card backgroundColor={colors.amplifyNeutral20} width={viewWidth - 50}>
                            <DistancesView
                                width={width}
                                space={{
                                    sewage_hookup: sewage_hookup,
                                    electric_hookup: electric_hookup,
                                    water_hookup: water_hookup,
                                    distance_electric_hookup: displaySpace.distance_electric_hookup,
                                    distance_sewage_hookup: displaySpace.distance_sewage_hookup,
                                    distance_water_hookup: displaySpace.distance_water_hookup,
                                }}
                                mobileView={mobileView} />
                        </Card>
                    )}
                    <Card backgroundColor={colors.amplifyNeutral20} width={viewWidth - 50}>
                        <DimensionsView
                            width={displaySpace.width}
                            length={displaySpace.length}
                            screenWidth={width}
                            mobileView={mobileView}
                        />
                    </Card>
                </View>
                <View style={globalStyles.centerColumn}>
                    {displaySpace?.images && displaySpace?.images[0] && (
                        <Image
                            source={{ uri: `${cloudfrontURL}${displaySpace.images[0]}` }}
                            resizeMode="cover"
                            style={[styles.images, { height: mobileView ? (width / 1.5) - 50 : width / 2.8, width: mobileView ? width / 1.5 : width / 2.5 }]}
                        />
                    )}
                </View>
            </View>
        </CustomSection >
    );
};

export default MultipleSpacesDisplay;

const styles = StyleSheet.create({
    images: {
        borderRadius: 8,
        alignSelf: "center",
        justifyContent: "center",
        alignContent: "center"
    },
    center: {
        alignSelf: "center",
        justifyContent: "center",
        alignContent: "center",
        alignItems: "center"
    }
});
