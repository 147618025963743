import React, { useState } from 'react';
import { Text, StyleSheet, useWindowDimensions, View } from 'react-native';
import { colors } from '../../styles/colors';
import { LoadingComponent } from '../LoadingComponent';
import Flex from './Flex';
import Animated from 'react-native-reanimated';

const LoadingBar = ({
    loadingPercentage="0%",
    loadingStatus=""
}) => {
    const { width } = useWindowDimensions();
    return (
        <Flex
            justifyContent="center"
            alignItems="center"
            alignContent="center"
            direction="column"
            marginTop={35}
            width={width / 1.7}
            marginBottom={30}
        >
            <LoadingComponent />
            {loadingStatus && <Text style={{ fontSize: 20, margin: 10, color: "black", textAlign: "center" }}>{loadingStatus}</Text>}
            <View style={styles.progressBar}>
                <Animated.View style={{ backgroundColor: colors.amplifyBlue20, width: loadingPercentage }} />
            </View>
            <Text style={{ fontSize: 17 }}>{loadingPercentage}</Text>
        </Flex>
    );
};

const styles = StyleSheet.create({
    centeredView: {
        flex: 1,
        justifyContent: "center",
        alignItems: "center",
    },
    feeButton: {
        textDecorationLine: "underline",
        marginLeft: 5
    },
    progressBar: {
        height: 20,
        marginTop: 10,
        flexDirection: "row",
        width: '100%',
        backgroundColor: 'white',
        borderColor: '#000',
        borderWidth: 2,
        borderRadius: 5
    },
});

export default LoadingBar;