import { formatDate } from "../../utils/formatDate";
import { notifyHookhub } from "../notifyHookhub";

export const sendRequestApprovedEmail = async ({
    bookingData
}) => {
    const { hostID, hostEmail } = bookingData;
    const requestOptions = {
        method: 'POST',
        body: JSON.stringify({
            recordData: bookingData,
            checkOutDate: formatDate(bookingData.check_out),
            checkInDate: formatDate(bookingData.check_in),
            recordType: "create"
        })
    };
    fetch('https://ruazir4hw446vtyq42sqvd6qim0ueola.lambda-url.us-west-2.on.aws/', requestOptions)
        .then(response => response.json())
        .then(data => {
            console.log("email sent: success");
        }).catch((e) => {
            console.log("ERROR sending email >>   ", e)
            const subject = `ERROR sending EMAIL`
            const body = `EMAIL send to hookhub for TYPE: ${type} failed. ERROR: Booking REQUEST ID: ${bookingData.id}`
            notifyHookhub({ subject, body, userID: hostID, userEmail: hostEmail })
        })
}


export const sendRequestDeniedEmail = async ({
    requestOptions,
    bookingData,
    renterID,
    renterEmail
}) => {
    fetch('https://ruazir4hw446vtyq42sqvd6qim0ueola.lambda-url.us-west-2.on.aws/', requestOptions)
        .then(response => response.json())
        .then(data => {
            console.log("email sent: success");
            const body = `Booking cancelled! ${bookingData.id}`;
            const subject = `BOOKING has ben CANCELLED`
            notifyHookhub({ subject, body, userID: renterID, userEmail: renterEmail })
            closeThis();
        }).catch((e) => {
            console.log("ERROR sending email >>   ", e)
            setError("Error sending booking cancelled email. Please reach out to Hookhub.");
            setModalVisible(false);
            setLoading(false);
        })
}