import React, { createContext, useContext, useEffect, useMemo, useState } from 'react'
import { onBookingRequestbySpaceOwnerId, onBookingbyRenterId } from '../graphql/subscriptions';
import { API } from 'aws-amplify';
import { listBookings, listBookingRequests } from "../graphql/queries";
import { UserDataContext } from './UserContextProvider';
import { getSpaceAndPropertyDetails } from "../api/space";
import { listMyHostedProperties } from '../api/property';

const BookingsContext = createContext(null);
export { BookingsContext };

export default ({ children }) => {
    const { user } = useContext(UserDataContext);
    const { userData } = user;
    const { sub } = userData;
    const [bookingsArray, setBookingsArray] = useState([]);
    const [bookingRequestsArray, setBookingRequestsArray] = useState([]);
    const [availabilityNeeded, setAvailabilityNeeded] = useState([]);
    const [mySpaces, setMySpaces] = useState([]);
    const bookings = useMemo(
        () => ({ bookingsArray, setBookingsArray }),
        [bookingsArray]
    );
    const bookingRequests = useMemo(
        () => ({ bookingRequestsArray, setBookingRequestsArray }),
        [bookingRequestsArray]
    );
    const availabilityMissing = useMemo(
        () => ({ availabilityNeeded, setAvailabilityNeeded }),
        [availabilityNeeded]
    );
    const myHosting = useMemo(
        () => ({ mySpaces, setMySpaces }),
        [mySpaces]
    );

    useEffect(() => {
        if (!!sub) {
            getmyHostedBookingRequests()
            getMyHostedBookings()
            listProperties();
        }
    }, [sub]);

    const subscribeNewBookingRequest = async () => {
        API.graphql({
            query: onBookingRequestbySpaceOwnerId,
            variables: { hostID: sub }
        }).subscribe({
            next: data => {
                const response = data.value.data.onBookingRequestbySpaceOwnerId;
                setBookingRequestsArray([...bookingRequestsArray, response])
            },
            error: (e) => console.log("ERROR subscribing to new booking request >>  ", e)
        });
    }

    const subscribeNewBooking = async () => {
        API.graphql({
            query: onBookingbyRenterId,
            variables: { renterID: sub }
        }).subscribe({
            next: data => {
                const response = data.value.data.onBookingbyRenterId;
                setBookingsArray([...bookingsArray, response])
            }
        });
    }

    const getmyHostedBookingRequests = async () => {
        await API.graphql({
            query: listBookingRequests,
            variables: {
                filter: {
                    hostID: { eq: sub },
                    reviewed: { eq: false },
                    cancelled: { eq: false }
                }
            },
            authMode: "AMAZON_COGNITO_USER_POOLS"
        }).then(async (d) => {
            let bookingRequests = d.data.listBookingRequests.items;
            setBookingRequestsArray(bookingRequests);
            subscribeNewBookingRequest();
        })
            .catch((e) => {
                console.log("Error getting booking requests", e)
            });
    };

    const getMyHostedBookings = async () => {
        await API.graphql({
            query: listBookings,
            variables: {
                filter: {
                    hostID: { eq: sub },
                    cancelled: { eq: false }
                }
            },
            authMode: "AMAZON_COGNITO_USER_POOLS"
        })
            .then(async (d) => {
                let bookings = d.data.listBookings?.items;
                if (!!bookings.length) {
                    for (let i = 0; i < bookings.length; i++) {
                        const spaceDetails = await getSpaceAndPropertyDetails(bookings[i].spaceID);
                        bookings[i].spaceDetails = spaceDetails;
                    };
                }
                setBookingsArray(bookings)
                subscribeNewBooking();
            })
            .catch((e) => {
                console.log("Error getting bookings", e)
            });
    };

    const listProperties = async () => {
        let properties = await listMyHostedProperties({ ownerID: sub });
        if (!!properties?.items) {
            setMySpaces(properties.items)
        }
    };

    return (
        <BookingsContext.Provider value={{ bookings, bookingRequests, availabilityMissing, myHosting }}>
            {useMemo(() => (
                children
            ), [])}
        </BookingsContext.Provider>
    )
};
