import React from "react";
import { View } from "react-native";
import {
    Ionicons,
    MaterialIcons,
    MaterialCommunityIcons,
    FontAwesome
} from "@expo/vector-icons";
import Flex from "../CustomElements/Flex"
import { useSelector } from "react-redux";
import { colors } from "../../styles/colors";
import { globalStyles } from "../../styles/styles";

const SpaceAmenitiesMini = ({ space, width }) => {
    const {
        water_hookup,
        electric_hookup,
        sewage_hookup,
        easy_entry,
        shower_available,
        wifi_available,
        laundry_available,
        pool_available,
        hottub_available,
        indoor_area_available,
        outdoor_area_available,
        trash_available,
        restroom_available,
        food_nearby,
        restaurant_nearby,
    } = space;
    const amenitiesPresent = (
        water_hookup ||
        electric_hookup ||
        sewage_hookup ||
        easy_entry ||
        shower_available ||
        wifi_available ||
        laundry_available ||
        pool_available ||
        hottub_available ||
        indoor_area_available ||
        outdoor_area_available ||
        trash_available ||
        restroom_available ||
        food_nearby ||
        restaurant_nearby
    );

    return (
        amenitiesPresent && (
            <View style={globalStyles.flexEnd}>
                {water_hookup && <Ionicons name="water-sharp" size={23} color={colors.amplifyNeutral80} />}
                {electric_hookup && (<MaterialIcons name="electrical-services" size={25} color={colors.amplifyNeutral80} />)}
                {sewage_hookup && (<MaterialCommunityIcons name="pipe" size={22} color={colors.amplifyNeutral80} style={{marginTop:1}}/>)}
                {/* {easy_entry && ( <MaterialCommunityIcons name="steering" size={mobileView ? 25 : 30} color={colors.amplifyNeutral80}/>)}
                    {shower_available && (<FontAwesome5 name="shower" size={mobileView ? 25 : 30} color={colors.amplifyNeutral80} />)}
                    {wifi_available && (<Ionicons name="wifi" size={mobileView ? 25 : 30} color={colors.amplifyNeutral80} />)}
                    {laundry_available && (<MaterialIcons name="local-laundry-service" size={mobileView ? 25 : 30} color={colors.amplifyNeutral80} />)}
                    {pool_available && (<MaterialIcons name="pool" size={mobileView ? 24 : 38} color="black" />)}
                    {hottub_available && (<FontAwesome5 name="hot-tub" size={mobileView ? 25 : 28} color={colors.amplifyNeutral80} />)}
                    {indoor_area_available && (<FontAwesome5 name="warehouse" size={mobileView ? 25 : 25} color={colors.amplifyNeutral80} />)}
                    {outdoor_area_available && (<MaterialCommunityIcons name="table-picnic" size={mobileView ? 25 : 30} color={colors.amplifyNeutral80} />)}
                    {trash_available && (<FontAwesome name="trash-o" size={mobileView ? 23 : 27} color={colors.amplifyNeutral80} />)} */}
            </View>
        )
    );
};

export default SpaceAmenitiesMini;
