import { useEffect, useState } from "react";
import { ScrollView } from "react-native";
import LearnMore from "../../components/LearnMore";
import BackButton from "../../components/other/BackButton";
import HelpForm from "../../components/other/HelpForm";
import FooterView from "../../components/other/FooterView";
import { Auth } from "aws-amplify";

export default function HelpScreen() {
  const [authed, setAuthed] = useState(false)

  const authCheck = async () => {
    await Auth.currentUserInfo()
      .then((userInfo) => {
        if (userInfo && userInfo.attributes) {
          setAuthed(true)
        } else { setAuthed(false) }
      })
  }

  useEffect(() => {
    authCheck();
  }, [])

  return (
    <ScrollView>
      <BackButton goBack={false} />
      <LearnMore />
      <HelpForm authed={authed} />
      <FooterView />
    </ScrollView>
  );
}
