import React, { useState } from 'react';
import {
    Dimensions,
    StyleSheet,
    Pressable
} from 'react-native';
import { colors } from '../../styles/colors';
import { useSelector } from 'react-redux';

const CustomButton = ({
    children,
    onPress,
    disabled = false,
    backgroundColor = "#fff",
    border = colors.amplifyNeutral60,
    width = Dimensions.get('window').width - 100,
    height = 30,
    padding = 0,
    marginBottom = 1,
    marginRight = 1,
    marginLeft = 1,
    marginTop = 1,
    textAlign = "center",
    alignItems = "center",
    hideBorder = false,
    opacity = .9,
}) => {
    const [onHover, setOnHover] = useState(false)
    const { mobileView } = useSelector(state => state.currentUser);
    const color = disabled ? colors.brandGrey : backgroundColor;

    return (
        <Pressable
            onPress={() => onPress()}
            onHoverIn={() => setOnHover(true)}
            onHoverOut={() => setOnHover(false)}
            disabled={disabled}
            style={[styles.button, {
                marginLeft: marginLeft,
                marginRight: marginRight,
                marginBottom: marginBottom,
                marginTop: marginTop,
                borderColor: border,
                padding: padding,
                textAlign: textAlign,
                alignItems: alignItems,
                width: width,
                height: height,
            }, !hideBorder && {
                borderWidth: .3,
                borderBottomWidth: 1,
                shadowColor: colors.amplifyNeutral80,
                shadowOffset: { width: .1, height: .5},
                shadowOpacity: .2,
                shadowRadius: 4,
                borderRadius: 7,
                backgroundColor: color
            }, opacity && {
                opacity: opacity
            }, onHover && !mobileView && {
                opacity: 1
            }]}
        >
            {children}
        </Pressable>
    )

}

const styles = StyleSheet.create({
    button: {
        alignItems: "center",
        justifyContent: "center",
        alignContent: "center",
        textAlign: "center",
    },
})
export default CustomButton;