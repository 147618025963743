import {
  StyleSheet,
  Text,
  Pressable,
  View as ReactView,
  useWindowDimensions,
  TouchableOpacity,
  ScrollView
} from "react-native";
import Modal from "react-native-modal";
import { Feather } from '@expo/vector-icons';
import { useNavigation } from "@react-navigation/native";
import Flex from "./CustomElements/Flex"
import Header from "./CustomElements/Header"
import { useDispatch, useSelector } from "react-redux";
import { colors } from "../styles/colors";
import { clearSpaceDetails } from "../redux/actions/HostOnboardingActions";

export const SuccessModal = ({ modalVisible, setModalVisible }) => {
  const navigation = useNavigation();
  const dispatch = useDispatch();
  const { height, width } = useWindowDimensions();
  const { mobileView } = useSelector(state => state.currentUser);

  const goToNextSteps = () => {
    navigation.navigate("hostaccount", {
      screen: "My Hosting"
    });
    dispatch(clearSpaceDetails());
    setModalVisible();
  }

  return (
    <Modal
      coverScreen={true}
      isVisible={modalVisible}
      style={{ margin: 0 }}
      onBackdropPress={() => setModalVisible()}
      onSwipeComplete={() => setModalVisible()}
      deviceHeight={height}
    >
      <ScrollView>
        <TouchableOpacity>
          <ReactView>
            <ReactView style={styles.modalView}>
              <Flex
                direction="column"
                textAlign="center"
                justifyContent="center"
                alignContent="center"
                alignItems="center"
                marginTop={8}
                marginBottom={8}
              >
                <Header level={mobileView ? 1 : 2} text="Success!"></Header>
                <Text>Your space has been created.</Text>
                <Pressable
                  onPress={goToNextSteps}
                  style={styles.forwardButton}
                >
                  <Text style={styles.forwardButtonText}>Next Steps</Text>
                </Pressable>
                <Flex
                  alignContent="center"
                  justifyContent="center"
                  alignSelf="center"
                  alignItems="center"
                  textAlign="center"
                  marginTop={8}
                  width={width / 1.5}
                >
                  <Feather name="info" size={24} color="#696969" />
                  <Text style={styles.infoText}>
                    NOTE: You may need to refresh to see your new spaces.
                  </Text>
                </Flex>
                <Flex
                  alignContent="center"
                  justifyContent="center"
                  alignSelf="center"
                  alignItems="center"
                  textAlign="center"
                  marginTop={8}
                  width={width / 1.5}
                >
                  <Feather name="info" size={24} color="#696969" />
                  <Text style={styles.infoText}>
                    IMPORTANT: Hoster obligations: You are responsible for being
                    aware of your property laws and limitations and responsible
                    for any income or other taxes. Taking transactions off the Hookhub platform 
                    for any current or future bookings is strictly prohibited.
                    Terms & Conditions can be found at the bottom of the screen or 
                    the bottom of the Learn More screen on mobile, labelled "Terms".
                  </Text>
                </Flex>
              </Flex>
            </ReactView>
          </ReactView>
        </TouchableOpacity>
      </ScrollView>
    </Modal>
  );
};

const styles = StyleSheet.create({
  modalView: {
    margin: 15,
    backgroundColor: "white",
    borderRadius: 20,
    padding: 10,
    shadowColor: "#000",
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity: 0.25,
    shadowRadius: 4,
    elevation: 5,
  },
  forwardButtonText: {
    color: "#fff",
    fontSize: 20,
  },
  infoText: {
    color: "#696969",
    marginLeft: 10
  },
  forwardButton: {
    borderColor: "grey",
    borderRadius: 10,
    marginTop: 30,
    marginBottom: 45,
    width: 150,
    borderWidth: 1,
    backgroundColor: colors.orange,
    alignItems: "center",
    borderBottomWidth: 2,
    justifyContent: "center",
    alignSelf: "center",
    height: 40,
    padding: 20,
    shadowColor: "#252625",
    shadowOffset: { width: -3, height: 5 },
    shadowOpacity: 0.5,
  },
  modalText: {
    marginBottom: 15,
    textAlign: "center",
  },
});
