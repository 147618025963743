import React, {useState} from "react";
import {
    View,
    Pressable,
    useWindowDimensions,
    ScrollView
} from "react-native";
import { FontAwesome5 } from '@expo/vector-icons';
import Flex from "../CustomElements/Flex"

function CustomHorizontalScrollView({
    children,
    arrowColor="#4d4c4c"
}) {
    let scroll;
    const { width } = useWindowDimensions();
    const [scrolls, setscrolls] = useState(0);
    const [rightButton, setRightButton] = useState(false);
    const [leftButton, setLeftButton] = useState(false);

    const scrollRight = () => {
        const currentscroll = scrolls + 1
        setscrolls(currentscroll);
        scroll.scrollTo({ x: width / 1.5 * currentscroll })
        setLeftButton(true);
    };

    const scrollLeft = () => {
        const currentScroll = scrolls - 1;
        scroll.scrollTo({ x: width / 1.8 * currentScroll })
        setscrolls(currentScroll);
    };

    const onContentSizeChange = (contentWidth, contentHeight) => {
        if (contentWidth > width) {
            setRightButton(true)
        }
    };

    const isCloseToRight = ({ layoutMeasurement, contentOffset, contentSize }) => {
        const paddingToRight = 20;
        return layoutMeasurement.width + contentOffset.x >= contentSize.width - paddingToRight;
    };

    const isCloseToLeft = ({ layoutMeasurement, contentOffset, contentSize }) => {
        return contentOffset.x === 0;
    };

    return (
        <Flex
            alignItems="center"
            width={width / 1.1}
            marginBottom={4}
        >
            <ScrollView
                horizontal
                style={{ flex: 1 }}
                pagingEnabled
                onContentSizeChange={onContentSizeChange}
                ref={(node) => scroll = node}
                decelerationRate={0}
                snapToInterval={40 + 10}
                snapToAlignment='start'
                onScroll={({ nativeEvent }) => {
                    if (isCloseToRight(nativeEvent)) {
                        setRightButton(false);
                    } else if (isCloseToLeft(nativeEvent)) {
                        setLeftButton(false);
                        setRightButton(true);
                    }
                }}
                scrollEventThrottle={10}
            >
                {children}
            </ScrollView>
            {rightButton &&
                <View style={{ position: "absolute", bottom: 10, right: 1 }}>
                    <Pressable onPress={() => scrollRight()}>
                        <View paddingRight={4}><FontAwesome5 name="arrow-circle-right" size={31} color={arrowColor} /></View>
                    </Pressable>
                </View>
            }
            {leftButton &&
                <View style={{ position: "absolute", bottom: 10, left: 10 }}>
                    <Pressable onPress={() => scrollLeft()}>
                        <View paddingRight={4}><FontAwesome5 name="arrow-circle-left" size={31} color={arrowColor} /></View>
                    </Pressable>
                </View>
            }
        </Flex>

    );
}

export default CustomHorizontalScrollView;
