export const sendNewMessageEmail = ({emailAddress, authorName}) => {
    const requestOptions = {
        method: 'POST',
        body: JSON.stringify({
            emailAddress,
            authorName
        })
    };
    fetch('https://uxsfxx5gn52dxsbt6rfeglixtu0gpyzn.lambda-url.us-west-2.on.aws/', requestOptions)
        .then(response => response.json())
        .then(data => {
            console.log("email sent: success");
        }).catch((e) => {
            console.log("ERROR sending email >>   ", e)
            setError(true);
        })
}