import React, { useState } from "react";
import { useDispatch, useSelector } from 'react-redux';
import HostOnboarding from "../../components/host/HostOnboarding";
import { setThingsToKnow } from "../../redux/actions/HostOnboardingActions";
import SetThingsToKnow from "../../components/space/SetThingsToKnow";

function ThingsToKnowScreen() {
  const dispatch = useDispatch();
  const {
    check_in_time,
    check_out_time,
    space_rules,
    check_in_instructions,
    quiet_hours_start,
    quiet_hours_end,
    full_refund_date,
    half_refund_date,
    wifi_available,
    wifi_network_name,
    wifi_password,
    instant_book
  } = useSelector(state => state.hostOnboarding);
  const [checkInTime, setcheckInTime] = useState((check_in_time || ''));
  const [checkOutTime, setcheckOutTime] = useState((check_out_time || ''));
  const [spaceRules, setspaceRules] = useState((space_rules || ''));
  const [instantBook, setInstantBook] = useState((instant_book || false));
  const [checkInInstructions, setcheckInInstructions] = useState((check_in_instructions || ''));
  const [quietStart, setquietStart] = useState((quiet_hours_start || ''));
  const [wifiNetwork, setwifiNetwork] = useState((wifi_network_name || ''));
  const [wifiPassword, setwifiPassword] = useState((wifi_password || ''));
  const [quietEnd, setquietEnd] = useState((quiet_hours_end || ''));
  const [fullRefundDate, setfullRefundDate] = useState((full_refund_date || 4));
  const [halfRefundDate, sethalfRefundDate] = useState((half_refund_date || 2));
  const [disabled, setDisabled] = useState(true);
  const [checkInDefined, setcheckInDefined] = useState(false);
  const [quietTimeDefined, setquietTimeDefined] = useState(false);

  const setDetails = () => {
    const data = {
      check_in_time: checkInDefined ? checkInTime : null,
      check_out_time: checkInDefined ? checkOutTime : null,
      space_rules: spaceRules,
      check_in_instructions: checkInInstructions,
      quiet_hours_start: quietTimeDefined ? quietStart : null,
      quiet_hours_end: quietTimeDefined ? quietEnd : null,
      full_refund_date: fullRefundDate,
      half_refund_date: halfRefundDate,
      wifi_network_name: wifiNetwork,
      wifi_password: wifiPassword,
      instant_book: instantBook
    }
    dispatch(setThingsToKnow(data));
  };

  return (
    <HostOnboarding
      heading="Any extra things to know?"
      navigateF="Confirmation"
      navigateB="SetPrice"
      nextButtonText="Next"
      onClickNext={() => setDetails()}
      disabledNextButton={disabled}
      required={`Rules and Policies${wifi_available ? " & Wifi details" : ""}`}
      multipleRequired={true}
    >
      <SetThingsToKnow
        checkInTime={checkInTime}
        checkOutTime={checkOutTime}
        spaceRules={spaceRules}
        instantBook={instantBook}
        checkInInstructions={checkInInstructions}
        quietStart={quietStart}
        quietEnd={quietEnd}
        fullRefundDate={fullRefundDate}
        halfRefundDate={halfRefundDate}
        quietTimeDefined={quietTimeDefined}
        checkInDefined={checkInDefined}
        wifiNetwork={wifiNetwork}
        wifiPassword={wifiPassword}
        wifiAvailable={wifi_available}
        setwifiNetwork={(v) => setwifiNetwork(v)}
        setwifiPassword={(v) => setwifiPassword(v)}
        setquietTimeDefined={(v) => setquietTimeDefined(v)}
        setcheckInDefined={(v) => setcheckInDefined(v)}
        setquietEnd={(v) => setquietEnd(v)}
        setfullRefundDate={(v) => setfullRefundDate(v)}
        sethalfRefundDate={(v) => sethalfRefundDate(v)}
        setcheckInTime={(v) => setcheckInTime(v)}
        setcheckOutTime={(v) => setcheckOutTime(v)}
        setspaceRules={(v) => setspaceRules(v)}
        setInstantBook={() => setInstantBook(!instantBook)}
        setcheckInInstructions={(v) => setcheckInInstructions(v)}
        setquietStart={(v) => setquietStart(v)}
        setDisabled={(v) => setDisabled(v)}
      />
    </HostOnboarding>
  )
};

export default ThingsToKnowScreen;