export const ADD_CONVO_MESSAGE = 'ADD_CONVO_MESSAGE';

const INITIAL_STATE = {
    conversations: [],
    activeConversationId: '',
    unreadBoolean: false
};

const conversationsReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case 'SET_INITIAL_DATA':
            return {
                ...state,
                conversations: action.convos,
                activeConversationId: action.convos.length > 0 && action.convos[0].id
            }
        case 'ADD_CONVO_MESSAGE':
            const newMessage = action.msg;
            const updatedConvoIndex = state.conversations.findIndex((obj => obj.id == newMessage.convoID));
            const updateThisConvo = state.conversations[updatedConvoIndex];
            updateThisConvo.unreadHost = action.unreadHost
            updateThisConvo.unreadHostee = action.unreadHostee
            state.conversations[updatedConvoIndex] = updateThisConvo;

            return {
                ...state,
                conversations: [...state.conversations],
                unreadBoolean: action.unreadBoolean
            }
        case 'ADD_NEW_CONVO':
            return {
                ...state,
                conversations: [action.convo, ...state.conversations],
                activeConversationId: action.convo.id,
                unreadBoolean: action.unreadBoolean
            }
        case 'UPDATE_ACTIVE_CONVERSATION':
            return {
                ...state,
                activeConversationId: action.convoId
            }
        case 'SET_UNREAD_BOOLEAN':
            return {
                ...state,
                unreadBoolean: action.unreadBoolean
            }
        case 'UPDATE_UNREAD_BOOLEAN':
            const convoIndex = state.conversations.findIndex((obj => obj.id == action.updatedConvo.id));
            state.conversations[convoIndex] = action.updatedConvo;
            return {
                ...state,
                unreadBoolean: action.unreadBoolean,
                conversations: [...state.conversations],
            }
        default:
            return state
    }
};

export default conversationsReducer;