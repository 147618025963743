import { View } from "react-native";
import { colors } from "../../styles/colors"
import { useSelector } from "react-redux";
import Slider from '@react-native-community/slider';
import { globalStyles } from "../../styles/styles";


const CustomSlider = ({
    goBack = true,
    width,
    value,
    setValue,
    minValue,
    maxValue
}) => {
    const { mobileView } = useSelector(state => state.currentUser);

    return (
        <View style={[globalStyles.centerColumn, { marginBottom: mobileView ? 2 : 20 }]}>
            <Slider
                value={value}
                minimumValue={1}
                step={1}
                maximumValue={30}
                onValueChange={setValue}
                minimumTrackTintColor={colors.hookhubOrange}
                maximumTrackTintColor={colors.amplifyNeutral60}
                thumbTintColor={colors.hookhubOrangeDark}
                style={{ width: width, height: 30 }}
            />
        </View>
    )
}

export default CustomSlider;
