import { useNavigation } from "@react-navigation/native";
import { AntDesign, FontAwesome5 } from "@expo/vector-icons";
import { Platform, Pressable, Text, View } from "react-native";
import { colors } from "../../styles/colors"
import { useSelector } from "react-redux";
import { globalStyles } from "../../styles/styles";
import CustomDivider from "../CustomDivider";
import PrivacyTermsSitemapFooter from "./PrivacyTermsSitemapFooter";


const FooterView = () => {
    const navigation = useNavigation();
    const { mobileView } = useSelector(state => state.currentUser);

    return (
        <View style={[mobileView ? globalStyles.flexStartColumn : globalStyles.centerColumn, { backgroundColor: colors.amplifyNeutral20, paddingBottom: 30 }]}>
            <View>
                <View style={[mobileView ? globalStyles.flexStartColumn : globalStyles.center, { margin: 20, flexWrap: "wrap" }]}>
                    <View style={[globalStyles.flexStartColumn, { marginVertical: 10, width: 200 }]}>
                        <Text style={{ fontWeight: "bold", marginBottom: 2 }}>Support</Text>
                        <Pressable onPress={() => navigation.reset({ routes: [{ name: 'help' }] })}><Text>Help Center</Text></Pressable>
                    </View>
                    <View style={[globalStyles.flexStartColumn, { marginVertical: 10, width: 200 }]}>
                        <Text style={{ fontWeight: "bold", marginBottom: 2 }}>Hosting</Text>
                        <Pressable onPress={() => navigation.reset({ routes: [{ name: 'host' }] })}><Text>Hosting resources</Text></Pressable>
                    </View>
                    <View style={[globalStyles.flexStartColumn, { marginVertical: 10, width: 200 }]}>
                        <Text style={{ fontWeight: "bold", marginBottom: 2 }}>Hookhub</Text>
                        <Pressable onPress={() => navigation.reset({ routes: [{ name: 'about' }] })}><Text>About</Text></Pressable>
                        {/* <Pressable onPress={() => navigation.reset({ routes: [{ name: 'help' }] })}><Text>Investors</Text></Pressable> */}
                    </View>
                </View>
                <View style={{ margin: 20 }}>
                    {Platform.OS === 'web' ? (
                        <a href="https://www.facebook.com/profile.php?id=100093356511676" target="_blank">
                            <AntDesign name="facebook-square" size={24} color="black" />
                        </a>
                    ) : (
                        <>
                            {/* <Pressable onPress={() => }><Text><AntDesign name="facebook-square" size={24} color="black" /></Text></Pressable> */}
                        </>
                    )}
                </View>
            </View>
            <CustomDivider marginVertical={5} />
            <View style={[mobileView ? globalStyles.flexStartColumn : globalStyles.center, { marginVertical: 20 }]}>
                <Text style={{ color: colors.amplifyNeutral90, marginLeft: 15 }}> &copy; {"2024 Hookhub, Inc."}</Text>
                <PrivacyTermsSitemapFooter />

                {Platform.OS === 'web' ? (
                    <View style={[globalStyles.center]}>
                        <a href="https://www.hookhub.co/security" target="_blank" style={{ marginLeft: 20, paddingRight: 10 }}>
                            <Text>Your Privacy and Security</Text>
                        </a>
                        <FontAwesome5 name="user-shield" size={18} color="royalblue" />
                    </View>
                ) : (<></>)}
            </View>
        </View >
    )
}

export default FooterView;
