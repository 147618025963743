import React, { useContext, useState, useEffect } from "react";
import { StyleSheet, Text, View, useWindowDimensions } from "react-native";
import { BookingsContext } from "../../utils/BookingsContextProvider";
import getEnvVars from '../../../environment';
const { REACT_APP_ENV } = getEnvVars();
import { useSelector } from "react-redux";
import moment from "moment";
import BookingHostView from "./BookingHostView";
import { AntDesign, MaterialCommunityIcons } from '@expo/vector-icons';
import { formatDate } from "../../utils/formatDate";
import Flex from "../CustomElements/Flex";
import Card from "../CustomElements/Card";
import Header from "../CustomElements/Header";
import { colors } from "../../styles/colors"
import CustomButton from "../CustomElements/CustomButton";
import CustomModal from "../CustomElements/CustomModal";
import BookingRenterView from "./BookingRenterView";
import CloseButtonUnderlined from "../CustomElements/CloseButtonUnderlined";
import LoadingBar from "../CustomElements/LoadingBar";
import { ErrorDisplay } from "../CustomElements/ErrorDisplay";
import { sendRequestDeniedEmail } from "../../lambdaFunctions/sendEmail/sendRequestDecisionEmail";
import { getUserDetails } from "../../api/user";
import { updateBookingMutation } from "../../api/bookings";
import { notifyHookhub } from "../../lambdaFunctions/notifyHookhub";
import HelpForm from "../other/HelpForm";


const BookingModal = ({ ImTheHost, modalVisible, setModalVisible, bookingDetails, listReservations = null, navigate }) => {
    const {
        id,
        cancelled,
        nextPaymentsSchedule,
        nextPaymentDue,
        numberOfMonths,
        bookingRequest,
        renterID,
        renterEmail,
        bookedDays,
        renterTotalBookingCost,
        payMonthly,
        stripePaymentIntentID,
        full_refund_enabled,
        half_refund_date,
        half_refund_enabled,
        full_refund_date,
        monthlyPaymentAmount,
        hostPayout,
        monthlyHostPayout,
        cancelledDate,
        paymentSchedule
    } = bookingDetails;
    const {
        check_in,
        check_out,
        propertyID
    } = bookingRequest;
    const { mobileView } = useSelector(state => state.currentUser);
    const { bookingRequests, bookings } = useContext(BookingsContext);
    const { bookingRequestsArray } = bookingRequests;
    const { setBookingsArray } = bookings;
    const [areYouSure, setAreYouSure] = useState(false);
    const [cancelText, setCancelText] = useState(false);
    const [cancelDate, setcancelDate] = useState(null);
    const [displayCancelDate, setdisplayCancelDate] = useState(null);
    const [refundAmount, setRefundAmount] = useState(null);
    const [tooLate, setTooLate] = useState(false);
    const [cancelledTooLate, setCancelledTooLate] = useState(false);
    const [cancelledTooLatePayout, setcancelledTooLatePayout] = useState(null);
    const [cancelledDaysHostPayout, setcancelledDaysHostPayout] = useState(null);
    const [cancelledFinalPayment, setcancelledFinalPayment] = useState(null);
    const [nextMonthlyPaymentDueDate, setNextMonthlyPaymentDueDate] = useState(nextPaymentDue);
    const [cancelledMoreThan7DaysBeforeCheckIn, setCancelledMoreThan7DaysBeforeCheckIn] = useState(false);
    const today = moment();
    const [renter, setRenter] = useState(null);
    const { width } = useWindowDimensions();
    const updateBookings = v => setBookingsArray(v);
    const [loading, setLoading] = useState(false);
    const [loadingPercentage, setLoadingPercentage] = useState('0%');
    const [loadingStatus, setLoadingStatus] = useState("");
    const [error, setError] = useState(false);
    const [showHelpEmail, setShowHelpEmail] = useState(false)

    const getRenter = async () => {
        const user = await getUserDetails(renterID);
        if (user) {
            setRenter(user);
        }
    };

    const closeThis = () => {
        setAreYouSure(false);
        setLoading(false);
        setError(false);
        setModalVisible(!modalVisible);
    };

    const cancelBooking = async () => {
        setLoading(true);
        setLoadingStatus('Cancelling Booking...')
        setLoadingPercentage("35%")
        const data = {
            id: id,
            cancelled: true,
            nextPaymentsSchedule: [],
            nextPaymentDue: nextMonthlyPaymentDueDate,
            cancelledDate: cancelDate,
            cancelledBeforeCheckIn: cancelledMoreThan7DaysBeforeCheckIn,
            // ONLY if cancelled BEFORE check_in
            refundRenter: refundAmount,
            cancelledTooLatePayout: cancelledTooLatePayout,
            cancelledTooLate: cancelledTooLate,
            // ONLY if cancelled AFTER check_in && payMonthly
            cancelledFinalHostPayout: cancelledDaysHostPayout,
            cancelledFinalRenterPayment: cancelledFinalPayment,
        };
        let updatedBooking = await updateBookingMutation({ data })
        if (updatedBooking) {
            const bookingData = v.data.updateBooking;
            const newArr = bookingRequestsArray.filter(function (el) { return el.id !== id; });
            updateBookings(newArr);
            !!refundAmount ? refundPaymentToRenter(bookingData) : sendEmail(bookingData);
        } else {
            console.log("Error CANCELLING Booking: ", e)
            setError("Error CANCELLING booking. Please reach out to Hookhub.");
            setModalVisible(false);
            setLoading(false);
        }
    };

    const refundPaymentToRenter = async (bookingData) => {
        setLoadingStatus('Sending Refund...')
        setLoadingPercentage("65%")
        const requestOptions = {
            method: 'POST',
            body: JSON.stringify({
                bookingID: id,
                paymentIntentID: stripePaymentIntentID,
                amount: refundAmount,
                enviro: REACT_APP_ENV
            })
        };
        fetch('https://4tsiiwmjahyvm3w773zxfzgwru0tpreq.lambda-url.us-west-2.on.aws/', requestOptions)
            .then(response => response.json())
            .then(data => {
                console.log("* refund successful ", data)
                !!listReservations && listReservations();
                sendEmail(bookingData);
            }).catch((e) => {
                console.log("ERROR making payment >>   ", e)
                setError("Error refunding payment. Please reach out to Hookhub.");
                setModalVisible(false);
                setLoading(false);
                setAreYouSure(false)
                console.log("ERROR making payment >>   ", e)
                const subject = `ERROR refunding renter after booking cancelled`
                const body = `BOOKING REFUND failed. ERROR: Booking ID: ${bookingData.id}. ////////////  REFUND AMOUNT: $ ${refund} to RENTER: ${renterID} ////////////////////////  Booking cancel attempted by HOST ////////////   BOOKING DATA: ${bookingData} **************     ////////////   ERROR: ${e}`
                notifyHookhub({ subject, body, userID: hostID, userEmail: hostEmail })

            })
    };

    const sendEmail = async (bookingData) => {
        setLoadingStatus('Sending Emails...')
        setLoadingPercentage("85%")
        // sendEmailsBookingCancelled({ bookingData: bookingData, cancelEmailTextHost, cancelEmailTextRenter });
        const requestOptions = {
            method: 'POST',
            body: JSON.stringify({
                recordData: bookingData,
                checkOutDate: displayCancelDate,
                checkInDate: formatDate(bookingData.check_in),
                recordType: 'delete'
            })
        };
        sendRequestDeniedEmail({ requestOptions, bookingData, renterID, renterEmail })
    }

    const cancelBeforeCheckIn = () => {
        let moreThan7DaysTillCheckIn = (moment(check_in).diff(today, 'days') > 7);

        if (moreThan7DaysTillCheckIn) {
            setCancelledMoreThan7DaysBeforeCheckIn(true);
            // ***************************************************************************************************************
            // ****************   (today > 7(days) from check_in)  ******************************************************************************
            // RENTER
            //      refundAmount = refer to host's cancellation policy and refund Renter % inital payment (if applicable)
            //      cancelledTooLatePayout = refer to host's cancellation policy and set payout Host (if applicable)
            //      cancelledTooLate =
            // HOST
            //      refundAmount = refund full initial payment to renter
            //--------------------------------------------------------------------------
            // BOOKING update:
            //      - nextPaymentsSchedule: []
            //      - cancelDate: null
            //      - refundRenter: refundAmount
            //      - cancelled: true
            //      - cancelledTooLatePayout: cancelledTooLatePayout
            //      - cancelledTooLate: cancelledTooLate
            //      - cancelledFinalHostPayout: null 
            //      - canecelledFinalRenterPayment: null
            // EB: cancelledTooLate ? schedule_sendPayoutBookingCancelledTooLate : n/a
            // Lambda: cancelledTooLate ? stripePayout_cancelledTooLate_bookings : n/a
            // ***************************************************************************************************************
            let text;
            let refund = 0;
            if (ImTheHost) {
                text = `IF you cancel today you will receive no payment for the booking, a total of $ ${Number(hostPayout).toLocaleString("en-US", { minimumFractionDigits: 2 })}`;
                refund = payMonthly ? monthlyPaymentAmount : renterTotalBookingCost;
            } else {
                if (full_refund_enabled) {
                    let days = moment(full_refund_date).diff(today, 'days');
                    if (days > 0) {
                        refund = payMonthly ? monthlyPaymentAmount : renterTotalBookingCost;
                        text = `IF you cancel today, you will receive 100% refund${payMonthly ? " from your initial payment" : ""}, a total of $ ${Number(refund).toLocaleString("en-US", { minimumFractionDigits: 2 })}${payMonthly ? (` All following payments will be cancelled.`) : (`.`)}`;
                        setcancelledTooLatePayout(null);
                        setCancelledTooLate(false);
                    }
                }
                if (!text && half_refund_enabled) {
                    let days = moment(half_refund_date).diff(today, 'days');
                    if (days > 0) {
                        const tooLatePayout = ((payMonthly ? monthlyHostPayout : hostPayout) / 2).toFixed(2);
                        refund = ((payMonthly ? monthlyPaymentAmount : renterTotalBookingCost) / 2).toFixed(2);
                        setcancelledTooLatePayout(tooLatePayout);
                        setCancelledTooLate(true);
                        text = `IF you cancel today, you will get a 50% refund${payMonthly ? " from your initial payment" : ""}, a total of $ ${refund}.${payMonthly ? (` All following payments will be cancelled.`) : (``)}`
                    }
                }
                if (!text) {
                    const tooLatePayout = payMonthly ? monthlyHostPayout : hostPayout;
                    refund = null;
                    setcancelledTooLatePayout(tooLatePayout);
                    setCancelledTooLate(true);
                    text = `Per this booking cancellation policy, NO refund ${payMonthly ? " from your initial payment" : ""} is available when this booking is cancelled${payMonthly ? (` All following payments will be cancelled.`) : (`.`)}`
                }
            }
            setNextMonthlyPaymentDueDate(null);
            setCancelText(text)
            setRefundAmount(Number(refund));
        } else {
            if (payMonthly && numberOfMonths > 1) {
                // ***************************************************************************************************************
                // ****************   (today < 7(days) from check_in)  ******************************************************************************
                // No refund on intial payment && stripe subscription deleted
                //
                // BOOKING update:
                //      - nextPaymentsSchedule: []
                //      - cancelledTooLate: true
                //      - cancelledTooLatePayout: monthlyHostPayout
                //      - cancelDate(booking check out): nextPaymentDue date
                // EB: schedule_sendPayoutBookingCancelledTooLate
                // Lambda: stripePayout_cancelledTooLate_bookings
                // ***************************************************************************************************************
                let text = ImTheHost ?
                    `IF you cancel now, the reservation will be cancelled 28 days after check_in and all following payments after the check_in payment will be cancelled.`
                    : `IF you cancel now, no refund is available for the initial payment, however all following payments will be cancelled.`
                setcancelDate(nextPaymentDue);
                const formattedDate = formatDate(nextPaymentDue)
                setdisplayCancelDate(formattedDate);
                setCancelText(text)
                setRefundAmount(null)
                setNextMonthlyPaymentDueDate(null)
                setcancelledTooLatePayout(monthlyHostPayout);
                setCancelledTooLate(true);
                setTooLate(false)
            } else {
                setCancelText("Check-in date is less than 7 days away. No cancellation available.")
                setTooLate(true)
            }
        }
    };

    const cancelAfterCheckIn = () => {
        let text;
        let tooLateAfterCheckIn;
        let daysLeft = moment(check_out).diff(today, 'days');

        // ***************************************************************************************************************
        // ****************  after check_in  ******************************************************************************
        // payMonthly ONLY  &&  (check_out > 28 days away)
        //--------------------------------------------------------------------------
        // BOOKING update:
        //      - cancelled: true
        //      - nextPaymentDue: nextPaymentDue || null
        //      - nextPaymentsSchedule: []
        //      - cancelDate: 28 days from Today
        //      - cancelledFinalHostPayout: # 
        //      - canecelledFinalRenterPayment: #
        //      - cancelledTooLate: false
        //      - cancelledTooLatePayout: null
        //      - refundRenter: null
        // EB: cancelledTooLate ? schedule_sendPayoutBookingCancelledTooLate : n/a
        // Lambda: cancelledTooLate ? stripePayout_cancelledTooLate_bookings : n/a
        // ***************************************************************************************************************

        const theCancelDate = moment(moment(today).add(28, 'days')).format("YYYY-MM-DD");
        setcancelDate(theCancelDate);

        if (payMonthly && daysLeft > 31 && numberOfMonths > 1) {
            let finalPayment;
            const formattedDate = formatDate(nextPaymentDue);
            setdisplayCancelDate(formattedDate);
            const dailyCost = renterTotalBookingCost / bookedDays.length;
            const dailyPayout = hostPayout / bookedDays.length;

            tooLateAfterCheckIn = false;
            const lastDaysBeforeCancel = (moment(theCancelDate).diff(nextPaymentDue, 'days'))
            if (lastDaysBeforeCancel > 0) {
                const finalPayout = lastDaysBeforeCancel * dailyPayout;
                finalPayment = lastDaysBeforeCancel * dailyCost;
                setcancelledDaysHostPayout(finalPayout);
                setcancelledFinalPayment(finalPayment);
            } else {
                setNextMonthlyPaymentDueDate(null)
            }
            text = ImTheHost ?
                `IF you cancel today, you will not recieve payment for the days after ${formattedDate} from the renter.`
                : `IF you cancel today, the payments for all days after: ${formattedDate} will be cancelled. ${(lastDaysBeforeCancel > 0) ? `Your last payment is still set for: ${formatDate(nextPaymentDue)} in the amount of: $ ${Number(finalPayment).toLocaleString("en-US", { minimumFractionDigits: 2, maximumFractionDigits: 2 })}` : ""}`;
        } else {
            tooLateAfterCheckIn = true;
            text = payMonthly ?
                "The check-out date is a month or less away. No cancellation is possible." :
                "Booking has already started. No cancellation is possible."
        }
        setTooLate(tooLateAfterCheckIn)
        setCancelText(text)
        setCancelledMoreThan7DaysBeforeCheckIn(false);
    }

    const checkCancellationAbility = () => {
        if (!cancelled) {
            const beforeBookingStartDate = (moment(check_in).diff(today, 'days') > 0);
            beforeBookingStartDate ? cancelBeforeCheckIn() : cancelAfterCheckIn();
        }
    }

    useEffect(() => {
        getRenter()
    }, []);

    // useEffect(() => {
    //     if (modalVisible) {
    //         checkCancellationAbility()
    //     }
    // }, [modalVisible]);

    return (
        <CustomModal modalVisible={modalVisible} setModalVisible={() => setModalVisible()} modalWidth={width / 1.15}>
            {areYouSure ? (
                <Flex direction="column" alignItems="center" marginTop={mobileView ? 5 : 20} padding={9}>
                    <Header textAlign="center" level={1.2} color={'#000'} text="Are you sure?"></Header>
                    <Card
                        backgroundColor={colors.mellowOrange}
                        alignItems="center"
                        width={width / 1.3}
                        textAlign="center"
                    >
                        <Header color={'#000'} textAlign="center" width={width / 1.5} text={cancelText}></Header>
                        {!cancelledMoreThan7DaysBeforeCheckIn && <Header textAlign="center" width={width / 1.5} text={`New check-out date:  ${displayCancelDate}`}></Header>}
                    </Card>
                    {ImTheHost ? (
                        <>
                            <Text textAlign="center" margin={5}>Cancellation policy for host: </Text>
                            <Flex width={width / 1.2} marginLeft={5}>
                                {!mobileView && <MaterialCommunityIcons name="cash-refund" size={24} color="black" />}
                                <Text>As a host, you can cancel a booking up to 1 week before the check-in date. If the booking is more than a month long, you can cancel the booking after check-in, as long as it is still 28 days before the check-out date. If you cancel today, the new check-out date will be 28 days from today.</Text>
                            </Flex>
                        </>
                    ) : (
                        <>
                            <Header color={colors.amplifyNeutral90} marginTop={25} text="The Host's cancellation policy:" />
                            {!!full_refund_enabled &&
                                <Flex width={width / 1.3} justifyContent="flex-start" alignItems="flex-start" marginTop={9} marginLeft={5}>
                                    <MaterialCommunityIcons name="cash-refund" size={29} color="black" />
                                    <Text>Cancel by <Text style={{ fontWeight: "bold" }}>{full_refund_date}</Text> to receive a <Text style={{ fontWeight: "bold" }}>FULL REFUND</Text>{payMonthly && ' of initial payment'}.</Text>
                                </Flex>
                            }
                            {!!half_refund_enabled &&
                                <Flex width={width / 1.3} justifyContent="flex-start" alignItems="flex-start" marginTop={9} marginLeft={5}>
                                    <MaterialCommunityIcons name="cash-refund" size={29} color="black" />
                                    <Text>Cancel by <Text style={{ fontWeight: "bold" }}>{half_refund_date}</Text> to receive a <Text style={{ fontWeight: "bold" }}>50% REFUND</Text>{payMonthly && ' of initial payment'}.</Text>
                                </Flex>
                            }
                            {!half_refund_enabled && !full_refund_enabled &&
                                <Flex width={width / 1.3} justifyContent="flex-start" alignItems="flex-start" marginTop={9} marginLeft={5}>
                                    <MaterialCommunityIcons name="cash-refund" size={29} color="black" />
                                    <Text>No refund {payMonthly && 'of initial payment'} is available if this booking is cancelled.</Text>
                                </Flex>
                            }
                        </>
                    )}
                    {loading ? (
                        <LoadingBar loadingStatus={loadingStatus} loadingPercentage={loadingPercentage} />
                    ) : (
                        <>
                            <CustomButton
                                width={170}
                                backgroundColor={colors.hookhubOrangeDark}
                                marginTop={20}
                                border={colors.amplifyNeutral90}
                                onPress={() => cancelBooking()}
                                disabled={tooLate ? true : false}
                            >
                                <Text style={{ fontSize: 17 }}>Yes, cancel rental</Text>
                            </CustomButton>
                            <CustomButton
                                width={170}
                                backgroundColor={colors.brandGrey}
                                marginTop={5}
                                marginBottom={15}
                                border={colors.amplifyNeutral90}
                                onPress={() => closeThis()}
                            >
                                <Text style={{ fontSize: 17 }}>close</Text>
                            </CustomButton>
                        </>
                    )}
                </Flex>
            ) : (
                <>
                    {ImTheHost ?
                        <BookingHostView
                            error={error}
                            cancelledDate={cancelledDate}
                            loading={loading}
                            cancelled={cancelled}
                            width={width}
                            mobileView={mobileView}
                            requestDetails={bookingDetails.bookingRequest}
                            renter={renter}
                            closeModal={() => setModalVisible(false)}
                            showBookingRequest={false}
                            spaceTitle={bookingRequest.spaceTitle}
                            propertyTitle={bookingRequest.propertyTitle}
                        />
                        : (
                            <BookingRenterView
                                mobileView={mobileView}
                                cancelled={cancelled}
                                bookingToDisplay={bookingDetails}
                                width={width}
                                closeModal={() => setModalVisible()}
                                spaceTitle={bookingRequest.spaceTitle}
                                propertyTitle={bookingRequest.propertyTitle}
                            />
                        )
                    }
                    {error ? (
                        <ErrorDisplay error="Error cancelling Rental" />
                    ) : (
                        <Flex justifyContent="center" alignItems="center" alignContent="center" direction="column" width={width / 1.5} marginBottom={20} marginTop={5}>
                            {tooLate &&
                                <View style={styles.tooLate}>
                                    <Text style={{ textAlign: "center" }}>This booking can no longer be cancelled. Start date is too close or has passed.</Text>
                                </View>
                            }
                            <Flex
                                wrap="wrap"
                                direction="column"
                                justifyContent="center"
                                alignItems="center"
                                alignContent="center"
                                marginTop={25}
                            >
                                {!!navigate && (
                                    <CustomButton
                                        width={150}
                                        backgroundColor={colors.brightBlue}
                                        border={colors.medBlue}
                                        color="#fff"
                                        onPress={() => navigate(propertyID)}
                                    >
                                        <Flex
                                            justifyContent="center"
                                            alignItems="center"
                                            wrap="wrap"
                                            alignContent="center"
                                            textAlign="center"
                                        >
                                            <Text style={{ fontSize: 17 }}>space details</Text>
                                            <AntDesign name="caretright" size={!!mobileView ? 15 : 18} color="#363636" />
                                        </Flex>
                                    </CustomButton>
                                )}
                                {!tooLate && !!ImTheHost && <Text padding={5}>* host can cancel rental up to 1 week prior to check-in date</Text>}
                                {!cancelled && (
                                    <>
                                        {showHelpEmail ? (
                                            <>
                                                <Text style={{ fontSize: 17, marginTop: 15 }}>If you need to cancel- reach out to us!</Text>
                                                <HelpForm />
                                            </>
                                        ) : (
                                            <CustomButton
                                                width={150}
                                                marginTop={7}
                                                backgroundColor={colors.amplifyNeutral60}
                                                color="#fff"
                                                border={colors.amplifyNeutral90}
                                                // onPress={() => setAreYouSure(true)}
                                                disabled={tooLate ? true : false}
                                                onPress={() => setShowHelpEmail(true)}
                                            >
                                                <Text style={{ fontSize: 17 }}>cancel rental</Text>
                                            </CustomButton>
                                        )}
                                    </>
                                )}
                                <CloseButtonUnderlined onPress={() => closeThis()} />
                            </Flex>
                        </Flex>
                    )}
                </>
            )}
        </CustomModal>
    );
};

const styles = StyleSheet.create({
    centeredView: {
        flex: 1,
        justifyContent: "center",
        alignItems: "center",
    },
    feeCustomButton: {
        textDecorationLine: "underline"
    },
    tooLate: {
        padding: 6,
        backgroundColor: colors.mellowOrange,
        borderRadius: 15,
        marginBottom: 15
    },
    modalView: {
        marginTop: 10,
        marginBottom: 15,
        backgroundColor: "#c4c4c4",
        borderRadius: 10,
        alignItems: "center",
        shadowColor: "#000",
        shadowOffset: {
            width: 0,
            height: 2,
        },
        shadowOpacity: 0.25,
        shadowRadius: 4,
        elevation: 5,
    },
    approveCustomButton: {
        borderRadius: 12,
        padding: 15,
        elevation: 5,
        backgroundColor: "#599cde",
        shadowColor: "grey",
        shadowOffset: {
            width: 1,
            height: 3,
        },
        shadowOpacity: 0.45,
        shadowRadius: 4,
    },
    denyCustomButton: {
        borderRadius: 12,
        backgroundColor: "#7a7a7a",
        height: 50,
        paddingTop: 5,
        paddingBottom: 5,
        paddingLeft: 50,
        paddingRight: 50,
        shadowColor: 'rgba(0, 0, 0, 0.5)',
        shadowOpacity: 0.8,
        elevation: 6,
        shadowRadius: 15,
        shadowOffset: { width: 1, height: 13 },
    },
    closeCustomButton: {
        padding: 10,
        marginBottom: 15
    },
    textStyle: {
        color: "white",
        fontWeight: "bold",
        textAlign: "center"
    },
    denyTextStyle: {
        color: "#292929",
        textAlign: "center",
        fontWeight: "bold",
    },
    closeText: {
        fontWeight: "bold",
        textDecorationLine: "underline",
        fontSize: 17
    },
    modalText: {
        marginBottom: 15,
        textAlign: "center"
    },
    title: {
        textAlign: "left",
        width: '100%',
        backgroundColor: "#2196F3"
    },
});

export default BookingModal;
