import {
    StyleSheet,
    Pressable,
    Text
} from 'react-native';

const CancelButton = ({
    disabled,
    onPress,
}) => {

    return (
        <Pressable onPress={() => onPress()} disabled={disabled} style={styles.backButton}>
            <Text style={styles.backButtonText}>Cancel</Text>
        </Pressable>
    )

}

const styles = StyleSheet.create({
    backButtonText: {
        color: "#484a4a",
        fontSize: 17,
        fontWeight: "bold",
    },
    backButton: {
        alignItems: "center",
        borderBottomWidth: 1,
        justifyContent: "center",
        alignSelf: "center",
        height: 30,
    },
})
export default CancelButton;