import React, { useState } from "react";
import { StyleSheet, View, Pressable, useWindowDimensions, Text } from "react-native";
import Flex from "../CustomElements/Flex"
import { AntDesign } from '@expo/vector-icons';
import { colors } from "../../styles/colors";


export const ServiceFeeExplanationModal = () => {
    const [viewDescription, setviewDescription] = useState(false);
    const { width } = useWindowDimensions();

    return (
        <Flex wrap="wrap" justifyContent="center" alignContent="center">
            {viewDescription ? (
                <Flex direction="column" wrap="wrap">
                    <Pressable onPress={() => setviewDescription(false)}>
                        <View style={styles.modalView}>
                            <Flex justifyContent="flex-start">
                                <AntDesign name="closecircleo" size={24} color="black" />
                                <Text style={{marginLeft:6, textAlign:"center"}}>10% for the Hookhub service charge. This helps us run the hookhub platform and provide our 24/7 services.</Text>
                            </Flex>
                        </View>
                    </Pressable>
                </Flex>
            ) : (
                <Pressable onPress={() => setviewDescription(true)} style={{ textDecorationLine: "underline" }}>
                    <Text style={{textAlign:"center",width:width/1.1,wrap:"wrap", marginTop:5, fontSize:17}}>* Your total income will be deducted by 10% for the Hookhub service charge.</Text>
                </Pressable>
            )}
        </Flex>
    );
};

const styles = StyleSheet.create({
    modalView: {
        backgroundColor: colors.lightBlue,
        borderRadius: 10,
        marginTop: 4,
        alignItems: "center",
        shadowColor: "grey",
        textAlign: "left",
        shadowOffset: {
            width: 2,
            height: 4
        },
        shadowOpacity: 0.85,
        shadowRadius: 4,
        elevation: 5,
        padding: 4
    }
});
