import { createSpace, deleteSpace, updateSpace } from "../graphql/mutations";
import { getProperty, getSpace } from "../graphql/queries";
import { API } from "aws-amplify";
import { notifyHookhub } from "../lambdaFunctions/notifyHookhub";


export const getSpaceDetails = async (spaceID) => {
    const space = await API.graphql({
        query: getSpace,
        variables: { id: spaceID },
        authMode: "API_KEY",
    })
    let spaceData = space?.data?.getSpace;
    return { ...spaceData };

};

export const getSpaceAndPropertyDetails = async (spaceID) => {
    const space = await API.graphql({
        query: getSpace,
        variables: { id: spaceID },
        authMode: "API_KEY",
    })
    let spaceData = space?.data?.getSpace;
    if (!!spaceData) {
        const property = await API.graphql({
            query: getProperty,
            variables: { id: spaceData.propertyID },
            authMode: "API_KEY",
        })

        return { ...spaceData, property: property?.data?.getProperty };
    }
};

export const deleteSpaceMutation = async (spaceID) => {
    try {
        let response = await API.graphql({
            query: deleteSpace,
            variables: {
                input: {
                    id: spaceID
                }
            },
            authMode: "AMAZON_COGNITO_USER_POOLS",
        })
        return response?.data?.deleteSpace
    } catch {
        return false
    }
};

export const deactivateSpaceMutation = async (spaceID) => {
    try {
        let response = await API.graphql({
            query: updateSpace,
            variables: {
                input: {
                    id: spaceID,
                    active: false
                }
            },
            authMode: "AMAZON_COGNITO_USER_POOLS",
        })
        return response?.data?.updateSpace
    } catch {
        return false
    }
};

export const reactivateSpaceMutation = async (spaceID) => {
    try {
        let response = await API.graphql({
            query: updateSpace,
            variables: {
                input: {
                    id: spaceID,
                    active: true
                }
            },
            authMode: "AMAZON_COGNITO_USER_POOLS",
        })
        return response?.data?.updateSpace
    } catch {
        return false
    }
};

export const createSpaceMutation = async ({ data, userEmail }) => {
    const space = await API.graphql({
        query: createSpace,
        variables: { input: data },
        authMode: "AMAZON_COGNITO_USER_POOLS",
    }).then((r) => {
        return r.data.createSpace;
    }).catch((e) => {
        console.log("e in create space >>  ", e)
        let subject = `ERROR creating SPACE`
        let body = `PropertyID: ${data.propertyID}.   ERROR: ${e.errors[0].message}`
        notifyHookhub({ subject, body, userID: data.ownerID, userEmail: userEmail })
        return false
    })
    return space;
};

export const updateSpaceMutation = async ({ data }) => {
    const space = await API.graphql({
        query: updateSpace,
        variables: { input: data },
        authMode: "AMAZON_COGNITO_USER_POOLS",
    }).then((r) => {
        return r.data.updateSpace;
    }).catch((e) => {
        return false
    })
    return space
};