import React, { useState, useEffect, useContext } from "react";
import {
  StyleSheet,
  Text as ReactText,
  Pressable,
  useWindowDimensions,
} from "react-native";
import { useNavigation } from "@react-navigation/native";
import Flex from "../CustomElements/Flex"
import Header from "../CustomElements/Header"
import Card from "../CustomElements/Card"
import { Ionicons } from '@expo/vector-icons';
import { useSelector } from "react-redux";
import { UserDataContext } from "../../utils/UserContextProvider";
import { colors } from "../../styles/colors"
import CustomSection from "../CustomElements/CustomSection";


const MessagePreview = ({ conversation, mobileView, myName, width }) => {
  const { propertyTitle, propertyLocation, spaceTitle } = conversation;
  const navigation = useNavigation();
  const [othersName, setOthersName] = useState('')

  useEffect(() => {
    if (conversation && conversation.membersNames) {
      setOthersName(conversation.membersNames.find(x => x !== myName))
    }
  }, [conversation])

  const navigateToMessages = () => {
    navigation.reset({
      index: 0,
      routes: [
        {
          name: "messages",
          screen: "messages"
        }
      ]
    })
  }

  return (
    <Pressable onPress={() => (navigateToMessages())}>
      <Card
        height={14}
        backgroundColor={colors.amplifyNeutral20}
        border="#4c4e52"
        boxShadow="#4c4e52"
        width={width / 1.25}
        padding={10}
      >
        <Flex
          justifyContent="space-between"
          alignItems="center"
          alignContent="center"
          width={width / 1.4}
          wrap="wrap"
        >
          <Ionicons name="mail-unread" size={20} color="grey" />
          <ReactText
            style={styles.othersName}
          >
            {othersName.length > 12 ? `${othersName.slice(0, 12)}...` : othersName}
          </ReactText>
          {!mobileView && (
            <>
              <ReactText
                numberOfLines={1}
                ellipsizeMode="head"
                style={styles.propertyTitle}
              >
                RE:   {propertyTitle}
              </ReactText>
            </>
          )}
        </Flex>
      </Card>
    </Pressable>
  );
}

const MessagesPreview = ({ myUnreadMsgs }) => {
  const { user } = useContext(UserDataContext);
  const { userData } = user;
  const { mobileView } = useSelector(state => state.currentUser);
  const { width } = useWindowDimensions();

  return (
    <CustomSection>
      <Header
        textAlign="left"
        color="#4c4e52"
        paddingLeft={4}
        level={.9}
        marginTop={mobileView ? 10 : 15}
        text="UNREAD HOST MESSAGES"
      >
      </Header>
      <Flex marginTop={15} justifyContent="flex-start" alignContent="flex-start" alignItems="flex-start" direction="column">
        {myUnreadMsgs.map((v, i) => (
          <MessagePreview conversation={v} key={i} mobileView={mobileView} myName={userData.name} width={width} />
        ))}
      </Flex>
    </CustomSection>
  );
};

export default MessagesPreview;

const styles = StyleSheet.create({
  messagesView: {
    margin: 10,
    paddingBottom: 15,
    backgroundColor: "#fff",
    borderRadius: 20,
    justifyContent: "center",
    alignItems: "flex-start",
    shadowColor: "#000",
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity: 0.25,
    shadowRadius: 4,
    elevation: 5,
  },
  locationText: {
    color: "white",
    fontSize: 15,
  },
  propertyTitle: {
    color: "#3a3b3b",
    fontSize: 15,
    fontWeight: "bold"
  },
  othersName: {
    color: "#3a3b3b",
    fontSize: 15,
  },
  header: {
    color: "white",
    fontWeight: "bold",
    fontSize: 17,
  },
  headerUnselected: {
    color: "#3a3b3b",
    fontWeight: "bold",
    fontSize: 17,
  },
  avatarImage: {
    borderRadius: 100,
    width: 30,
    height: 30,
    borderWidth: 1,
  },
});
