import { createAddress } from "../graphql/mutations";
import { API } from "aws-amplify";
import { notifyHookhub } from "../lambdaFunctions/notifyHookhub";

export const createAddressMutation = async ({ data, userID, userEmail }) => {
    const property = await API.graphql({
        query: createAddress,
        variables: { input: data },
        authMode: "AMAZON_COGNITO_USER_POOLS",
    }).then((r) => {
        return r.data.createAddress
    }).catch((e) => {
        console.log("e in create address >>  ", e)
        let subject = `ERROR creating ADDRESS`
        let body = `ERROR: ${e.errors[0].message}`
        notifyHookhub({ subject, body, userID: userID, userEmail: userEmail })
        return false
    })
    return property;
};