import { createRig, updateRig } from "../graphql/mutations";
import { API } from "aws-amplify";


export const createRigMutation = async ({ data }) => {
    let updatedRig = await API.graphql({
        query: createRig,
        variables: { input: data },
        authMode: "AMAZON_COGNITO_USER_POOLS",
    }).then((r) => {
        return r.data.createRig;
    }).catch((e) => {
        console.log("e in here >>  ", e)
        return false;
    })
    return updatedRig;
};

export const updateRigMutation = async ({ data }) => {
    let updatedRig = await API.graphql({
        query: updateRig,
        variables: { input: data },
        authMode: "AMAZON_COGNITO_USER_POOLS",
    }).then((r) => {
        return r.data.updateRig;
    }).catch((e) => {
        console.log("e in here >>  ", e)
        return false;
    })
    return updatedRig;
};
