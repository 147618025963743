import React from "react";
import { Text, View, useWindowDimensions } from "react-native";
import Card from "./Card";
import { colors } from "../../styles/colors";
import { Ionicons, MaterialCommunityIcons } from "@expo/vector-icons";

export const ErrorDisplay = ({ error, customWidth }) => {
    const { width } = useWindowDimensions();
    return (
        <Card
            backgroundColor={colors.hookhubOrangeDark}
            alignItems="center"
            textAlign="center"
            justifyContent="center"
            alignContent="center"
            marginTop={20}
            marginBottom={20}
            width={customWidth ? customWidth : width / 1.3}
        >
            <View style={{ flexDirection: 'column', justifyContent: 'center', alignContent: "center", alignItems: "center", padding:10 }}>
                <Ionicons name="alert-circle-outline" size={40} color="black" />
                <Text style={{ fontSize: 17, textAlign: "center" }}>{error}</Text>
            </View>
        </Card>
    );
};




