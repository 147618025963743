import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from 'react-redux';
import HostOnboarding from "../../components/host/HostOnboarding";
import { setSpaceType } from "../../redux/actions/HostOnboardingActions";
import SpaceTypes from "../../components/space/SpaceTypes";
import { useNavigation } from "@react-navigation/native";

function HostSpaceScreen () {
  const [disabled, setDisabled] = useState(false);
  const navigation = useNavigation();
  const {type_shortterm, type_longterm, type_yearly, type_storage, newProperty, additionalSpacesAlteredData } = useSelector(state => state.hostOnboarding);
  const [shortterm, setshortterm] = useState(type_shortterm || false);
  const [longterm, setlongterm] = useState(type_longterm || false);
  const [yearly, setyearly] = useState(type_yearly || false);
  const [storage, setstorage] = useState(type_storage || false);
  const dispatch = useDispatch();

  useEffect(() => {
    if (shortterm || longterm || storage || yearly) {
      setDisabled(false)
    } else {
      setDisabled(true)
    }
  }, [shortterm, longterm, storage, yearly]);

  const setSpace = () => {
    dispatch(setSpaceType({
      short: shortterm,
      long: longterm,
      yearly: yearly,
      storage: storage
    }));
  };

  return (
    <HostOnboarding
      heading="What kind of space will you offer?"
      navigateF={newProperty ? "SpaceDetails" : "SetDistances"}
      navigateB="AddTitle"
      nextButtonText="Next"
      disabledNextButton={disabled}
      onClickNext={() => setSpace()}
    >
      <SpaceTypes
        shortterm={shortterm}
        longterm={longterm}
        yearly={yearly}
        storage={storage}
        onShortChange={() => setshortterm(!shortterm)}
        onLongChange={() => setlongterm(!longterm)}
        onYearlyChange={() => setyearly(!yearly)}
        onStorageChange={() => setstorage(!storage)}
      />
    </HostOnboarding>
  )
};

export default HostSpaceScreen;
