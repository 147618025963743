import React, { useState, useEffect, useRef, useContext } from "react";
import { StyleSheet, ScrollView, useWindowDimensions, View, Text, Pressable } from "react-native";
import { useNavigation, useRoute } from "@react-navigation/native";
import Flex from "../../components/CustomElements/Flex";
import Header from "../../components/CustomElements/Header";
import Card from "../../components/CustomElements/Card";
import DetailedSpace from "../../components/space/DetailedSpace";
import EditMyProperty from "../../components/space/EditMyProperty";
import { AntDesign, Entypo, FontAwesome5, Ionicons, MaterialIcons } from "@expo/vector-icons";
import DeactivateOrDeletePropertyModal from "../../components/host/DeactivateOrDeletePropertyModal";
import { UserDataContext } from "../../utils/UserContextProvider";
import { useDispatch, useSelector } from "react-redux";
import { BookingsContext } from "../../utils/BookingsContextProvider";
import CustomButton from "../../components/CustomElements/CustomButton";
import { colors } from "../../styles/colors";
import { getPropertyDetails, reactivatePropertyMutation } from "../../api/property";
import { reactivateSpaceMutation } from "../../api/space";
import Badge from "../../components/CustomElements/Badge";
import ReserveCard from "../../components/booking/ReserveCard";
import { getUserDetails } from "../../api/user";
import { LoadingComponent } from "../../components/LoadingComponent";
import { setAuthed } from "../../redux/actions/CurrentUserActions";
import { SignUpModal } from "../../components/user/SignUpModal";
import FooterView from "../../components/other/FooterView";
import { globalStyles } from "../../styles/styles";

const BackToDetailedSpace = ({ cancelEditing, navToAddSpace, mobileView, width }) => {
  return (
    <Flex direction={mobileView ? "column" : "row"} marginTop={mobileView ? 3 : 20} marginBottom={10} marginLeft={!mobileView ? 8 : 0} justifyContent={!!mobileView ? "center" : "space-between"} alignItems="center" width={width / 1.05}>
      <CustomButton
        width={90}
        backgroundColor={colors.amplifyNeutral20}
        onPress={() => cancelEditing()}
      >
        <Flex justifyContent="center" alignContent="center" alignItems="center">
          <AntDesign name="caretleft" size={24} color="grey" />
          <Text style={{ marginLeft: 10 }}>back</Text>
        </Flex>
      </CustomButton>
      <CustomButton
        width={300}
        backgroundColor={colors.mellowOrange}
        border="#4c4e52"
        onPress={() => navToAddSpace()}
      >
        <Flex justifyContent="center" alignContent="center" alignItems="center">
          <MaterialIcons name="add" size={24} color={colors.amplifyNeutral90} />
          <Text style={{ fontSize: 17 }}>Add another space to property</Text>
        </Flex>
      </CustomButton>
    </Flex>
  )
}

function SpaceScreen() {
  const dispatch = useDispatch();
  const route = useRoute();
  const scrollRef = useRef(null);
  const hostingContext = useContext(BookingsContext);
  const navigation = useNavigation();
  const mySpaceData = hostingContext?.myHosting;
  const { mobileView } = useSelector(state => state.currentUser);
  const { width } = useWindowDimensions();
  const loggedInUser = useContext(UserDataContext);
  const [userData, setUserData] = useState(null);
  const [error, setError] = useState(false);
  const [mySpace, setMySpace] = useState(false);
  const [editSpace, setEditSpace] = useState(false);
  const [displayProperty, setDisplayProperty] = useState({});
  const [space, setSpace] = useState({});
  const [allSpaces, setAllSpaces] = useState([]);
  const [allActiveSpaces, setAllActiveSpaces] = useState([]);
  const [inactiveSpaces, setInactiveSpaces] = useState([]);
  const [spaceUpdated, setSpaceUpdated] = useState(false);
  const [imLoggedIn, setImLoggedIn] = useState(false);
  const [notFound, setNotFound] = useState(false);
  const [activeSpace, setActiveSpace] = useState(true);
  const [activeProperty, setActiveProperty] = useState(true);
  const [initialDate, setInitialDate] = useState("");
  const [currentDateSpan, setCurrentDateSpan] = useState([]);
  const [hostUser, setHostUser] = useState(null);
  const [loading, setLoading] = useState(false);
  const [spaceTypes, setSpaceTypes] = useState([]);
  const [calendarError, setCalendarError] = useState(null);
  const [displaySpace, setDisplaySpace] = useState({});
  const [signUpModalOpen, setSignUpModalOpen] = useState(false);
  const [deactivateOrDeletePropertyModalOpen, setDeactivateOrDeletePropertyModalOpen] = useState(false);
  const updateMySpaces = v => mySpaceData?.setMySpaces(v);
  const mobileReserveCardHeight = spaceTypes.length > 3 ? 120 : spaceTypes.length > 2 ? 105 : 90;

  const finishUpdate = () => {
    getPropertyData();
    setEditSpace(false);
    setSpaceUpdated(true);
  };

  const authenticate = () => {
    dispatch(setAuthed({ setAuthed: true }))
  }

  const scrollAndSetHeight = () => {
    scrollRef.current?.scrollTo({
      y: 0,
      animated: true,
    })
  };

  const goToHome = () => {
    navigation.reset({ routes: [{ name: 'home' }] })
  }

  const getPropertyData = async () => {
    setLoading(true)
    let myProperty = false;
    let spacesNotActive = [];
    let spacesActive = [];
    const spaceOptions = [];
    const propertyResponse = await getPropertyDetails(route.params.propertyID);
    if (!!propertyResponse.property) {
      const propertyData = propertyResponse.property;
      let spaces = propertyData.spaces.items;
      setAllSpaces(spaces)
      setSpace(spaces[0]);
      setActiveSpace(spaces[0].active)
      setActiveProperty(propertyData.active);

      if (!!loggedInUser && !!Object.keys(loggedInUser?.user?.userData).length) {
        const data = loggedInUser.user;
        setImLoggedIn(true);
        setUserData(data);
        myProperty = propertyData.ownerID === data.userData.sub;
      }
      setMySpace(myProperty);
      setNotFound(false);

      spaces.forEach(v => {
        if (!v.active) {
          spacesNotActive.push(v)
        } else {
          spacesActive.push(v)
        }
      });
      setInactiveSpaces(spacesNotActive)
      setAllActiveSpaces(spacesActive)
      setDisplaySpace(spacesActive[0])
      setDisplayProperty(propertyData);

      propertyData.type_shortterm && spaceOptions.push({
        title: 'Nightly',
        type: 'shortterm',
        price: propertyData.price_shortterm,
        increment: 'night',
      });
      propertyData.type_longterm && spaceOptions.push({
        title: 'Monthly',
        price: propertyData.price_longterm,
        type: 'longterm',
        increment: 'month',
        subtext: 'stay longer than 28 days'
      });
      propertyData.type_yearly && spaceOptions.push({
        title: 'Yearly',
        price: propertyData.price_yearly,
        type: 'yearly',
        increment: 'year',
        subtext: 'stay longer than 1 year'
      });
      propertyData.type_storage && spaceOptions.push({
        title: 'Storage',
        type: 'storage',
        price: propertyData.price_storage,
        increment: 'storage'
      });
      setSpaceTypes(spaceOptions);
      scrollAndSetHeight();
      const user = await getUserDetails(propertyData.ownerID);
      setHostUser(user)
      setLoading(false)
    } else {
      setNotFound(true)
    }
  }

  const updateDates = (v) => {
    setCurrentDateSpan(v);
  };

  async function reactivateProperty() {
    let successfulActivated = await reactivatePropertyMutation(displayProperty.id)
    if (!!successfulActivated) {
      setActiveProperty(true)
    } else { setError(true) }
  };

  async function reactivateSpace() {
    let successfulActivated = await reactivateSpaceMutation(space.id)
    if (!!successfulActivated) {
      const updatedInactiveSpaces = inactiveSpaces.filter((v) => v.id !== space.id);
      const updatedActiveSpaces = [...allActiveSpaces, successfulActivated]
      setSpace[updatedInactiveSpaces[0]];
      setInactiveSpaces(updatedInactiveSpaces);
      setAllActiveSpaces(updatedActiveSpaces);
    } else { setError(true) }
  };

  const setCalendarStart = () => {
    const dt = new Date();
    const year = dt.toLocaleString("default", { year: "numeric" });
    const month = dt.toLocaleString("default", { month: "2-digit" });
    const day = dt.toLocaleString("default", { day: "2-digit" });
    const theDate = year + "-" + month + "-" + day;
    setInitialDate(theDate);
  };

  useEffect(() => {
    setCalendarStart();
  }, []);

  useEffect(() => {
    setSpaceUpdated(false);
  }, []);

  useEffect(() => {
    if (route && route?.params) {
      getPropertyData();
    }
  }, [route?.params?.propertyID]);

  if (notFound) {
    return (
      <Flex
        marginTop={40}
        alignContent="center"
        justifyContent="center"
        alignItems="center"
        direction="column"
      >
        <Text style={{ marginBottom: 40, fontSize: 17 }}>Oh no! That is not a valid website link.</Text>
        <CustomButton
          onPress={goToHome}
          width={mobileView ? width / 1.25 : 300}
          style={[styles.goToHomeButton]}
        >
          <View style={{ flexDirection: "row" }}>
            <Text style={styles.goToHomeButtonText}>check out spaces</Text>
            <FontAwesome5 name="arrow-right" size={mobileView ? 20 : 24} color="#403f3f" />
          </View>
        </CustomButton>
      </Flex>
    )
  }
  return (
    <>
      {loading ? (
        <View style={{ marginTop: 50 }}>
          <LoadingComponent />
        </View>
      ) : (
        <>
          {!!Object.keys(displayProperty) && !!Object.keys(displayProperty).length && (
            <ScrollView ref={scrollRef}>
              <View style={{ marginTop: mobileView ? 5 : 10 }} />
              {editSpace ? (
                <>
                  <BackToDetailedSpace
                    mobileView={mobileView}
                    width={width}
                    cancelEditing={() => setEditSpace(false)}
                    navToAddSpace={() => navigation.reset({ routes: [{ name: 'HostCreateSpaceNavigator' }] })}
                  />
                  <Header level={1.2} marginRight={5} text={`Edit: ${displayProperty.title}`}></Header>
                  <EditMyProperty
                    property={displayProperty}
                    finishUpdate={() => finishUpdate()}
                    mobileView={mobileView}
                    width={width}
                  />
                </>
              ) : (
                <>
                  {!!mySpace && (
                    <View style={globalStyles.center}>
                      {activeProperty ? (
                        <Flex direction="column" alignContent="flex-start" justifyContent={!!mobileView ? "center" : "space-between"} alignItems="flex-start" width={width / 1.05}>
                          <View style={[mobileView ? globalStyles.flexStartColumn : globalStyles.flexStart, { padding: 3 }]}>
                            <View style={mobileView ? globalStyles.flexStartColumn : globalStyles.flexStart}>
                              <CustomButton
                                width={180}
                                backgroundColor={colors.amplifyBlue20}
                                border="#4f7d9e"
                                onPress={() => setEditSpace(true)}
                              >
                                <View style={{ flexDirection: "row", justifyContent: "center", alignItems: "center" }}>
                                  <Entypo name="edit" size={20} color={colors.amplifyNeutral90} />
                                  <Text style={{ fontSize: 16, marginLeft: 7 }}>Edit my Property</Text>
                                </View>
                              </CustomButton>
                              <CustomButton
                                width={180}
                                backgroundColor={colors.mellowOrange}
                                border="#4c4e52"
                                onPress={() => navigation.reset({ routes: [{ name: 'HostCreateSpaceNavigator' }] })}
                              >
                                <View style={{ flexDirection: "row", justifyContent: "center", alignItems: "center" }}>
                                  <MaterialIcons name="add" size={24} color={colors.amplifyNeutral90} />
                                  <Text style={{ fontSize: 16 }}>Add another space</Text>
                                </View>
                              </CustomButton>
                            </View>
                            <CustomButton
                              width={180}
                              backgroundColor={colors.amplifyRed40}
                              border="#4c4e52"
                              onPress={() => setDeactivateOrDeletePropertyModalOpen(true)}
                            >
                              <Text style={{ fontSize: 16, color: colors.amplifyNeutral20 }}>Deactivate or Delete</Text>
                            </CustomButton>
                          </View>
                          {spaceUpdated && (
                            <Flex
                              marginLeft={mobileView ? 5 : 20}
                              marginTop={20}
                              width={width / 1.13}
                              justifyContent="flex-start"
                              alignContent="center"
                              alignItems="center"
                            >
                              <Ionicons name="checkmark-circle" size={40} color={colors.brightBlue} />
                              <Header text="Space updated" color={colors.amplifyNeutral80} />
                            </Flex>
                          )}
                        </Flex>
                      ) : (
                        <Card
                          backgroundColor={colors.amplifyNeutral60}
                          padding={!!mobileView ? 4 : 8}
                          width={width / 1.05}
                        >
                          <Header marginBottom={6} level={1.2} text="PROPERTY IS NOT ACTIVE" color={colors.amplifyNeutral90} />
                          <Flex direction="column" justifyContent="center" alignContent="center" alignItems="center">
                            <CustomButton
                              width={width / 1.2}
                              margin={2}
                              backgroundColor={colors.mellowOrange}
                              onPress={() => reactivateProperty()}
                            >
                              <Header color={"black"} text="Reactivate my Property"></Header>
                            </CustomButton>
                            <CustomButton
                              width={width / 1.2}
                              margin={2}
                              backgroundColor={colors.hookhubOrangeDark}
                              onPress={() => setDeactivateOrDeletePropertyModalOpen(true)}
                            >
                              <Header color={'black'} text="Delete Property"></Header>
                            </CustomButton>
                          </Flex>
                        </Card>
                      )}
                      {!!inactiveSpaces.length && activeProperty && (
                        <View>
                          <Card
                            backgroundColor={colors.amplifyNeutral20}
                            border="#4c4e52"
                            padding={!!mobileView ? 4 : 8}
                            alignItems="flex-start"
                            textAlign="left"
                            width={width / 1.05}
                          >
                            <Header level={.9} text="ONE OR MORE SPACES ARE NOT ACTIVE" color={colors.amplifyNeutral90} />
                            <View style={{ marginTop: 10 }}>
                              {(inactiveSpaces).map((v, i) => (
                                <Badge onPress={() => setSpace(v)} margin={2} variation={space.id === v.id ? "white" : "brandGrey"}>
                                  <View style={globalStyles.flexStart}>
                                    <Ionicons name={space.id === v.id ? "radio-button-on-sharp" : "radio-button-off-sharp"} size={20} color={space.id === v.id ? colors.amplifyNeutral90 : colors.amplifyNeutral80} />
                                    <Text
                                      style={{ marginLeft: 10, fontSize: 17, paddingRight: 10 }}
                                      numberOfLines={1}
                                      ellipsizeMode="tail"
                                    >
                                      {v.title}
                                    </Text>
                                  </View>
                                </Badge>
                              ))}
                            </View>
                            <CustomButton
                              width={200}
                              marginTop={10}
                              backgroundColor={colors.hookhubOrangeDark}
                              onPress={() => reactivateSpace()}
                            >
                              <Text style={{ fontSize: 16, width: 230, textAlign: 'center' }}>
                                Reactivate
                              </Text>
                            </CustomButton>
                          </Card>
                        </View>
                      )}
                    </View>
                  )}
                  <DetailedSpace
                    images={displayProperty?.images || space?.images}
                    property={displayProperty}
                    activeSpaces={allActiveSpaces || allSpaces}
                    signUpModalOpen={signUpModalOpen}
                    setSignUpModalOpen={(v) => setSignUpModalOpen(v)}
                    user={userData}
                    imLoggedIn={imLoggedIn}
                    mySpace={mySpace}
                    updateDates={(v) => updateDates(v)}
                    currentDateSpan={currentDateSpan}
                    displaySpace={displaySpace}
                    setDisplaySpace={(v) => setDisplaySpace(v)}
                    spaceTypes={spaceTypes}
                    hostUser={hostUser}
                    calendarError={calendarError}
                    setCalendarError={(v) => setCalendarError(v)}
                    scrollAndSetHeight={scrollAndSetHeight}
                  />
                  <DeactivateOrDeletePropertyModal
                    property={displayProperty}
                    spaces={allSpaces}
                    mySpaceData={mySpaceData}
                    updateMySpaces={v => updateMySpaces(v)}
                    deleteOrDeactivate={(v) => deleteOrDeactivate(v)}
                    mobileView={mobileView}
                    modalVisible={deactivateOrDeletePropertyModalOpen}
                    setModalVisible={() => setDeactivateOrDeletePropertyModalOpen(!deactivateOrDeletePropertyModalOpen)}
                    setActiveProperty={() => setActiveProperty(false)}
                    setActiveSpace={(v) => setActiveSpace(v)}
                    allActiveSpaces={allActiveSpaces}
                    inactiveSpaces={inactiveSpaces}
                    setAllActiveSpaces={(v) => setAllActiveSpaces(v)}
                    setInactiveSpaces={(v) => setInactiveSpaces(v)}
                  />
                </>
              )}
              <FooterView />
              {mobileView && <View style={{ paddingBottom: 20, backgroundColor: colors.amplifyNeutral20 }} />}
            </ScrollView>
          )}
          {mobileView && hostUser && !mySpace && (
            <View style={[styles.footer, { width: width, height: mobileReserveCardHeight, bottom: "6vh" }]}>
              <ReserveCard
                mobileView={mobileView}
                signUpModalOpen={signUpModalOpen}
                setSignUpModalOpen={(v) => setSignUpModalOpen(v)}
                userData={userData?.userData}
                mySpace={mySpace}
                hostEmail={hostUser.email}
                hostID={hostUser.id}
                stripeConnectedAccountId={hostUser.stripeConnectedAccountId}
                displaySpace={displaySpace}
                setDisplaySpace={(v) => setDisplaySpace(v)}
                displayProperty={displayProperty}
                propertyID={displayProperty.id}
                propertyTitle={displayProperty.title}
                setSelectedDateSpan={(v) => updateDates(v)}
                currentDateSpan={currentDateSpan}
                spaceTypes={spaceTypes}
                scrollToTop={() => scrollAndSetHeight()}
                initialDate={initialDate}
                error={calendarError}
                paddingBottom={mobileReserveCardHeight / 2}
                activeSpaces={allSpaces}
              />
            </View>
          )}
          <SignUpModal
            authenticate={() => authenticate()}
            modalVisible={signUpModalOpen}
            setModalVisible={() => setSignUpModalOpen(false)}
            mobileView={mobileView}
            width={width}
          />
        </>
      )}
    </>
  )

}

const styles = StyleSheet.create({
  footer: {
    position: "fixed",
    borderWidth: .2,
    borderColor: colors.amplifyNeutral80,
    width: "100%",
    backgroundColor: colors.white,
    flexDirection: "row",
    alignItems: "flex-start",
    alignContent: "center",
    justifyContent: "center",
    alignSelf: "center",
    paddingTop: 5
  },
  page: {
    alignContent: "center",
    justifyContent: "center",
    alignItems: "center",
    marginTop: 30
  },
  goToHomeButtonText: {
    color: "#303030",
    fontSize: 17,
    marginRight: 20
  },
  goToHomeButton: {
    borderColor: "grey",
    borderRadius: 10,
    marginTop: 40,
    borderWidth: 1,
    backgroundColor: "#c4833d",
    alignItems: "center",
    borderBottomWidth: 2,
    justifyContent: "center",
    alignSelf: "center",
    height: 40,
    padding: 20,
    shadowColor: "#252625",
    shadowOffset: { width: -2, height: 3 },
    shadowOpacity: 0.5,
  },
});

export default SpaceScreen;
