
export const sendEmail = ({emailAddress, emailBody, emailSubject}) => {
    const requestOptions = {
        method: 'POST',
        body: JSON.stringify({
            emailBody,
            emailAddress,
            emailSubject
        })
    };
    fetch('https://wyxlsoge347lul7liaselbwmsa0jpgbn.lambda-url.us-west-2.on.aws/', requestOptions)
        .then(response => response.json())
        .then(data => {
            console.log("email sent: success");
        }).catch((e) => {
            console.log("ERROR sending email >>   ", e)
            setError(true);
        })
}