import React, { useState } from "react";
import {
  Text, View, useWindowDimensions,
} from "react-native";
import Flex from "../CustomElements/Flex"
import Card from "../CustomElements/Card"
import Header from "../CustomElements/Header"
import { useSelector } from "react-redux";
import CustomTextInput from "../CustomElements/CustomTextInput";
import { validateNumbersOnly } from "../../utils/fieldValidators";
import { CustomNumberInput } from "../CustomElements/CustomNumberInput";

const SetDistances = ({ val, setVal, title, icon, direction = "column", compact = false }) => {
  const [greaterThan50, setgreaterThan50] = useState(false);
  const { mobileView } = useSelector(state => state.currentUser);
  const { width } = useWindowDimensions();
  const cardWidth = compact ? (200) : (!!mobileView ? width / 1.3 : width / 3.3)
  const innerWidth = compact ? (195) : (!!mobileView ? width / 1.35 : width / 3.4)

  return (
    <Card
      backgroundColor="#fff"
      width={cardWidth}
      padding={!mobileView ? 9 : 20}
      alignItems="center"
      textAlign="center"
    >
      <Flex
        direction={direction}
        alignItems="center"
        justifyContent="center"
        alignContent="center"
        width={innerWidth}
      >
        {icon}
        {mobileView ? <Text style={{ textAlign: "center" }}>{title}</Text> : <Header text={title} textAlign="center" />}
        <Text style={{ fontStyle: "none" }}>in feet</Text>
        <View style={{ marginTop: 20 }}>
          <CustomNumberInput
            value={val}
            setValue={(v) => setVal(v)}
          />
        </View>
      </Flex>
    </Card>
  );
};

export default SetDistances;
